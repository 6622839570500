// src/pages/ForecastDashboard.jsx v1.0.1
import React from 'react'
import { 
  Card, 
  Button, 
  Alert, 
  Typography,
  Grid,
  Box,
  Container,
  Paper
} from '@mui/material'
import { ResponsiveLine } from '@nivo/line'
import { useDashboardTheme } from '../hooks/useDashboardTheme'
import {
  Warning as AlertCircle,
  CheckCircle as CheckCircle2,
  Cancel as XCircle,
  BarChart as BarChart4,
  Psychology as Brain,
  ShowChart as Activity,
  Report as FileWarning,
  TrendingUp as ArrowUp,
  AttachMoney as CircleDollarSign
} from '@mui/icons-material'
import { Link } from 'react-router-dom'

// Sample data for the forecast dashboard
const sampleData = {
  currentMRR: 125000,
  currentARR: 1500000,
  forecastedDeals: {
    totalCount: 45,
    totalValue: 2750000,
    highConfidence: 18,
    mediumConfidence: 15,
    atRisk: 12,
  },
  monthlyForecast: [
    { month: 'Dec', baseline: 125000, optimistic: 135000, pessimistic: 120000, actual: 128000 },
    { month: 'Jan', baseline: 145000, optimistic: 160000, pessimistic: 135000 },
    { month: 'Feb', baseline: 160000, optimistic: 180000, pessimistic: 145000 },
    { month: 'Mar', baseline: 175000, optimistic: 200000, pessimistic: 155000 },
    { month: 'Apr', baseline: 190000, optimistic: 220000, pessimistic: 165000 },
    { month: 'May', baseline: 210000, optimistic: 245000, pessimistic: 180000 },
  ],
  predictedOutcomes: [
    { id: 1, name: "Enterprise Platform Deal", value: 450000, probability: 85, closeDate: "2024-01-15" },
    { id: 2, name: "Mid-Market SaaS Bundle", value: 120000, probability: 65, closeDate: "2024-01-30" },
    { id: 3, name: "SMB Annual Contract", value: 75000, probability: 45, closeDate: "2024-02-15" },
  ],
  riskFactors: {
    seasonality: -5,
    marketConditions: -8,
    competitorActivity: -3,
    teamCapacity: 2,
    productUpdates: 5,
  }
}

function DemoForecast() {
  const { colors, isDark } = useDashboardTheme()
  
  const themeColors = {
    background: colors.background,
    cardBg: colors.card.background,
    cardHover: colors.card.hover,
    text: colors.text,
    chart: {
      baseline: colors.chart.baseline,
      optimistic: colors.chart.optimistic,
      pessimistic: colors.chart.pessimistic,
      actual: colors.chart.actual,
      deals: colors.chart.deals,
      contacts: colors.chart.contacts,
      score: colors.chart.score,
      grid: colors.chart.grid
    },
    status: colors.status,
    border: colors.card.border,
    progressBar: colors.progressBar,
    cta: colors.cta
  }

  const formatCurrency = (value) => 
    new Intl.NumberFormat('en-US', { 
      style: 'currency', 
      currency: 'USD',
      maximumFractionDigits: 0 
    }).format(value)

  const getConfidenceColor = (probability) => {
    if (probability >= 75) return themeColors.status.success
    if (probability >= 50) return themeColors.status.warning
    return themeColors.status.error
  }

  const tooltipFormatter = (value) => {
    if (typeof value === 'number') {
      return formatCurrency(value)
    }
    return value
  }

  const getCommonChartProperties = (chartType) => ({
    margin: { top: 50, right: 130, bottom: 50, left: 60 },
    animate: true,
    enableGridX: false,
    enableGridY: true,
    theme: {
      grid: {
        line: {
          stroke: themeColors.chart.grid,
          strokeWidth: 1,
          strokeDasharray: '4 4'
        }
      },
      axis: {
        ticks: {
          text: {
            fill: themeColors.text.secondary
          }
        }
      },
      tooltip: {
        container: {
          background: themeColors.cardBg,
          border: `1px solid ${themeColors.border}`,
          borderRadius: '4px',
          padding: '8px'
        }
      }
    }
  })

  const getCustomTooltip = (data) => ({
    title: data.month,
    items: [
      { label: 'Baseline', value: data.baseline, formatType: 'currency' },
      { label: 'Optimistic', value: data.optimistic, formatType: 'currency' },
      { label: 'Pessimistic', value: data.pessimistic, formatType: 'currency' },
      ...(data.actual ? [{ label: 'Actual', value: data.actual, formatType: 'currency' }] : [])
    ]
  })

  // Transform data for Nivo
  const chartData = [
    {
      id: 'baseline',
      color: themeColors.chart.baseline,
      data: sampleData.monthlyForecast.map(d => ({
        x: d.month,
        y: d.baseline
      }))
    },
    {
      id: 'optimistic',
      color: themeColors.chart.optimistic,
      data: sampleData.monthlyForecast.map(d => ({
        x: d.month,
        y: d.optimistic
      }))
    },
    {
      id: 'pessimistic',
      color: themeColors.chart.pessimistic,
      data: sampleData.monthlyForecast.map(d => ({
        x: d.month,
        y: d.pessimistic
      }))
    },
    ...(sampleData.monthlyForecast.some(d => d.actual) ? [{
      id: 'actual',
      color: themeColors.chart.actual,
      data: sampleData.monthlyForecast
        .filter(d => d.actual)
        .map(d => ({
          x: d.month,
          y: d.actual
        }))
    }] : [])
  ]

  return (
    <Box sx={{ 
      bgcolor: themeColors.background,
      p: 3 
    }}>
      <Container maxWidth="lg">
        {/* Header with Key Metrics */}
        <Box sx={{ mb: 4 }}>
          <Typography 
            variant="h4" 
            sx={{ 
              mb: 2, 
              fontWeight: 'bold', 
              color: themeColors.text.primary
            }}
          >
            AI-Powered Revenue Forecast
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Card sx={{ 
                p: 2,
                bgcolor: themeColors.cardBg,
                border: isDark ? `1px solid ${themeColors.border}` : 'none',
                transition: 'background-color 0.3s',
                '&:hover': {
                  bgcolor: themeColors.cardHover
                }
              }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box>
                    <Typography variant="body2" color="text.secondary">Current MRR</Typography>
                    <Typography variant="h6" fontWeight="bold" color="text.primary">
                      {formatCurrency(sampleData.currentMRR)}
                    </Typography>
                  </Box>
                  <CircleDollarSign sx={{ 
                    fontSize: 32, 
                    color: themeColors.chart.baseline
                  }} />
                </Box>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box>
                    <Typography variant="body2" color="text.secondary">Projected MRR</Typography>
                    <Typography variant="h6" fontWeight="bold" color="success.main">
                      {formatCurrency(sampleData.monthlyForecast[5].baseline)}
                    </Typography>
                  </Box>
                  <ArrowUp sx={{ fontSize: 32, color: 'success.main' }} />
                </Box>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box>
                    <Typography variant="body2" color="text.secondary">Current ARR</Typography>
                    <Typography variant="h6" fontWeight="bold">
                      {formatCurrency(sampleData.currentARR)}
                    </Typography>
                  </Box>
                  <BarChart4 sx={{ fontSize: 32, color: 'purple.main' }} />
                </Box>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box>
                    <Typography variant="body2" color="text.secondary">Pipeline Coverage</Typography>
                    <Typography variant="h6" fontWeight="bold">3.2x</Typography>
                  </Box>
                  <Activity sx={{ fontSize: 32, color: 'indigo.main' }} />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>

        {/* AI Insights Alert */}
        <Alert 
          icon={<Brain />}
          severity="info"
          sx={{ 
            mb: 3,
            bgcolor: isDark ? 'rgba(54, 181, 173, 0.1)' : undefined,
            border: isDark ? `1px solid ${themeColors.border}` : undefined,
            '& .MuiAlert-icon': {
              color: isDark ? themeColors.chart.baseline : undefined
            }
          }}
        >
          <Typography>
            AI Analysis: Based on current trends and market conditions, we predict a
            <Box component="span" sx={{ fontWeight: 'bold' }}> 68% probability </Box>
            of exceeding the baseline forecast by 15% or more.
          </Typography>
        </Alert>

        {/* Revenue Forecast Chart */}
        <Card sx={{ 
          p: 2, 
          mb: 6,
          bgcolor: themeColors.cardBg,
          border: isDark ? `1px solid ${themeColors.border}` : 'none'
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
            <Typography 
              variant="h6" 
              fontWeight="semibold"
              color="text.primary"
            >
              6-Month Revenue Forecast
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ 
                  width: 10, 
                  height: 10, 
                  bgcolor: themeColors.chart.baseline,
                  borderRadius: '50%', 
                  mr: 1 
                }} />
                <Typography variant="body2" color="text.secondary">Baseline</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: 10, height: 10, bgcolor: themeColors.chart.optimistic, borderRadius: '50%', mr: 1 }} />
                <Typography variant="body2" color="text.secondary">Optimistic</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: 10, height: 10, bgcolor: themeColors.chart.pessimistic, borderRadius: '50%', mr: 1 }} />
                <Typography variant="body2" color="text.secondary">Pessimistic</Typography>
              </Box>
            </Box>
          </Box>
          <div style={{ height: 256 }}>
            <ResponsiveLine
              {...getCommonChartProperties('line')}
              data={chartData}
              colors={({ id }) => {
                switch (id) {
                  case 'baseline':
                    return themeColors.chart.baseline;
                  case 'optimistic':
                    return themeColors.chart.optimistic;
                  case 'pessimistic':
                    return themeColors.chart.pessimistic;
                  case 'actual':
                    return themeColors.chart.actual;
                  default:
                    return themeColors.chart.primary;
                }
              }}
              margin={{ top: 20, right: 20, bottom: 40, left: 60 }}
              xScale={{ type: 'point' }}
              yScale={{ 
                type: 'linear',
                min: 'auto',
                max: 'auto'
              }}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0
              }}
              tooltip={getCustomTooltip}
            />
          </div>
        </Card>

        {/* Predicted Deal Outcomes */}
        <Card sx={{ p: 2, mb: 6 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
            <Brain sx={{ fontSize: 24, color: 'purple.main', mr: 2 }} />
            <Typography variant="h6" fontWeight="semibold">AI-Predicted Deal Outcomes</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {sampleData.predictedOutcomes.map((deal) => (
              <Box key={deal.id} sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #e0e0e0', pb: 2 }}>
                <Box>
                  <Typography variant="body1" fontWeight="medium">{deal.name}</Typography>
                  <Typography variant="body2" color="text.secondary">Close Date: {deal.closeDate}</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Box sx={{ textAlign: 'right' }}>
                    <Typography variant="body1" fontWeight="medium">{formatCurrency(deal.value)}</Typography>
                    <Typography variant="body2" color={getConfidenceColor(deal.probability)}>
                      {deal.probability}% Confidence
                    </Typography>
                  </Box>
                  {deal.probability >= 75 ? (
                    <CheckCircle2 sx={{ fontSize: 24, color: 'success.main' }} />
                  ) : deal.probability >= 50 ? (
                    <AlertCircle sx={{ fontSize: 24, color: 'warning.main' }} />
                  ) : (
                    <XCircle sx={{ fontSize: 24, color: 'error.main' }} />
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        </Card>

        {/* Risk Factors */}
        <Card sx={{ p: 2, mb: 8 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
            <FileWarning sx={{ fontSize: 24, color: 'warning.main', mr: 2 }} />
            <Typography variant="h6" fontWeight="semibold">Risk Factor Analysis</Typography>
          </Box>
          <Grid container spacing={2}>
            {Object.entries(sampleData.riskFactors).map(([factor, impact]) => (
              <Grid item xs={2} key={factor}>
                <Typography variant="body2" color="text.secondary">{factor.split(/(?=[A-Z])/).join(' ')}</Typography>
                <Typography variant="h6" color={impact > 0 ? 'success.main' : 'error.main'}>
                  {impact > 0 ? '+' : ''}{impact}%
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Card>

        {/* CTA Section */}
        <Paper
          elevation={0}
          sx={{ 
            p: 8,
            borderRadius: 2,
            bgcolor: themeColors.cta.background,
            border: `1px solid ${themeColors.cta.border}`,
            textAlign: 'center',
            mb: 3,
            backdropFilter: isDark ? 'blur(8px)' : 'none',
          }}
        >
          <Brain 
            sx={{ 
              color: themeColors.cta.text,
              fontSize: 48,
              mb: 4
            }} 
          />
          
          <Typography 
            variant="h4" 
            sx={{ 
              fontWeight: 700,
              fontSize: '1.875rem',
              color: themeColors.cta.text,
              mb: 2
            }}
          >
            Want More Accurate Revenue Predictions?
          </Typography>
          
          <Typography 
            variant="body1" 
            sx={{ 
              color: themeColors.cta.text,
              mb: 6,
              maxWidth: '600px',
              mx: 'auto',
              lineHeight: 1.5
            }}
          >
            Our AI-powered forecasting model can help you make data-driven decisions 
            with up to 95% accuracy. Schedule a demo to see how we can enhance your 
            revenue forecasting.
          </Typography>

          <Link 
            to="/contact/sales" 
            style={{ textDecoration: 'none' }}
          >
            <Button
              variant="contained"
              size="large"
              sx={{
                px: 8,
                py: 2,
                minWidth: '320px',
                fontSize: '1.1rem',
                bgcolor: themeColors.cta.button,
                color: isDark ? '#000000' : '#FFFFFF',
                '&:hover': {
                  bgcolor: themeColors.cta.buttonHover,
                },
                boxShadow: 'none',
                textTransform: 'uppercase',
                fontWeight: 600,
                letterSpacing: '0.5px'
              }}
            >
              Sign Up for a Demo
            </Button>
          </Link>
        </Paper>
      </Container>
    </Box>
  )
}

export default DemoForecast
