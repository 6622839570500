export const hubspotStages = {
    '38999577': 'New',
    '6543965': 'Qualified',
    '6543967': 'Proposal',
    '6543970': 'Closed Won',
    '16522630': 'Closed Lost',
    '16561663': 'Unqualified'
  };
  
  export const getStageColor = (stageId) => {
    const colors = {
      'New': '#FF6B6B',
      'Qualified': '#4ECDC4',
      'Proposal': '#45B7D1',
      'Closed Won': '#66BB6A',
      'Closed Lost': '#FF0000',
      'Unqualified': '#FFA07A'
    };
    return colors[hubspotStages[stageId]] || '#999999';
  };