import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { usePipeline } from '../../../contexts/PipelineContext';
import { Box, Typography } from '@mui/material';
import { stageOrder, getStageInfo } from '../../../contexts/utils/dealStages';

const DealTypeDistribution = () => {
  const {
    dealTypeDistribution,
    getCommonChartProperties,
    isMobile,
    getCustomTooltip,
    formatCurrency,
    formatPercentage,
    theme,
  } = usePipeline();

  if (!dealTypeDistribution || dealTypeDistribution.length === 0) {
    return <Typography>No deal type distribution data available.</Typography>;
  }

  // Sort and process the chartData
  const chartData = stageOrder
    .filter(stage => dealTypeDistribution.some(item => item.id === stage))
    .map(stage => {
      const stageData = dealTypeDistribution.find(item => item.id === stage);
      const { color } = getStageInfo(stage);
      console.log(`Stage: ${stage}, Color: ${color}`); // Add this log
      return {
        id: stage,
        value: stageData ? stageData.value : 0,
        percentage: stageData ? stageData.percentage : 0,
        color: color || '#999999' // Add a fallback color
      };
    })
    .filter(item => item.value > 0); // Remove stages with no value

  console.log('Chart Data:', chartData); // Add this log

  const getDealTypeDistributionTooltip = (datum) => ({
    title: datum.id,
    items: [
      { label: 'Value', value: datum.value, formatType: 'currency' },
    ],
    comparisonItem: {
      label: 'Percentage',
      value: datum.percentage,
      formatType: 'percentage',
    },
  });

  const chartProps = {
    ...getCommonChartProperties('bar'),
    data: chartData,
    keys: ['value'],
    indexBy: 'id',
    margin: { top: 50, right: 130, bottom: 50, left: 60 },
    padding: 0.3,
    valueScale: { type: 'linear' },
    indexScale: { type: 'band', round: true },
    colors: ({ data }) => {
      console.log('Color function data:', data); // Add this log
      return data.color || '#999999'; // Add a fallback color
    },
    borderColor: { from: 'color', modifiers: [['darker', 1.6]] },
    axisTop: null,
    axisRight: null,
    axisBottom: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Deal Type',
      legendPosition: 'middle',
      legendOffset: 32,
    },
    axisLeft: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Value',
      legendPosition: 'middle',
      legendOffset: -40,
      format: value => formatCurrency(value, { notation: 'compact' }),
    },
    labelSkipWidth: 12,
    labelSkipHeight: 12,
    labelTextColor: { from: 'color', modifiers: [['darker', 1.6]] },
    legends: isMobile ? [] : [
      {
        dataFrom: 'indexes',
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1
            }
          }
        ]
      }
    ],
    tooltip: ({ data }) => getCustomTooltip(getDealTypeDistributionTooltip(data)),
  };

  return (
    <Box sx={{ height: 400 }}>
      <ResponsiveBar {...chartProps} />
    </Box>
  );
};

export default DealTypeDistribution;