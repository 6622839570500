import React, { useState } from 'react';
import { Box, Typography, Grid, Paper, useTheme, Tabs, Tab } from "@mui/material";
import { useAuth } from '../../contexts/AuthContext';
import { useHubSpot } from '../../contexts/HubSpotContext';

const ProfileDashboard = () => {
  const { currentUser, userData } = useAuth();
  const { hubspotConnected, hubspotOwnerData } = useHubSpot();
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ 
      p: 3, 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      gap: 2,
      backgroundColor: theme.palette.background.default,
    }}>
      {/* Header */}
      <Typography variant="h4" gutterBottom color={theme.palette.text.primary}>Affiliate Dashboard</Typography>
      <Typography variant="subtitle1" gutterBottom color={theme.palette.secondary.main}>https://affiliates.heylieu.com</Typography>

      {/* Main Stats */}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {['Total Profit', 'Average Conversion Rate', 'Active Affiliates'].map((title) => (
          <Grid item xs={12} sm={4} key={title}>
            <Paper sx={{ 
              p: 2, 
              textAlign: 'center', 
              height: '100%', 
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
            }}>
              <Typography variant="h6" color={theme.palette.secondary.main}>{title}</Typography>
              <Typography variant="h4">Placeholder</Typography>
              <Typography variant="body2" color={theme.palette.text.secondary}>06/23/2024 - 07/22/2024</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Secondary Stats */}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {['Affiliate Sales', 'New Active Affiliates', 'Conversion Rate'].map((title) => (
          <Grid item xs={12} sm={4} key={title}>
            <Paper sx={{ 
              p: 2, 
              height: '100%', 
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
            }}>
              <Typography variant="h6" color={theme.palette.secondary.main}>{title}</Typography>
              <Typography variant='body1'>Placeholder for {title} component</Typography>
              <Typography variant="body2" color={theme.palette.text.secondary}>Additional info placeholder</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Top Affiliates, Visitors/Leads/Customers/Purchases, Actions Needed, and Recent Events */}
      <Grid container spacing={2} sx={{ mb: 2, flexGrow: 1 }}>
        <Grid item xs={12} md={8} spacing={2}>
          <Paper sx={{ 
            p: 2, 
            height: '100%', 
            mb: 2,
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            display: 'flex',
            flexDirection: 'column',
          }}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6" color={theme.palette.secondary.main}>Top Affiliates</Typography>
              <Typography>Placeholder for Top Affiliates table component. This should display a table with columns for Affiliate, Sales, Conversion, and New Customers. If no active affiliates, it should show "No Active Affiliates".</Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h6" color={theme.palette.secondary.main}>Visitors, Leads, Customers, Purchases</Typography>
              <Tabs value={tabValue} onChange={handleTabChange} sx={{ mb: 2 }}>
                <Tab label="Visitors" />
                <Tab label="Leads" />
                <Tab label="Customers" />
                <Tab label="Purchases" />
              </Tabs>
              <Box>
                {tabValue === 0 && <Typography>Placeholder for Visitors chart</Typography>}
                {tabValue === 1 && <Typography>Placeholder for Leads chart</Typography>}
                {tabValue === 2 && <Typography>Placeholder for Customers chart</Typography>}
                {tabValue === 3 && <Typography>Placeholder for Purchases chart</Typography>}
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} spacing={2}>
          <Grid container direction="column" spacing={2} sx={{ height: '100%' }}>
            <Grid item xs={6}>
              <Paper sx={{ 
                p: 2, 
                height: '100%', 
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
              }}>
                <Typography variant="h6" color={theme.palette.secondary.main}>Actions Needed</Typography>
                <Typography>Placeholder for Actions Needed component</Typography>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper sx={{ 
                p: 2, 
                height: '100%', 
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
              }}>
                <Typography variant="h6" color={theme.palette.secondary.main}>Recent Events</Typography>
                <Typography>Placeholder for Recent Events component. This should show a list of recent activities, including visits and their sources, with timestamps.</Typography>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfileDashboard;