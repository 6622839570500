import React, { createContext, useContext } from 'react';
import { app, auth, db, functions, analytics, storage } from '../firebaseConfig';
import { doc, onSnapshot } from 'firebase/firestore';
import logger from '../utils/frontendLogger';

const FirebaseContext = createContext(null);

export const useFirebase = () => useContext(FirebaseContext);

export const FirebaseProvider = ({ children }) => {
  const firebase = {
    app,
    auth,
    db,
    functions,
    analytics,
    storage,
    firestore: {
      doc,
      onSnapshot
    }
  };

  logger.info('FirebaseProvider initialized', { 
    hasDb: !!firebase.db,
    hasAuth: !!firebase.auth,
    hasFunctions: !!firebase.functions,
    hasStorage: !!firebase.storage
  });

  return (
    <FirebaseContext.Provider value={firebase}>
      {children}
    </FirebaseContext.Provider>
  );
};