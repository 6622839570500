import { useTheme } from '@mui/material';
import { dashboardTheme } from '../theme/dashboardTheme';

export const useDashboardTheme = () => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const colors = dashboardTheme.tokens(isDark ? 'dark' : 'light');

  const greyPalette = {};
  Object.entries(theme.palette.grey).forEach(([key, value]) => {
    greyPalette[key] = value;
  });

  const healthScore = {
    colors: {
      success: {
        main: isDark ? '#33D3B8' : '#00C0A0',  // turquoise : mint
        light: '#66E6D1',                       // turquoiseLight
      },
      warning: {
        main: isDark ? '#FF4400' : '#FEA58E',   // hubspotOrange : melon
      },
      error: {
        main: '#FF4400',                        // hubspotOrange
      },
      primary: {
        main: isDark ? '#BABDE2' : '#8285A1',   // periwinkle : coolGray
      },
      secondary: {
        main: isDark ? '#33D3B8' : '#00C0A0',   // turquoise : mint
      },
      grey: greyPalette,
    },
    charts: {
      deals: colors.chart.deals,
      contacts: colors.chart.contacts,
      score: colors.chart.score,
    },
    status: colors.status,
    progressBars: {
      background: colors.progressBar.background,
      deals: colors.progressBar.primary,
      contacts: colors.progressBar.secondary,
    },
    isDark,
  };

  return {
    colors,
    healthScore,
    components: dashboardTheme.components,
    isDark,
  };
};