import React from 'react';
import { Box, Typography, TextField, Button, useTheme } from '@mui/material';
import { useChartConfig } from '../../components/hooks/useChartConfig';

const UserInfo = () => {
  const theme = useTheme();
  const { isMobile } = useChartConfig();

  return (
    <Box sx={{ 
      backgroundColor: theme.palette.background.paper, 
      p: isMobile ? 2 : 3,
      color: theme.palette.text.primary
    }}>
      <Typography variant={isMobile ? "h5" : "h3"} mb={2}>
        Profile Information
      </Typography>
      <TextField
        fullWidth
        label="Name"
        margin="normal"
        sx={{ 
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: theme.palette.divider,
            },
            '&:hover fieldset': {
              borderColor: theme.palette.action.hover,
            },
            '&.Mui-focused fieldset': {
              borderColor: theme.palette.secondary.main,
            },
          },
          '& .MuiInputLabel-root': {
            color: theme.palette.text.secondary,
          },
        }}
      />
      <TextField
        fullWidth
        label="Email"
        margin="normal"
        sx={{ 
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: theme.palette.divider,
            },
            '&:hover fieldset': {
              borderColor: theme.palette.action.hover,
            },
            '&.Mui-focused fieldset': {
              borderColor: theme.palette.secondary.main,
            },
          },
          '& .MuiInputLabel-root': {
            color: theme.palette.text.secondary,
          },
        }}
      />
      <TextField
        fullWidth
        label="Company"
        margin="normal"
        sx={{ 
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: theme.palette.divider,
            },
            '&:hover fieldset': {
              borderColor: theme.palette.action.hover,
            },
            '&.Mui-focused fieldset': {
              borderColor: theme.palette.secondary.main,
            },
          },
          '& .MuiInputLabel-root': {
            color: theme.palette.text.secondary,
          },
        }}
      />
      <Button
        variant="contained"
        sx={{ 
          mt: 2, 
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
          }
        }}
      >
        Save Changes
      </Button>
    </Box>
  );
};

export default UserInfo;