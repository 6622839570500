// src/components/sales/HealthcheckDashboard/DealQualityChart.jsx
import React, { useMemo } from 'react';
import { useRawData } from '../../../contexts/RawDataContext';
import { Paper, Typography, useTheme, Box } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { useChartStyles } from '../../hooks/useChartStyles';

const DealQualityChart = () => {
  const { deals, formatPercentage } = useRawData();
  const theme = useTheme();
  const { getCommonChartProperties, getColor } = useChartStyles();

  const qualityRanges = [
    { min: 0, max: 20, label: '0-20%' },
    { min: 21, max: 40, label: '21-40%' },
    { min: 41, max: 60, label: '41-60%' },
    { min: 61, max: 80, label: '61-80%' },
    { min: 81, max: 100, label: '81-100%' },
  ];

  const dealQualityData = useMemo(() => {
    const distribution = qualityRanges.map(range => ({
      range: range.label,
      count: 0,
      color: getColor({ data: { probability: (range.min + range.max) / 2 } }),
    }));

    let totalQuality = 0;
    let totalDeals = 0;

    deals.forEach(deal => {
      if (deal.hs_deal_stage_probability !== undefined) {
        const quality = deal.hs_deal_stage_probability * 100;
        const rangeIndex = qualityRanges.findIndex(range => quality >= range.min && quality <= range.max);
        if (rangeIndex !== -1) {
          distribution[rangeIndex].count++;
          totalQuality += quality;
          totalDeals++;
        }
      }
    });

    const averageQuality = totalDeals > 0 ? totalQuality / totalDeals : 0;

    return {
      distribution,
      averageQuality,
    };
  }, [deals, getColor, qualityRanges]);

  const chartProperties = getCommonChartProperties('bar');

  const CustomTooltip = ({ id, value, color }) => (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        padding: theme.spacing(1, 1.5),
        border: `1px solid ${color}`,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[3],
      }}
    >
      <Typography variant="subtitle2" sx={{ color, fontWeight: 'bold' }}>
        {id}
      </Typography>
      <Typography variant="body2">Count: {value}</Typography>
    </Box>
  );

  return (
    <Paper sx={{ p: 3, height: '100%', backgroundColor: theme.palette.background.paper }}>
      <Typography variant="h6" gutterBottom color="text.primary">
        Deal Quality Distribution
      </Typography>
      <div style={{ height: 300 }}>
        <ResponsiveBar
          {...chartProperties}
          data={dealQualityData.distribution}
          keys={['count']}
          indexBy="range"
          colors={({ data }) => data.color}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisBottom={{
            ...chartProperties.axisBottom,
            legend: 'Quality Range',
          }}
          axisLeft={{
            ...chartProperties.axisLeft,
            legend: 'Number of Deals',
          }}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          tooltip={({ id, value, color }) => (
            <CustomTooltip id={id} value={value} color={color} />
          )}
          annotations={[
            {
              type: 'line',
              match: { key: 'count' },
              value: dealQualityData.averageQuality,
              lineStyle: { stroke: theme.palette.secondary.main, strokeWidth: 2 },
              legendOrientation: 'vertical',
              legend: `Avg: ${formatPercentage(dealQualityData.averageQuality / 100)}`,
            },
          ]}
          role="application"
          ariaLabel="Deal Quality Distribution"
          barAriaLabel={e => `${e.indexValue}: ${e.value} deals`}
          legends={[]} // Add this line to remove the legend
        />
      </div>
    </Paper>
  );
};

export default DealQualityChart;
