// src/contexts/utils/pipelineProcessing.js v1.0.0

import { format, parseISO, startOfYear, endOfYear, eachMonthOfInterval } from 'date-fns';
import { getCustomStageFromProbability, getStageInfo, stageOrder } from './dealStages';

const filterOpenDeals = (deals) => deals.filter(deal => deal.customStage === 'open');

export const processPipelineData = (deals) => {
  const openDeals = filterOpenDeals(deals);

  return {
    allDeals: deals,
    openDeals,
    dealValueOverTime: processDealValueOverTime(deals),
    dealSizeScatterPlot: processDealSizeScatterPlot(openDeals),
    pipelineFunnel: processPipelineFunnel(openDeals),
    dealTypeDistribution: processDealTypeDistribution(openDeals),
    topDeals: processTopDeals(openDeals),
    dealAgeDistribution: processDealAgeDistribution(openDeals),
    dealAttentionNeeds: processDealAttentionNeeds(openDeals),
    winProbabilityHeatmap: processWinProbabilityHeatmap(deals),
  };
};

export const processDealValueOverTime = (deals) => {
  const { currentYear, previousYear } = getCurrentAndPreviousYear();
  const monthlyData = { [currentYear]: {}, [previousYear]: {} };

  eachMonthOfInterval({
    start: startOfYear(new Date(previousYear, 0, 1)),
    end: endOfYear(new Date(currentYear, 0, 1))
  }).forEach(month => {
    const year = month.getFullYear();
    const monthKey = format(month, 'MMM');
    if (year === currentYear || year === previousYear) {
      monthlyData[year][monthKey] = 0;
    }
  });

  deals.forEach(deal => {
    if (deal.closedate) {
      const closeDate = parseISO(deal.closedate);
      const year = closeDate.getFullYear();
      const month = format(closeDate, 'MMM');
      const amount = parseFloat(deal.amount) || 0;

      if (year === currentYear || year === previousYear) {
        monthlyData[year][month] = (monthlyData[year][month] || 0) + amount;
      }
    }
  });

  return [
    {
      id: `${currentYear} Deal Value`,
      data: Object.entries(monthlyData[currentYear]).map(([month, value]) => ({ x: month, y: value })),
    },
    {
      id: `${previousYear} Deal Value`,
      data: Object.entries(monthlyData[previousYear]).map(([month, value]) => ({ x: month, y: value })),
    }
  ];
};

const processDealSizeScatterPlot = (openDeals) => [{
  id: 'Open Deals',
  data: openDeals.map(deal => ({
    x: deal.daysOpen || 0,
    y: parseFloat(deal.amount) || 0,
    probability: parseFloat(deal.hs_deal_stage_probability) || 0,
    dealName: deal.dealname || 'Unnamed Deal',
  }))
}];

export const processPipelineFunnel = (openDeals) => {
  const stageData = openDeals.reduce((acc, deal) => {
    const customStage = getCustomStageFromProbability(deal.hs_deal_stage_probability);
    const { name, color } = getStageInfo(customStage);
    if (!acc[name]) acc[name] = { value: 0, dealCount: 0, color };
    acc[name].value += parseFloat(deal.amount) || 0;
    acc[name].dealCount += 1;
    return acc;
  }, {});

  return stageOrder
    .map(stage => {
      const data = stageData[stage] || { value: 0, dealCount: 0, color: getStageInfo(stage).color };
      return {
        id: stage,
        label: stage,
        value: data.value,
        dealCount: data.dealCount,
        color: data.color,
      };
    })
    .filter(stage => stage.value > 0);
};

export const processDealTypeDistribution = (openDeals) => {
  const typeDistribution = openDeals.reduce((acc, deal) => {
    const probability = parseFloat(deal.hs_deal_stage_probability);
    if (isNaN(probability)) {
      console.warn(`Invalid probability for deal: ${deal.id}`);
      return acc;
    }

    const dealType = getCustomStageFromProbability(probability);
    const stageInfo = getStageInfo(dealType);

    if (!stageInfo) {
      console.warn(`Invalid stage info for deal type: ${dealType}, deal: ${deal.id}`);
      return acc;
    }

    const { color } = stageInfo;

    if (!acc[dealType]) {
      acc[dealType] = { value: 0, color };
    }
    acc[dealType].value += parseFloat(deal.amount) || 0;
    return acc;
  }, {});

  const total = Object.values(typeDistribution).reduce((sum, item) => sum + item.value, 0);

  return Object.entries(typeDistribution).map(([type, data]) => ({
    id: type,
    label: type,
    value: data.value,
    percentage: (data.value / total) * 100,
    color: data.color || '#999999', // Fallback color if undefined
  }));
};

const processTopDeals = (openDeals, limit = 10) => {
  const totalAmount = openDeals.reduce((sum, deal) => sum + (parseFloat(deal.amount) || 0), 0);

  return openDeals
    .sort((a, b) => parseFloat(b.amount) - parseFloat(a.amount))
    .slice(0, limit)
    .map(deal => {
      const amount = parseFloat(deal.amount) || 0;
      const percentOfPipe = totalAmount > 0 ? (amount / totalAmount) * 100 : 0;

      return {
        id: deal.id,
        name: deal.dealname,
        amount,
        probability: parseFloat(deal.hs_deal_stage_probability) || 0,
        closeDate: deal.closedate,
        stage: deal.dealstage,
        daysOpen: deal.daysOpen || 0,
        owner: deal.hubspot_owner_id,
        percentOfPipe,
        needsAttention: deal.needsAttention > 0,
      };
    });
};

const processDealAgeDistribution = (openDeals) => {
  const ageRanges = [
    { label: '0-30 days', min: 0, max: 30, count: 0 },
    { label: '31-60 days', min: 31, max: 60, count: 0 },
    { label: '61-90 days', min: 61, max: 90, count: 0 },
    { label: '90+ days', min: 91, max: Infinity, count: 0 }
  ];

  openDeals.forEach(deal => {
    const age = deal.daysOpen || 0;
    const range = ageRanges.find(r => age >= r.min && age <= r.max);
    if (range) range.count++;
  });

  return ageRanges;
};

const processDealAttentionNeeds = (openDeals) => {
  const categories = {
    pastDue: { name: 'Past Due', value: 0 },
    missingInformation: { name: 'Missing Information', value: 0 },
    missingOwner: { name: 'Missing Owner', value: 0 },
    missingAmount: { name: 'Missing Amount', value: 0 },
    noRecentActivity: { name: 'No Recent Activity', value: 0 },
    stagnant: { name: 'Stagnant', value: 0 },
    highValue: { name: 'High Value at Risk', value: 0 },
    closingSoon: { name: 'Closing Soon', value: 0 }
  };

  openDeals.forEach(deal => {
    if (deal.pastDue) categories.pastDue.value++;
    if (deal.missingInformation) categories.missingInformation.value++;
    if (deal.missingOwner) categories.missingOwner.value++;
    if (deal.missingAmount) categories.missingAmount.value++;
    if (noRecentActivity(deal)) categories.noRecentActivity.value++;
    if (isStagnant(deal)) categories.stagnant.value++;
    if (isHighValueAtRisk(deal)) categories.highValue.value++;
    if (isClosingSoon(deal)) categories.closingSoon.value++;
  });

  return Object.values(categories).filter(category => category.value > 0);
};

const noRecentActivity = (deal) => {
  const lastActivityDate = new Date(deal.notes_last_updated || deal.notes_last_contacted || deal.updatedAt);
  return (new Date() - lastActivityDate) / (1000 * 60 * 60 * 24) > 30;
};

const isStagnant = (deal) => deal.daysOpen > 90;

const isHighValueAtRisk = (deal) => {
  const amount = parseFloat(deal.amount);
  const probability = parseFloat(deal.hs_deal_stage_probability);
  return amount > 100000 && probability < 50;
};

const isClosingSoon = (deal) => {
  const closeDate = new Date(deal.closedate);
  const daysUntilClose = (closeDate - new Date()) / (1000 * 60 * 60 * 24);
  return daysUntilClose <= 30 && daysUntilClose > 0;
};

export const getCurrentAndPreviousYear = () => {
  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;
  return { currentYear, previousYear };
};

export const processWinProbabilityHeatmap = (deals) => {
  if (!deals || deals.length === 0) {
    return []; // Return an empty array if there are no deals
  }

  const stages = {
    '16602267': 'Qualification',
    '16602268': 'Meeting Scheduled',
    '16602269': 'Proposal',
    '16602270': 'Negotiation',
    '16602271': 'Closed Won'
  };

  const dealSizes = ['0-10k', '10k-50k', '50k-100k', '100k-500k', '500k+'];
  
  const heatmapData = Object.values(stages).map(stage => {
    const stageData = { stage };
    dealSizes.forEach(size => {
      stageData[size] = { sum: 0, count: 0 };
    });
    return stageData;
  });

  deals.forEach(deal => {
    const stage = stages[deal.dealstage] || 'Unknown';
    const amount = parseFloat(deal.amount) || 0;
    const probability = parseFloat(deal.hs_deal_stage_probability) || 0;
    
    const sizeCategory = getDealSizeCategory(amount);
    const stageIndex = Object.values(stages).indexOf(stage);
    
    if (stageIndex !== -1 && sizeCategory) {
      heatmapData[stageIndex][sizeCategory].sum += probability;
      heatmapData[stageIndex][sizeCategory].count += 1;
    }
  });

  // Calculate average probabilities
  heatmapData.forEach(row => {
    dealSizes.forEach(size => {
      const { sum, count } = row[size];
      row[size] = count > 0 ? Math.round(sum / count) : 0;
    });
  });

  return heatmapData;
};

const getDealSizeCategory = (amount) => {
  if (amount < 10000) return '0-10k';
  if (amount < 50000) return '10k-50k';
  if (amount < 100000) return '50k-100k';
  if (amount < 500000) return '100k-500k';
  return '500k+';
};