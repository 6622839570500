import React from 'react';
import { Box, Typography, TextField, Button, Grid, Divider, useTheme } from '@mui/material';
import { useChartConfig } from '../hooks/useChartConfig';

const BillingInfo = () => {
  const theme = useTheme();
  const { isMobile } = useChartConfig();

  return (
    <Box sx={{ 
      backgroundColor: theme.palette.background.paper, 
      p: isMobile ? 2 : 3,
      color: theme.palette.text.primary
    }}>
      <Typography variant={isMobile ? "h5" : "h3"} mb={3}>
        Billing Information
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" mb={2}>Payment Method</Typography>
          <TextField fullWidth label="Card Number" margin="normal" />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField fullWidth label="Expiration Date" margin="normal" />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth label="CVV" margin="normal" />
            </Grid>
          </Grid>
          <TextField fullWidth label="Cardholder Name" margin="normal" />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" mb={2}>Billing Address</Typography>
          <TextField fullWidth label="Address Line 1" margin="normal" />
          <TextField fullWidth label="Address Line 2" margin="normal" />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField fullWidth label="City" margin="normal" />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth label="State/Province" margin="normal" />
            </Grid>
          </Grid>
          <TextField fullWidth label="Postal Code" margin="normal" />
          <TextField fullWidth label="Country" margin="normal" />
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ my: 3 }} />
          <Typography variant="h6" mb={2}>Subscription Details</Typography>
          <Typography variant="body1">Current Plan: Enterprise</Typography>
          <Typography variant="body1">Billing Cycle: Annual</Typography>
          <Typography variant="body1">Next Billing Date: January 1, 2024</Typography>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            sx={{ 
              mt: 2, 
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText,
              '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
              }
            }}
          >
            Update Billing Information
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BillingInfo;