import NotificationsIcon from '@mui/icons-material/Notifications';
import SyncIcon from '@mui/icons-material/Sync';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AssessmentIcon from '@mui/icons-material/Assessment';

export const getNotificationIcon = (type, status) => {
  let Icon;
  switch (type) {
    case 'deal':
      Icon = NotificationsIcon;
      break;
    case 'sync':
      Icon = SyncIcon;
      break;
    case 'watchlist':
      Icon = VisibilityIcon;
      break;
    case 'report':
      Icon = AssessmentIcon;
      break;
    default:
      Icon = NotificationsIcon;
  }

  return <Icon color={status === 'urgent' ? 'error' : 'primary'} />;
};