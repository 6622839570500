import React, { useState, useEffect } from 'react'
import { 
  Box, 
  Typography, 
  Button,
  Grid,
  useTheme,
  useMediaQuery,
  Paper
} from '@mui/material'
import { useAuth } from '../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import SignupModal from '../components/common/SignupModal'
import DemoForecast from './DemoForecast'
import { trackEvent, trackPageView } from '../utils/analytics'

function ForecastPage() {
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [showAuthModal, setShowAuthModal] = useState(false)

  // Add page view tracking
  useEffect(() => {
    trackPageView('Forecast Page', {
      product_type: 'forecast',
      source: sessionStorage.getItem('source') || 'direct'
    });
  }, []);

  const handleGetDashboard = () => {
    trackEvent('dashboard_cta_click', {
      product_type: 'forecast',
      user_status: currentUser ? 'authenticated' : 'unauthenticated',
      journey_step: 'product_to_dashboard'
    });

    if (currentUser) {
      navigate('/forecast/dashboard');
    } else {
      sessionStorage.setItem('redirectAfterAuth', '/forecast/dashboard');
      setShowAuthModal(true);
    }
  };

  return (
    <Box 
      sx={{ 
        py: { xs: 14, md: 14, lg: 20, xl: 20 },
        maxWidth: '1440px',
        margin: '20px',
        mx: 'auto',
        px: { xs: 2, sm: 3, md: 4 }
      }}
    >
      <Grid container spacing={4}>
        {/* Content Section */}
        <Grid item xs={12}>
          <Box sx={{ textAlign: 'center', mb: 6 }}>
            <Typography 
              variant="h2" 
              gutterBottom 
              sx={{ 
                fontSize: { xs: '2rem', md: '3rem' },
                fontWeight: 700,
                mb: 3
              }}
            >
              AI-Powered Revenue Forecasting
            </Typography>
            <Typography 
              variant="h5" 
              sx={{ 
                mb: 4,
                color: 'text.secondary',
                maxWidth: '800px',
                mx: 'auto'
              }}
            >
              Get accurate revenue predictions and insights powered by advanced AI algorithms. 
              Make data-driven decisions with confidence.
            </Typography>
            <Button
              variant="contained"
              size="large"
              onClick={handleGetDashboard}
              sx={{ 
                px: 8,
                py: 2,
                minWidth: '280px',
                fontSize: '1.1rem',
                bgcolor: theme.palette.secondary.main,
                color: 'white',
                '&:hover': {
                  bgcolor: theme.palette.secondary.dark,
                },
                boxShadow: 'none',
                textTransform: 'uppercase',
                fontWeight: 600,
                letterSpacing: '0.5px'
              }}
            >
              Get Your Dashboard
            </Button>
            <Typography 
              variant="body2" 
              sx={{ 
                mt: 2,
                color: 'text.secondary',
                fontStyle: 'italic'
              }}
            >
              Takes less than 2 minutes to set up
            </Typography>
          </Box>
        </Grid>

        {/* Demo Section */}
        <Grid item xs={12}>
          <Paper 
            elevation={3} 
            sx={{ 
              overflow: 'hidden',
              borderRadius: 2,
              height: '100%',
              maxWidth: '1200px',
              mx: 'auto',
              bgcolor: theme => theme.palette.mode === 'dark' 
                ? 'rgba(26, 26, 26, 0.95)' 
                : 'background.paper',
              border: theme => theme.palette.mode === 'dark' 
                ? '1px solid rgba(255, 255, 255, 0.1)' 
                : 'none',
              boxShadow: theme => theme.palette.mode === 'dark' 
                ? '0 0 20px rgba(0, 0, 0, 0.5)' 
                : undefined
            }}
          >
            <DemoForecast />
          </Paper>
        </Grid>
      </Grid>

      {/* Auth Modal */}
      <SignupModal 
        open={showAuthModal} 
        onClose={() => setShowAuthModal(false)}
      />
    </Box>
  )
}

export default ForecastPage
