import React, { useMemo, useState } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  TablePagination, TableSortLabel, LinearProgress, Box, Typography, Chip,
  Button, Skeleton, useMediaQuery
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useUsersData } from '../../../contexts/UsersDataContext';
import { processTopDeals } from '../../../utils/dealsDataProcessing';
import { formatCurrency, formatPercentage, formatDate } from '../../utils/formatters';
import { useChartStyles } from '../../hooks/useChartStyles';
import { useTheme } from '@mui/material/styles';
import { getCustomStageFromProbability, getStageInfo } from '../../../contexts/utils/dealStages';

const TopDealsTable = () => {
  const theme = useTheme();
  const { allDeals } = useUsersData();
  const { getTableStyles, getStatusColor } = useChartStyles();
  const tableStyles = getTableStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('amount');
  const [order, setOrder] = useState('desc');
  const [showMore, setShowMore] = useState(false);

  const processedData = useMemo(() => {
    const deals = processTopDeals(allDeals);
    return deals.map(deal => {
      const customStage = getCustomStageFromProbability(deal.probability);
      const { color } = getStageInfo(customStage);
      return {
        ...deal,
        customStage,
        stageColor: color
      };
    });
  }, [allDeals]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (property) => () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData = useMemo(() => {
    return processedData.sort((a, b) => {
      if (b[orderBy] < a[orderBy]) return order === 'asc' ? 1 : -1;
      if (b[orderBy] > a[orderBy]) return order === 'asc' ? -1 : 1;
      return 0;
    });
  }, [processedData, order, orderBy]);

  const calculateStatus = (closeDate) => {
    const today = new Date();
    const closeDateObj = new Date(closeDate);
    const daysUntilClose = Math.ceil((closeDateObj - today) / (1000 * 60 * 60 * 24));

    if (daysUntilClose <= 7) return { text: 'Closing Soon', color: getStatusColor('Closing Soon') };
    if (daysUntilClose <= 30) return { text: 'Closing This Month', color: getStatusColor('Closing This Month') };
    return { text: 'Open', color: getStatusColor('Open') };
  };

  if (!sortedData || sortedData.length === 0) {
    return <Typography>No top deals data available.</Typography>;
  }

  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer component={Paper} sx={{ maxHeight: 440, overflow: 'auto' }}>
        <Table stickyHeader aria-label="top deals table" sx={tableStyles.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={handleSort('name')}
                >
                  Deal Name
                </TableSortLabel>
              </TableCell>
              {!isMobile && (
                <>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'customStage'}
                      direction={orderBy === 'customStage' ? order : 'asc'}
                      onClick={handleSort('customStage')}
                    >
                      Stage
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'createDate'}
                      direction={orderBy === 'createDate' ? order : 'asc'}
                      onClick={handleSort('createDate')}
                    >
                      Create Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Status</TableCell>
                </>
              )}
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === 'amount'}
                  direction={orderBy === 'amount' ? order : 'asc'}
                  onClick={handleSort('amount')}
                >
                  Amount
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'percentOfPipe'}
                  direction={orderBy === 'percentOfPipe' ? order : 'asc'}
                  onClick={handleSort('percentOfPipe')}
                >
                  % of Pipe
                </TableSortLabel>
              </TableCell>
              <TableCell>Probability</TableCell>
              {!isMobile && (
                <>
                  <TableCell align="right">
                    <TableSortLabel
                      active={orderBy === 'daysOpen'}
                      direction={orderBy === 'daysOpen' ? order : 'asc'}
                      onClick={handleSort('daysOpen')}
                    >
                      Days Open
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'closeDate'}
                      direction={orderBy === 'closeDate' ? order : 'asc'}
                      onClick={handleSort('closeDate')}
                    >
                      Close Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'lastContacted'}
                      direction={orderBy === 'lastContacted' ? order : 'asc'}
                      onClick={handleSort('lastContacted')}
                    >
                      Last Contacted
                    </TableSortLabel>
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((deal) => (
                <TableRow key={deal.id} sx={tableStyles.tableRow}>
                  <TableCell component="th" scope="row">
                    {deal.name}
                  </TableCell>
                  {!isMobile && (
                    <>
                      <TableCell>
                        <Chip
                          label={deal.customStage}
                          size="small"
                          sx={{
                            backgroundColor: deal.stageColor,
                            color: theme.palette.getContrastText(deal.stageColor),
                          }}
                        />
                      </TableCell>
                      <TableCell>{formatDate(deal.createDate)}</TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                          <Chip
                            label={calculateStatus(deal.closeDate).text}
                            size="small"
                            sx={{
                              backgroundColor: calculateStatus(deal.closeDate).color,
                              color: theme.palette.getContrastText(calculateStatus(deal.closeDate).color),
                            }}
                          />
                          {deal.needsAttention && (
                            <Chip
                              label="Needs Attention"
                              size="small"
                              sx={{
                                backgroundColor: theme.palette.error.main,
                                color: theme.palette.error.contrastText,
                              }}
                            />
                          )}
                        </Box>
                      </TableCell>
                    </>
                  )}
                  <TableCell align="right">{formatCurrency(deal.amount)}</TableCell>
                  <TableCell>{formatPercentage(deal.percentOfPipe)}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ width: '100%', mr: 1 }}>
                        <LinearProgress
                          variant="determinate"
                          value={deal.probability}
                          sx={{
                            ...tableStyles.probabilityBar,
                            height: 10,
                            borderRadius: 5,
                          }}
                        />
                      </Box>
                      <Box sx={{ minWidth: 35 }}>
                        <Typography variant="body2" color="text.secondary">
                          {formatPercentage(deal.probability)}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  {!isMobile && (
                    <>
                      <TableCell align="right">{deal.daysOpen}</TableCell>
                      <TableCell>{formatDate(deal.closeDate)}</TableCell>
                      <TableCell>{deal.lastContacted ? formatDate(deal.lastContacted) : 'Never'}</TableCell>
                    </>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={sortedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {!showMore && (
        <Box
          sx={{
            position: 'relative',
            height: '100px',
            background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
            padding: '20px',
          }}
        >
          <Button
            variant="outlined"
            onClick={() => setShowMore(true)}
            endIcon={<ExpandMoreIcon />}
          >
            Show More
          </Button>
        </Box>
      )}
      {showMore && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" color="text.secondary" align="center" gutterBottom>
            Premium Content
          </Typography>
          {[...Array(5)].map((_, index) => (
            <Skeleton key={index} variant="rectangular" height={40} sx={{ my: 1 }} />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default TopDealsTable;