import React, { useState, useCallback, useEffect } from 'react';
import { Box, Typography, Button, Container, Stack, Card, CardContent, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { mint, dodgerBlue } from '../../theme';
import { trackEvent } from '../../utils/analytics';
import { Link, useNavigate } from 'react-router-dom';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import PieChartIcon from '@mui/icons-material/PieChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HeroVideo from './assets/herovideo.mp4';
import CertificationBadge from './assets/Certification-Badge.png';

// Enhanced styled components
const GradientBackground = styled(Box)(({ theme }) => ({
  background: `linear-gradient(180deg, 
    ${theme.palette.background.default} 0%, 
    ${theme.palette.mode === 'dark' ? '#1a1c1e' : '#f8fafc'} 100%)`,
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '100%',
    background: `radial-gradient(circle at 50% 0%, 
      ${theme.palette.mode === 'dark' ? 'rgba(0, 176, 144, 0.15)' : 'rgba(0, 176, 144, 0.1)'} 0%, 
      transparent 70%)`,
    pointerEvents: 'none',
  }
}));

const AnimatedProductCard = styled(Card)(({ theme, color }) => ({
  transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
  cursor: 'pointer',
  background: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.05)' 
    : 'rgba(255, 255, 255, 0.9)',
  backdropFilter: 'blur(10px)',
  width: '100%',
  maxWidth: '450px',
  borderRadius: '16px',
  border: `1px solid ${theme.palette.divider}`,
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: color === 'mint' ? mint : dodgerBlue,
    transform: 'scaleX(0)',
    transition: 'transform 0.3s ease-in-out',
  },
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: theme.shadows[8],
    '&::before': {
      transform: 'scaleX(1)',
    }
  }
}));

const FeatureBadge = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  background: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.1)' 
    : 'rgba(0, 0, 0, 0.05)',
  borderRadius: '20px',
  padding: theme.spacing(0.5, 2),
  marginBottom: theme.spacing(1),
}));

const StyledVideo = styled('video')(({ theme }) => ({
  marginTop: theme.spacing(3),
  aspectRatio: '1920 / 1022',
  objectFit: 'cover',
  objectPosition: 'center',
  borderRadius: '25px',
  overflow: 'hidden',
  maxHeight: '500px',
  width: '100%',
  maxWidth: '940px',
  display: 'block',
  margin: '0 auto',
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(4),
  },
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(6),
  },
}));

const BadgeWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '-25px',
  right: '-90px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  zIndex: 1,
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
  [theme.breakpoints.down('sm')]: {
    top: '-15px',
    right: '0px',
  },
}));

const BadgeImage = styled('img')(({ theme }) => ({
  width: '100px',
  height: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '80px',
  },
}));

const HeroNew = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const features = [
    { text: 'No credit card required', icon: <CheckCircleIcon fontSize="small" /> },
    { text: 'Instant setup', icon: <CheckCircleIcon fontSize="small" /> },
    { text: 'HubSpot certified', icon: <CheckCircleIcon fontSize="small" /> }
  ];

  const handleProductSelect = useCallback((product) => {
    sessionStorage.setItem('source', 'homepage_hero');
    
    trackEvent('product_selected', {
      product_name: product,
      location: 'hero_section',
      journey_step: 'homepage_to_product'
    });
    
    if (product === 'healthcheck') {
      navigate('/healthcheck');
    } else if (product === 'forecast') {
      navigate('/forecast');
    }
  }, [navigate]);

  const handleVideoError = (e) => {
    console.error('Error loading hero video:', e);
    // Optionally hide video container or show fallback
  };

  return (
    <Box variant="hero" sx={{ 
      pt: { xs: 10, sm: 14, md: 20 },
      pb: { xs: 10, sm: 12, md: 14 },
      position: 'relative',
    }}>
      <Container 
        maxWidth="lg" 
        disableGutters 
        sx={{ 
          px: { xs: 2, sm: 3, md: 4 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Stack 
          spacing={3} 
          useFlexGap 
          sx={{ 
            width: '100%', 
            maxWidth: '70rem', 
            margin: '0 auto',
            position: 'relative'
          }}
        >
          {/* Title and subtitle section */}
          <Box sx={{ maxWidth: '800px', margin: '0 auto', textAlign: 'center' }}>
            <Typography 
              variant="h1" 
              sx={{ 
                fontSize: { xs: '2.5rem', md: '4rem' },
                fontWeight: 700,
                mb: 3,
                background: `linear-gradient(135deg, ${theme.palette.text.primary} 0%, ${theme.palette.text.secondary} 100%)`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Unlock the Power of AI for Revenue Teams
            </Typography>
            <Typography 
              variant="h2" 
              sx={{ 
                fontSize: { xs: '1.25rem', md: '1.5rem' },
                color: 'text.secondary',
                mb: 4,
                maxWidth: '600px',
                mx: 'auto'
              }}
            >
              State-of-the-art forecasting and real-time pipeline health checks for HubSpot.
            </Typography>

            {/* Feature badges */}
            <Stack 
              direction="row" 
              spacing={2} 
              justifyContent="center" 
              flexWrap="wrap"
              sx={{ mb: 6 }}
            >
              {features.map((feature, index) => (
                <FeatureBadge key={index}>
                  {feature.icon}
                  <Typography variant="body2">{feature.text}</Typography>
                </FeatureBadge>
              ))}
            </Stack>

            {/* Product cards with certification badge */}
            <Box sx={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Stack
                direction={{ xs: 'column', md: 'row' }}
                spacing={4}
                sx={{ 
                  width: '100%', 
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative'
                }}
              >
                <AnimatedProductCard color="mint" onClick={() => handleProductSelect('healthcheck')}>
                  <CardContent sx={{ p: 4 }}>
                    <Box sx={{ 
                      height: 56, 
                      width: 56, 
                      borderRadius: '12px',
                      bgcolor: 'rgba(0, 176, 144, 0.1)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mb: 3
                    }}>
                      <PieChartIcon sx={{ color: mint, fontSize: '2rem' }} />
                    </Box>
                    <Typography variant="h4" gutterBottom sx={{ color: mint, fontWeight: 600 }}>
                      Free AI Healthcheck
                    </Typography>
                    <Typography sx={{ mb: 3, color: 'text.secondary' }}>
                      Get instant insights into your pipeline health, deal quality, and team performance.
                    </Typography>
                    <Button
                      variant="outlined"
                      endIcon={<ArrowRightAltIcon />}
                      sx={{ 
                        borderColor: mint,
                        color: mint,
                        '&:hover': {
                          borderColor: mint,
                          backgroundColor: 'rgba(0, 176, 144, 0.1)'
                        }
                      }}
                    >
                      Try Now
                    </Button>
                  </CardContent>
                </AnimatedProductCard>

                <AnimatedProductCard color="blue" onClick={() => handleProductSelect('forecast')}>
                  <CardContent sx={{ p: 4 }}>
                    <Box sx={{ 
                      height: 56, 
                      width: 56, 
                      borderRadius: '12px',
                      bgcolor: 'rgba(30, 144, 255, 0.1)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mb: 3
                    }}>
                      <BarChartIcon sx={{ color: dodgerBlue, fontSize: '2rem' }} />
                    </Box>
                    <Typography variant="h4" gutterBottom sx={{ color: dodgerBlue, fontWeight: 600 }}>
                      Free AI Forecast
                    </Typography>
                    <Typography sx={{ mb: 3, color: 'text.secondary' }}>
                      Get accurate revenue predictions powered by advanced AI algorithms.
                    </Typography>
                    <Button
                      variant="outlined"
                      endIcon={<ArrowRightAltIcon />}
                      sx={{ 
                        borderColor: dodgerBlue,
                        color: dodgerBlue,
                        '&:hover': {
                          borderColor: dodgerBlue,
                          backgroundColor: 'rgba(30, 144, 255, 0.1)'
                        }
                      }}
                    >
                      Try Now
                    </Button>
                  </CardContent>
                </AnimatedProductCard>
              </Stack>
              
              {/* HubSpot Certification Badge */}
              <BadgeWrapper>
                <Link 
                  to="https://ecosystem.hubspot.com/marketplace/apps/dashboards-by-truepipe" 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  <BadgeImage src={CertificationBadge} alt="HubSpot Certified App" />
                </Link>
              </BadgeWrapper>
            </Box>

            {/* Updated Demo Video */}
            <StyledVideo 
              autoPlay 
              loop 
              muted 
              playsInline
              onError={handleVideoError}
            >
              <source src={HeroVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </StyledVideo>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default HeroNew;
