// src/contexts/HealthcheckDashboardContext.js v1.0.0

import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useRawData } from './RawDataContext';
import { useAuth } from './AuthContext';
import { useFirebase } from './FirebaseContext';
import { format } from 'd3-format';
import logger from '../utils/frontendLogger';
// Create the HealthcheckDashboardContext
const HealthcheckDashboardContext = createContext();

// Custom hook to use the HealthcheckDashboardContext
export const useHealthcheckDashboard = () => {
  const context = useContext(HealthcheckDashboardContext);
  if (context === undefined) {
    console.error('useHealthcheckDashboard must be used within a HealthcheckDashboardProvider');
    throw new Error('useHealthcheckDashboard must be used within a HealthcheckDashboardProvider');
  }
  return context;
};

// HealthcheckDashboardProvider component
export const HealthcheckDashboardProvider = ({ children }) => {
  const { deals, owners, contacts, engagements, loading: rawLoading, error: rawError } = useRawData();
  const { currentUser } = useAuth();
  const { db } = useFirebase();

  const [crmHealthMetrics, setCrmHealthMetrics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // COMMON CALCULATIONS (used across all dashboards)
  const calculateCommonMetrics = useCallback(() => {
    const totalDeals = deals.length;
    const totalRevenue = deals.reduce((sum, deal) => sum + (deal.amount || 0), 0);
    const averageDealSize = totalRevenue / (totalDeals || 1);

    return {
      totalDeals,
      totalRevenue,
      averageDealSize,
    };
  }, [deals]);

  // HEALTHCHECK SECTION
  // ----------------------------------------------------------------
  // Healthcheck-specific metrics
  const calculateHealthcheckMetrics = useCallback(() => {
    const openDeals = deals.filter((deal) => deal.isOpenDeal);
    const totalOpenDeals = openDeals.length;
    const averageDealAge =
      openDeals.reduce((sum, deal) => sum + (deal.daysOpen || 0), 0) / (totalOpenDeals || 1);
    const averageSalesCycle =
      openDeals.reduce((sum, deal) => sum + (deal.salesCycleDays || 0), 0) / (totalOpenDeals || 1);

    const now = new Date();
    const dealsPastAvgCycle = openDeals.filter((deal) => {
      const createDate = new Date(deal.createdate);
      return (now - createDate) / (1000 * 60 * 60 * 24) > averageSalesCycle;
    }).length;

    const totalQuality = openDeals.reduce(
      (sum, deal) => sum + (deal.hs_deal_stage_probability || 0),
      0
    );
    const pipelineFreshness = (totalQuality / (totalOpenDeals || 1)) * 100;

    return {
      totalOpenDeals: { value: totalOpenDeals, title: 'Total Open Deals' },
      dealsPastAvgCycle: { value: dealsPastAvgCycle, title: 'Deals Past Avg Cycle' },
      pipelineFreshness: { value: pipelineFreshness, title: 'Pipeline Freshness' },
      averageSalesCycle: { value: averageSalesCycle, title: 'Avg Sales Cycle' },
    };
  }, [deals]);

  // Owner metrics for Healthcheck
  const calculateOwnerHealthMetrics = useCallback(() => {
    const now = new Date();
    const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
    const startOfQuarter = new Date(now.getFullYear(), Math.floor(now.getMonth() / 3) * 3, 1);
    const endOfQuarter = new Date(startOfQuarter.getFullYear(), startOfQuarter.getMonth() + 3, 0);

    return Object.values(owners).map((owner) => {
      const ownerDeals = deals.filter((deal) => deal.hubspot_owner_id === owner.id);
      const totalDeals = ownerDeals.length;
      const totalValue = ownerDeals.reduce((sum, deal) => sum + (deal.amount || 0), 0) || 0;

      // Existing Metrics
      const outdatedDeals = ownerDeals.filter(
        (deal) => deal.pastDue === 1 || deal.missingAmount === 1 || deal.missingCloseDate === 1
      ).length;
      const avgDealAge =
        ownerDeals.reduce((sum, deal) => sum + (deal.daysOpen || 0), 0) / (totalDeals || 1);
      const dealsClosingSoon = ownerDeals.filter((deal) => deal.closingSoon === 1).length;
      const avgWinProbability =
        ownerDeals.reduce((sum, deal) => sum + (deal.dealWinProbability || 0), 0) /
        (totalDeals || 1);
      const revenueAtRisk = ownerDeals
        .filter((deal) => deal.criticalBad === 1)
        .reduce((sum, deal) => sum + (deal.amount || 0), 0);
      const dealVelocity =
        ownerDeals.reduce((sum, deal) => sum + (deal.dealVelocity || 0), 0) / (totalDeals || 1);
      const forecastAccuracy =
        ownerDeals.reduce((sum, deal) => sum + (deal.forecastAccuracy || 0), 0) / (totalDeals || 1);

      // Health Score Calculation
      const healthScore =
        ((1 - outdatedDeals / (totalDeals || 1)) * 0.25 +
          avgWinProbability * 0.15 +
          (1 - revenueAtRisk / (totalValue || 1)) * 0.15 +
          forecastAccuracy * 0.1) *
        100;

      return {
        id: owner.id,
        owner: `${owner.firstName} ${owner.lastName}`,
        email: owner.email,
        totalDeals,
        totalValue,
        outdatedDeals,
        avgDealAge,
        dealsClosingSoon,
        avgWinProbability,
        healthScore: Math.min(Math.max(healthScore, 0), 100),
        revenueAtRisk,
        dealVelocity,
        forecastAccuracy,
        // Additional metrics can be added here
      };
    });
  }, [deals, owners]);

  // Perform calculations for insights
  const performInsightsCalculations = useCallback(
    (dealsData) => {
      const totalRevenue = dealsData.reduce((sum, deal) => sum + (deal.amount || 0), 0);
      const averageDealSize = totalRevenue / (dealsData.length || 1);
      const averageDealAge =
        dealsData.reduce((sum, deal) => sum + (deal.daysOpen || 0), 0) / (dealsData.length || 1);

      const dealsByMonth = {};
      dealsData.forEach((deal) => {
        const month = new Date(deal.createdate).toLocaleString('default', {
          month: 'short',
          year: 'numeric',
        });
        if (!dealsByMonth[month]) {
          dealsByMonth[month] = { count: 0, totalAmount: 0 };
        }
        dealsByMonth[month].count += 1;
        dealsByMonth[month].totalAmount += deal.amount || 0;
      });

      const cohortData = Object.keys(dealsByMonth).map((month) => ({
        month,
        ...dealsByMonth[month],
      }));

      return {
        revenue: {
          type: 'revenue',
          title: 'Total Revenue',
          value: totalRevenue,
          insight: `Total revenue: ${formatCurrency(totalRevenue)}`,
          historicalData: cohortData.map((data) => data.totalAmount),
        },
        // ...other insights
      };
    },
    [format]
  );

  // Helper function for currency formatting
  const formatCurrency = useCallback((value) => {
    const formatter = format('$,.0f');
    return formatter(value);
  }, []);

  // CRM health calculation function
  const calculateCRMHealth = useCallback(() => {
    if (!deals || !contacts || !engagements) {
      logger.debug('Missing required data:', { 
        hasDeals: !!deals, 
        hasContacts: !!contacts, 
        hasEngagements: !!engagements 
      });
      setLoading(true);
      return;
    }

    try {
      // Only proceed if we have data
      if (deals.length === 0 && contacts.length === 0) {
        setCrmHealthMetrics({
          accountHealth: {
            totalDeals: 0,
            totalContacts: 0,
            totalEngagements: 0,
          },
          dataQuality: {
            deals: { total: 0, withNulls: 0, percentComplete: 0 },
            contacts: { total: 0, withNulls: 0, percentComplete: 0 }
          }
        });
        setLoading(false);
        return;
      }

      // Common metrics
      const commonMetrics = calculateCommonMetrics();

      // Healthcheck metrics
      const healthcheckMetrics = calculateHealthcheckMetrics();

      // Owner health metrics
      const ownerHealthMetrics = calculateOwnerHealthMetrics();

      // Insights
      const insights = performInsightsCalculations(deals);

      // Assemble crmHealthMetrics
      const metrics = {
        accountHealth: {
          years: 2, // Placeholder: Replace with actual calculation if available
          months: 5, // Placeholder: Replace with actual calculation if available
          inactiveMonths: 3, // Placeholder: Replace with actual calculation if available
          totalDeals: commonMetrics.totalDeals,
          totalContacts: contacts.length,
          totalEngagements: engagements.length,
        },
        dataQuality: {
          deals: {
            total: commonMetrics.totalDeals,
            withNulls: deals.filter(deal => !deal.amount || !deal.closedate || !deal.hubspot_owner_id || !deal.dealstage).length,
            percentComplete:
              (Object.values({
                amount: (deals.filter((d) => d.amount != null).length / (commonMetrics.totalDeals || 1)) * 100,
                closedate: (deals.filter((d) => d.closedate != null).length / (commonMetrics.totalDeals || 1)) * 100,
                owner: (deals.filter((d) => d.hubspot_owner_id != null).length / (commonMetrics.totalDeals || 1)) * 100,
                stage: (deals.filter((d) => d.dealstage != null).length / (commonMetrics.totalDeals || 1)) * 100,
              }).reduce((sum, val) => sum + val, 0)) / 4,
            criticalFields: {
              amount: (deals.filter((d) => d.amount != null).length / (commonMetrics.totalDeals || 1)) * 100,
              closedate: (deals.filter((d) => d.closedate != null).length / (commonMetrics.totalDeals || 1)) * 100,
              owner: (deals.filter((d) => d.hubspot_owner_id != null).length / (commonMetrics.totalDeals || 1)) * 100,
              stage: (deals.filter((d) => d.dealstage != null).length / (commonMetrics.totalDeals || 1)) * 100,
            },
          },
          contacts: {
            total: contacts.length,
            withNulls: contacts.filter(contact => !contact.email || !contact.firstName || !contact.lastName || !contact.phone || !contact.company).length,
            percentComplete:
              (Object.values({
                email: (contacts.filter((c) => c.email).length / (contacts.length || 1)) * 100,
                firstName: (contacts.filter((c) => c.firstName).length / (contacts.length || 1)) * 100,
                lastName: (contacts.filter((c) => c.lastName).length / (contacts.length || 1)) * 100,
                phone: (contacts.filter((c) => c.phone).length / (contacts.length || 1)) * 100,
                company: (contacts.filter((c) => c.company).length / (contacts.length || 1)) * 100,
              }).reduce((sum, val) => sum + val, 0)) / 5,
            criticalFields: {
              email: (contacts.filter((c) => c.email).length / (contacts.length || 1)) * 100,
              firstName: (contacts.filter((c) => c.firstName).length / (contacts.length || 1)) * 100,
              lastName: (contacts.filter((c) => c.lastName).length / (contacts.length || 1)) * 100,
              phone: (contacts.filter((c) => c.phone).length / (contacts.length || 1)) * 100,
              company: (contacts.filter((c) => c.company).length / (contacts.length || 1)) * 100,
            },
          },
        },
        activityTrends: [], // Placeholder: Populate based on actual activity trends logic
        emailDeliverability: {
          rate: 95, // Placeholder: Replace with actual calculation if available
          total: 100, // Placeholder
          delivered: 95, // Placeholder
        },
        duplicateRate: 2, // Placeholder: Replace with actual calculation if available
      };

      setCrmHealthMetrics(metrics);
      setLoading(false);
    } catch (err) {
      logger.error('Error calculating CRM Health Metrics:', { 
        error: err,
        deals: deals?.length,
        contacts: contacts?.length,
        engagements: engagements?.length
      });
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [
    deals,
    contacts,
    engagements,
    calculateCommonMetrics,
    calculateHealthcheckMetrics,
    calculateOwnerHealthMetrics,
    performInsightsCalculations,
  ]);

  // Effect to calculate CRM Health Metrics when raw data changes
  useEffect(() => {
    if (rawLoading) {
      setLoading(true);
      return;
    }

    if (rawError) {
      setError(rawError);
      setLoading(false);
      return;
    }

    calculateCRMHealth();
  }, [rawLoading, rawError, calculateCRMHealth]);

  // Effect to handle authentication changes
  useEffect(() => {
    if (!currentUser) {
      setCrmHealthMetrics(null);
      setLoading(false);
      setError(null);
    }
  }, [currentUser]);

  // Assemble context value
  const value = {
    crmHealthMetrics,
    loading,
    error,
  };

  return (
    <HealthcheckDashboardContext.Provider value={value}>
      {children}
    </HealthcheckDashboardContext.Provider>
  );
};