import React, { useState } from 'react';
import { Box, Typography, FormGroup, FormControlLabel, Checkbox, Button, Divider, useTheme } from '@mui/material';
import { useChartConfig } from '../hooks/useChartConfig';

const NotificationPreferences = () => {
  const theme = useTheme();
  const { isMobile } = useChartConfig();
  const [emailNotifications, setEmailNotifications] = useState(true);
  const [pushNotifications, setPushNotifications] = useState(false);
  const [weeklyDigest, setWeeklyDigest] = useState(true);

  return (
    <Box sx={{ 
      backgroundColor: theme.palette.background.paper, 
      p: isMobile ? 2 : 3,
      color: theme.palette.text.primary
    }}>
      <Typography variant={isMobile ? "h5" : "h3"} mb={3}>
        Notification Preferences
      </Typography>

      <FormGroup>
        <Typography variant="h6" mb={2}>Email Notifications</Typography>
        <FormControlLabel
          control={<Checkbox checked={emailNotifications} onChange={(e) => setEmailNotifications(e.target.checked)} />}
          label="Receive email notifications"
        />
        {emailNotifications && (
          <Box ml={3}>
            <FormControlLabel control={<Checkbox defaultChecked />} label="Account updates" />
            <FormControlLabel control={<Checkbox defaultChecked />} label="New features and product updates" />
            <FormControlLabel control={<Checkbox defaultChecked />} label="Security alerts" />
          </Box>
        )}

        <Divider sx={{ my: 3 }} />

        <Typography variant="h6" mb={2}>Push Notifications</Typography>
        <FormControlLabel
          control={<Checkbox checked={pushNotifications} onChange={(e) => setPushNotifications(e.target.checked)} />}
          label="Receive push notifications"
        />
        {pushNotifications && (
          <Box ml={3}>
            <FormControlLabel control={<Checkbox defaultChecked />} label="Task assignments" />
            <FormControlLabel control={<Checkbox defaultChecked />} label="Mentions and comments" />
            <FormControlLabel control={<Checkbox defaultChecked />} label="Project updates" />
          </Box>
        )}

        <Divider sx={{ my: 3 }} />

        <Typography variant="h6" mb={2}>Digest</Typography>
        <FormControlLabel
          control={<Checkbox checked={weeklyDigest} onChange={(e) => setWeeklyDigest(e.target.checked)} />}
          label="Receive weekly digest"
        />
      </FormGroup>

      <Button
        variant="contained"
        sx={{ 
          mt: 3, 
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
          }
        }}
      >
        Save Preferences
      </Button>
    </Box>
  );
};

export default NotificationPreferences;