// src/utils/ErrorDisplay.jsx v1.1.0
import React from 'react'
import PropTypes from 'prop-types'
import { 
  Box, 
  Paper, 
  Typography, 
  Button, 
  useTheme,
  Alert
} from '@mui/material'
import { 
  ErrorOutline as ErrorIcon,
  Refresh as RefreshIcon 
} from '@mui/icons-material'
import logger from './frontendLogger'

function ErrorDisplay({ 
  error, 
  height = 400, 
  onRetry, 
  message = 'An error occurred while loading the data.', 
  showRefresh = true,
  severity = 'error' // New prop to control severity
}) {
  const theme = useTheme()
  const isDark = theme.palette.mode === 'dark'

  // Log the error when component mounts
  React.useEffect(() => {
    if (error) {
      logger.error('ErrorDisplay rendered:', { 
        error, 
        message,
        stack: error?.stack
      });
    }
  }, [error, message]);

  const handleRefresh = () => {
    if (onRetry) {
      onRetry()
    } else {
      window.location.reload()
    }
  }

  // Determine if this is a data availability error vs. a true error
  const isDataError = error?.message?.includes('data not available');
  const displaySeverity = isDataError ? 'warning' : severity;
  const displayMessage = isDataError ? 
    'Loading forecast data... This may take a moment.' : 
    message;

  return (
    <Paper
      elevation={0}
      sx={{
        height,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: isDark ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,0.02)',
        border: `1px solid ${isDark ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'}`,
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          p: 3,
          maxWidth: 400
        }}
      >
        <Alert 
          severity={displaySeverity}
          icon={<ErrorIcon />}
          sx={{ mb: 2 }}
        >
          {displayMessage}
        </Alert>

        {process.env.NODE_ENV === 'development' && error && (
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ 
              mb: 2,
              p: 2,
              bgcolor: 'background.paper',
              borderRadius: 1,
              fontFamily: 'monospace',
              fontSize: '0.8rem',
              wordBreak: 'break-all'
            }}
          >
            {error.message}
          </Typography>
        )}

        {showRefresh && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleRefresh}
            startIcon={<RefreshIcon />}
          >
            Refresh
          </Button>
        )}
      </Box>
    </Paper>
  )
}

ErrorDisplay.propTypes = {
  error: PropTypes.any,
  height: PropTypes.number,
  onRetry: PropTypes.func,
  message: PropTypes.string,
  showRefresh: PropTypes.bool,
  severity: PropTypes.string
}

export default ErrorDisplay 