// Helper functions for each factor calculation

const calculateDaysOpenFactor = (daysOpen) => {
  // Assuming 90 days as a benchmark for a typical sales cycle
  const maxDays = 90;
  return Math.min(daysOpen / maxDays, 1);
};

const calculateTimeToCloseFactor = (closedate, hs_deal_stage_probability) => {
  const daysToClose = (new Date(closedate) - new Date()) / (1000 * 60 * 60 * 24);
  const expectedDaysToClose = (1 - hs_deal_stage_probability) * 90; // Assuming 90 days total sales cycle
  return Math.max(0, 1 - (daysToClose / expectedDaysToClose));
};

const calculateContactFrequencyFactor = (num_contacted_notes) => {
  if (num_contacted_notes >= 10) return 1;
  if (num_contacted_notes >= 5) return 0.5;
  return num_contacted_notes / 10;
};

const calculateRecencyOfContactFactor = (notes_last_contacted) => {
  const daysSinceLastContact = (new Date() - new Date(notes_last_contacted)) / (1000 * 60 * 60 * 24);
  if (daysSinceLastContact <= 7) return 1;
  if (daysSinceLastContact <= 14) return 0.5;
  return 0;
};

const calculateDealAmountFactor = (amount, averageDealSize) => {
  return Math.min(amount / averageDealSize, 1);
};

const calculateNumberOfNotesFactor = (num_notes) => {
  return Math.min(num_notes / 20, 1); // Assuming 20 notes as a benchmark
};

const calculateStageProgressFactor = (hs_time_in_stage, expectedTimeInStage) => {
  return Math.min(hs_time_in_stage / expectedTimeInStage, 1);
};

// Main truepipe probability calculation function
export const calculateTruepipeProbability = (deal, averageDealSize, expectedTimeInStage) => {
  const baseProbability = parseFloat(deal.hs_deal_stage_probability) || 0;

  const daysOpenFactor = calculateDaysOpenFactor(deal.daysOpen);
  const timeToCloseFactor = calculateTimeToCloseFactor(deal.closedate, baseProbability);
  const contactFrequencyFactor = calculateContactFrequencyFactor(parseInt(deal.num_contacted_notes) || 0);
  const recencyOfContactFactor = calculateRecencyOfContactFactor(deal.notes_last_contacted);
  const percentOfPipeFactor = deal.percentOfPipe || 0;
  const dealAmountFactor = calculateDealAmountFactor(parseFloat(deal.amount) || 0, averageDealSize);
  const numberOfNotesFactor = calculateNumberOfNotesFactor(parseInt(deal.num_notes) || 0);
  const stageProgressFactor = calculateStageProgressFactor(parseFloat(deal.hs_time_in_16602267) || 0, expectedTimeInStage);

  const missingInfoPenalty = (deal.missingAmount + deal.missingCloseDate + deal.missingOwner) * 0.02;
  const highImpactBonus = deal.isHighImpact ? 0.02 : 0;

  const weightedSum = 
    (baseProbability * 0.30) +
    ((daysOpenFactor * 0.10 + timeToCloseFactor * 0.15) * 0.25) +
    ((contactFrequencyFactor * 0.10 + recencyOfContactFactor * 0.10) * 0.20) +
    ((percentOfPipeFactor * 0.10 + dealAmountFactor * 0.05) * 0.15) +
    ((numberOfNotesFactor * 0.05 + stageProgressFactor * 0.05) * 0.10) +
    (highImpactBonus * 0.05) -
    missingInfoPenalty;

  // Apply scaling factor to increase probabilities
  const scalingFactor = 1.5;
  const scaledProbability = Math.min(weightedSum * scalingFactor, 1);

  return scaledProbability;
};

// Function to categorize deals based on truepipe probability
export const categorizeDealsByTruepipeProbability = (deals, averageDealSize, expectedTimeInStage) => {
  const dealsWithTruepipe = deals.map(deal => ({
    ...deal,
    truepipeProbability: calculateTruepipeProbability(deal, averageDealSize, expectedTimeInStage)
  }));

  const sortedDeals = dealsWithTruepipe.sort((a, b) => b.truepipeProbability - a.truepipeProbability);

  return {
    bestDeals: sortedDeals.slice(0, 15),
    criticalDeals: sortedDeals.slice(-15).reverse()
  };
};