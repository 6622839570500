import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Badge } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NotificationsIcon from '@mui/icons-material/Notifications';

const categories = [
  { name: 'Deal Alerts', hasNotification: true, isUrgent: false },
  { name: 'Data Sync', hasNotification: false, isUrgent: false },
  { name: 'Watchlist', hasNotification: true, isUrgent: true },
  { name: 'Reports', hasNotification: false, isUrgent: false },
];

const NotificationBadge = ({ hasNotification, isUrgent }) => (
  <Badge
    color={isUrgent ? 'error' : 'primary'}
    variant={isUrgent ? 'dot' : 'standard'}
    invisible={!hasNotification}
  >
    <NotificationsIcon color={hasNotification ? (isUrgent ? 'error' : 'primary') : 'action'} />
  </Badge>
);

const NotificationDropdown = ({ onCategoryClick }) => {
  return (
    <Box sx={{ width: 300, backgroundColor: 'background.paper', borderRadius: 1, boxShadow: 3 }}>
      {categories.map((category) => (
        <Accordion key={category.name}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            onClick={() => onCategoryClick(category.name)}
          >
            <Typography sx={{ flexGrow: 1 }}>{category.name}</Typography>
            <NotificationBadge
              hasNotification={category.hasNotification}
              isUrgent={category.isUrgent}
            />
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {/* Placeholder for notification content */}
              Notification details for {category.name}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default NotificationDropdown;