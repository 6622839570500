import React from 'react';
import { Popper, Box, Typography, Paper, Grow } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { formatCurrency } from '../utils/formatters';
import { useChartConfig } from '../hooks/useChartConfig';

const KPITooltip = ({ deals, owners, title, open, onClose, anchorEl, popperWidth, popperHeight }) => {
  const theme = useTheme();
  const { getTooltipStyles, getLinkColor } = useChartConfig();
  const tooltipStyles = getTooltipStyles();
  const linkColor = getLinkColor();

  const isPastDue = (closeDate) => {
    return new Date(closeDate) < new Date();
  };

  const formatCloseDate = (closeDate) => {
    if (!closeDate) return <span style={{ color: 'red' }}>Missing Close Date</span>;
    const formattedDate = new Date(closeDate).toLocaleDateString();
    return isPastDue(closeDate) ? (
      <span style={{ color: 'red' }}>{formattedDate} (Past Due)</span>
    ) : (
      formattedDate
    );
  };

  const getOwnerName = (ownerId) => {
    if (!ownerId || !owners[ownerId]) {
      return <span style={{ color: 'red' }}>No Owner</span>;
    }
    return owners[ownerId].name;
  };

  const tooltipContent = (
    <Box sx={{ p: 2 }}>
      {deals && deals.length > 0 ? (
        deals.map((deal, index) => (
          <Box
            key={index}
            sx={{
              mt: 2,
              '&:not(:last-child)': {
                mb: 2,
                pb: 2,
                borderBottom: `1px solid ${theme.palette.divider}`,
              },
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 1 }}>
              {deal.dealname || 'Unnamed Deal'}
            </Typography>
            <Typography variant="body2">
              <strong>Amount:</strong>{' '}
              {deal.amount ? formatCurrency(deal.amount) : <span style={{ color: 'red' }}>Missing Amount</span>}
            </Typography>
            <Typography variant="body2">
              <strong>Close Date:</strong> {formatCloseDate(deal.closedate)}
            </Typography>
            <Typography variant="body2">
              <strong>Owner:</strong> {getOwnerName(deal.hubspot_owner_id)}
            </Typography>
            <Typography variant="body2" sx={{ color: theme.palette.text.secondary, mt: 1 }}>
              <Link 
                to={`/deals/${deal.hs_object_id}`} 
                style={{ color: linkColor }}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Deal
              </Link>
            </Typography>
          </Box>
        ))
      ) : (
        <Typography variant="body2">No deals data available</Typography>
      )}
    </Box>
  );

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement="right-start"
      transition
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 5],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            altAxis: true,
            tether: true,
            rootBoundary: 'viewport',
            padding: 8,
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['left-start', 'right-end', 'left-end'],
          },
        },
      ]}
      style={{ zIndex: 1300 }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} timeout={200}>
          <Paper
            sx={{
              ...tooltipStyles.container,
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
              boxShadow: theme.shadows[3],
              width: popperWidth,
              maxWidth: popperWidth,
              minHeight: 200,
              height: 'auto',
              maxHeight: '80vh', // Set a maximum height relative to the viewport
              overflowY: 'auto',
            }}
            onMouseEnter={(e) => e.stopPropagation()} // Prevent closing when hovering over tooltip
            onMouseLeave={onClose}
          >
            {tooltipContent}
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default KPITooltip;