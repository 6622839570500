// src/utils/frontendLogger.js v1.0.0

import { LOG_LEVEL } from '../config/environment';

// Simple browser-compatible logger

const logLevels = {
  error: 0,
  warn: 1,
  info: 2,
  debug: 3,
};

const currentLogLevel = logLevels[LOG_LEVEL.toLowerCase()]; // Convert to lowercase to match our logLevels object keys


const logger = {
  error: (message, ...args) => {
    if (currentLogLevel >= logLevels.error) {
      console.error(`[ERROR] ${message}`, ...args);
    }
  },
  warn: (message, ...args) => {
    if (currentLogLevel >= logLevels.warn) {
      console.warn(`[WARN] ${message}`, ...args);
    }
  },
  info: (message, ...args) => {
    if (currentLogLevel >= logLevels.info) {
      console.info(`[INFO] ${message}`, ...args);
    }
  },
  debug: (message, ...args) => {
    if (currentLogLevel >= logLevels.debug) {
      console.debug(`[DEBUG] ${message}`, ...args);
    }
  },
};

// Authentication logging methods
const authLogger = {
  logSignUpAttempt: (email, isSignUp) => {
    logger.info('Sign up attempt', { email, isSignUp, event: 'SIGNUP_ATTEMPT' });
  },
  logSignUpSuccess: (email) => {
    logger.info('Sign up successful', { email, event: 'SIGNUP_SUCCESS' });
  },
  logSignUpFailure: (email, reason, isSignUp) => {
    logger.error('Sign up failed', { email, reason, isSignUp, event: 'SIGNUP_FAILURE' });
  },
  logLoginAttempt: (email) => {
    logger.info('Login attempt', { email, event: 'LOGIN_ATTEMPT' });
  },
  logLoginSuccess: (email) => {
    logger.info('Login successful', { email, event: 'LOGIN_SUCCESS' });
  },
  logLoginFailure: (email, reason) => {
    logger.error('Login failed', { email, reason, event: 'LOGIN_FAILURE' });
  },
};

export const createErrorHandler = () => {
  return (error, info) => {
    logger.error('An error occurred:', error, info);
    // You could also send this error to a logging service
  };
};

export default { ...logger, ...authLogger, createErrorHandler };