import React, { useState, useEffect } from 'react';
import { Box, IconButton, Typography, Button, Drawer, List, ListItem, ListItemIcon, ListItemText, TextField, Chip, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandIcon from '@mui/icons-material/ChevronRight';
import { mockNotifications } from '../../data/mockNotifications';
import { getNotificationIcon } from '../utils/notificationUtils';

const RightSidebar = ({ open, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expanded, setExpanded] = useState(false);
  const [expandedTimeline, setExpandedTimeline] = useState(false);
  const [interactionMode, setInteractionMode] = useState('ai');
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    // Using mock data for now, replace with actual data fetching later
    setNotifications(mockNotifications);
  }, []);

  const toggleExpand = () => setExpanded(!expanded);
  const toggleTimeline = () => setExpandedTimeline(!expandedTimeline);

  const drawerWidth = isMobile ? '100vw' : (expanded ? '50vw' : '350px');

  const handleNotificationClick = (notification) => {
    // Implement navigation or action based on notification type
    console.log('Clicked notification:', notification);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
      }}
    >
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', p: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6">Notifications</Typography>
          <Box>
            {!isMobile && (
              <IconButton onClick={toggleExpand} sx={{ mr: 1 }}>
                <ExpandIcon sx={{ transform: expanded ? 'rotate(180deg)' : 'none' }} />
              </IconButton>
            )}
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        <Box mb={2} sx={{ flexGrow: expandedTimeline ? 1 : 0, overflow: 'auto' }}>
          <List sx={{ py: 0 }}>
            {notifications.slice(0, expandedTimeline ? undefined : 5).map((notification) => (
              <ListItem 
                key={notification.id} 
                sx={{ py: 0.5, cursor: 'pointer' }}
                onClick={() => handleNotificationClick(notification)}
              >
                <ListItemIcon>
                  {getNotificationIcon(notification.type, notification.status)}
                </ListItemIcon>
                <ListItemText 
                  primary={notification.text} 
                  secondary={notification.time}
                  primaryTypographyProps={{ variant: 'body2' }}
                  secondaryTypographyProps={{ variant: 'caption' }}
                />
              </ListItem>
            ))}
          </List>
          {notifications.length > 5 && (
            <Button onClick={toggleTimeline} size="small">
              {expandedTimeline ? 'Show Less' : 'Show More'}
            </Button>
          )}
        </Box>

        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Typography variant="subtitle1" mb={1}>Interaction</Typography>
          <Box display="flex" justifyContent="space-between" mb={1}>
            {['AI', 'EMAIL', 'TEAM', 'SUPPORT'].map((mode) => (
              <Chip
                key={mode}
                label={mode}
                onClick={() => setInteractionMode(mode.toLowerCase())}
                color={interactionMode === mode.toLowerCase() ? 'primary' : 'default'}
                sx={{ 
                  borderRadius: '16px',
                  '&.MuiChip-colorPrimary': {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                  }
                }}
              />
            ))}
          </Box>
          <TextField
            fullWidth
            multiline
            rows={4}
            placeholder={`Type your ${interactionMode} message here...`}
            sx={{ flexGrow: 1, mb: 1 }}
          />
          <Button variant="contained" fullWidth>
            Send
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default RightSidebar;