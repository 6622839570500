// src/contexts/utils/privateRoute.jsx
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useHubSpot } from '../../contexts/HubSpotContext';

const PrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();
  const { hubspotConnected } = useHubSpot();
  const location = useLocation();

  // If not authenticated, redirect to home
  if (!currentUser) {
    return <Navigate to="/" />;
  }

  // Don't interfere with HubSpot callback process
  if (location.pathname === '/hubspot-callback') {
    return children;
  }

  return children;
};

export default PrivateRoute;