// src/pages/PrivateForecast.jsx v1.0.5
import React, { useEffect } from 'react'
import { Box, Alert, Typography } from '@mui/material'
import ForecastDashboard from './ForecastDashboard'
import ConnectHubSpot from '../components/sales/OverviewDashboard/ConnectHubSpot'
import { useAuth } from '../contexts/AuthContext'
import { useForecast } from '../contexts/ForecastContext'
import ErrorBoundary from '../components/common/ErrorBoundary'
import logger from '../utils/frontendLogger'
import SkeletonLoader from '../utils/SkeletonLoader'
import { ForecastProvider } from '../contexts/ForecastContext'

function PrivateForecast() {
  const { userData, loading: authLoading } = useAuth()
  const { 
    loading: forecastLoading, 
    error: forecastError,
    deals,
    currentMRR,
    currentARR 
  } = useForecast()

  const isLoading = authLoading || forecastLoading;
  const hasData = deals?.length > 0 && typeof currentMRR === 'number' && typeof currentARR === 'number';

  if (isLoading) {
    return <SkeletonLoader height={600} />;
  }

  if (forecastError) {
    return (
      <Box p={4}>
        <Alert severity="error">
          {forecastError.message}
        </Alert>
      </Box>
    );
  }

  if (!userData?.hubspotConnected) {
    return <ConnectHubSpot />;
  }

  if (!hasData) {
    return (
      <Box p={4}>
        <Alert severity="info">
          Loading forecast data... This may take a few moments.
          {deals?.length === 0 && ' Waiting for deals to load.'}
        </Alert>
      </Box>
    );
  }

  return (
    <ErrorBoundary>
      <Box 
        sx={{ 
          py: { xs: 14, md: 14, lg: 20, xl: 20 },
          maxWidth: '1440px',
          margin: '20px', 
          mx: 'auto',
          px: { xs: 2, sm: 3, md: 4 }
        }}
      >
        <Box sx={{ textAlign: 'center', mb: 6 }}>
          <Typography 
            variant="h2" 
            gutterBottom 
            sx={{ 
              fontSize: { xs: '2rem', md: '3rem' },
              fontWeight: 700,
              mb: 3
            }}
          >
            Your Revenue Forecast Dashboard
          </Typography>
          <Typography 
            variant="h5" 
            sx={{ 
              mb: 4,
              color: 'text.secondary',
              maxWidth: '800px',
              mx: 'auto'
            }}
          >
            Access your personalized AI-powered revenue predictions based on your HubSpot data. 
            Generate detailed PDF reports to share forecasts with stakeholders.
          </Typography>
        </Box>
        
        <ForecastDashboard />
      </Box>
    </ErrorBoundary>
  );
}

const PrivateForecastWithContext = () => (
  <ForecastProvider>
    <PrivateForecast />
  </ForecastProvider>
);

export default PrivateForecastWithContext