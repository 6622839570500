// src/components/sales/OverviewDashboard/DataSyncStatus.jsx

import React, { useMemo } from 'react';
import { ResponsivePie } from '@nivo/pie';
import { useTheme, Box, Typography } from '@mui/material';
import { useChartStyles } from '../../hooks/useChartStyles';
import CustomTooltip from '../../charts/CustomTooltip';
import { useOverview } from '../../../contexts/OverviewContext';
import SkeletonLoader from '../../../utils/SkeletonLoader';
import { mint, melon } from '../../../theme';

const DataSyncStatus = React.memo(({ compact }) => {
  const { syncProgress, loading, error } = useOverview();
  const theme = useTheme();
  const { getCommonChartProperties, isMobile } = useChartStyles();

  const chartData = useMemo(() => {
    if (loading || error || !syncProgress) return [];

    const steps = [
      'fetchPipelineStages',
      'fetchDeals',
      'fetchOwners',
      'fetchAssociations',
      'fetchContacts',
      'fetchCompanies',
      'fetchEngagements',
      'calculateCustomMetrics',
      'structureDashboardData'
    ];

    const totalSteps = steps.length;
    const syncedSteps = steps.filter(step => syncProgress[step]?.status === 'completed').length;
    const pendingSteps = steps.filter(step => !syncProgress[step] || syncProgress[step]?.status === 'in_progress').length;
    const failedSteps = steps.filter(step => syncProgress[step]?.status === 'error').length;

    return [
      { id: 'Synced', value: syncedSteps },
      { id: 'Pending', value: pendingSteps },
      { id: 'Failed', value: failedSteps },
    ];
  }, [syncProgress, loading, error]);

  const commonProperties = getCommonChartProperties('pie');

  const tooltipContent = ({ datum }) => {
    const totalSteps = chartData.reduce((sum, item) => sum + item.value, 0);
    const percentage = (datum.value / totalSteps) * 100;
    return {
      title: 'Sync Status',
      items: [
        { label: datum.id, value: datum.value },
      ],
      comparisonItem: {
        label: 'Percentage of Total',
        value: percentage,
        percentage: percentage / 100,
      },
      formatType: 'number',
    };
  };

  if (loading) {
    return (
      <Box sx={{ width: '100%', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <SkeletonLoader variant="circular" width={200} height={200} />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">Error loading data sync status: {error}</Typography>;
  }

  return (
    <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <ResponsivePie
        data={chartData}
        {...commonProperties}
        colors={({ id }) => {
          switch (id) {
            case 'Synced':
              return theme.palette.mode === 'dark' ? theme.palette.secondary.main : mint;
            case 'Pending':
              return melon;
            case 'Failed':
              return theme.palette.error.main;
            default:
              return theme.palette.grey[300];
          }
        }}
        margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
        innerRadius={0.7}
        padAngle={0.5}
        cornerRadius={3}
        activeOuterRadiusOffset={3}
        borderWidth={1}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        isInteractive={!compact}
        tooltip={({ datum }) => (
          <CustomTooltip {...tooltipContent({ datum })} />
        )}
      />
    </Box>
  );
});

export default DataSyncStatus;