// src/components/sales/OverviewDashboard/ConnectHubSpot.jsx v1.4.0 - Last edited by AI Assistant

import React, { useState, useCallback, useEffect } from 'react';
import { 
  Box,
  Typography, 
  Button, 
  Alert, 
  Snackbar,
  Stack
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAuth } from '../../../contexts/AuthContext';
import { useHubSpot } from '../../../contexts/HubSpotContext';
import ConnectHubSpotLogo from '../../../components/home/assets/ConnectHubSpotLogo.png';
import { useLocation } from 'react-router-dom';
import logger from '../../../utils/frontendLogger';

const ConnectHubSpot = () => {
  const { userData, currentUser } = useAuth();
  const { getAuthUrl, error: contextError } = useHubSpot();
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const theme = useTheme();
  const location = useLocation();

  // Check if there's a 'welcome' parameter in the URL
  const hasWelcomeParam = new URLSearchParams(location.search).get('welcome') !== null;

  const handleConnect = () => {
    // The path storage is handled by getAuthUrl() in HubSpotContext
    // which internally uses sessionStorage.setItem('redirectPath', currentPath)
    const authUrl = getAuthUrl();
    if (authUrl !== '#') {
      window.location.href = authUrl;
    } else {
      logger.error('Failed to get HubSpot auth URL');
      setError('Authentication failed. Please try again.');
    }
  };

  const handleNotYet = () => {
    setShowModal(false);
    setShowAlert(true);
    // TODO: Implement email reminder functionality
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowAlert(false);
  };

  // Update useEffect to handle both error sources
  useEffect(() => {
    if (contextError || error) {
      setShowAlert(true);
    }
  }, [contextError, error]);

  if (userData?.hubspotConnected || hasWelcomeParam) {
    return null; // Don't render if already connected or if there's a welcome parameter
  }

  return (
    <>
      {showModal && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'rgba(0, 0, 0, 0.5)',
            zIndex: (theme) => theme.zIndex.modal,
          }}
          onClick={() => setShowModal(false)}
        >
          <Box
            sx={{
              width: '90%', // Change from 100% to 90%
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              maxWidth: {
                xs: '100%', // Full width on extra small screens
                sm: 400, // 400px on small screens and above
              },
              height: 'auto',
              spacing: 3,
              py: 8,
              px: 3,
              bgcolor: 'background.paper',
              borderRadius: 2,
              boxShadow: 24,
              m: 2, // Add margin to ensure it's not touching the edges
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <Box sx={{ mb: 6, justifyContent: 'center' }}>
              <img src={ConnectHubSpotLogo} alt="Connect HubSpot Logo" style={{ width: '150px', height: 'auto' }} />
            </Box>
            <Typography variant="h5" sx={{ mb: 1, textAlign: 'center' }}>
              Connect Your HubSpot Account
            </Typography>
            <Typography variant="body2" sx={{ mb: 5, textAlign: 'center', color: 'text.secondary' }}>
              To use our services, we need to connect to your HubSpot account. This allows us to access your sales data and provide valuable insights.
            </Typography>
            <Stack spacing={2} sx={{ width: '100%', alignItems: 'center' }}>
              <Button
                onClick={handleConnect}
                variant="contained"
                color="primary"
                sx={{ 
                  mb: 2, 
                  width: '100%', 
                  display: 'block', 
                  mx: 'auto',
                  '&:hover': {
                    transform: 'scale(1)',
                  },
                }}
              >
                Connect HubSpot
              </Button>
              <Button
                onClick={handleNotYet}
                variant="outlined"
                color="primary"
                sx={{ 
                  mb: 2, 
                  width: '100%', 
                  display: 'block', 
                  mx: 'auto',
                  '&:hover': {
                    transform: 'scale(1)',
                  },
                }}
              >
                Not yet
              </Button>
            </Stack>
          </Box>
        </Box>
      )}

      <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
          {error || 'Before you can access your dashboards, you need to connect HubSpot. We will send you a reminder via email.'}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ConnectHubSpot;