import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { collection, onSnapshot, doc } from 'firebase/firestore';
import { useFirebase } from './FirebaseContext';
import { useAuth } from './AuthContext';
import { formatCurrency } from '../components/utils/formatters';

const ForecastContext = createContext();

export const useForecast = () => {
  const context = useContext(ForecastContext);
  if (!context) {
    throw new Error('useForecast must be used within a ForecastProvider');
  }
  return context;
};

export const ForecastProvider = ({ children }) => {
  const { db } = useFirebase();
  const { currentUser } = useAuth();

  // State variables
  const [deals, setDeals] = useState([]);
  const [aggregatedData, setAggregatedData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Track separate loading states
  const [dealsLoading, setDealsLoading] = useState(true);
  const [aggregatesLoading, setAggregatesLoading] = useState(true);

  // Initialize forecast metrics
  const [forecastMetrics, setForecastMetrics] = useState({
    currentMRR: 0,
    currentARR: 0,
    forecastedDeals: {
      totalCount: 0,
      totalValue: 0,
      highConfidence: 0,
      mediumConfidence: 0,
      atRisk: 0
    },
    monthlyForecast: [],
    predictedOutcomes: [],
    riskFactors: {
      seasonality: 0,
      marketConditions: 0,
      competitorActivity: 0,
      teamCapacity: 0,
      productUpdates: 0
    },
    growthMetrics: {
      momGrowthRate: 0,
      yoyGrowthRate: 0,
      netRetention: 0
    },
    pipelineCoverage: 0
  });

  // Add after the forecastMetrics state initialization:
  const [dealHealthMetrics, setDealHealthMetrics] = useState({
    engagementHealth: {
      highEngagement: 0,
      moderateEngagement: 0,
      lowEngagement: 0,
      atRisk: 0,
      averageContactFrequency: 0,
      responseRates: 0
    },
    velocityMetrics: {
      averageDaysToClose: 0,
      dealsAboveTarget: 0,
      dealsBelowTarget: 0,
      velocityTrend: 0
    },
    qualityIndicators: {
      multiThreaded: 0,
      singleThreaded: 0,
      recentActivity: 0,
      stale: 0
    }
  });

  // Function to process deals into predicted outcomes
  const processDeals = useCallback((deals) => {
    return deals
      // First filter out closed won/lost deals
      .filter(deal => 
        deal.hs_deal_stage_probability !== 1.0 && 
        deal.hs_deal_stage_probability !== 0.0
      )
      // Then map the remaining deals
      .map(deal => ({
        id: deal.id,
        name: deal.dealname || 'Unnamed Deal',
        closeDate: deal.closedate ? new Date(deal.closedate).toLocaleDateString() : 'No date set',
        value: deal.amount || 0,
        probability: Math.round((deal.hs_deal_stage_probability || 0) * 100)
      }))
      .sort((a, b) => b.value - a.value)
      // Optionally limit to top 20
      .slice(0, 20);
  }, []);

  // Add this function after processDeals:
  const calculateDealHealth = useCallback((deals) => {
    const now = new Date();
    
    // First, filter out closed deals
    const activeDeals = deals.filter(deal => 
      deal.hs_deal_stage_probability !== 1.0 && 
      deal.hs_deal_stage_probability !== 0.0
    );
    
    return activeDeals.reduce((metrics, deal) => {
      // Engagement Level Calculation
      const contactFrequency = deal.num_contacted_notes / (deal.daysOpen || 1);
      const lastContactDays = deal.notes_last_contacted ? 
        Math.floor((now - new Date(deal.notes_last_contacted)) / (1000 * 60 * 60 * 24)) : 
        999;
      
      // Determine engagement level
      if (contactFrequency >= 0.5 && lastContactDays <= 7) {
        metrics.engagementHealth.highEngagement++;
      } else if (contactFrequency >= 0.3 && lastContactDays <= 14) {
        metrics.engagementHealth.moderateEngagement++;
      } else if (contactFrequency >= 0.1 && lastContactDays <= 30) {
        metrics.engagementHealth.lowEngagement++;
      } else {
        metrics.engagementHealth.atRisk++;
      }

      // Velocity Metrics
      const targetDays = 90; // Example target sales cycle
      if (deal.daysOpen > targetDays) {
        metrics.velocityMetrics.dealsBelowTarget++;
      } else {
        metrics.velocityMetrics.dealsAboveTarget++;
      }

      // Quality Indicators
      if (deal.num_associated_contacts > 1) {
        metrics.qualityIndicators.multiThreaded++;
      } else {
        metrics.qualityIndicators.singleThreaded++;
      }

      if (lastContactDays <= 14) {
        metrics.qualityIndicators.recentActivity++;
      } else {
        metrics.qualityIndicators.stale++;
      }

      return metrics;
    }, {
      engagementHealth: {
        highEngagement: 0,
        moderateEngagement: 0,
        lowEngagement: 0,
        atRisk: 0,
        averageContactFrequency: 0,
        responseRates: 0
      },
      velocityMetrics: {
        averageDaysToClose: deals.reduce((acc, deal) => acc + (deal.daysOpen || 0), 0) / deals.length,
        dealsAboveTarget: 0,
        dealsBelowTarget: 0,
        velocityTrend: 0
      },
      qualityIndicators: {
        multiThreaded: 0,
        singleThreaded: 0,
        recentActivity: 0,
        stale: 0
      }
    });
  }, []);

  // Effect to fetch and process deals
  useEffect(() => {
    if (!currentUser || !db) {
      setDealsLoading(false);
      return;
    }

    setDealsLoading(true);
    
    const dealsRef = collection(db, 'users', currentUser.uid, 'deals');
    
    const unsubscribe = onSnapshot(
      dealsRef,
      (snapshot) => {
        const dealsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setDeals(dealsData);
        setDealsLoading(false);
        
        // Update metrics related to deals
        const processedDeals = processDeals(dealsData);
        const healthMetrics = calculateDealHealth(dealsData);
        
        setDealHealthMetrics(healthMetrics);
        
        setForecastMetrics(prev => ({
          ...prev,
          predictedOutcomes: processedDeals,
          forecastedDeals: {
            totalCount: dealsData.length,
            totalValue: dealsData.reduce((sum, deal) => sum + (deal.amount || 0), 0),
            highConfidence: dealsData.filter(d => (d.hs_deal_stage_probability || 0) > 0.7).length,
            mediumConfidence: dealsData.filter(d => (d.hs_deal_stage_probability || 0) > 0.3 && (d.hs_deal_stage_probability || 0) <= 0.7).length,
            atRisk: dealsData.filter(d => (d.hs_deal_stage_probability || 0) <= 0.3).length
          }
        }));
      },
      (err) => {
        console.error('Error fetching deals:', err);
        setError(err);
        setDealsLoading(false);
      }
    );

    return () => unsubscribe();
  }, [currentUser, db, processDeals, calculateDealHealth]);

  // Effect to fetch and process aggregates
  useEffect(() => {
    if (!currentUser || !db) {
      setAggregatesLoading(false);
      return;
    }

    setAggregatesLoading(true);
    
    const aggregatesRef = doc(db, 'users', currentUser.uid, 'aggregates', 'revenue');
    
    const unsubscribe = onSnapshot(
      aggregatesRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          setAggregatedData(data);
          
          // Get current year's revenue data
          const currentYear = new Date().getFullYear();
          const currentYearData = data.yoyRevenueComparison?.find(
            year => year.id === `${currentYear} Revenue`
          )?.data || [];

          // Calculate growth rates
          const momGrowthRate = data.revenueGrowth || 0;
          const yoyGrowthRate = ((data.monthlyRevenue || 0) / (data.quarterlyRevenue / 3) - 1) * 100;

          // Inside the aggregates effect, update the risk factors calculation
          const calculateRiskFactors = (data) => {
            // Deal Velocity Risk
            const currentCycle = data.avgSalesCycle || 0;
            const historicalCycle = 90; // You might want to store this as a reference
            const cycleRisk = ((historicalCycle - currentCycle) / historicalCycle) * 100;

            // Deal Size Risk
            const recentDeals = data.avgDealSizeOverTime?.slice(-3) || [];
            const currentAvg = recentDeals[recentDeals.length - 1]?.avgDealSize || 0;
            const previousAvg = recentDeals[0]?.avgDealSize || 0;
            const dealSizeRisk = previousAvg ? ((currentAvg - previousAvg) / previousAvg) * 100 : 0;

            // Revenue Stability
            const monthlyRevenues = data.yoyRevenueComparison?.[0]?.data || [];
            const revenueValues = monthlyRevenues.map(m => m.y);
            const avgRevenue = revenueValues.reduce((a, b) => a + b, 0) / revenueValues.length;
            const variance = revenueValues.reduce((a, b) => a + Math.pow(b - avgRevenue, 2), 0) / revenueValues.length;
            const stabilityRisk = -1 * (Math.sqrt(variance) / avgRevenue) * 100;

            // Pipeline Health
            const pipelineRisk = ((data.totalClosedWonRevenue / (data.quarterlyRevenue * 4)) - 1) * 100;

            // Growth Momentum
            const growthRisk = data.revenueGrowth || 0;

            return {
              dealVelocity: Math.round(cycleRisk),
              dealSize: Math.round(dealSizeRisk),
              revenueStability: Math.round(stabilityRisk),
              pipelineHealth: Math.round(pipelineRisk),
              growthMomentum: Math.round(growthRisk)
            };
          };

          // Update the metrics
          setForecastMetrics(prev => ({
            ...prev,
            currentMRR: data.monthlyRevenue || 0,
            currentARR: (data.monthlyRevenue || 0) * 12,
            monthlyForecast: currentYearData.map(month => ({
              month: month.x,
              baseline: month.y,
              optimistic: month.y * 1.15,
              pessimistic: month.y * 0.85,
              actual: month.y
            })),
            growthMetrics: {
              momGrowthRate,
              yoyGrowthRate,
              netRetention: data.netRetention || 95
            },
            riskFactors: calculateRiskFactors(data),
            pipelineCoverage: (data.totalClosedWonRevenue || 0) / (data.quarterlyRevenue || 1)
          }));
          setAggregatesLoading(false);
        } else {
          setAggregatesLoading(false);
        }
      },
      (err) => {
        console.error('Error fetching aggregates:', err);
        setError(err);
        setAggregatesLoading(false);
      }
    );

    return () => unsubscribe();
  }, [currentUser, db]);

  // Update overall loading state when both deals and aggregates are loaded
  useEffect(() => {
    setLoading(dealsLoading || aggregatesLoading);
  }, [dealsLoading, aggregatesLoading]);

  const contextValue = {
    deals,
    ...forecastMetrics,
    dealHealthMetrics,
    loading,
    error,
    formatCurrency
  };

  return (
    <ForecastContext.Provider value={contextValue}>
      {children}
    </ForecastContext.Provider>
  );
};

export default ForecastProvider;