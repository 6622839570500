/**
 * Firebase configuration and initialization.
 * 
 * When REACT_APP_USE_EMULATOR is set to 'true' in the .env file,
 * this module will connect to local Firebase emulators for development.
 * 
 * Emulator ports:
 * - Auth: 3690
 * - Firestore: 5690
 * - Functions: 4690
 * - Storage: 7990
 * 
 * To use in other files, import like this:
 * import { auth, db, functions, analytics, storage } from './path/to/firebaseConfig';
 */

import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { isRunningInEmulator, EMULATOR_CONFIG, FIREBASE_CONFIG } from './config/environment';

const app = initializeApp(FIREBASE_CONFIG);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const analytics = getAnalytics(app);
const storage = getStorage(app);

if (isRunningInEmulator()) {
  connectAuthEmulator(auth, `http://${EMULATOR_CONFIG.host}:${EMULATOR_CONFIG.authPort}`);
  connectFirestoreEmulator(db, EMULATOR_CONFIG.host, parseInt(EMULATOR_CONFIG.firestorePort));
  connectFunctionsEmulator(functions, EMULATOR_CONFIG.host, parseInt(EMULATOR_CONFIG.functionsPort));
  connectStorageEmulator(storage, EMULATOR_CONFIG.host, parseInt(EMULATOR_CONFIG.storagePort));
  console.log('Firebase emulators connected');
}

export { app, auth, db, functions, analytics, storage };