// src/components/sales/RevenueDashboard/RevenueTable.jsx

import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Chip,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { format } from 'd3-format';

const RevenueTable = ({ data }) => {
  const theme = useTheme();
  const currencyFormatter = format('$,.2f');

  const getStatusChip = (status) => {
    if (status.pastDue) {
      return <Chip label="Past Due" color="error" />;
    }
    if (status.missingCloseDate) {
      return <Chip label="Missing Close Date" color="warning" />;
    }
    if (status.missingAmount) {
      return <Chip label="Missing Amount" color="warning" />;
    }
    if (status.missingOwner) {
      return <Chip label="Missing Owner" color="warning" />;
    }
    return null;
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Deal Name</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Created Date</TableCell>
            <TableCell>Close Date</TableCell>
            <TableCell>Num Notes</TableCell>
            <TableCell>Num Contacted Notes</TableCell>
            <TableCell>Deal Stage</TableCell>
            <TableCell>Owner</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((deal) => (
            <TableRow key={deal.id}>
              <TableCell>{deal.dealName}</TableCell>
              <TableCell>{currencyFormatter(deal.amount)}</TableCell>
              <TableCell>{new Date(deal.createdDate).toLocaleDateString()}</TableCell>
              <TableCell>{new Date(deal.closeDate).toLocaleDateString()}</TableCell>
              <TableCell>{deal.numNotes}</TableCell>
              <TableCell>{deal.numContactedNotes}</TableCell>
              <TableCell>{deal.dealStage}</TableCell>
              <TableCell>{deal.ownerName}</TableCell>
              <TableCell>{getStatusChip(deal)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RevenueTable;