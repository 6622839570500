// src/contexts/HubSpotContext.jsx v2.1.0
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useFirebase } from './FirebaseContext';
import { useAuth } from './AuthContext';
import { doc, onSnapshot } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { getEnvironmentConfig } from '../config/environment';
import logger from '../utils/frontendLogger';

const HubSpotContext = createContext();

export const useHubSpot = () => useContext(HubSpotContext);

export const HubSpotProvider = ({ children }) => {
  const { db } = useFirebase();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [hubspotConnected, setHubspotConnected] = useState(false);
  const [dataFetchProgress, setDataFetchProgress] = useState(null);
  const [error, setError] = useState(null);
  const { HUBSPOT_AUTH_URL, HUBSPOT_CLIENT_ID, FORMATTED_HUBSPOT_SCOPES, REDIRECT_URI } = getEnvironmentConfig();

  useEffect(() => {
    if (currentUser?.uid) {
      const userDocRef = doc(db, 'users', currentUser.uid);
      logger.info('Setting up HubSpot connection listener');
      
      const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          const newHubspotConnected = data.hubspotConnected || false;
          
          logger.info('HubSpot connection status updated', { 
            previous: hubspotConnected, 
            new: newHubspotConnected 
          });
          
          setHubspotConnected(newHubspotConnected);
        }
      });

      return () => unsubscribe();
    }
  }, [currentUser?.uid, db]);

  useEffect(() => {
    if (hubspotConnected && currentUser?.uid) {
      const progressDocRef = doc(db, 'users', currentUser.uid, 'syncProgress', 'currentSync');
      const unsubscribe = onSnapshot(progressDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          setDataFetchProgress(docSnapshot.data());
        }
      });

      return () => unsubscribe();
    }
  }, [hubspotConnected, currentUser?.uid, db]);

  const getAuthUrl = () => {
    if (!currentUser?.uid) {
      logger.error('Attempting to get auth URL without user');
      return '#';
    }

    // Store the current path before redirecting
    const currentPath = window.location.pathname;
    logger.info('Storing redirect path', { path: currentPath });
    sessionStorage.setItem('redirectPath', currentPath);
    
    // Store the state for validation
    sessionStorage.setItem('hubspotState', currentUser.uid);
    
    return `${HUBSPOT_AUTH_URL}?client_id=${HUBSPOT_CLIENT_ID}&scope=${FORMATTED_HUBSPOT_SCOPES}&redirect_uri=${REDIRECT_URI}&state=${currentUser.uid}`;
  };

  const value = {
    hubspotConnected,
    dataFetchProgress,
    error,
    setError,
    getAuthUrl
  };

  return <HubSpotContext.Provider value={value}>{children}</HubSpotContext.Provider>;
};