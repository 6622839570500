import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import HomePage from "./scenes/home";
import AdminLayout from "./scenes/admin/AdminLayout";
import SalesLayout from "./scenes/sales/SalesLayout";
import ProfileLayout from "./scenes/profile/ProfileLayout";
import PrivateRoute from "./contexts/utils/privateRoute";
import PublicRoute from "./contexts/utils/publicRoute"; // Add this import


// Import admin components
import Dashboard from "./components/admin/dashboard";
import Team from "./components/admin/team";
import Invoices from "./components/admin/invoices";
import Contacts from "./components/admin/contacts";
import Bar from "./components/admin/bar";
import Form from "./components/admin/form";
import Line from "./components/admin/line";
import Pie from "./components/admin/pie";
import FAQ from "./components/admin/faq";
import Geography from "./components/admin/geography";
import Calendar from "./components/admin/calendar/calendar";

// Import sales components
import SalesOverview from "./scenes/sales/overview";
import RevenueDashboard from "./scenes/sales/revenue";
import PipelineDashboard from "./scenes/sales/pipeline";

import InsightsDashboard from "./scenes/sales/insights";
import AIDashboard from "./scenes/sales/ai";

// Import Profile components
import UserInfo from "./components/profile/UserInfo";
import ProfileSettings from "./components/profile/ProfileSettings";
import SecuritySettings from "./components/profile/SecuritySettings";
import NotificationPreferences from "./components/profile/NotificationPreferences";
import BillingInfo from "./components/profile/BillingInfo";
import ProfileDashboard from "./components/profile/Dashboard";

// Import misc components
import InstallGuide from "./components/global/InstallGuide";
import ContactUs from "./components/home/ContactUs";
import ContactSales from "./components/home/ContactSales";
import Sandbox from "./sandbox/sandbox";
import HubSpotCallback from './components/sales/OverviewDashboard/HubSpotCallback';

// Import new components
import TermsAndConditions from "./components/home/TermsAndConditions";
import PrivacyPolicy from "./components/home/PrivacyPolicy";
import BlogPage from "./components/global/BlogPage"; // Add this import

// Import the new ComparisonPage component
import ComparisonPage from "./components/global/ComparisonPage";

// Import Layout component
import Layout from "./components/home/Layout";
import ContactLayout from "./components/home/ContactLayout";

// Import HealthcheckLanding component
import HealthcheckLanding from "./components/healthcheck_lp/healthcheck_landingpage";

// Import new AI pages

import ForecastPage from "./pages/ForecastPage"

import HealthcheckModule from "./pages/HealthcheckDashboard"
import ForecastModule from "./pages/ForecastDashboard"

// Update this import
import PrivateForecast from "./pages/PrivateForecast";

// Make sure these imports are correct and distinct
import HealthcheckPage from "./pages/HealthcheckPage"
import PrivateHealthcheck from "./pages/PrivateHealthcheck"
import SalesHealthcheckDashboard from "./scenes/sales/healthcheck"  // Rename import to be clearer

function App() {
  const [theme, colorMode] = useMode();
  const location = useLocation();

  useEffect(() => {
    // Remove the initGTM call as GTM is already initialized in index.html
    // Instead, we can log that the app has mounted
    console.log('React app mounted');
  }, []);

  useEffect(() => {
    // Push virtual pageview to dataLayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'virtualPageview',
      pageUrl: location.pathname + location.search
    });
  }, [location]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/hubspot-callback" element={
            <PrivateRoute>
              <HubSpotCallback />
            </PrivateRoute>
          } />
          <Route path="/healthcheck" element={
            <PublicRoute>
              <Layout>
                <HealthcheckPage />
              </Layout>
            </PublicRoute>
          } />
          <Route path="/healthcheck/dashboard" element={
            <PrivateRoute>
              <Layout>
                <PrivateHealthcheck />
              </Layout>
            </PrivateRoute>
          } />
          <Route path="/admin" element={<PrivateRoute><AdminLayout /></PrivateRoute>}>
            <Route index element={<Dashboard />} />
            <Route path="team" element={<Team />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="invoices" element={<Invoices />} />
            <Route path="form" element={<Form />} />
            <Route path="bar" element={<Bar />} />
            <Route path="pie" element={<Pie />} />
            <Route path="line" element={<Line />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="calendar" element={<Calendar />} />
            <Route path="geography" element={<Geography />} />
          </Route>
          <Route path="/sales" element={<PrivateRoute><SalesLayout /></PrivateRoute>}>
            <Route index element={<SalesOverview />} />
            <Route path="revenue" element={<RevenueDashboard />} />
            <Route path="pipeline" element={<PipelineDashboard />} />
            <Route path="healthcheck" element={<SalesHealthcheckDashboard />} />
            <Route path="insights" element={<InsightsDashboard />} />
            <Route path="ai" element={<AIDashboard />} />
          </Route>
          <Route path="/profile" element={<PrivateRoute><ProfileLayout /></PrivateRoute>}>
            <Route index element={<UserInfo />} />
            <Route path="settings" element={<ProfileSettings />} />
            <Route path="security" element={<SecuritySettings />} />
            <Route path="userinfo" element={<UserInfo />} />
            <Route path="dashboard" element={<ProfileDashboard />} />
            <Route path="notifications" element={<NotificationPreferences />} />
            <Route path="billing" element={<BillingInfo />} />
          </Route>
          <Route path="/install-guide" element={<InstallGuide />} />
          <Route path="/sandbox" element={<Sandbox />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/hubspot-callback" element={<HubSpotCallback />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/hubspot-comparison" element={<PublicRoute><ComparisonPage /></PublicRoute>} />
          <Route path="/contact">
            <Route
              path="general"
              element={
                <ContactLayout>
                  <ContactUs />
                </ContactLayout>
              }
            />
            <Route
              path="sales"
              element={
                <ContactLayout>
                  <ContactSales />
                </ContactLayout>
              }
            />
          </Route>
          <Route path="/forecast" element={
            <PublicRoute>
              <Layout>
                <ForecastPage />
              </Layout>
            </PublicRoute>
          } />
          <Route path="/forecast/dashboard" element={
            <PrivateRoute>
              <Layout>
                <PrivateForecast />
              </Layout>
            </PrivateRoute>
          } />
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
