// src/scenes/sales/SalesLayout.jsx

import React from 'react';
import { Outlet } from 'react-router-dom';
import {
  Box,
  useMediaQuery,
  Container,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Topbar from '../../components/global/Topbar';
import { KPIProvider } from '../../contexts/KPIContext';
import MobileKPIStrip from '../../components/global/mobileComponents/MobileKPIStrip';
import DashboardKPIs from '../../components/global/DashboardKPIs';
import { useAuth } from '../../contexts/AuthContext';

const SalesLayout = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { currentUser } = useAuth();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <Topbar />
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            mt: 3,
            mb: 2,
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {isMobile ? <MobileKPIStrip /> : <DashboardKPIs />}
        </Container>
        <Box
          sx={{ flexGrow: 1, p: 3, overflow: 'auto', minHeight: 0 }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

const SalesLayoutWithKPIProvider = () => (
  <KPIProvider>
    <SalesLayout />
  </KPIProvider>
);

export default SalesLayoutWithKPIProvider;
