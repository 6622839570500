import React, { useEffect } from 'react';
import { Typography, Container, Box, Paper, Divider } from '@mui/material';
import Footer from '../global/Footer';
import AppAppBar from './AppAppBar';

const SectionTitle = ({ children }) => (
  <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4, mb: 2, fontWeight: 'bold' }}>
    {children}
  </Typography>
);

const SubsectionTitle = ({ children }) => (
  <Typography variant="h6" component="h3" gutterBottom sx={{ mt: 3, mb: 1, fontWeight: 'bold' }}>
    {children}
  </Typography>
);

const Paragraph = ({ children }) => (
  <Typography variant="body1" paragraph>
    {children}
  </Typography>
);

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container maxWidth="md" sx={{ pt: 12 }}>
        <AppAppBar />
        <Paper elevation={3} sx={{ my: 4, p: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold' }}>
            Privacy Policy
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Last Updated: March 30th, 2024
          </Typography>
          <Divider sx={{ my: 2 }} />

          <SectionTitle>1. INTRODUCTION</SectionTitle>
          <Paragraph>
            truepipe, Inc. ("us", "we", "our", or "truepipe") empowers teams to efficiently forecast and drive revenue in B2B sales. This Privacy Policy describes how truepipe collects, uses and discloses information through our website, www.truepipe.ai, (the "Site"), our software (including application programming interfaces ("APIs"), browser extensions, and integrations with third-party software) and the services we offer (collectively referred to as the "Services"). This Privacy Policy includes specific information regarding the General Data Protection Regulation ("GDPR").
          </Paragraph>

          <SectionTitle>2. INFORMATION COLLECTION</SectionTitle>
          <SubsectionTitle>Users' Data</SubsectionTitle>
          <SubsectionTitle>Site Visitor</SubsectionTitle>
          <Paragraph>
            If you express an interest in our Services and wish to be contacted by us, you will be asked to submit the following information on our Site: full name, work email address, phone number, company name, job title. You may unsubscribe from our contact list at any time by notifying us at support@truepipe.ai.
          </Paragraph>
          <SubsectionTitle>truepipe Account</SubsectionTitle>
          <Paragraph>
            When you register to set up an account and use our Services, you will be asked to submit the following personal information: full name, work email address, phone number, company name, job title. To purchase and access our Services, you may be asked to submit payment information, which is collected and processed by our trusted third party payment processor. truepipe does not retain payment information. We also collect information from customers about their use of the Service, such as user session time and activity. This information is used internally for research and to improve our services.
          </Paragraph>
          <SubsectionTitle>Integrated Data</SubsectionTitle>
          <Paragraph>
            Our customers may choose to integrate information, including personal data, from their accounts with third party products or services for use with the Services (“Integrated Data”). The Integrated Data that we may process from our customers include information about our customers’ prospects and customers, such as the full name, business contact information (email, phone number, company address), company name, job title, professional network profiles, and similar information often stored in customer relationship management databases.
          </Paragraph>
          <SubsectionTitle>Cookies & Tracking Technologies</SubsectionTitle>
          <Paragraph>
            truepipe uses cookies and other tracking technologies to track your use of our Site and Services. When you visit our Site, we automatically receive and derive certain information that your web browser sends to our server, such as IP address, Internet Service Provider, approximate geographic location, referring/exit URLs, page requests, browser type, operating system.
          </Paragraph>
          <Paragraph>
            We also use cookies, which are small data files that are sent to your device when you visit the Site or use our Services. Cookies usually include an identification number that is unique to the device you are using. Cookies allow us to better understand our users and how they interact and use our Site and Services, and enables recognition of the user to maintain their settings and preferences. Certain third parties may also set cookies on your device when you visit our Site. These cookies serve a number of purposes, including but not limited to monitoring and analyzing how you use our Site, providing features such as social sharing widgets, for targeted advertising and measuring advertising effectiveness. You may disable these cookies via your browser settings, however, this may cause limitations to your user experience on the Site.
          </Paragraph>
          <Paragraph>
            Non-identifiable usage information may be combined with other information we collect about you that will allow you to be personally identifiable.
          </Paragraph>
          <SubsectionTitle>Do Not Track</SubsectionTitle>
          <Paragraph>
            We currently do not offer website functionality for you to opt out of any “do not track” listings.
          </Paragraph>
          <SubsectionTitle>Third Party Websites</SubsectionTitle>
          <Paragraph>
            Our Site contains links to third party websites. If you click on these links, the third party websites may collect information about you. We cannot control and are not responsible for what information third party websites process about you and encourage you to review the third party’s privacy policy and data processing practices.
          </Paragraph>

          <SectionTitle>3. USE OF YOUR INFORMATION</SectionTitle>
          <Paragraph>
            When you use our Service or interact with our Site, we process personal information for the following purposes:
          </Paragraph>
          <SubsectionTitle>Provide our Services</SubsectionTitle>
          <Paragraph>
            To set up and maintain secure access to your truepipe account and provide you with the services you requested.
          </Paragraph>
          <SubsectionTitle>Improve and Customize our Services</SubsectionTitle>
          <Paragraph>
            To better understand your needs by analyzing usage data or through your voluntary feedback so we can personalize your experience and improve our Services.
          </Paragraph>
          <SubsectionTitle>Communications</SubsectionTitle>
          <Paragraph>
            To communicate with you regarding software updates, service announcements, respond to support requests and questions, and manage our ongoing relationship with you. If you submit a request to be contacted by us on our Site, we will process your personal information in order to respond to you.
          </Paragraph>
          <SubsectionTitle>Marketing</SubsectionTitle>
          <Paragraph>
            To contact you about our new product features, promotions, events or other news we believe may be of interest to you. If you wish to unsubscribe from our marketing list, please use the unsubscribe link or contact us at support@truepipe.ai.
          </Paragraph>
          <SubsectionTitle>Legal & Compliance</SubsectionTitle>
          <Paragraph>
            To comply with our necessary legal and regulatory obligations (e.g. civil or criminal subpoenas, court orders, and other compulsory orders), and also to investigate violations and enforce our terms, agreements or policies.
          </Paragraph>

          <SectionTitle>4. INFORMATION SHARING</SectionTitle>
          <SubsectionTitle>Third Party Service Providers</SubsectionTitle>
          <Paragraph>
            truepipe shares some personal information with our vendors and third party service providers for the business purposes of helping us to provide and support our Services. These third parties include but are not limited to companies or individuals that provide us with information technology, marketing, accounting and legal services. We enter into legally binding agreements with these third-party service providers to ensure your personal information is protected.
          </Paragraph>
          <SubsectionTitle>Our Customers</SubsectionTitle>
          <Paragraph>
            truepipe gathers anonymous information from our forecasting database as part of our Services.
          </Paragraph>
          <SubsectionTitle>Advertising Partners</SubsectionTitle>
          <Paragraph>
            We may partner with third-party advertising networks and social media platforms that may use technology (e.g. a cookie) to collect information about your use of our Site over time and across other sites and services to serve ads that are tailored to your interests on our Site or other sites.
          </Paragraph>
          <SubsectionTitle>Law Enforcement</SubsectionTitle>
          <Paragraph>
            We may disclose your information to the extent that we are required to do so i) by law, to comply with lawful requests, subpoenas, search warrants or other law enforcement requirements; ii) in connection with any legal proceedings or prospective legal proceedings and to establish, exercise or defend our legal rights; iii) if we reasonably believe that you have violated any US laws or the terms of any of our agreements.
          </Paragraph>
          <SubsectionTitle>Business Reorganizations</SubsectionTitle>
          <Paragraph>
            In the event that truepipe is involved in a bankruptcy, merger, acquisition, reorganization or sale of assets, your information may be sold or transferred as part of that transaction. You will be notified via email and/or a prominent notice on our Site of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information.
          </Paragraph>

          <SectionTitle>5. ACCESS, UPDATE OR DELETE YOUR INFORMATION</SectionTitle>
          <Paragraph>
            If you are a registered user of our Services, you may be able to access and update some of your personal information via your account dashboard.
          </Paragraph>
          <Paragraph>
            If you have any questions or wish to review, update, or delete any of your information collected by us, please contact us at support@truepipe.ai. For requests by email, please include in the subject line: “Privacy Request”. We will use our best efforts to respond to your inquiry as soon as practicable, and may ask you to verify your identity before we can act on your requests.
          </Paragraph>

          <SectionTitle>6. DATA RETENTION</SectionTitle>
          <Paragraph>
            truepipe will only retain your personal information for as long as required for the relevant purpose. We will keep your personal information:
          </Paragraph>
          <Paragraph>
            Until we no longer have a business need to retain your personal information.
          </Paragraph>
          <Paragraph>
            For any legally required duration to comply with our legal obligations, resolve disputes or enforce our agreements.
          </Paragraph>
          <Paragraph>
            Upon your request to delete or update your personal information.
          </Paragraph>

          <SectionTitle>7. SECURITY MEASURES</SectionTitle>
          <Paragraph>
            We use physical and electronic safeguards to protect your information and follow industry standards. However, as the Services and Site are hosted electronically, we cannot guarantee absolute security and recommend that you also use anti-virus software, routine credit checks, firewalls, and other precautions to protect yourself from security threats. For more information about the security of the Services and our Site, please contact us at support@truepipe.ai.
          </Paragraph>

          <SectionTitle>8. GDPR - INFORMATION FOR RESIDENTS OF EEA</SectionTitle>
          <SubsectionTitle>Purpose and lawful basis of processing</SubsectionTitle>
          <Paragraph>
            truepipe processes personal data for varying purposes as explained at Section 3 above. We process personal data under the following lawful bases:
          </Paragraph>
          <Paragraph>
            With your consent. You have the right to withdraw such consent at any time and we will cease to process data after consent is withdrawn. Your consent may have been obtained by third parties on truepipe’s behalf.
          </Paragraph>
          <Paragraph>
            As necessary to perform our agreement to provide the Services.
          </Paragraph>
          <Paragraph>
            As necessary for our legitimate interest to provide the Site and Services for our customers, where those interests do not override your fundamental rights and freedom related to data privacy.
          </Paragraph>
          <Paragraph>
            For more information please see our FAQ.
          </Paragraph>
          <SubsectionTitle>Your rights in relation to your personal data</SubsectionTitle>
          <Paragraph>
            You have the following rights over the way we process personal data relating to you under GDPR. We aim to comply without undue delay, and within one month at the latest. To make a request in relation to your personal data, please contact us at support@truepipe.ai.
          </Paragraph>
          <Paragraph>
            The right to be informed - truepipe wishes to keep you informed as to what we do with your personal information. We strive to be transparent about how we use your data.
          </Paragraph>
          <Paragraph>
            The right to access - You have the right to access your information at any time.
          </Paragraph>
          <Paragraph>
            The right to rectification - If the information truepipe holds about you is inaccurate or not complete, you have the right to ask us to rectify it.
          </Paragraph>
          <Paragraph>
            The right to erasure - Sometimes called 'the right to be forgotten'. You have the right to request that truepipe erase your personal data.
          </Paragraph>
          <Paragraph>
            The right to restrict processing - You have the right to ask truepipe to restrict how we process your data. This means we are permitted to store the data but not further process it. We will only keep enough data to ensure that we can accommodate any additional requests.
          </Paragraph>
          <Paragraph>
            The right to data portability - truepipe must allow you to port and reuse your personal data for your own purposes across different platforms. This right only applies to personal data that you have provided to us as a data controller.
          </Paragraph>
          <Paragraph>
            The right to object - You have the right to object to truepipe processing your data even if our processing is due to legitimate purposes as described in our Privacy Notice.
          </Paragraph>
          <Paragraph>
            The right to withdraw consent - If you have given us your consent to process your data but change your mind later, you have the right to withdraw your consent at any time, and truepipe must stop processing your data.
          </Paragraph>
          <Paragraph>
            If you are unhappy with the way we process your personal data, please let us know in the first instance. If you do not agree with the way we have processed your data or responded to your concerns, or if you do not think we are handling your personal data adequately, you have the right to lodge a complaint with your local supervisory authority.
          </Paragraph>

          <SectionTitle>9. INTERNATIONAL TRANSFERS</SectionTitle>
          <Paragraph>
            truepipe and our service providers may collect, process, and store your personal information in the United States and other countries. When transferring data from the EU, the EEA, the United Kingdom, and Switzerland, truepipe relies upon Standard Contractual Clauses as approved by the European Commission under Decision 2021/914 of 4 June 2021.
          </Paragraph>

          <SectionTitle>10. AGE COMPLIANCE</SectionTitle>
          <Paragraph>
            We intend to fully comply with US and international laws respecting children’s privacy. Therefore, we do not collect or process any information for any persons under the age of 18. If you are under 18 please do not access the Site or Service.
          </Paragraph>

          <SectionTitle>11. PRIVACY POLICY AMENDMENTS</SectionTitle>
          <Paragraph>
            We amend this Privacy Policy from time to time. When we amend this Privacy Policy, we will modify the date listed on this Policy and/or we may contact you. We encourage you to periodically review this page for the latest information on our privacy policy practices.
          </Paragraph>

          <SectionTitle>12. CONTACT US</SectionTitle>
          <Paragraph>
            If you have any further questions about this Privacy Policy or our treatment of your personal information, please contact us at support@truepipe.ai.
          </Paragraph>
          <Paragraph>
            Our address is: 2880 Zuni Street, Denver, CO 802111, USA
          </Paragraph>
        </Paper>
      </Container>
      <Footer />
    </>
  );
}