import React, { useState, useCallback } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  Container, 
  Stack, 
  Card, 
  CardContent,
  Grid,
  useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { mint } from '../../theme';
import { trackEvent } from '../utils/analytics';
import SignupModal from '../common/SignupModal';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import TimelineIcon from '@mui/icons-material/Timeline';
import AssessmentIcon from '@mui/icons-material/Assessment';
import MockDashboard from './mockdashboard';

// Styled components (reusing some from the main Hero)
const GradientBackground = styled(Box)(({ theme }) => ({
  background: `linear-gradient(180deg, 
    ${theme.palette.background.default} 0%, 
    ${theme.palette.mode === 'dark' ? '#1a1c1e' : '#f8fafc'} 100%)`,
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '100%',
    background: `radial-gradient(circle at 50% 0%, 
      ${theme.palette.mode === 'dark' ? 'rgba(0, 176, 144, 0.15)' : 'rgba(0, 176, 144, 0.1)'} 0%, 
      transparent 70%)`,
    pointerEvents: 'none',
  }
}));

const FeatureCard = styled(Card)(({ theme }) => ({
  height: '100%',
  background: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.05)' 
    : 'rgba(255, 255, 255, 0.9)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  border: `1px solid ${theme.palette.divider}`,
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[4]
  }
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  height: 56,
  width: 56,
  borderRadius: '12px',
  backgroundColor: 'rgba(0, 176, 144, 0.1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(2)
}));

const HealthcheckLanding = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showMockDashboard, setShowMockDashboard] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleStartHealthcheck = useCallback(() => {
    trackEvent('healthcheck_cta_click', {
      event_category: 'engagement',
      event_label: 'Healthcheck Landing CTA',
      page: '/healthcheck'
    });
    setIsModalOpen(true);
  }, []);

  const handleToggleMockDashboard = useCallback(() => {
    setShowMockDashboard(prev => !prev);
  }, []);

  const features = [
    {
      icon: <WarningAmberIcon sx={{ color: mint, fontSize: '2rem' }} />,
      title: 'Risk Detection',
      description: 'AI-powered analysis identifies at-risk deals and provides actionable recommendations.'
    },
    {
      icon: <TimelineIcon sx={{ color: mint, fontSize: '2rem' }} />,
      title: 'Pipeline Velocity',
      description: 'Track deal progression and spot bottlenecks in your sales process.'
    },
    {
      icon: <AssessmentIcon sx={{ color: mint, fontSize: '2rem' }} />,
      title: 'Deal Quality Scoring',
      description: 'Get objective assessments of deal quality and likelihood to close.'
    }
  ];

  return (
    <GradientBackground>
      <Container 
        maxWidth="lg" 
        sx={{ 
          pt: { xs: 12, sm: 14, md: 16 },
          pb: { xs: 8, sm: 10, md: 12 }
        }}
      >
        {/* Hero Section */}
        <Stack 
          spacing={4} 
          alignItems="center" 
          textAlign="center"
          sx={{ mb: 8 }}
        >
          <Typography 
            variant="h1" 
            sx={{ 
              fontSize: { xs: '2.5rem', md: '4rem' },
              fontWeight: 700,
              background: `linear-gradient(135deg, ${theme.palette.text.primary} 0%, ${mint} 100%)`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              mb: 2
            }}
          >
            Free AI Pipeline Healthcheck
          </Typography>

          <Typography 
            variant="h2" 
            sx={{ 
              fontSize: { xs: '1.25rem', md: '1.5rem' },
              color: 'text.secondary',
              maxWidth: '600px'
            }}
          >
            Get instant insights into your HubSpot pipeline health with our AI-powered analysis tool.
          </Typography>

          <Stack 
            direction="row" 
            spacing={2} 
            justifyContent="center" 
            flexWrap="wrap"
            sx={{ mb: 2 }}
          >
            {['No credit card required', 'Analysis in 5 minutes', 'HubSpot certified'].map((feature, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  backgroundColor: theme.palette.mode === 'dark' 
                    ? 'rgba(255, 255, 255, 0.1)' 
                    : 'rgba(0, 0, 0, 0.05)',
                  borderRadius: '20px',
                  padding: '4px 16px',
                }}
              >
                <CheckCircleIcon sx={{ fontSize: '1rem', color: mint }} />
                <Typography variant="body2">{feature}</Typography>
              </Box>
            ))}
          </Stack>

          <Button
            variant="contained"
            size="large"
            endIcon={<ArrowRightAltIcon />}
            onClick={handleStartHealthcheck}
            sx={{
              backgroundColor: mint,
              '&:hover': {
                backgroundColor: 'rgba(0, 176, 144, 0.9)'
              },
              px: 4,
              py: 1.5,
              borderRadius: '12px',
              fontSize: '1.1rem'
            }}
          >
            Start Free Healthcheck
          </Button>
        </Stack>

        {/* Features Grid */}
        <Grid container spacing={4} sx={{ mb: 8 }}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <FeatureCard>
                <CardContent sx={{ p: 4 }}>
                  <IconWrapper>
                    {feature.icon}
                  </IconWrapper>
                  <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
                    {feature.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {feature.description}
                  </Typography>
                </CardContent>
              </FeatureCard>
            </Grid>
          ))}
        </Grid>

        {/* How It Works Section */}
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography variant="h3" gutterBottom sx={{ fontWeight: 600 }}>
            How It Works
          </Typography>
          <Grid container spacing={3} sx={{ mt: 4 }}>
            {[
              {
                step: '1',
                title: 'Connect HubSpot',
                description: 'Securely connect your HubSpot account with just a few clicks.'
              },
              {
                step: '2',
                title: 'AI Analysis',
                description: 'Our AI analyzes your pipeline data and identifies key patterns.'
              },
              {
                step: '3',
                title: 'Get Insights',
                description: 'Receive detailed insights and actionable recommendations.'
              }
            ].map((item, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Box sx={{ p: 2 }}>
                  <Typography
                    variant="h2"
                    sx={{
                      color: mint,
                      fontWeight: 700,
                      mb: 2
                    }}
                  >
                    {item.step}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Mock Dashboard Section */}
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography variant="h3" gutterBottom sx={{ fontWeight: 600 }}>
            Preview Your AI-Powered Insights
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Get a sneak peek at the powerful insights you'll receive with our AI Pipeline Healthcheck.
          </Typography>
          <Button
            variant="outlined"
            onClick={handleToggleMockDashboard}
            sx={{ mb: 4 }}
          >
            {showMockDashboard ? 'Hide Preview' : 'Show Preview'}
          </Button>
          {showMockDashboard && (
            <Box sx={{ mt: 4 }}>
              <MockDashboard data={{
                pastDue: 3,
                needsAttention: 5,
                missingAmount: 2
              }} />
            </Box>
          )}
        </Box>
      </Container>

      <SignupModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        product="healthcheck"
      />
    </GradientBackground>
  );
};

export default HealthcheckLanding;
