import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Chip,
  IconButton,
  Tooltip,
  LinearProgress,
  Button,
  Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  Cell,
  PieChart,
  Pie,
} from 'recharts';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TimelineIcon from '@mui/icons-material/Timeline';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { mint } from '../../theme';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  background: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.05)' 
    : 'rgba(255, 255, 255, 0.9)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  border: `1px solid ${theme.palette.divider}`,
}));

const MetricValue = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 600,
  color: theme.palette.text.primary,
}));

const HealthcheckDashboard = ({ data }) => {
  const [selectedTimeframe, setSelectedTimeframe] = useState('quarter');
  
  // Calculate health score (example algorithm)
  const calculateHealthScore = () => {
    const score = 75; // This would be calculated based on various metrics
    return score;
  };

  // Attention needed metrics
  const attentionMetrics = [
    {
      count: data.pastDue || 0,
      label: 'Past Due Deals',
      severity: 'error',
    },
    {
      count: data.needsAttention || 0,
      label: 'Needs Attention',
      severity: 'warning',
    },
    {
      count: data.missingAmount || 0,
      label: 'Missing Amount',
      severity: 'info',
    },
  ];

  // Deal velocity data
  const velocityData = [
    { stage: 'Qualification', avgDays: 5 },
    { stage: 'Discovery', avgDays: 12 },
    { stage: 'Proposal', avgDays: 8 },
    { stage: 'Negotiation', avgDays: 6 },
  ];

  // Engagement metrics
  const engagementData = [
    { name: 'High', value: 30, color: '#4CAF50' },
    { name: 'Medium', value: 45, color: '#FFA726' },
    { name: 'Low', value: 25, color: '#EF5350' },
  ];

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      {/* Header Section */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Pipeline Health Report
        </Typography>
        <Typography color="text.secondary">
          Last updated: {new Date().toLocaleDateString()}
        </Typography>
      </Box>

      {/* Overall Health Score */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <StyledCard>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6">Overall Health Score</Typography>
                <Tooltip title="Based on deal freshness, engagement, and pipeline velocity">
                  <IconButton size="small" sx={{ ml: 1 }}>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ position: 'relative', mb: 2 }}>
                <LinearProgress
                  variant="determinate"
                  value={calculateHealthScore()}
                  sx={{
                    height: 20,
                    borderRadius: 10,
                    bgcolor: 'rgba(0, 0, 0, 0.1)',
                    '& .MuiLinearProgress-bar': {
                      bgcolor: mint,
                    },
                  }}
                />
                <Typography
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'white',
                    fontWeight: 600,
                  }}
                >
                  {calculateHealthScore()}%
                </Typography>
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Key Metrics */}
        {attentionMetrics.map((metric, index) => (
          <Grid item xs={12} md={2.67} key={index}>
            <StyledCard>
              <CardContent>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  {metric.label}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <MetricValue>{metric.count}</MetricValue>
                  <Chip
                    size="small"
                    color={metric.severity}
                    label={metric.severity.toUpperCase()}
                  />
                </Box>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}

        {/* Deal Velocity */}
        <Grid item xs={12} md={8}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Deal Velocity by Stage
              </Typography>
              <Box sx={{ height: 300 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={velocityData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="stage" />
                    <YAxis label={{ value: 'Avg. Days', angle: -90, position: 'insideLeft' }} />
                    <RechartsTooltip />
                    <Bar dataKey="avgDays" fill={mint} />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Engagement Distribution */}
        <Grid item xs={12} md={4}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Deal Engagement
              </Typography>
              <Box sx={{ height: 300 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={engagementData}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={80}
                      label
                    >
                      {engagementData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <RechartsTooltip />
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Action Items */}
        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Recommended Actions
              </Typography>
              <Grid container spacing={2}>
                {[
                  {
                    icon: <WarningIcon color="error" />,
                    text: "Update 3 deals with past due close dates",
                  },
                  {
                    icon: <TimelineIcon color="warning" />,
                    text: "Review 5 stalled deals in discovery stage",
                  },
                  {
                    icon: <CalendarMonthIcon color="info" />,
                    text: "Schedule follow-ups for 4 low-engagement deals",
                  },
                  {
                    icon: <LocalOfferIcon color="success" />,
                    text: "Add missing amounts to 2 qualified opportunities",
                  },
                ].map((action, index) => (
                  <Grid item xs={12} md={6} key={index}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      {action.icon}
                      <Typography>{action.text}</Typography>
                      <Button variant="outlined" size="small" sx={{ ml: 'auto' }}>
                        Take Action
                      </Button>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HealthcheckDashboard;