import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { collection, doc, onSnapshot } from 'firebase/firestore';
import { useFirebase } from '../contexts/FirebaseContext';
import { useAuth } from '../contexts/AuthContext';

const HealthCheckContext = createContext(undefined);

// Move DEMO_METRICS before the component
const DEMO_METRICS = {
  accountAge: {
    years: 2,
    months: 3,
    totalDeals: 156,
    totalContacts: 892,
    inactiveMonths: 3,
    totalEngagements: 1243
  },
  dataHealth: {
    deals: {
      total: 156,
      withNulls: 79,
      percentComplete: 49,
      criticalFields: {
        amount: 65,
        stage: 82,
        owner: 91,
        closeDate: 73
      }
    },
    contacts: {
      total: 892,
      withNulls: 321,
      percentComplete: 36,
      criticalFields: {
        email: 95,
        phone: 45,
        company: 36,
        title: 52
      }
    }
  },
  activityTrends: [
    { month: 'Jun', deals: 12, contacts: 45, score: 65 },
    { month: 'Jul', deals: 15, contacts: 52, score: 72 },
    { month: 'Aug', deals: 8, contacts: 38, score: 48 },
    { month: 'Sep', deals: 6, contacts: 25, score: 35 },
    { month: 'Oct', deals: 4, contacts: 18, score: 28 },
    { month: 'Nov', deals: 3, contacts: 15, score: 22 }
  ],
  emailDeliverability: {
    rate: 97,
    bounceRate: 2,
    spamRate: 1
  },
  duplicateRate: 3.5,
  aggregates: {
    engagements: {
      callCount: 245,
      emailCount: 998
    }
  }
};

// Add new helper functions after existing ones
function calculateEmailMetrics(engagements) {
  // Only consider emails from the last 30 days
  const thirtyDaysAgo = Date.now() - (30 * 24 * 60 * 60 * 1000);
  
  const emailEngagements = engagements.filter(e => 
    e.type === 'email' && 
    e.emailStatus &&
    e.timestamp >= thirtyDaysAgo
  );
  
  const total = emailEngagements.length;
  if (total === 0) return { rate: 0, bounceRate: 0, spamRate: 0 };
  
  const delivered = emailEngagements.filter(e => 
    e.emailStatus.toUpperCase() === 'DELIVERED'
  ).length;
  
  const bounced = emailEngagements.filter(e => 
    ['BOUNCED', 'HARD_BOUNCE', 'SOFT_BOUNCE'].includes(e.emailStatus.toUpperCase())
  ).length;
  
  const spam = emailEngagements.filter(e => 
    ['SPAM', 'BLOCKED'].includes(e.emailStatus.toUpperCase())
  ).length;

  return {
    rate: Math.round((delivered / total) * 100),
    bounceRate: Math.round((bounced / total) * 100),
    spamRate: Math.round((spam / total) * 100)
  };
}

function calculateDuplicateRate(contacts) {
  if (!contacts.length) return 0;
  
  const emailGroups = contacts.reduce((acc, contact) => {
    const email = contact.properties?.email?.toLowerCase();
    if (!email) return acc;
    acc[email] = (acc[email] || 0) + 1;
    return acc;
  }, {});

  const duplicates = Object.values(emailGroups)
    .filter(count => count > 1)
    .reduce((sum, count) => sum + count - 1, 0);

  return (duplicates / contacts.length) * 100;
}

function calculateDataFreshness(lastHubspotFetch, deals, contacts, engagements) {
  const now = Date.now();
  
  const latestDealUpdate = Math.max(...deals.map(d => 
    d.hs_lastmodifieddate ? new Date(d.hs_lastmodifieddate).getTime() : 0));
  
  const latestContactUpdate = Math.max(...contacts.map(c => 
    c.properties?.lastmodifieddate ? new Date(c.properties.lastmodifieddate).getTime() : 0));
    
  const latestEngagement = Math.max(...engagements.map(e => e.timestamp || 0));
  
  const lastActivityDate = Math.max(
    latestDealUpdate,
    latestContactUpdate,
    latestEngagement,
    lastHubspotFetch || 0
  );
  
  return {
    inactiveMonths: Math.floor((now - lastActivityDate) / (1000 * 60 * 60 * 24 * 30)),
    lastActivityDate,
    dataAge: {
      deals: Math.floor((now - latestDealUpdate) / (1000 * 60 * 60 * 24)),
      contacts: Math.floor((now - latestContactUpdate) / (1000 * 60 * 60 * 24)),
      engagements: Math.floor((now - latestEngagement) / (1000 * 60 * 60 * 24))
    }
  };
}

// Add new helper functions for field completeness
function calculateCriticalFields(items, fields, type = 'deals') {
  const result = {};
  fields.forEach(field => {
    const total = items.length;
    if (total === 0) {
      result[field] = 0;
      return;
    }

    const complete = items.filter(item => {
      if (type === 'deals') {
        if (field === 'amount') return !item.missingAmount;
        if (field === 'stage') return item.dealstage;
        if (field === 'owner') return !item.missingOwner;
        if (field === 'closeDate') return !item.missingCloseDate;
      } else { // contacts
        if (field === 'email') return Boolean(item.properties?.email);
        if (field === 'phone') return Boolean(item.properties?.phone);
        if (field === 'company') return Boolean(item.properties?.company);
        if (field === 'jobtitle') return Boolean(item.properties?.jobtitle);
      }
      return false;
    }).length;

    result[field] = Math.round((complete / total) * 100);
  });
  return result;
}

function calculateNullCount(items, fields) {
  if (!items.length) return 0;
  
  return items.filter(item => {
    return fields.some(field => {
      if (field === 'amount') return item.missingAmount;
      if (field === 'stage') return !item.dealstage;
      if (field === 'owner') return item.missingOwner;
      if (field === 'closeDate') return item.missingCloseDate;
      if (field === 'email') return !item.properties?.email;
      if (field === 'phone') return !item.properties?.phone;
      if (field === 'company') return !item.properties?.company;
      if (field === 'jobtitle') return !item.properties?.jobtitle;
      return true;
    });
  }).length;
}

export function HealthCheckProvider({ children }) {
  const { db } = useFirebase();
  const { currentUser, loading: authLoading } = useAuth();
  const [metrics, setMetrics] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Raw data states
  const [userData, setUserData] = useState(null);
  const [deals, setDeals] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [engagements, setEngagements] = useState([]);
  const [aggregates, setAggregates] = useState({});

  useEffect(() => {
    if (authLoading) return;
    if (!currentUser?.uid) {
      setIsLoading(false);
      setMetrics(DEMO_METRICS); // Use demo metrics for unauthenticated users
      return;
    }

    setIsLoading(true);
    setError(null);

    const unsubscribers = [];

    try {
      const userRef = doc(db, 'users', currentUser.uid);
      const dealsRef = collection(db, 'users', currentUser.uid, 'deals');
      const contactsRef = collection(db, 'users', currentUser.uid, 'contacts');
      const engagementsRef = collection(db, 'users', currentUser.uid, 'engagements');
      const aggregatesRef = collection(db, 'users', currentUser.uid, 'aggregates');

      // Fetch user data
      const unsubUser = onSnapshot(userRef, (doc) => {
        setUserData({ id: doc.id, ...doc.data() });
      }, (err) => {
        console.error('Error fetching user data:', err);
        setError(err instanceof Error ? err : new Error('Error fetching user data'));
      });
      unsubscribers.push(unsubUser);

      // Fetch deals
      const unsubDeals = onSnapshot(dealsRef, (snapshot) => {
        const fetchedDeals = snapshot.docs
          .map(doc => {
            const data = doc.data();
            // Validate required fields according to schema
            if (!data || typeof data !== 'object') {
              console.warn(`Deal document ${doc.id} has invalid data structure`);
              return null;
            }
            return {
              id: doc.id,
              ...data,
              // Ensure critical fields exist with defaults
              amount: data.amount || 0,
              dealstage: data.dealstage || '',
              hubspot_owner_id: data.hubspot_owner_id || '',
              closedate: data.closedate || null,
              createdate: data.createdate || null
            };
          })
          .filter(Boolean); // Remove null entries
        console.log('Fetched Deals:', fetchedDeals);
        setDeals(fetchedDeals);
      }, (err) => {
        console.error('Error fetching deals:', err);
        setError(err instanceof Error ? err : new Error('Error fetching deals'));
      });
      unsubscribers.push(unsubDeals);

      // Fetch contacts
      const unsubContacts = onSnapshot(contactsRef, (snapshot) => {
        const fetchedContacts = snapshot.docs
          .map(doc => {
            const data = doc.data();
            // Preserve all properties from the original document
            return {
              id: doc.id,
              properties: data.properties || {},
            };
          })
          .filter(Boolean);
        console.log('Fetched Contacts:', fetchedContacts); // For debugging
        setContacts(fetchedContacts);
      }, (err) => {
        console.error('Error fetching contacts:', err);
        setError(err instanceof Error ? err : new Error('Error fetching contacts'));
      });
      unsubscribers.push(unsubContacts);

      // Fetch engagements
      const unsubEngagements = onSnapshot(engagementsRef, (snapshot) => {
        const fetchedEngagements = snapshot.docs
          .map(doc => {
            const data = doc.data();
            // Validate and normalize engagement fields
            return {
              id: doc.id,
              type: data.type ? data.type.toLowerCase() : 'unknown', // Normalize type
              timestamp: data.timestamp ? data.timestamp : Date.now(),
              emailStatus: data.emailStatus ? data.emailStatus.toUpperCase() : 'UNKNOWN', // For email metrics
              ownerId: data.ownerId || '',
              // Include other necessary fields if any
            };
          })
          .filter(engagement => engagement.type === 'call' || engagement.type === 'email'); // Filter valid types
        console.log('Fetched Engagements:', fetchedEngagements); // Added for debugging
        setEngagements(fetchedEngagements);
      }, (err) => {
        console.error('Error fetching engagements:', err);
        setError(err instanceof Error ? err : new Error('Error fetching engagements'));
      });
      unsubscribers.push(unsubEngagements);

      // Fetch aggregates
      const unsubAggregates = onSnapshot(aggregatesRef, (snapshot) => {
        const fetchedAggregates = {};
        snapshot.docs.forEach(doc => {
          const data = doc.data();
          // Validate and transform according to schema
          switch (doc.id) {
            case 'engagements':
              fetchedAggregates[doc.id] = {
                callCount: data?.callCount || 0,
                emailCount: data?.emailCount || 0,
                updatedAt: data?.updatedAt || Date.now()
              };
              break;
            case 'email':
              fetchedAggregates[doc.id] = {
                rate: data?.rate || 0,
                bounceRate: data?.bounceRate || 0,
                spamRate: data?.spamRate || 0
              };
              break;
            case 'contacts':
              fetchedAggregates[doc.id] = {
                duplicateRate: data?.duplicateRate || 0
              };
              break;
            default:
              fetchedAggregates[doc.id] = data;
          }
        });
        setAggregates(fetchedAggregates);
      }, (err) => {
        console.error('Error fetching aggregates:', err);
        setError(err instanceof Error ? err : new Error('Error fetching aggregates'));
      });
      unsubscribers.push(unsubAggregates);
    } catch (err) {
      console.error('Error setting up data fetching:', err);
      setError(err instanceof Error ? err : new Error('Error setting up data fetching'));
      setIsLoading(false);
      return;
    }

    return () => {
      unsubscribers.forEach(unsub => unsub());
    };
  }, [authLoading, currentUser, db]);

  // Data Transformation
  const transformedMetrics = useMemo(() => {
    if (!userData) return null;

    try {
      // Add debug logging
      console.log('Sample contact properties:', contacts[0]?.properties);
      
      // Calculate engagement metrics first
      const engagementMetrics = calculateEngagementMetrics(engagements);

      const createdAt = userData.createdAt?.toDate ? userData.createdAt.toDate() : new Date(userData.createdAt);
      const now = new Date();
      const accountAgeYears = now.getFullYear() - createdAt.getFullYear();
      const accountAgeMonths = now.getMonth() - createdAt.getMonth() + (accountAgeYears * 12);

      // Then, prepare engagement data
      const callEngagements = engagements.filter(e => e.type === 'call');
      const emailEngagements = engagements.filter(e => e.type === 'email');
      const latestCall = Math.max(...callEngagements.map(e => e.timestamp || 0));
      const latestEmail = Math.max(...emailEngagements.map(e => e.timestamp || 0));

      // Calculate deals completeness
      const dealFields = ['amount', 'stage', 'owner', 'closeDate'];
      const dealsCriticalFields = calculateCriticalFields(deals, dealFields, 'deals');
      const dealsWithNulls = deals.filter(deal => 
        dealFields.some(field => {
          if (field === 'amount') return deal.missingAmount;
          if (field === 'stage') return !deal.dealstage;
          if (field === 'owner') return deal.missingOwner;
          if (field === 'closeDate') return deal.missingCloseDate;
          return false;
        })
      ).length;

      // Calculate contacts completeness
      const contactFields = ['email', 'phone', 'company', 'jobtitle'];
      const contactsCriticalFields = calculateCriticalFields(contacts, contactFields, 'contacts');
      const contactsWithNulls = contacts.filter(contact => 
        contactFields.some(field => !contact.properties?.[field])
      ).length;

      // Calculate email metrics
      const emailMetrics = calculateEmailMetrics(engagements);

      // Calculate data freshness
      const freshness = calculateDataFreshness(
        userData.lastHubspotFetch,
        deals,
        contacts,
        engagements
      );

      // Return the complete metrics object
      return {
        accountAge: {
          years: accountAgeYears,
          months: accountAgeMonths % 12,
          totalDeals: deals.length,
          totalContacts: contacts.length,
          inactiveMonths: freshness.inactiveMonths,
          totalEngagements: engagements.length,
          lastActivityDate: freshness.lastActivityDate,
          dataAge: freshness.dataAge
        },
        dataHealth: {
          deals: {
            total: deals.length,
            withNulls: dealsWithNulls,
            percentComplete: deals.length ? 
              Math.round(((deals.length - dealsWithNulls) / deals.length) * 100) : 0,
            criticalFields: dealsCriticalFields
          },
          contacts: {
            total: contacts.length,
            withNulls: contactsWithNulls,
            percentComplete: calculateContactCompleteness(contacts, contactFields),
            criticalFields: contactsCriticalFields
          }
        },
        activityTrends: calculateActivityTrends(deals, contacts, engagements),
        emailDeliverability: emailMetrics,
        duplicateRate: calculateDuplicateRate(contacts),
        aggregates: {
          engagements: engagementMetrics  // Explicitly set the engagement metrics
        }
      };
    } catch (err) {
      console.error('Error transforming data:', err);
      setError(err);
      return null;
    }
  }, [userData, deals, contacts, engagements]);

  useEffect(() => {
    if (transformedMetrics) {
      setMetrics(transformedMetrics);
    }
    setIsLoading(false);
  }, [transformedMetrics]);

  const value = {
    metrics,
    isLoading,
    error,
    rawData: {
      userData,
      deals,
      contacts,
      engagements,
      aggregates
    }
  };

  return (
    <HealthCheckContext.Provider value={value}>
      {children}
    </HealthCheckContext.Provider>
  );
}

// Helper functions
function calculateActivityTrends(deals, contacts, engagements) {
  const months = Array.from({ length: 6 }, (_, i) => {
    const date = new Date();
    date.setMonth(date.getMonth() - i);
    return date;
  }).reverse();

  return months.map(monthDate => {
    const month = monthDate.toLocaleString('default', { month: 'short' });
    const nextMonth = new Date(monthDate.getFullYear(), monthDate.getMonth() + 1);

    // Safe date comparisons
    const monthDeals = deals.filter(deal => {
      const createDate = deal?.createdate ? new Date(deal.createdate) : null;
      return createDate && createDate >= monthDate && createDate < nextMonth;
    }).length;

    const monthContacts = contacts.filter(contact => {
      const createDate = contact?.properties?.createdate ? 
        new Date(contact.properties.createdate) : null;
      return createDate && createDate >= monthDate && createDate < nextMonth;
    }).length;

    const monthEngagements = engagements.filter(engagement => {
      const engagementDate = engagement?.timestamp ? 
        new Date(engagement.timestamp) : null;
      return engagementDate && engagementDate >= monthDate && engagementDate < nextMonth;
    }).length;

    // Calculate activity score (normalized to 100)
    const score = Math.min(100, Math.round(
      ((monthDeals * 2) + monthContacts + (monthEngagements * 0.5)) / 4
    ));

    return {
      month,
      deals: monthDeals,
      contacts: monthContacts,
      score
    };
  });
}

const calculateContactCompleteness = (contacts, fields) => {
  if (!contacts.length) return 0;
  
  const completenessScores = contacts.map(contact => {
    const filledFields = fields.filter(field => 
      Boolean(contact.properties?.[field])
    ).length;
    return (filledFields / fields.length) * 100;
  });
  
  return Math.round(
    completenessScores.reduce((sum, score) => sum + score, 0) / contacts.length
  );
};

const calculateEngagementMetrics = (engagements) => {
  if (!engagements?.length) {
    return {
      callCount: 0,
      emailCount: 0
    };
  }

  const calls = engagements.filter(e => e.type === 'call');
  const emails = engagements.filter(e => e.type === 'email');
  
  return {
    callCount: calls.length,
    emailCount: emails.length
  };
};

export function useHealthCheck() {
  const context = useContext(HealthCheckContext);
  if (!context) {
    throw new Error('useHealthCheck must be used within a HealthCheckProvider');
  }

  return context;
}