export const formatCurrency = (value, options = {}) => {
  const {
    currency = 'USD',
    locale = 'en-US',
    notation = 'standard',
    minimumFractionDigits = 0,
    maximumFractionDigits = 2,
  } = options;

  if (value === undefined || value === null) return '-';

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    notation,
    minimumFractionDigits,
    maximumFractionDigits,
  });

  return formatter.format(value);
};