// src/components/sales/InsightsDashboard/QueryBuilder.jsx

import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  ListItemText,
} from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useInsights } from '../../../contexts/InsightsContext';

const QueryBuilder = () => {
  const { updateFilters, owners, pipelineStages } = useInsights();
  const [filters, setFilters] = useState({
    dateRange: [null, null],
    pipelines: [],
    dealStages: [],
    owners: [],
    includeClosedDeals: false,
  });

  // Convert owners object to array
  const ownersList = useMemo(
    () =>
      Object.values(owners).map((owner) => ({
        value: owner.id,
        label: `${owner.firstName} ${owner.lastName}`,
      })),
    [owners]
  );

  // Construct pipelines using IDs and labels
  const pipelines = useMemo(() => {
    const pipelineMap = new Map();
    pipelineStages.forEach((stage) => {
      pipelineMap.set(stage.pipelineId, stage.pipelineLabel);
    });
    return Array.from(pipelineMap.entries()).map(([value, label]) => ({ value, label }));
  }, [pipelineStages]);

  // Filter stages based on selected pipelines
  const filteredStages = useMemo(() => {
    if (filters.pipelines.length === 0 || filters.pipelines.length === pipelines.length) {
      return pipelineStages;
    }
    return pipelineStages.filter((stage) => filters.pipelines.includes(stage.pipelineId));
  }, [pipelineStages, filters.pipelines, pipelines.length]);

  // Set default filters when data is loaded
  useEffect(() => {
    if (ownersList.length && filters.owners.length === 0) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        owners: ownersList.map((owner) => owner.value),
      }));
    }
    if (pipelines.length && filters.pipelines.length === 0) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        pipelines: pipelines.map((pipeline) => pipeline.value),
      }));
    }
    if (filteredStages.length && filters.dealStages.length === 0) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        dealStages: filteredStages.map((stage) => stage.id),
      }));
    }
  }, [ownersList, pipelines, filteredStages, filters.owners.length, filters.pipelines.length, filters.dealStages.length]);

  const handleDateChange = useCallback(
    (index) => (date) => {
      setFilters((prevFilters) => {
        const newDateRange = [...prevFilters.dateRange];
        newDateRange[index] = date;
        return { ...prevFilters, dateRange: newDateRange };
      });
    },
    []
  );

  const handleCheckboxChange = useCallback((event) => {
    setFilters((prevFilters) => ({ ...prevFilters, includeClosedDeals: event.target.checked }));
  }, []);

  const handleMultipleSelect = useCallback(
    (field) => (event) => {
      const { value } = event.target;
      setFilters((prevFilters) => {
        const allOptions = field === 'pipelines'
          ? pipelines.map((p) => p.value)
          : field === 'dealStages'
          ? filteredStages.map((s) => s.id)
          : ownersList.map((o) => o.value);

        let newValue;
        if (value[value.length - 1] === 'all') {
          if (prevFilters[field].length === allOptions.length) {
            newValue = [];
          } else {
            newValue = allOptions;
          }
        } else {
          newValue = value.filter((val) => val !== 'all');
        }

        // If pipelines change, reset dealStages
        if (field === 'pipelines') {
          return { ...prevFilters, [field]: newValue, dealStages: [] };
        }

        return { ...prevFilters, [field]: newValue };
      });
    },
    [pipelines, filteredStages, ownersList]
  );

  const handleAnalyze = useCallback(() => {
    updateFilters(filters);
  }, [updateFilters, filters]);

  return (
    <Box>
      <Grid container spacing={2}>
        {/* Filters Row */}
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12} md={2}>
              {/* Start Date Picker */}
              <DatePicker
                selected={filters.dateRange[0]}
                onChange={handleDateChange(0)}
                selectsStart
                startDate={filters.dateRange[0]}
                endDate={filters.dateRange[1]}
                placeholderText="Start Date"
                customInput={<TextField fullWidth />}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              {/* End Date Picker */}
              <DatePicker
                selected={filters.dateRange[1]}
                onChange={handleDateChange(1)}
                selectsEnd
                startDate={filters.dateRange[0]}
                endDate={filters.dateRange[1]}
                minDate={filters.dateRange[0]}
                placeholderText="End Date"
                customInput={<TextField fullWidth />}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              {/* Pipeline Select */}
              <FormControl fullWidth>
                <InputLabel>Pipeline</InputLabel>
                <Select
                  multiple
                  value={filters.pipelines}
                  onChange={handleMultipleSelect('pipelines')}
                  renderValue={(selected) =>
                    selected
                      .map((val) => pipelines.find((p) => p.value === val)?.label || val)
                      .join(', ')
                  }
                  label="Pipeline"
                >
                  <MenuItem value="all">
                    <Checkbox
                      checked={filters.pipelines.length === pipelines.length && pipelines.length > 0}
                      indeterminate={
                        filters.pipelines.length > 0 && filters.pipelines.length < pipelines.length
                      }
                    />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  {pipelines.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      <Checkbox checked={filters.pipelines.includes(value)} />
                      <ListItemText primary={label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              {/* Deal Stage Select */}
              <FormControl fullWidth>
                <InputLabel>Deal Stage</InputLabel>
                <Select
                  multiple
                  value={filters.dealStages}
                  onChange={handleMultipleSelect('dealStages')}
                  renderValue={(selected) =>
                    selected
                      .map((val) => filteredStages.find((s) => s.id === val)?.label || val)
                      .join(', ')
                  }
                  label="Deal Stage"
                  disabled={filters.pipelines.length === 0}
                >
                  <MenuItem value="all">
                    <Checkbox
                      checked={
                        filters.dealStages.length === filteredStages.length &&
                        filteredStages.length > 0
                      }
                      indeterminate={
                        filters.dealStages.length > 0 &&
                        filters.dealStages.length < filteredStages.length
                      }
                    />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  {filteredStages.map(({ id, label }) => (
                    <MenuItem key={id} value={id}>
                      <Checkbox checked={filters.dealStages.includes(id)} />
                      <ListItemText primary={label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              {/* Owner Select */}
              <FormControl fullWidth>
                <InputLabel>Owner</InputLabel>
                <Select
                  multiple
                  value={filters.owners}
                  onChange={handleMultipleSelect('owners')}
                  renderValue={(selected) =>
                    selected
                      .map((val) => ownersList.find((o) => o.value === val)?.label || val)
                      .join(', ')
                  }
                  label="Owner"
                >
                  <MenuItem value="all">
                    <Checkbox
                      checked={filters.owners.length === ownersList.length && ownersList.length > 0}
                      indeterminate={
                        filters.owners.length > 0 && filters.owners.length < ownersList.length
                      }
                    />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  {ownersList.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      <Checkbox checked={filters.owners.includes(value)} />
                      <ListItemText primary={label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        {/* Checkbox and Button Row */}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.includeClosedDeals}
                  onChange={handleCheckboxChange}
                />
              }
              label="Include Closed Deals"
            />
            <Button variant="contained" color="primary" onClick={handleAnalyze}>
              Analyze
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QueryBuilder;