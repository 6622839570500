// src/contexts/InsightsContext.jsx

import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { collection, onSnapshot, doc, getDoc } from 'firebase/firestore';
import { useFirebase } from './FirebaseContext';
import { useAuth } from './AuthContext';

const InsightsContext = createContext();

export const useInsights = () => useContext(InsightsContext);

export const InsightsProvider = ({ children }) => {
  const [filters, setFilters] = useState({});
  const [deals, setDeals] = useState([]);
  const [filteredDeals, setFilteredDeals] = useState([]);
  const [owners, setOwners] = useState({});
  const [insights, setInsights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pipelineStages, setPipelineStages] = useState([]);
  const [overallMetrics, setOverallMetrics] = useState({});

  const { db } = useFirebase();
  const { currentUser } = useAuth();

  // Define functions before they are used

  const applyFilters = useCallback(
    (dealsData, filters) => {
      let filteredDeals = [...dealsData];

      // Date Range Filter
      if (filters.dateRange && filters.dateRange[0] && filters.dateRange[1]) {
        const [startDate, endDate] = filters.dateRange;
        filteredDeals = filteredDeals.filter((deal) => {
          const dealDate = new Date(deal.createdate);
          return dealDate >= startDate && dealDate <= endDate;
        });
      }

      // Pipelines Filter
      if (filters.pipelines && filters.pipelines.length > 0) {
        filteredDeals = filteredDeals.filter((deal) =>
          filters.pipelines.includes(deal.pipeline)
        );
      }

      // Deal Stages Filter
      if (filters.dealStages && filters.dealStages.length > 0) {
        filteredDeals = filteredDeals.filter((deal) =>
          filters.dealStages.includes(deal.dealstage)
        );
      }

      // Owners Filter
      if (filters.owners && filters.owners.length > 0) {
        filteredDeals = filteredDeals.filter((deal) =>
          filters.owners.includes(deal.hubspot_owner_id)
        );
      }

      // Include Closed Deals Filter
      if (!filters.includeClosedDeals) {
        filteredDeals = filteredDeals.filter((deal) => deal.isOpenDeal);
      }

      return filteredDeals;
    },
    []
  );

  const performCalculations = (dealsData) => {
    const totalRevenue = dealsData.reduce((sum, deal) => sum + (deal.amount || 0), 0);
    const averageDealSize = totalRevenue / (dealsData.length || 1);
    const averageDealAge =
      dealsData.reduce((sum, deal) => sum + (deal.daysOpen || 0), 0) / (dealsData.length || 1);

    const dealsByMonth = {};
    dealsData.forEach((deal) => {
      const month = new Date(deal.createdate).toLocaleString('default', { month: 'short', year: 'numeric' });
      if (!dealsByMonth[month]) {
        dealsByMonth[month] = { count: 0, totalAmount: 0 };
      }
      dealsByMonth[month].count += 1;
      dealsByMonth[month].totalAmount += deal.amount || 0;
    });

    const cohortData = Object.keys(dealsByMonth).map((month) => ({
      month,
      ...dealsByMonth[month],
    }));

    return {
      revenue: {
        type: 'revenue',
        title: 'Total Revenue',
        value: totalRevenue,
        insight: `Total revenue: $${totalRevenue.toLocaleString()}`,
        historicalData: cohortData.map((data) => data.totalAmount),
      },
      averageDealSize: {
        type: 'averageDealSize',
        title: 'Average Deal Size',
        value: averageDealSize,
        insight: `Average deal size: $${averageDealSize.toLocaleString()}`,
        historicalData: cohortData.map((data) => data.totalAmount / data.count || 0),
      },
      averageDealAge: {
        type: 'averageDealAge',
        title: 'Average Deal Age',
        value: averageDealAge,
        insight: `Average deal age: ${averageDealAge.toFixed(1)} days`,
      },
      dealCount: {
        type: 'dealCount',
        title: 'Total Deals',
        value: dealsData.length,
        insight: `Total deals: ${dealsData.length}`,
        historicalData: cohortData.map((data) => data.count),
      },
    };
  };

  const updateFilters = useCallback(
    (newFilters) => {
      setFilters(newFilters);
      const filtered = applyFilters(deals, newFilters);
      setFilteredDeals(filtered);

      const calculatedInsights = performCalculations(filtered);
      setInsights(Object.values(calculatedInsights));
    },
    [deals, applyFilters]
  );

  useEffect(() => {
    if (deals.length > 0) {
      const filtered = applyFilters(deals, filters);
      setFilteredDeals(filtered);

      const calculatedInsights = performCalculations(filtered);
      setInsights(Object.values(calculatedInsights));

      const overallInsights = performCalculations(deals);
      setOverallMetrics(overallInsights);
    } else {
      setFilteredDeals([]);
      setInsights([]);
      setOverallMetrics({});
    }
  }, [deals, filters, applyFilters]);

  useEffect(() => {
    if (!currentUser) {
      setDeals([]);
      setOwners({});
      setPipelineStages([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    const dealsRef = collection(db, 'users', currentUser.uid, 'deals');
    const ownersRef = collection(db, 'users', currentUser.uid, 'owners');

    const unsubscribeDeals = onSnapshot(
      dealsRef,
      (snapshot) => {
        const dealsData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setDeals(dealsData);
        setLoading(false);
      },
      (err) => {
        console.error('Error fetching deals:', err);
        setError(err);
        setLoading(false);
      }
    );

    const unsubscribeOwners = onSnapshot(
      ownersRef,
      (snapshot) => {
        const ownersData = {};
        snapshot.forEach((doc) => {
          ownersData[doc.id] = doc.data();
        });
        setOwners(ownersData);
      },
      (err) => {
        console.error('Error fetching owners:', err);
        setError(err);
      }
    );

    const pipelineStagesRef = doc(db, 'users', currentUser.uid, 'pipelineStages', 'stages');
    getDoc(pipelineStagesRef)
      .then((docSnapshot) => {
        if (docSnapshot.exists()) {
          setPipelineStages(docSnapshot.data().stages || []);
        }
      })
      .catch((err) => {
        console.error('Error fetching pipeline stages:', err);
        setError(err);
      });

    return () => {
      unsubscribeDeals();
      unsubscribeOwners();
    };
  }, [db, currentUser]);

  const getStageLabel = (stageId) => {
    const stage = pipelineStages.find((s) => s.id === stageId);
    return stage ? stage.label : 'Unknown Stage';
  };

  const value = {
    deals,
    filteredDeals,
    owners,
    pipelineStages,
    loading,
    error,
    updateFilters,
    insights,
    overallMetrics,
    getStageLabel,
    filters,
  };

  return <InsightsContext.Provider value={value}>{children}</InsightsContext.Provider>;
};