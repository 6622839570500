// src/contexts/RawDataContext.jsx v1.1.0

import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { collection, onSnapshot, doc, getDoc } from 'firebase/firestore';
import { useFirebase } from './FirebaseContext';
import { useAuth } from './AuthContext';
import { useTheme } from '@mui/material/styles';
import { format } from 'd3-format';

// Create the RawDataContext
const RawDataContext = createContext();

// Custom hook to use the RawDataContext
export const useRawData = () => {
  const context = useContext(RawDataContext);
  if (context === undefined) {
    console.error('useRawData must be used within a RawDataProvider');
    throw new Error('useRawData must be used within a RawDataProvider');
  }
  return context;
};

// RawDataProvider component
export const RawDataProvider = ({ children }) => {
  // COMMON SECTION
  // ----------------------------------------------------------------
  // State variables for raw data
  const [deals, setDeals] = useState([]);
  const [owners, setOwners] = useState({});
  const [pipelineStages, setPipelineStages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Firebase and Auth contexts
  const { db } = useFirebase();
  const { currentUser } = useAuth();
  const theme = useTheme();
  const [filters, setFilters] = useState({});
  const [filteredDeals, setFilteredDeals] = useState([]);
  const [insights, setInsights] = useState([]);
  const applyFilters = useCallback((dealsData, filterCriteria) => {
    return dealsData.filter(deal => {
      // Implement your filter logic here
      // Example:
      return Object.entries(filterCriteria).every(([key, value]) => {
        if (!value) return true;
        return deal[key] === value;
      });
    });
  }, []);

  // Data fetching effect
  useEffect(() => {
    if (!currentUser) {
      setDeals([]);
      setOwners({});
      setPipelineStages([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    const dealsRef = collection(db, 'users', currentUser.uid, 'deals');
    const ownersRef = collection(db, 'users', currentUser.uid, 'owners');
    const pipelineStagesRef = doc(db, 'users', currentUser.uid, 'pipelineStages', 'stages');

    // Fetch deals
    const unsubscribeDeals = onSnapshot(
      dealsRef,
      (snapshot) => {
        const dealsData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setDeals(dealsData);
        setLoading(false);
      },
      (err) => {
        console.error('Error fetching deals:', err);
        setError(err);
        setLoading(false);
      }
    );

    // Fetch owners
    const unsubscribeOwners = onSnapshot(
      ownersRef,
      (snapshot) => {
        const ownersData = {};
        snapshot.forEach((doc) => {
          ownersData[doc.id] = doc.data();
        });
        setOwners(ownersData);
      },
      (err) => {
        console.error('Error fetching owners:', err);
        setError(err);
      }
    );

    // Fetch pipeline stages
    getDoc(pipelineStagesRef)
      .then((docSnapshot) => {
        if (docSnapshot.exists()) {
          setPipelineStages(docSnapshot.data().stages || []);
        }
      })
      .catch((err) => {
        console.error('Error fetching pipeline stages:', err);
        setError(err);
      });

    // Cleanup subscriptions on unmount
    return () => {
      unsubscribeDeals();
      unsubscribeOwners();
    };
  }, [db, currentUser]);

  // Common helper functions
  const formatCurrency = useCallback((value) => {
    const formatter = format('$,.0f');
    return formatter(value);
  }, []);

  const formatPercentage = (value) => {
    const formatter = format('.1%');
    return formatter(value);
  };

  const getStageLabel = (stageId) => {
    const stage = pipelineStages.find((s) => s.id === stageId);
    return stage ? stage.label : 'Unknown Stage';
  };

  // COMMON CALCULATIONS (used across all dashboards)
  // ----------------------------------------------------------------
  const calculateCommonMetrics = useCallback(() => {
    const totalDeals = deals.length;
    const totalRevenue = deals.reduce((sum, deal) => sum + (deal.amount || 0), 0);
    const averageDealSize = totalRevenue / (totalDeals || 1);

    return {
      totalDeals,
      totalRevenue,
      averageDealSize,
    };
  }, [deals]);

  // HEALTHCHECK SECTION
  // ----------------------------------------------------------------
  // Healthcheck-specific metrics
  const [overallHealthMetrics, setOverallHealthMetrics] = useState({});
  const calculateHealthcheckMetrics = useCallback(() => {
    const openDeals = deals.filter((deal) => deal.isOpenDeal);
    const totalOpenDeals = openDeals.length;
    const averageDealAge =
      openDeals.reduce((sum, deal) => sum + (deal.daysOpen || 0), 0) / (totalOpenDeals || 1);
    const averageSalesCycle =
      openDeals.reduce((sum, deal) => sum + (deal.salesCycleDays || 0), 0) / (totalOpenDeals || 1);

    const now = new Date();
    const dealsPastAvgCycle = openDeals.filter((deal) => {
      const createDate = new Date(deal.createdate);
      return (now - createDate) / (1000 * 60 * 60 * 24) > averageSalesCycle;
    }).length;

    const totalQuality = openDeals.reduce(
      (sum, deal) => sum + (deal.hs_deal_stage_probability || 0),
      0
    );
    const pipelineFreshness = (totalQuality / (totalOpenDeals || 1)) * 100;

    setOverallHealthMetrics({
      totalOpenDeals: { value: totalOpenDeals, title: 'Total Open Deals' },
      dealsPastAvgCycle: { value: dealsPastAvgCycle, title: 'Deals Past Avg Cycle' },
      pipelineFreshness: { value: pipelineFreshness, title: 'Pipeline Freshness' },
      averageSalesCycle: { value: averageSalesCycle, title: 'Avg Sales Cycle' },
    });
  }, [deals]);

  // Owner metrics for Healthcheck
  const calculateOwnerHealthMetrics = useCallback(() => {
    const now = new Date();
    const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
    const startOfQuarter = new Date(now.getFullYear(), Math.floor(now.getMonth() / 3) * 3, 1);
    const endOfQuarter = new Date(startOfQuarter.getFullYear(), startOfQuarter.getMonth() + 3, 0);

    return Object.values(owners).map((owner) => {
      const ownerDeals = deals.filter((deal) => deal.hubspot_owner_id === owner.id);
      const totalDeals = ownerDeals.length;
      const totalValue = ownerDeals.reduce((sum, deal) => sum + (deal.amount || 0), 0) || 0;

      // Existing Metrics
      const outdatedDeals = ownerDeals.filter(
        (deal) => deal.pastDue === 1 || deal.missingAmount === 1 || deal.missingCloseDate === 1
      ).length;
      const avgDealAge =
        ownerDeals.reduce((sum, deal) => sum + (deal.daysOpen || 0), 0) / (totalDeals || 1);
      const dealsClosingSoon = ownerDeals.filter((deal) => deal.closingSoon === 1).length;
      const avgWinProbability =
        ownerDeals.reduce((sum, deal) => sum + (deal.dealWinProbability || 0), 0) /
        (totalDeals || 1);
      const revenueAtRisk = ownerDeals
        .filter((deal) => deal.criticalBad === 1)
        .reduce((sum, deal) => sum + (deal.amount || 0), 0);
      const dealVelocity =
        ownerDeals.reduce((sum, deal) => sum + (deal.dealVelocity || 0), 0) / (totalDeals || 1);
      const forecastAccuracy =
        ownerDeals.reduce((sum, deal) => sum + (deal.forecastAccuracy || 0), 0) / (totalDeals || 1);

      // Health Score Calculation
      const healthScore =
        ((1 - outdatedDeals / (totalDeals || 1)) * 0.25 +
          avgWinProbability * 0.15 +
          (1 - revenueAtRisk / (totalValue || 1)) * 0.15 +
          forecastAccuracy * 0.1) *
        100;

      return {
        id: owner.id,
        owner: `${owner.firstName} ${owner.lastName}`,
        email: owner.email,
        totalDeals,
        totalValue,
        outdatedDeals,
        avgDealAge,
        dealsClosingSoon,
        avgWinProbability,
        healthScore: Math.min(Math.max(healthScore, 0), 100),
        revenueAtRisk,
        dealVelocity,
        forecastAccuracy,
        // Additional metrics can be added here
      };
    });
  }, [deals, owners]);

  // Perform calculations for insights
  const performInsightsCalculations = useCallback(
    (dealsData) => {
      const totalRevenue = dealsData.reduce((sum, deal) => sum + (deal.amount || 0), 0);
      const averageDealSize = totalRevenue / (dealsData.length || 1);
      const averageDealAge =
        dealsData.reduce((sum, deal) => sum + (deal.daysOpen || 0), 0) / (dealsData.length || 1);

      const dealsByMonth = {};
      dealsData.forEach((deal) => {
        const month = new Date(deal.createdate).toLocaleString('default', {
          month: 'short',
          year: 'numeric',
        });
        if (!dealsByMonth[month]) {
          dealsByMonth[month] = { count: 0, totalAmount: 0 };
        }
        dealsByMonth[month].count += 1;
        dealsByMonth[month].totalAmount += deal.amount || 0;
      });

      const cohortData = Object.keys(dealsByMonth).map((month) => ({
        month,
        ...dealsByMonth[month],
      }));

      return {
        revenue: {
          type: 'revenue',
          title: 'Total Revenue',
          value: totalRevenue,
          insight: `Total revenue: ${formatCurrency(totalRevenue)}`,
          historicalData: cohortData.map((data) => data.totalAmount),
        },
        // ...other insights
      };
    },
    [formatCurrency]
  );

  // Update filters and compute insights
const updateFilters = useCallback(
  (newFilters) => {
    setFilters(newFilters);
    const filtered = applyFilters(deals, newFilters);
    setFilteredDeals(filtered);

    const calculatedInsights = performInsightsCalculations(filtered);
    setInsights(Object.values(calculatedInsights));
  },
    [deals, performInsightsCalculations, applyFilters]
  );

  // REVENUE SECTION
  // ----------------------------------------------------------------
  // Revenue-specific calculations
  const [revenueMetrics, setRevenueMetrics] = useState({});
  const calculateRevenueMetrics = useCallback(() => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const lastYear = currentYear - 1;

    // Filter deals for current and last year with hs_deal_stage_probability of 1.0 or 0.0
    const closedDeals = deals.filter(
      (deal) =>
        (deal.hs_deal_stage_probability === 1.0 || deal.hs_deal_stage_probability === 0.0) &&
        (new Date(deal.closedate).getFullYear() === currentYear ||
          new Date(deal.closedate).getFullYear() === lastYear)
    );

    // Aggregate revenue data by month for current and last year
    const revenueByMonth = closedDeals.reduce(
      (acc, deal) => {
        const closeDate = new Date(deal.closedate);
        const year = closeDate.getFullYear();
        const month = closeDate.getMonth(); // 0-11
        const amount = deal.amount || 0;

        if (year === currentYear) {
          acc.currentYear[month] = (acc.currentYear[month] || 0) + amount;
        } else if (year === lastYear) {
          acc.lastYear[month] = (acc.lastYear[month] || 0) + amount;
        }
        return acc;
      },
      { currentYear: {}, lastYear: {} }
    );

    // Prepare data for chart
    const yoyRevenueData = Array.from({ length: 12 }, (_, month) => ({
      month: new Date(0, month).toLocaleString('default', { month: 'short' }),
      [currentYear]: revenueByMonth.currentYear[month] || 0,
      [lastYear]: revenueByMonth.lastYear[month] || 0,
      forecast: 0, // Placeholder for forecasted data
    }));

    // Implement forecasting logic (e.g., linear regression, moving average)
    // For now, we'll leave forecast values as placeholders

    // Update state
    setRevenueMetrics({
      yoyRevenueData,
      // ...other revenue metrics
    });
  }, [deals]);

  // Effect to recalculate metrics when deals change
  useEffect(() => {
    calculateHealthcheckMetrics();
    calculateRevenueMetrics();
    // Add other calculations as needed
  }, [deals, calculateHealthcheckMetrics, calculateRevenueMetrics]);

  // Add new state for CRM health metrics
  const [crmHealthMetrics, setCrmHealthMetrics] = useState({
    accountHealth: {
      years: 0,
      months: 0,
      totalDeals: 0,
      totalContacts: 0,
      inactiveMonths: 0,
      totalEngagements: 0,
    },
    dataQuality: {
      deals: {
        total: 0,
        withNulls: 0,
        percentComplete: 0,
        criticalFields: {
          amount: 0,
          closedate: 0,
          owner: 0,
          stage: 0,
        },
      },
      contacts: {
        total: 0,
        withNulls: 0,
        percentComplete: 0,
        criticalFields: {
          email: 0,
          firstName: 0,
          lastName: 0,
          phone: 0,
          company: 0,
        },
      },
    },
    activityTrends: Array(6).fill({
      month: '',
      deals: 0,
      contacts: 0,
      score: 0,
    }),
    emailDeliverability: {
      rate: 0,
      total: 0,
      delivered: 0,
    },
    duplicateRate: 0,
  });

  // Add new state for contacts and engagements
  const [contacts, setContacts] = useState([]);
  const [engagements, setEngagements] = useState([]);

  // Add new Firestore listeners
  useEffect(() => {
    if (!currentUser) return;

    // Add listeners for contacts and activities
    const contactsRef = collection(db, 'users', currentUser.uid, 'contacts');
    const activitiesRef = collection(db, 'users', currentUser.uid, 'activities');

    const unsubscribeContacts = onSnapshot(
      contactsRef,
      (snapshot) => {
        const contactsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setContacts(contactsData);
      },
      (err) => {
        console.error('Error fetching contacts:', err);
        setError(err);
      }
    );

    const unsubscribeActivities = onSnapshot(
      activitiesRef,
      (snapshot) => {
        const engagementsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEngagements(engagementsData);
      },
      (err) => {
        console.error('Error fetching engagements:', err);
        setError(err);
      }
    );

    return () => {
      unsubscribeContacts();
      unsubscribeActivities();
    };
  }, [db, currentUser]);

  // Add contact health calculation functions
  const calculateContactHealthMetrics = useCallback(() => {
    // Even if contacts are empty, return default values
    const totalContacts = contacts.length;

    const emailDeliverability = (() => {
      const emailEngagements = engagements.filter((e) => e.type === 'EMAIL');
      const bounced = emailEngagements.filter(
        (e) => e.mailStatus === 'BOUNCED' || e.mailStatus === 'HARD_BOUNCE'
      ).length;
      return {
        total: emailEngagements.length,
        delivered: emailEngagements.length - bounced,
        rate: emailEngagements.length
          ? ((emailEngagements.length - bounced) / emailEngagements.length) * 100
          : 0,
      };
    })();

    const criticalFields = {
      email: (contacts.filter((c) => c.email).length / (totalContacts || 1)) * 100,
      firstName: (contacts.filter((c) => c.firstName).length / (totalContacts || 1)) * 100,
      lastName: (contacts.filter((c) => c.lastName).length / (totalContacts || 1)) * 100,
      phone: (contacts.filter((c) => c.phone).length / (totalContacts || 1)) * 100,
      company: (contacts.filter((c) => c.company).length / (totalContacts || 1)) * 100,
    };

    // Additional calculations can be added here

    return {
      totalContacts,
      emailDeliverability,
      criticalFields,
      // Additional metrics can be added here
    };
  }, [contacts, engagements]);

  // Add CRM health calculation function
  const calculateCRMHealth = useCallback(() => {
    if (!deals.length) return;

    // Get contact metrics
    const contactMetrics = calculateContactHealthMetrics();

    // Calculate deal data quality
    const totalDeals = deals.length;
    const criticalDealFields = {
      amount: (deals.filter((d) => d.amount != null).length / (totalDeals || 1)) * 100,
      closedate: (deals.filter((d) => d.closedate != null).length / (totalDeals || 1)) * 100,
      owner: (deals.filter((d) => d.hubspot_owner_id != null).length / (totalDeals || 1)) * 100,
      stage: (deals.filter((d) => d.dealstage != null).length / (totalDeals || 1)) * 100,
    };
    const percentComplete =
      Object.values(criticalDealFields).reduce((sum, val) => sum + val, 0) /
      Object.keys(criticalDealFields).length;

    // Activity trends
    const activityTrends = (() => {
      const last6Months = Array.from({ length: 6 }, (_, i) => {
        const date = new Date();
        date.setMonth(date.getMonth() - i);
        return {
          month: date.toLocaleString('default', { month: 'short' }),
          deals: deals.filter(
            (deal) =>
              new Date(deal.createdate).getMonth() === date.getMonth() &&
              new Date(deal.createdate).getFullYear() === date.getFullYear()
          ).length,
          contacts: contacts.filter(
            (contact) =>
              new Date(contact.createdate).getMonth() === date.getMonth() &&
              new Date(contact.createdate).getFullYear() === date.getFullYear()
          ).length,
          score: 0, // Placeholder for score calculation
        };
      }).reverse();

      return last6Months;
    })();

    // Account age
    const accountAge = (() => {
      const oldestDealDate = deals.reduce((oldest, deal) => {
        const dealDate = new Date(deal.createdate);
        return oldest && oldest < dealDate ? oldest : dealDate;
      }, null);

      if (oldestDealDate) {
        const now = new Date();
        let years = now.getFullYear() - oldestDealDate.getFullYear();
        let months = now.getMonth() - oldestDealDate.getMonth();
        if (months < 0) {
          years -= 1;
          months += 12;
        }
        return { years, months };
      } else {
        return { years: 0, months: 0 };
      }
    })();

    // Update CRM health metrics
    setCrmHealthMetrics({
      accountHealth: {
        ...accountAge,
        totalDeals,
        totalContacts: contacts.length,
        inactiveMonths: 0, // Placeholder for inactive months calculation
        totalEngagements: engagements.length,
      },
      dataQuality: {
        deals: {
          total: totalDeals,
          withNulls: 0, // Placeholder
          percentComplete,
          criticalFields: criticalDealFields,
        },
        contacts: {
          total: contacts.length,
          withNulls: 0, // Placeholder
          percentComplete:
            Object.values(contactMetrics.criticalFields).reduce((sum, val) => sum + val, 0) /
            Object.keys(contactMetrics.criticalFields).length,
          criticalFields: contactMetrics.criticalFields,
        },
      },
      activityTrends,
      emailDeliverability: contactMetrics.emailDeliverability,
      duplicateRate: 0, // Placeholder for duplicate rate calculation
      // Additional metrics can be added here
    });
  }, [deals, contacts, engagements, calculateContactHealthMetrics]);

  // Effect to recalculate metrics when data changes
  useEffect(() => {
    if (currentUser && deals.length > 0 && contacts.length > 0) {
      calculateCRMHealth();
    }
  }, [currentUser, deals, contacts, engagements, calculateCRMHealth]);

  // Prepare value to be provided by the context
  const value = {
    // Common data and functions
    deals,
    owners,
    pipelineStages,
    loading,
    error,
    revenueMetrics,
    formatCurrency,
    formatPercentage,
    getStageLabel,
    calculateCommonMetrics,

    // Healthcheck data and functions
    overallHealthMetrics,
    calculateOwnerHealthMetrics,
    crmHealthMetrics,
    calculateCRMHealth,

    // Add new state for contacts and engagements
    contacts,
    engagements,

    // Add contact health calculation functions
    calculateContactHealthMetrics,
  };

  return <RawDataContext.Provider value={value}>{children}</RawDataContext.Provider>;
};