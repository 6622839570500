import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const SummaryCard = ({ title, value, format }) => {
  const theme = useTheme();

  const textColor = theme.palette.mode === 'dark' ? '#F5F5F5' : '#333333'; // whiteSmoke for dark mode, slateBlue for light mode

  return (
    <Paper
      elevation={3}
      sx={{
        padding: theme.spacing(3),
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'all 0.3s',
        backgroundColor: theme.palette.background.paper,
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: theme.shadows[6],
        },
      }}
    >
      <Typography variant="h6" gutterBottom sx={{ color: textColor }}>
        {title}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'center',
        }}
      >
        {typeof format === 'function' ? (
          format(value)
        ) : (
          <Typography
            variant="h4"
            component="span"
            sx={{ fontWeight: 'bold', color: textColor }}
          >
            {value}
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

export default SummaryCard;
