import React from 'react';
import { useTheme, Box, Typography, LinearProgress, useMediaQuery } from '@mui/material';
import { CheckCircle, Error, Pending } from '@mui/icons-material';
import { useOverview } from '../../../contexts/OverviewContext';
import { mint } from '../../../theme';

const SyncStep = ({ step, message, status, compact }) => {
  const theme = useTheme();
  
  const getIcon = () => {
    switch (status) {
      case 'completed':
        return <CheckCircle sx={{ color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : mint, fontSize: compact ? '0.8rem' : '.8rem' }} />;
      case 'error':
        return <Error color="error" sx={{ fontSize: compact ? '0.8rem' : '1rem' }} />;
      default:
        return <Pending color="action" sx={{ fontSize: compact ? '0.8rem' : '1rem' }} />;
    }
  };

  const displayText = status === 'completed' ? message : step;

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      p: compact ? '0.3rem' : '0.3rem',
    }}>
      {getIcon()}
      <Typography variant="body2" sx={{ ml: '0.5rem', flexGrow: 1, fontSize: compact ? '0.875rem' : '0.875rem' }} noWrap>
        {displayText}
      </Typography>
    </Box>
  );
};

const SyncProgress = ({ compact }) => {
  const { syncProgress, loading, error } = useOverview();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <Typography color="error" variant="body2" sx={{ fontSize: compact ? '0.875rem' : '0.875rem' }}>Error loading sync progress: {error}</Typography>;
  }

  const steps = [
    'fetchPipelineStages',
    'fetchDeals',
    'fetchOwners',
    'fetchAssociations',
    'fetchContacts',
    'fetchCompanies',
    'fetchEngagements',
    'calculateCustomMetrics',
    'structureDashboardData'
  ];

  const stepMapping = {
    'fetchPipelineStages': 'Fetching pipeline stages',
    'fetchDeals': 'Searching for two years of deals',
    'fetchOwners': 'Looking for sales reps',
    'fetchAssociations': 'Grabbing relevant associations',
    'fetchContacts': 'Finding associated contacts and leads',
    'fetchCompanies': 'Analyzing associated companies',
    'fetchEngagements': 'Fetching sales engagement metrics',
    'calculateCustomMetrics': 'Running advanced analysis and AI calcs',
    'structureDashboardData': 'Structuring data for presentation'
  };

  return (
    <Box sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column', 
      overflow: isMobile ? 'visible' : 'hidden' 
    }}>
      <Box sx={{ 
        flexGrow: 1, 
        overflowY: isMobile ? 'visible' : 'auto', 
        pr: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: isMobile ? 'flex-start' : 'space-between'
      }}>
        {steps.map((step) => (
          <SyncStep
            key={step}
            step={stepMapping[step]}
            message={syncProgress[step]?.message || 'Pending...'}
            status={syncProgress[step]?.status || 'pending'}
            compact={compact || isMobile}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SyncProgress;