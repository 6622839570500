// src/components/utils/formatNumber.jsx
export const formatNumber = (value, options = {}) => {
  const {
    locale = 'en-US',
    notation = 'standard',
    minimumFractionDigits = 0,
    maximumFractionDigits = 2,
  } = options;

  if (value === undefined || value === null) return '-';

  const formatter = new Intl.NumberFormat(locale, {
    notation,
    minimumFractionDigits,
    maximumFractionDigits,
    ...options,
  });

  return formatter.format(value);
};

// Usage examples:
// formatNumber(1234567.89) // "1,234,567.89"
// formatNumber(1234567.89, { notation: 'compact' }) // "1.23M"
// formatNumber(1234.56, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) // "1,234.56"
