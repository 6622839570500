import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { useFirebase } from './FirebaseContext';

const SubscriptionContext = createContext();

export const useSubscription = () => useContext(SubscriptionContext);

export const SubscriptionProvider = ({ children }) => {
  const [subscription, setSubscription] = useState('free');
  const { currentUser } = useAuth();
  const firebase = useFirebase();

  useEffect(() => {
    if (currentUser && firebase) {
      const userDocRef = firebase.firestore.doc(firebase.db, 'users', currentUser.uid);
      const unsubscribe = firebase.firestore.onSnapshot(userDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          setSubscription(userData?.subscription || 'free');
        } else {
          setSubscription('free');
        }
      }, (error) => {
        console.error("Error fetching subscription data:", error);
        setSubscription('free');
      });

      return () => unsubscribe();
    } else {
      setSubscription('free');
    }
  }, [currentUser, firebase]);

  const isPro = subscription === 'pro';

  const value = {
    subscription,
    isPro,
  };

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
};