import React from 'react';
import { Box, Typography, Container, Grid, Paper, useTheme } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SpeedIcon from '@mui/icons-material/Speed';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import InsightsIcon from '@mui/icons-material/Insights';
import SecurityIcon from '@mui/icons-material/Security';
import {
  turquoise,
  persianGreen,
  skyBlue,
  bittersweet,
  coolGray,
  tangerine,
} from '../../theme';
import { trackEvent } from '../utils/analytics';

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
  backgroundSize: '200% 200%',
  animation: `${gradientAnimation} 10s ease infinite`,
  color: 'transparent',
  WebkitBackgroundClip: 'text',
  backgroundClip: 'text',
  fontWeight: 800,
  textAlign: 'center',
  fontSize: '2.5rem',
  marginBottom: theme.spacing(4),
}));

const HighlightCard = styled(Paper)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[6],
  },
}));

// Highlights content
const highlights = [
  {
    title: 'Real-Time Pipeline Visibility',
    icon: <VisibilityIcon fontSize="large" />,
    description:
      'Gain instant insights into your entire sales pipeline. Monitor deal status, value, and progression in real-time to make informed decisions quickly.',
    color: skyBlue,
  },
  {
    title: 'Accelerate Deal Velocity',
    icon: <SpeedIcon fontSize="large" />,
    description:
      'Identify and prioritize high-potential deals. Close more business faster by focusing on opportunities that matter most.',
    color: bittersweet,
  },
  {
    title: 'Improve Forecast Accuracy',
    icon: <TrendingUpIcon fontSize="large" />,
    description:
      'Leverage AI-powered predictions for precise revenue projections. Plan confidently with accurate sales forecasts.',
    color: turquoise,
  },
  {
    title: 'Data-Driven Decision Making',
    icon: <AutoGraphIcon fontSize="large" />,
    description:
      'Make strategic decisions based on comprehensive analytics and visualizations. Transform data into actionable insights.',
    color: coolGray,
  },
  {
    title: 'Automated Sales Insights',
    icon: <InsightsIcon fontSize="large" />,
    description:
      'Save time with AI-generated insights that highlight areas for improvement. Optimize your sales strategy effortlessly.',
    color: persianGreen,
  },
  {
    title: 'Secure and Compliant',
    icon: <SecurityIcon fontSize="large" />,
    description:
      'Rest easy knowing your data is protected with enterprise-grade security measures. We prioritize your privacy and compliance.',
    color: tangerine,
  },
];

const Highlights = () => {
  const theme = useTheme();

  const handleHighlightClick = (highlightTitle) => {
    trackEvent('highlight_click', {
      event_category: 'engagement',
      event_label: `Highlight - ${highlightTitle}`,
    });
  };

  return (
    <Box
      id="highlights"
      sx={{
        py: { xs: 4, sm: 6, md: 8 },
        bgcolor: theme.palette.background.default,
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="sectionTitle">Unlock Your Sales Potential</Typography>
        <Grid container spacing={4}>
          {highlights.map((highlight, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <HighlightCard onClick={() => handleHighlightClick(highlight.title)}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: highlight.color,
                    color: 'white',
                    borderRadius: '50%',
                    width: 60,
                    height: 60,
                    mb: 2,
                  }}
                >
                  {React.cloneElement(highlight.icon, { style: { fontSize: '2rem' } })}
                </Box>
                <Typography
                  variant="h5"
                  component="h3"
                  gutterBottom
                  sx={{ fontWeight: 700 }}
                >
                  {highlight.title}
                </Typography>
                <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
                  {highlight.description}
                </Typography>
              </HighlightCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Highlights;
