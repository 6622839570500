import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  MenuItem,
  Snackbar,
  useTheme,
  Grid,
  Link,
  useMediaQuery
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ContactHero from './ContactHero';
import { Link as RouterLink } from 'react-router-dom';

const reasons = [
  'General Inquiry',
  'Product Information',
  'Technical Support',
  'Partnership Opportunity',
  'Other'
];

const ContactUs = () => {
  const [state, handleSubmit] = useForm("mgvwvqvw");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    if (state.succeeded) {
      setOpenSnackbar(true);
    }
  }, [state.succeeded]);

  const linkColor = theme.palette.mode === 'dark' ? '#33D3B8' : '#00a86b';

  return (
    <Container maxWidth="lg" sx={{ 
      mb: 2, 
      height: '100%', 
      display: 'flex', 
      alignItems: 'center',
      mt: isMobile ? 2.5 : 0 // Add top margin for mobile
    }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <ContactHero
            title="Get in touch with us"
            subtitle="We're here to help and answer any question you might have."
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ p: { xs: 2, md: 3 } }}>
            <Typography variant="h4" sx={{ mb: 2 }}>
              Contact Us
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              We're here to help and answer any question you might have.
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Name"
                    required
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    type="email"
                    required
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
              <TextField
                fullWidth
                margin="normal"
                id="company"
                name="company"
                label="Company"
                variant="outlined"
                size="small"
              />
              <TextField
                select
                fullWidth
                margin="normal"
                id="reason"
                name="reason"
                label="Reason for Contact"
                required
                variant="outlined"
                size="small"
              >
                {reasons.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                margin="normal"
                id="message"
                name="message"
                label="Message"
                multiline
                rows={4}
                required
                variant="outlined"
                size="small"
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={state.submitting}
                sx={{ mt: 2, width: '100%' }}
              >
                Submit
              </Button>
            </form>
            <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
              Looking to schedule a demo? <Link component={RouterLink} to="/contact/sales" sx={{ color: linkColor }}>Contact our sales team</Link>.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="success"
          onClose={() => setOpenSnackbar(false)}
        >
          Your message has been sent successfully!
        </MuiAlert>
      </Snackbar>
    </Container>
  );
};

export default ContactUs;