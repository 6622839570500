// src/scenes/sales/revenue.jsx

import React, { useState } from 'react';
import { Box, Typography, Grid, Tabs, Tab, useTheme, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import YoYRevenueChart from '../../components/sales/RevenueDashboard/charts/YoYRevenueChart';
import RevenueTable from '../../components/sales/RevenueDashboard/charts/RevenueTable';
import RepPerformanceVisualizations from '../../components/sales/RevenueDashboard/charts/RepPerformanceVisualizations';
import RepLeaders from '../../components/sales/RevenueDashboard/charts/RepLeaders';
import { RevenueProvider, useRevenue } from '../../contexts/RevenueContext';
import SkeletonLoader from '../../utils/SkeletonLoader';

const RevenueDashboard = () => (
  <RevenueProvider>
    <RevenueDashboardContent />
  </RevenueProvider>
);

const RevenueDashboardContent = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { yoyRevenueData, revenueTableData, repPerformanceData, loading, error, pipelineStages, selectedPipeline, setSelectedPipeline, createdDateRange, setCreatedDateRange, closedDateRange, setClosedDateRange } = useRevenue();
  const theme = useTheme();

  if (loading) {
    return <SkeletonLoader height={800} />;
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" color="error">
          Error loading revenue data: {error.message}
        </Typography>
      </Box>
    );
  }

  // Handle Pipeline Filter Change
  const handlePipelineChange = (event) => {
    setSelectedPipeline(event.target.value);
  };

  // Handle Created Date Range Change
  const handleCreatedDateRangeChange = (dates) => {
    const [start, end] = dates;
    setCreatedDateRange([start, end]);
  };

  // Handle Closed Date Range Change
  const handleClosedDateRangeChange = (dates) => {
    const [start, end] = dates;
    setClosedDateRange([start, end]);
  };

  return (
    <Box sx={{ p: 3, backgroundColor: theme.palette.background.default }}>
      <Typography variant="h4" gutterBottom sx={{ color: theme.palette.text.primary }}>
        Revenue Dashboard
      </Typography>

      {/* Global Filters */}
      <Box sx={{ mb: 3 }}>
        <Grid container spacing={2} alignItems="center">
          {/* Pipeline Filter */}
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="pipeline-filter-label">Pipeline</InputLabel>
              <Select
                labelId="pipeline-filter-label"
                value={selectedPipeline}
                label="Pipeline"
                onChange={handlePipelineChange}
              >
                <MenuItem value="All">All</MenuItem>
                {pipelineStages.map((pipeline) => (
                  <MenuItem key={pipeline.id} value={pipeline.id}>
                    {pipeline.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Created Date Range Picker */}
          <Grid item xs={12} sm={4}>
            <DatePicker
              selectsRange={true}
              startDate={createdDateRange[0]}
              endDate={createdDateRange[1]}
              onChange={handleCreatedDateRangeChange}
              isClearable={true}
              placeholderText="Select created date range"
              customInput={<TextField fullWidth />}
            />
          </Grid>

          {/* Closed Date Range Picker */}
          <Grid item xs={12} sm={4}>
            <DatePicker
              selectsRange={true}
              startDate={closedDateRange[0]}
              endDate={closedDateRange[1]}
              onChange={handleClosedDateRangeChange}
              isClearable={true}
              placeholderText="Select closed date range"
              customInput={<TextField fullWidth />}
            />
          </Grid>
        </Grid>
      </Box>

      <Tabs
        value={activeTab}
        onChange={(event, newValue) => setActiveTab(newValue)}
        sx={{ mb: 3 }}
      >
        <Tab label="Revenue Overview" />
        <Tab label="Rep Performance" />
      </Tabs>

      {activeTab === 0 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <YoYRevenueChart data={yoyRevenueData} />
          </Grid>
          <Grid item xs={12}>
            <RevenueTable data={revenueTableData} />
          </Grid>
        </Grid>
      )}

      {activeTab === 1 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RepPerformanceVisualizations data={repPerformanceData} />
          </Grid>
          <Grid item xs={12}>
            <RepLeaders data={repPerformanceData} />
          </Grid>
        </Grid>
      )}

    </Box>
  );
};

export default RevenueDashboard;