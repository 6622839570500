import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as LogoNoText } from '../../../components/home/assets/LogoNoText.svg';
import HeroVideo from '../../../components/home/assets/herovideo.mp4';
import MainHero from '../../../components/home/assets/MainHero.png';

const StyledVideo = styled('video')(({ theme }) => ({
  width: '100%',
  aspectRatio: '16/9',
  objectFit: 'cover',
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(3),
}));

const WelcomeBack = ({ onClose }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleGoToProfile = () => {
    onClose();
    navigate('/profile');
  };

  const handleGoToDashboard = () => {
    onClose();
    navigate('/sales');
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.1)',
        zIndex: (theme) => theme.zIndex.modal,
      }}
      onClick={onClose}
    >
      <Box
        variant="popup"
        sx={{
          width: '90%',
          maxWidth: {
            xs: '100%',
            sm: 600,
          },
          height: 'auto',
          maxHeight: 'calc(100vh - 2rem)',
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          overflow: 'auto',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
          <LogoNoText style={{ width: '50px', height: 'auto' }} />
        </Box>
        <Typography variant="h5" sx={{ mb: 1, textAlign: 'center', color: 'text.primary' }}>Welcome to Your Dashboard</Typography>
        <Typography variant="body2" sx={{ mb: 3, textAlign: 'center', color: 'text.secondary' }}>
          You've successfully connected to HubSpot. Explore our features in the video below.
        </Typography>

        <StyledVideo autoPlay loop muted playsInline poster={MainHero}>
          <source src={HeroVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </StyledVideo>

        <Button
          onClick={handleGoToProfile}
          variant="contained"
          color="secondary"
          fullWidth
          sx={{ mb: 2 }}
        >
          Go to Profile
        </Button>
        <Button
          onClick={handleGoToDashboard}
          variant="outlined"
          color="secondary"
          fullWidth
          sx={{ mb: 2 }}
        >
          Explore Dashboard
        </Button>
      </Box>
    </Box>
  );
};

export default WelcomeBack;