import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { collection, onSnapshot, doc, getDoc, query, where } from 'firebase/firestore';
import { useFirebase } from './FirebaseContext';
import { useAuth } from './AuthContext';
import { useTheme } from '@mui/material';
import { getChartColor } from '../components/charts/chartColors';
import { formatCurrency, formatNumber, formatPercentage, formatDate, formatWholePercentage } from '../components/utils/formatters';
import CustomTooltip from '../components/charts/CustomTooltip';

// Create the RevenueContext
const RevenueContext = createContext();

// Custom hook to use the RevenueContext
export const useRevenue = () => useContext(RevenueContext);

// RevenueProvider component
export const RevenueProvider = ({ children }) => {
  // State variables for revenue data
  const [deals, setDeals] = useState([]);
  const [owners, setOwners] = useState({});
  const [pipelineStages, setPipelineStages] = useState([]);
  const [yoyRevenueData, setYoyRevenueData] = useState([]);
  const [yoyWonRevenueData, setYoyWonRevenueData] = useState([]);
  const [revenueTableData, setRevenueTableData] = useState([]);
  const [repPerformanceData, setRepPerformanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [overallWinRate, setOverallWinRate] = useState(0);
  const [overallAverages, setOverallAverages] = useState({
    totalDeals: 0,
    closedWonDeals: 0,
    averageSalesCycle: 0,
    averageDealSize: 0,
  });

  // Global Filters
  const [selectedPipeline, setSelectedPipeline] = useState('All');
  const [createdDateRange, setCreatedDateRange] = useState([null, null]);
  const [closedDateRange, setClosedDateRange] = useState([null, null]);

  // Firebase and Auth contexts
  const { db } = useFirebase();
  const { currentUser } = useAuth();
  const theme = useTheme();

  // Data fetching effect
  useEffect(() => {
    if (!currentUser) {
      setDeals([]);
      setOwners({});
      setPipelineStages([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    const dealsRef = collection(db, 'users', currentUser.uid, 'deals');
    const ownersRef = collection(db, 'users', currentUser.uid, 'owners');
    const pipelineStagesRef = doc(db, 'users', currentUser.uid, 'pipelineStages', 'stages');

    // Apply global filters to deals query
    let dealsQuery = dealsRef;

    if (selectedPipeline !== 'All') {
      dealsQuery = query(dealsRef, where('pipeline', '==', selectedPipeline));
    }

    if (createdDateRange[0] && createdDateRange[1]) {
      dealsQuery = query(
        dealsQuery,
        where('createdate', '>=', createdDateRange[0]),
        where('createdate', '<=', createdDateRange[1])
      );
    }

    if (closedDateRange[0] && closedDateRange[1]) {
      dealsQuery = query(
        dealsQuery,
        where('closedate', '>=', closedDateRange[0]),
        where('closedate', '<=', closedDateRange[1])
      );
    }

    // Fetch deals with applied filters
    const unsubscribeDeals = onSnapshot(
      dealsQuery,
      (snapshot) => {
        const dealsData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setDeals(dealsData);
        setLoading(false);
      },
      (err) => {
        console.error('Error fetching deals:', err);
        setError(err);
        setLoading(false);
      }
    );

    // Fetch owners
    const unsubscribeOwners = onSnapshot(
      ownersRef,
      (snapshot) => {
        const ownersData = {};
        snapshot.forEach((doc) => {
          ownersData[doc.id] = doc.data();
        });
        setOwners(ownersData);
      },
      (err) => {
        console.error('Error fetching owners:', err);
        setError(err);
      }
    );

    // Fetch pipeline stages
    getDoc(pipelineStagesRef)
      .then((docSnapshot) => {
        if (docSnapshot.exists()) {
          setPipelineStages(docSnapshot.data().stages || []);
        }
      })
      .catch((err) => {
        console.error('Error fetching pipeline stages:', err);
        setError(err);
      });

    // Cleanup subscriptions on unmount
    return () => {
      unsubscribeDeals();
      unsubscribeOwners();
    };
  }, [db, currentUser, selectedPipeline, createdDateRange, closedDateRange]);

  // Helper function to get owner name
  const getOwnerName = useCallback(
    (ownerId) => {
      const owner = owners[ownerId];
      return owner ? `${owner.firstName} ${owner.lastName}` : 'Unknown Owner';
    },
    [owners]
  );

  // Helper function to get stage label
  const getStageLabel = useCallback(
    (dealStage, pipelineId) => {
      const stage = pipelineStages.find(
        (s) => s.id === dealStage && s.pipelineId === pipelineId
      );
      return stage ? stage.label : 'Unknown Stage';
    },
    [pipelineStages]
  );

  // Calculate YoY Revenue Data
  const calculateYoYRevenue = useCallback(() => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const lastYear = currentYear - 1;

    const closedDeals = deals.filter((deal) => {
      const closeDate = new Date(deal.closedate);
      const year = closeDate.getFullYear();
      return (
        (deal.hs_deal_stage_probability === 1.0 || deal.hs_deal_stage_probability === 0.0) &&
        (year === currentYear || year === lastYear)
      );
    });

    const revenueByMonth = closedDeals.reduce(
      (acc, deal) => {
        const closeDate = new Date(deal.closedate);
        const year = closeDate.getFullYear();
        const month = closeDate.getMonth(); // 0-11
        const amount = deal.amount || 0;

        if (!acc[year]) acc[year] = {};
        acc[year][month] = (acc[year][month] || 0) + amount;
        return acc;
      },
      {}
    );

    // Prepare data for chart
    const data = Array.from({ length: 12 }, (_, month) => {
      const monthName = new Date(0, month).toLocaleString('default', { month: 'short' });
      const currentYearRevenue = revenueByMonth[currentYear]?.[month] || 0;
      const lastYearRevenue = revenueByMonth[lastYear]?.[month] || 0;
      const entry = {
        month: monthName,
        [lastYear]: lastYearRevenue,
        [currentYear]: currentYearRevenue,
      };

      // Add forecast for remaining months of the current year
      if (month > now.getMonth()) {
        const monthsElapsed = now.getMonth() + 1;
        const totalRevenueSoFar = Object.values(revenueByMonth[currentYear] || {}).reduce(
          (sum, value) => sum + value,
          0
        );
        const averageMonthlyRevenue = monthsElapsed > 0 ? totalRevenueSoFar / monthsElapsed : 0;
        entry.forecast = averageMonthlyRevenue;
      }

      return entry;
    });

    setYoyRevenueData(data);
  }, [deals]);

  // Calculate YoY Won Revenue Data
  const calculateYoYWonRevenue = useCallback(() => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const lastYear = currentYear - 1;

    const wonDeals = deals.filter((deal) => {
      const closeDate = new Date(deal.closedate);
      const year = closeDate.getFullYear();
      return (
        deal.hs_deal_stage_probability === 1.0 &&
        (year === currentYear || year === lastYear)
      );
    });

    const revenueByMonth = wonDeals.reduce(
      (acc, deal) => {
        const closeDate = new Date(deal.closedate);
        const year = closeDate.getFullYear();
        const month = closeDate.getMonth(); // 0-11
        const amount = deal.amount || 0;

        if (!acc[year]) acc[year] = {};
        acc[year][month] = (acc[year][month] || 0) + amount;
        return acc;
      },
      {}
    );

    // Prepare data for chart (same as before)
    const data = Array.from({ length: 12 }, (_, month) => {
      const monthName = new Date(0, month).toLocaleString('default', { month: 'short' });
      const currentYearRevenue = revenueByMonth[currentYear]?.[month] || 0;
      const lastYearRevenue = revenueByMonth[lastYear]?.[month] || 0;
      const entry = {
        month: monthName,
        [lastYear]: lastYearRevenue,
        [currentYear]: currentYearRevenue,
      };

      // Add forecast for remaining months of the current year
      if (month > now.getMonth()) {
        const monthsElapsed = now.getMonth() + 1;
        const totalRevenueSoFar = Object.values(revenueByMonth[currentYear] || {}).reduce(
          (sum, value) => sum + value,
          0
        );
        const averageMonthlyRevenue = monthsElapsed > 0 ? totalRevenueSoFar / monthsElapsed : 0;
        entry.forecast = averageMonthlyRevenue;
      }

      return entry;
    });

    return data;
  }, [deals]);

  // Prepare data for Revenue Table
  const prepareRevenueTableData = useCallback(() => {
    const tableData = deals
      .filter((deal) => deal.hs_deal_stage_probability === 1.0 || deal.hs_deal_stage_probability === 0.0)
      .map((deal) => {
        const ownerName = getOwnerName(deal.hubspot_owner_id);
        const stageLabel = getStageLabel(deal.dealstage, deal.pipeline);
        return {
          id: deal.id,
          dealName: deal.dealname,
          amount: deal.amount || 0,
          createdDate: deal.createdate,
          closeDate: deal.closedate,
          numNotes: deal.num_notes || 0,
          numContactedNotes: deal.num_contacted_notes || 0,
          ownerName,
          dealStage: stageLabel,
          pastDue: deal.pastDue === 1,
          missingCloseDate: deal.missingCloseDate === 1,
          missingAmount: deal.missingAmount === 1,
          missingOwner: deal.missingOwner === 1,
        };
      });

    setRevenueTableData(tableData);
  }, [deals, getOwnerName, getStageLabel]);

  // Updated calculateRepPerformance function
  const calculateRepPerformance = useCallback(() => {
    const now = new Date();
    const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
    const ownerIds = Object.keys(owners);
    
    let totalClosedDeals = 0;
    let totalClosedWonDeals = 0;
    let totalDealsSum = 0;
    let closedWonDealsSum = 0;
    let salesCycleSum = 0;
    let dealSizeSum = 0;
    let repCount = 0;

    const performanceData = ownerIds
      .map((ownerId) => {
        const ownerDeals = deals.filter((deal) => deal.hubspot_owner_id === ownerId);
        const totalDeals = ownerDeals.length;

        // Skip owners with zero deals
        if (totalDeals === 0) {
          return null;
        }

        const closedWonDeals = ownerDeals.filter((deal) => deal.hs_deal_stage_probability === 1.0);
        const closedLostDeals = ownerDeals.filter((deal) => deal.hs_deal_stage_probability === 0.0);

        // Calculate Won Revenue
        const wonRevenue = closedWonDeals.reduce((sum, deal) => sum + (deal.amount || 0), 0);

        // Engagement Metrics
        const totalNotes = ownerDeals.reduce((sum, deal) => sum + (deal.num_notes || 0), 0);
        const avgNotesPerDeal = totalDeals > 0 ? totalNotes / totalDeals : 0;
        const idealNotesPerDeal = 5; // Adjust as per your standard

        const dealsWithNextActivity = ownerDeals.filter((deal) => deal.nextactivitydate).length;
        const percentageDealsWithNextActivity = totalDeals > 0 ? (dealsWithNextActivity / totalDeals) * 100 : 0;

        const dealsContactedRecently = ownerDeals.filter((deal) => {
          if (deal.notes_last_contacted) {
            const lastContactedDate = new Date(deal.notes_last_contacted);
            return lastContactedDate >= sevenDaysAgo;
          }
          return false;
        }).length;
        const percentageDealsContactedRecently = totalDeals > 0 ? (dealsContactedRecently / totalDeals) * 100 : 0;

        // Engagement Score Calculation
        const engagementScore =
          ((avgNotesPerDeal / idealNotesPerDeal) * 0.4 +
          (percentageDealsWithNextActivity / 100) * 0.3 +
          (percentageDealsContactedRecently / 100) * 0.3) * 100;

        // Ensure score is between 0 and 100
        const normalizedEngagementScore = Math.min(Math.max(engagementScore, 0), 100);

        const totalClosedDealsOwner = closedWonDeals.length + closedLostDeals.length;
        const winRate = totalClosedDealsOwner > 0 ? (closedWonDeals.length / totalClosedDealsOwner) * 100 : 0;

        const totalAmount = ownerDeals.reduce((sum, deal) => sum + (deal.amount || 0), 0);
        const averageDealSize = totalDeals > 0 ? totalAmount / totalDeals : 0;

        // Calculate average sales cycle (similar to avgDealAge in RawDataContext.jsx)
        const averageSalesCycle = totalDeals > 0 
          ? ownerDeals.reduce((sum, deal) => sum + (deal.daysOpen || 0), 0) / totalDeals 
          : 0;

        // Update sums for overall averages
        totalDealsSum += totalDeals;
        closedWonDealsSum += closedWonDeals.length;
        salesCycleSum += averageSalesCycle;
        dealSizeSum += averageDealSize;
        repCount++;

        return {
          ownerId,
          ownerName: getOwnerName(ownerId),
          totalDeals,
          closedWonDeals: closedWonDeals.length,
          wonRevenue, // Add this line
          engagementScore: normalizedEngagementScore,
          winRate: isNaN(winRate) ? 0 : winRate,
          averageDealSize: isNaN(averageDealSize) ? 0 : averageDealSize,
          averageSalesCycle: isNaN(averageSalesCycle) ? 0 : averageSalesCycle,
          totalAmount,
          pipelineId: owners[ownerId]?.pipelineId || 'Unknown', // Ensure pipelineId is included
        };
      })
      .filter(Boolean); // Remove null entries (owners with zero deals)

    // Calculate overall win rate
    const calculatedOverallWinRate = totalClosedDeals > 0 
      ? (totalClosedWonDeals / totalClosedDeals) * 100 
      : 0;

    // Calculate overall averages
    setOverallAverages({
      totalDeals: repCount > 0 ? totalDealsSum / repCount : 0,
      closedWonDeals: repCount > 0 ? closedWonDealsSum / repCount : 0,
      averageSalesCycle: repCount > 0 ? salesCycleSum / repCount : 0,
      averageDealSize: repCount > 0 ? dealSizeSum / repCount : 0,
    });

    setOverallWinRate(calculatedOverallWinRate);
    setRepPerformanceData(performanceData);
  }, [deals, owners, getOwnerName]);

  // Recalculate metrics when data changes
  useEffect(() => {
    if (deals.length > 0 && Object.keys(owners).length > 0 && pipelineStages.length > 0) {
      calculateYoYRevenue();
      setYoyWonRevenueData(calculateYoYWonRevenue());
      prepareRevenueTableData();
      calculateRepPerformance();
    }
  }, [
    deals,
    owners,
    pipelineStages,
    calculateYoYRevenue,
    calculateYoYWonRevenue,
    prepareRevenueTableData,
    calculateRepPerformance,
  ]);

  // Common chart properties function
  const getCommonChartProperties = useCallback((chartType) => {
    return {
      margin: { top: 50, right: 130, bottom: 50, left: 60 },
      animate: true,
      enableSlices: chartType === 'line' ? 'x' : undefined,
      theme: {
        axis: {
          ticks: {
            text: {
              fill: theme.palette.text.primary,
            },
          },
          legend: {
            text: {
              fill: theme.palette.text.primary,
            },
          },
        },
        grid: {
          line: {
            stroke: theme.palette.divider,
          },
        },
        legends: {
          text: {
            fill: theme.palette.text.primary,
          },
        },
        tooltip: {
          container: {
            background: theme.palette.background.paper,
            color: theme.palette.text.primary,
            fontSize: '12px',
          },
        },
      },
      colors: ({ id }) => getChartColor(id, theme.palette.mode),
    };
  }, [theme]);

  // Tooltip function for YoY Revenue Chart
  const getYoYRevenueComparisonTooltip = useCallback((slice) => {
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;

    const monthIndex = yoyWonRevenueData.findIndex(d => d.month === slice.points[0].data.x);
    const currentYearRevenue = yoyWonRevenueData[monthIndex]?.[currentYear] || 0;
    const previousYearRevenue = yoyWonRevenueData[monthIndex]?.[previousYear] || 0;
    const forecastRevenue = yoyWonRevenueData[monthIndex]?.forecast;

    const items = [
      { label: currentYear.toString(), value: currentYearRevenue },
      { label: previousYear.toString(), value: previousYearRevenue },
    ];

    if (forecastRevenue !== undefined) {
      items.push({ label: 'Forecast', value: forecastRevenue });
    }

    let comparisonItem = null;
    if (currentYearRevenue !== 0 || previousYearRevenue !== 0) {
      const difference = currentYearRevenue - previousYearRevenue;
      let percentageChange = 0;
      
      if (previousYearRevenue !== 0) {
        percentageChange = (currentYearRevenue / previousYearRevenue) - 1;
      } else if (currentYearRevenue > 0) {
        percentageChange = 1; // 100% increase if previous year was 0
      }

      comparisonItem = {
        label: 'YoY Change',
        value: difference,
        percentage: percentageChange,
      };
    }

    return {
      title: slice.points[0].data.xFormatted,
      items,
      comparisonItem,
    };
  }, [yoyWonRevenueData]);

  // Tooltip function for Rep Performance Visualization
  const getRepPerformanceTooltip = useCallback((rep) => {
    const tooltipData = {
      title: rep.ownerName,
      items: [
        { label: 'Won Revenue', value: rep.wonRevenue, formatType: 'currency' },
        { label: 'Total Deals', value: rep.totalDeals, formatType: 'number' },
        { label: 'Won Deals', value: rep.closedWonDeals, formatType: 'number' },
        { label: 'Win Rate', value: rep.winRate, formatType: 'percentage' },
        { label: 'Avg Cycle (Days)', value: rep.averageSalesCycle, formatType: 'number' },
        { label: 'Avg Deal Size', value: rep.averageDealSize, formatType: 'currency' },
        { label: 'Engagement Score', value: rep.engagementScore, formatType: 'percentage' },
      ],
    };

    return (
      <CustomTooltip
        title={tooltipData.title}
        items={tooltipData.items.map(item => ({
          label: item.label,
          value: item.value !== undefined && item.value !== null ? item.value : 0,
          formatType: item.formatType,
        }))}
        comparisonItem={null} // No comparison needed for rep performance tooltips
        formatType="currency" // Default format type
      />
    );
  }, []);

  // Update getCustomTooltip function
  const getCustomTooltipWrapper = useCallback((tooltipData) => {
    return (
      <CustomTooltip
        title={tooltipData.title}
        items={tooltipData.items.map(item => ({
          ...item,
          value: item.value !== undefined && item.value !== null ? item.value : 0,
        }))}
        comparisonItem={tooltipData.comparisonItem ? {
          ...tooltipData.comparisonItem,
          value: tooltipData.comparisonItem.value !== undefined && tooltipData.comparisonItem.value !== null ? tooltipData.comparisonItem.value : 0,
          percentage: tooltipData.comparisonItem.percentage !== undefined && tooltipData.comparisonItem.percentage !== null ? tooltipData.comparisonItem.percentage : 0,
        } : null}
        formatType="currency"
      />
    );
  }, []);

  // Context value
  const value = {
    deals,
    owners,
    pipelineStages,
    loading,
    error,
    yoyRevenueData,
    yoyWonRevenueData,
    revenueTableData,
    repPerformanceData,
    overallWinRate,
    calculateRepPerformance,
    overallAverages,
    // Global Filters
    selectedPipeline,
    setSelectedPipeline,
    createdDateRange,
    setCreatedDateRange,
    closedDateRange,
    setClosedDateRange,
    getCommonChartProperties,
    // Formatting functions
    formatCurrency,
    formatNumber,
    formatPercentage,
    formatWholePercentage,
    formatDate,
    getYoYRevenueComparisonTooltip,
    getRepPerformanceTooltip,
    getCustomTooltip: getCustomTooltipWrapper,
    yoyWonRevenueData,
  };

  return <RevenueContext.Provider value={value}>{children}</RevenueContext.Provider>;
};