import React, { useEffect } from 'react';
import { Box, Container, Typography, Button, useTheme } from '@mui/material';
import { useABTest } from '../hooks/useABTest';
import { trackEvent } from '../utils/analytics';

const ctaContent = {
  control: {
    title: 'Ready to Transform Your Sales Pipeline?',
    description: 'Join the sales leaders who are leveraging AI to drive growth and efficiency. Get started with Truepipe.ai today.',
    buttonText: 'Start Now',
  },
  variant_a: {
    title: 'Unlock Your HubSpot Data\'s Full Potential',
    description: 'Start your free trial today and see how Truepipe.ai can revolutionize your sales process.',
    buttonText: 'Start Free Trial',
  },
  variant_b: {
    title: 'Boost Your Sales Performance with AI',
    description: 'Join thousands of sales leaders who have increased their revenue by 30% using Truepipe.ai.',
    buttonText: 'Get Started',
  },
};

const CallToAction = ({ onOpenSignupModal }) => {
  const theme = useTheme();
  const variants = useABTest({
    call_to_action: ['control', 'variant_a', 'variant_b']
  });

  const content = ctaContent[variants.call_to_action || 'control'];

  useEffect(() => {
    trackEvent('call_to_action_impression', {
      'event_category': 'impression',
      'event_label': 'Call to Action Section',
      'ab_test_call_to_action': variants.call_to_action,
    });
  }, [variants.call_to_action]);

  const handleClick = () => {
    trackEvent('cta_click', {
      'event_category': 'engagement',
      'event_label': 'Call to Action',
      'ab_test_call_to_action': variants.call_to_action,
    });
    onOpenSignupModal();
  };

  return (
    <Box
      sx={{
        py: { xs: 8, md: 12 },
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Container maxWidth="lg">
        <Box 
          sx={{ 
            textAlign: 'center',
            backgroundColor: theme.palette.mode === 'light' ? '#F0FFFC' : theme.palette.background.paper,
            borderRadius: '16px',
            padding: { xs: 4, sm: 6, md: 12 },
            px: { xs: 2, sm: 4, md: 30 },
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            component="h2"
            sx={{
              textAlign: 'center',
              mb: { xs: 1, sm: 2, md: 3 },
              pt: 0,
              fontWeight: 800,
              fontSize: { xs: '1rem', sm: '1.5rem', md: '2.5rem'},
              lineHeight: 1.2,
              background: theme.palette.mode === 'light' 
                ? 'linear-gradient(45deg, #33475B, #2E3A59)'
                : 'linear-gradient(45deg, #66E6D1, #00C0A0)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            {content.title}
          </Typography>
          <Typography sx={{ 
            mb: { xs: 2, sm: 3, md: 4 }, 
            fontSize: { xs: '1rem', sm: '1.5rem', md: '1.75rem' }, 
            fontWeight: 400,
            lineHeight: 1.3,
            color: theme.palette.text.secondary,
            padding: { xs: '1rem 1rem', sm: '1.5rem 3rem' },
          }}>
            {content.description}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleClick}
          >
            {content.buttonText}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default CallToAction;