import React from 'react';
import { Box, Container, Typography, Accordion, AccordionSummary, AccordionDetails, useTheme, Icon } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SecurityIcon from '@mui/icons-material/Security';
import ShieldIcon from '@mui/icons-material/Shield';
import LockIcon from '@mui/icons-material/Lock';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import DarkModePartner from './assets/DarkModePartner.png';
import LightModePartner from './assets/LightModePartner.png';

const faqs = [
  {
    question: 'How does Truepipe.ai ensure the security of my HubSpot data?',
    answer: 'At truepipe.ai, we prioritize the security of your sensitive data. We employ enterprise-grade encryption, adhere to SOC II compliance standards, and implement strict data protection measures. All data transmissions are encrypted using TLS 1.2 or higher, and we regularly conduct security audits to maintain the highest level of protection for your information.',
    icon: <SecurityIcon />
  },
  {
    question: 'Is truepipe.ai compliant with data protection regulations?',
    answer: 'Yes, truepipe.ai is fully compliant with major data protection regulations, including GDPR and CCPA. We have implemented robust processes to handle Personally Identifiable Information (PII) in accordance with these regulations. Our team regularly reviews and updates our compliance measures to ensure we meet or exceed industry standards.',
    icon: <ShieldIcon />
  },
  {
    question: 'How does Truepipe.ai handle access control and user permissions?',
    answer: 'We implement strict access controls and user permission management. Our system uses role-based access control (RBAC) to ensure that users only have access to the data they need. We also provide detailed audit logs of all data access and modifications, giving you complete visibility into how your data is being used within our platform.',
    icon: <LockIcon />
  },
  {
    question: 'What measures does Truepipe.ai take to prevent data breaches?',
    answer: 'truepipe.ai employs a multi-layered approach to prevent data breaches. This includes regular security assessments, penetration testing, and vulnerability scanning. We use advanced intrusion detection and prevention systems, and our team is trained in the latest cybersecurity best practices. In the unlikely event of a security incident, we have a comprehensive incident response plan in place to quickly address and mitigate any potential risks.',
    icon: <VerifiedUserIcon />
  },
];

const FAQ = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box sx={{ py: { xs: 8, md: 12 }, backgroundColor: theme.palette.background.default }}>
      <Container maxWidth="lg">
        <Typography variant="h2" sx={{ mb: 4, textAlign: 'center', fontWeight: 700 }}>
          Your Security is Our Priority
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 6 }}>
          <img
            src={isDarkMode ? DarkModePartner : LightModePartner}
            alt="HubSpot App Partner"
            style={{ maxWidth: '100%', height: 'auto', maxHeight: '50px' }}
          />
        </Box>
        <Box sx={{ maxWidth: '800px', mx: 'auto' }}>
          {faqs.map((faq, index) => (
            <Accordion key={index} disableGutters elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  '& .MuiAccordionSummary-expandIconWrapper': {
                    color: theme.palette.primary.contrastText,
                  },
                  borderRadius: '8px',
                  mb: 1,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Icon component={() => faq.icon} fontSize="large" />
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>{faq.question}</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 3, backgroundColor: theme.palette.background.paper, borderRadius: '8px' }}>
                <Typography variant="body1">{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default FAQ;