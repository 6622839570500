import React from 'react';
import TypographyComparison from './TypographyComparison';
import Topbar from '../components/global/Topbar';
import Layout from '../components/home/Layout';

const Sandbox = () => {
  return (
    <Layout>
      <Topbar />
      <h1>Sandbox</h1>
      <TypographyComparison />
    </Layout>
  );
};

export default Sandbox;
