import React, { useState } from 'react';
import { 
  Box, Typography, Tabs, Tab, Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, IconButton, Tooltip, Collapse, LinearProgress, useTheme, useMediaQuery 
} from '@mui/material';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useOverview } from '../../../contexts/OverviewContext';
import { useDashboardStyles } from '../../hooks/useDashboardStyles';
import { bittersweet, melon, mint, turquoise } from '../../../theme';

const formatAmount = (amount) => {
  if (amount >= 1000000) return `${(amount / 1000000).toFixed(1)}M`;
  if (amount >= 1000) return `${(amount / 1000).toFixed(1)}K`;
  return amount.toString();
};

const formatDate = (date) => new Date(date).toLocaleDateString();

const getProgressColor = (probability, isDarkMode) => {
  if (probability >= 0.7) {
    return isDarkMode ? turquoise : mint;
  } else if (probability >= 0.4) {
    return isDarkMode ? '#ffa726' : '#ff9800';
  } else {
    return isDarkMode ? bittersweet : bittersweet;
  }
};

const Row = ({ deal, onToggleWatchlist, isMobile }) => {
  const [open, setOpen] = useState(false);
  const { components } = useDashboardStyles();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton size="small" onClick={() => onToggleWatchlist(deal.id)}>
            {deal.isWatchlisted ? <StarIcon color="primary" /> : <StarBorderIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Tooltip title={deal.dealname}>
            <Typography noWrap>{deal.dealname.slice(0, 20)}...</Typography>
          </Tooltip>
        </TableCell>
        {!isMobile && <TableCell>{formatAmount(deal.amount)}</TableCell>}
        {!isMobile && <TableCell>{formatDate(deal.closedate)}</TableCell>}
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress 
                variant="determinate" 
                value={deal.truepipeProbability * 100} 
                sx={{
                  height: 10,
                  borderRadius: 5,
                  backgroundColor: theme.palette.grey[isDarkMode ? 800 : 200],
                  '& .MuiLinearProgress-bar': {
                    borderRadius: 5,
                    backgroundColor: getProgressColor(deal.truepipeProbability, isDarkMode),
                  },
                }}
              />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">
                {`${(deal.truepipeProbability * 100).toFixed(1)}%`}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <IconButton
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Deal Details
              </Typography>
              {/* Add more deal details here */}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const Watchlist = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { aiWatchlist, loading, error, toggleWatchlistItem } = useOverview();
  const { layout, components } = useDashboardStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (loading) {
    return (
      <Box sx={layout.chartContainer}>
        <Typography variant="h6">Watchlist</Typography>
        <LinearProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">Error loading watchlist: {error}</Typography>;
  }

  const dealsToShow = activeTab === 0 ? aiWatchlist.bestDeals : aiWatchlist.criticalDeals;

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" sx={{ mb: 2 }}>Watchlist</Typography>
      <Tabs value={activeTab} onChange={handleTabChange} sx={components.tabs.root}>
        <Tab label="AI's Best Bets" sx={components.tabs.tab} />
        <Tab label="AI is Concerned" sx={components.tabs.tab} />
      </Tabs>
      <TableContainer sx={{ flexGrow: 1, overflow: 'auto' }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Watchlist</TableCell>
              <TableCell>Deal Name</TableCell>
              {!isMobile && <TableCell>Amount</TableCell>}
              {!isMobile && <TableCell>Closing</TableCell>}
              <TableCell>Truepipe Probability</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dealsToShow.slice(0, isMobile ? 5 : 15).map((deal) => (
              <Row key={deal.id} deal={deal} onToggleWatchlist={toggleWatchlistItem} isMobile={isMobile} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Watchlist;