import React from 'react';
import { Provider } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';
import { store } from './store';
import { queryClient } from './utils/queryClient';
import { AuthProvider } from "./contexts/AuthContext";
import { FirebaseProvider } from "./contexts/FirebaseContext";
import { HubSpotProvider } from "./contexts/HubSpotContext";
import { UsersDataProvider } from "./contexts/UsersDataContext";
import { SubscriptionProvider } from "./contexts/SubscriptionContext";
import { RawDataProvider } from "./contexts/RawDataContext";
import { HealthcheckDashboardProvider } from "./contexts/HealthcheckDashboardContext";
import { HealthCheckProvider } from './pages/ContextProvider';

export const AppProviders = ({ children }) => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <FirebaseProvider>
        <AuthProvider>
          <HubSpotProvider>
            <UsersDataProvider>
              <SubscriptionProvider>
                <RawDataProvider>
                  <HealthcheckDashboardProvider>
                    <HealthCheckProvider>
                      {children}
                    </HealthCheckProvider>
                  </HealthcheckDashboardProvider>
                </RawDataProvider>
              </SubscriptionProvider>
            </UsersDataProvider>
          </HubSpotProvider>
        </AuthProvider>
      </FirebaseProvider>
    </QueryClientProvider>
  </Provider>
);