// src/components/sales/OverviewDashboard/HubSpotCallback.jsx v2.1.1

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useHubSpot } from '../../../contexts/HubSpotContext';
import { useAuth } from '../../../contexts/AuthContext';
import logger from '../../../utils/frontendLogger';
import { initiateHubSpotIntegration } from '../../../services/hubspotService';
import CircularProgress from '@mui/material/CircularProgress'; // Material-UI spinner
import Box from '@mui/material/Box';

const HubSpotCallback = () => {
  const location = useLocation();
  const { setError } = useHubSpot();
  const { currentUser } = useAuth();
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const handleCallback = async () => {
      if (isProcessing) return;
      setIsProcessing(true);

      const params = new URLSearchParams(location.search);
      const code = params.get('code');
      const state = params.get('state');
      
      // Get stored redirect path
      const redirectPath = sessionStorage.getItem('redirectPath') || '/';
      
      logger.info('Processing HubSpot callback', {
        hasCode: !!code,
        stateMatch: state === currentUser?.uid,
        redirectPath
      });

      if (code && state === currentUser?.uid) {
        try {
          await initiateHubSpotIntegration(code, state);
          logger.info('HubSpot integration successful, redirecting to:', redirectPath);
          
          // Clear session storage
          sessionStorage.removeItem('redirectPath');
          sessionStorage.removeItem('hubspotState');
          
          // Redirect back to original path
          window.location.href = redirectPath;
        } catch (error) {
          logger.error('Error in HubSpot integration:', error);
          setError(error.message || 'Failed to connect to HubSpot');
          // Redirect with error
          window.location.href = `${redirectPath}?error=hubspot_connection_failed`;
        }
      } else {
        logger.warn('Invalid callback parameters', { 
          hasCode: !!code, 
          hasValidState: state === currentUser?.uid 
        });
        setError('Invalid HubSpot callback');
        window.location.href = `${redirectPath}?error=invalid_callback`;
      }
      setIsProcessing(false);
    };

    handleCallback();
  }, [location.search, currentUser, setError, isProcessing]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      flexDirection="column"
    >
      <CircularProgress size={60} />  {/* MUI spinner */}
      <p style={{ marginTop: '20px', fontSize: '1.2rem', color: '#555' }}>
        Connecting to HubSpot, please wait...
      </p>
    </Box>
  );
};

export default HubSpotCallback;
