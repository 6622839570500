// src/components/sales/HealthcheckDashboard/ProblemAreas.jsx
import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import PastDueDealsChart from './PastDueDealsChart';
import MissingDataChart from './MissingDataChart';
import DealQualityChart from './DealQualityChart';
import { useRawData } from '../../../contexts/RawDataContext';
import SkeletonLoader from '../../../utils/SkeletonLoader';

const ProblemAreas = () => {
  const { loading } = useRawData();

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5" gutterBottom>
        Problem Areas
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          {loading ? (
            <SkeletonLoader height={300} />
          ) : (
            <PastDueDealsChart />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {loading ? (
            <SkeletonLoader height={300} />
          ) : (
            <MissingDataChart />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {loading ? (
            <SkeletonLoader height={300} />
          ) : (
            <DealQualityChart />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProblemAreas;
