import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { usePipeline } from '../../../contexts/PipelineContext';
import { Box, Typography } from '@mui/material';

const DealValueOverTime = () => {
  const {
    dealValueOverTime,
    formatCurrency,
    formatPercentage,
    getCommonChartProperties,
    getDealValueOverTimeTooltip,
    getCustomTooltip,
    isMobile,
    getChartColorPair,
    getCurrentAndPreviousYear
  } = usePipeline();

  if (!dealValueOverTime || dealValueOverTime.length === 0) {
    return <Typography>No deal data available for the selected time period.</Typography>;
  }

  const { currentYear, previousYear } = getCurrentAndPreviousYear();
  const commonProperties = getCommonChartProperties('line');

  const chartProps = {
    ...commonProperties,
    data: dealValueOverTime,
    xScale: { type: 'point' },
    yScale: { type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false },
    axisBottom: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Month',
      legendOffset: 36,
      legendPosition: 'middle'
    },
    axisLeft: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Deal Value',
      legendOffset: -40,
      legendPosition: 'middle',
      format: value => formatCurrency(value, { notation: 'compact' })
    },
    enableSlices: 'x',
    sliceTooltip: ({ slice }) => getCustomTooltip(getDealValueOverTimeTooltip(slice)),
    legends: isMobile ? [] : [
      {
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 100,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1
            }
          }
        ]
      }
    ],
    colors: ({ id }) => getChartColorPair(id.includes(currentYear) ? 0 : 1).lineColor
  };

  return (
    <Box sx={{ height: 400 }}>
      <ResponsiveLine {...chartProps} />
    </Box>
  );
};

export default DealValueOverTime;