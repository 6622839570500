// src/components/sales/HealthcheckDashboard/SummaryCards.jsx
import React from 'react';
import { Grid } from '@mui/material';
import SummaryCard from './SummaryCard';
import { useRawData } from '../../../contexts/RawDataContext';

const SummaryCards = () => {
  const { overallHealthMetrics, formatCurrency, formatPercentage } = useRawData();

  if (!overallHealthMetrics) {
    return null;
  }

  return (
    <Grid container spacing={3}>
      {Object.entries(overallHealthMetrics).map(([key, metric]) => (
        <Grid item xs={12} md={3} key={key}>
          <SummaryCard
            title={metric.title}
            value={metric.value}
            format={
              key === 'averageDealSize' ? formatCurrency :
              key === 'pipelineFreshness' ? (val) => `${val.toFixed(1)}%` :
              undefined
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default SummaryCards;
