// src/store/hubspotSlice.js v1.0.0

import { createSlice } from '@reduxjs/toolkit';
import logger from '../utils/frontendLogger';

const initialState = {
  isAuthenticated: false,
  hubspotConnected: false,
  ownerData: null,
  accountInfo: null,
  dataFetchProgress: 0,
  accessToken: null,
  refreshToken: null,
  tokenExpiry: null,
};

export const hubspotSlice = createSlice({
  name: 'hubspot',
  initialState,
  reducers: {
    setIsAuthenticated: (state, action) => {
      logger.info('Setting isAuthenticated', { value: action.payload });
      state.isAuthenticated = action.payload;
    },
    setHubspotConnected: (state, action) => {
      state.hubspotConnected = action.payload;
    },
    setHubSpotOwnerData: (state, action) => {
      state.ownerData = action.payload;
    },
    setHubSpotAccountInfo: (state, action) => {
      state.accountInfo = action.payload;
    },
    setDataFetchProgress: (state, action) => {
      state.dataFetchProgress = action.payload;
    },
    setHubSpotTokens: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.tokenExpiry = action.payload.tokenExpiry;
    },
  },
});

export const { 
  setIsAuthenticated, 
  setHubspotConnected, 
  setHubSpotOwnerData,
  setHubSpotAccountInfo,
  setDataFetchProgress,
  setHubSpotTokens
} = hubspotSlice.actions;

export default hubspotSlice.reducer;