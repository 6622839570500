// src/components/sales/RevenueDashboard/charts/YoYRevenueChart.jsx

import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { Box, Typography, useTheme } from '@mui/material';
import { useRevenue } from '../../../../contexts/RevenueContext';
import { melon, tangerine, mint, turquoise, coolGray, periwinkle } from '../../../../theme';

const YoYRevenueChart = () => {
  const theme = useTheme();
  const {
    yoyWonRevenueData,
    formatCurrency,
    getCommonChartProperties,
    getYoYRevenueComparisonTooltip, // Updated function name
    formatWholePercentage,
    isMobile,
  } = useRevenue();

  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;

  const getColor = (id) => {
    if (id === currentYear.toString()) {
      return theme.palette.mode === 'dark' ? turquoise : mint;
    }
    if (id === previousYear.toString()) {
      return theme.palette.mode === 'dark' ? tangerine : melon;
    }
    if (id === 'Forecast') {
      return theme.palette.mode === 'dark' ? periwinkle : coolGray;
    }
    return '#000000';
  };

  const formattedData = [
    {
      id: currentYear.toString(),
      data: yoyWonRevenueData.map((d) => ({ x: d.month, y: d[currentYear] })),
    },
    {
      id: previousYear.toString(),
      data: yoyWonRevenueData.map((d) => ({ x: d.month, y: d[previousYear] })),
    },
    {
      id: 'Forecast',
      data: [
        { x: yoyWonRevenueData[yoyWonRevenueData.findIndex(d => d.forecast !== undefined) - 1]?.month || 'Sep', y: 0 },
        ...yoyWonRevenueData.map((d) => ({ x: d.month, y: d.forecast || null })).filter(d => d.y !== null),
      ],
    },
  ];

  const commonProperties = getCommonChartProperties('line');

  const CustomTooltip = ({ slice }) => {
    const tooltipData = getYoYRevenueComparisonTooltip(slice); // Corrected function name

    return (
      <Box sx={{
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[3],
        padding: 0,
        justifyContent: 'space-between',
        height: 'auto',
        minWidth: '170px',
        width: 'auto',
        overflow: 'hidden',
        '& .MuiTypography-root': {
          fontSize: '0.875rem',
        },
      }}>
        <Box sx={{
          backgroundColor: theme.palette.mode === 'light' 
            ? theme.palette.grey[200] 
            : theme.palette.grey[800],
          color: theme.palette.mode === 'light'
            ? theme.palette.text.primary
            : theme.palette.common.white,
          padding: theme.spacing(1, 2),
          width: '100%',
        }}>
          <Typography variant="subtitle2">
            {tooltipData.title}
          </Typography>
        </Box>
        <Box sx={{
          padding: theme.spacing(1, 1.5),
        }}>
          {tooltipData.items.map((item, index) => (
            <Box key={item.label} sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: theme.spacing(0.5),
            }}>
              <Typography sx={{
                display: 'flex',
                alignItems: 'center',
                marginRight: theme.spacing(1),
              }}>
                <Box sx={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  backgroundColor: getColor(item.label),
                  marginRight: theme.spacing(1),
                }} />
                {item.label}:
              </Typography>
              <Typography>
                {formatCurrency(item.value, { maximumFractionDigits: 0 })}
              </Typography>
            </Box>
          ))}
          {tooltipData.comparisonItem && (
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: theme.spacing(1),
            }}>
              <Typography sx={{
                display: 'flex',
                alignItems: 'center',
                marginRight: theme.spacing(1),
              }}>
                {tooltipData.comparisonItem.label}:
              </Typography>
              <Typography sx={{
                color: tooltipData.comparisonItem.value >= 0 ? theme.palette.success.main : theme.palette.error.main 
              }}>
                {formatCurrency(tooltipData.comparisonItem.value, { maximumFractionDigits: 0 })}
                {tooltipData.comparisonItem.percentage && 
                  ` (${formatWholePercentage(tooltipData.comparisonItem.percentage, { signDisplay: 'always' })})`}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const chartProps = {
    ...commonProperties,
    data: formattedData,
    margin: { top: 50, right: 110, bottom: 50, left: 60 },
    xScale: { type: 'point' },
    yScale: { type: 'linear', min: 0, max: 'auto', stacked: false, reverse: false },
    axisBottom: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Month',
      legendOffset: 36,
      legendPosition: 'middle',
    },
    axisLeft: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Revenue',
      legendOffset: -40,
      legendPosition: 'middle',
      format: value => formatCurrency(value, { notation: 'compact' }),
    },
    enableGridX: false,
    enableGridY: false,
    pointSize: 8,
    pointColor: { theme: 'background' },
    pointBorderWidth: 2,
    pointBorderColor: { from: 'serieColor' },
    enableArea: true,
    areaOpacity: 0.15,
    areaBaselineValue: 0,
    useMesh: true,
    enableSlices: 'x',
    curve: 'monotoneX',
    sliceTooltip: CustomTooltip,
    legends: isMobile ? [] : [
      {
        anchor: 'top-right',
        direction: 'column',
        justify: false,
        translateX: -50,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1,
            },
          },
        ],
      },
    ],
    colors: ({ id }) => getColor(id),
  };

  return (
    <Box sx={{ height: 400 }}>
      <ResponsiveLine {...chartProps} />
    </Box>
  );
};

export default YoYRevenueChart;