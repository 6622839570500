export const processDealsForAIInsights = (deals) => {
  const insights = [];
  const now = new Date();
  const twoWeeksAgo = new Date(now.getTime() - 14 * 24 * 60 * 60 * 1000);
  const { startDate: quarterStartDate, endDate: quarterEndDate } = getCurrentQuarterDateRange();
  const highValueThreshold = getHighValueThreshold(deals);

  // 1. Recently closed deals (last two weeks)
  const recentlyClosedDeals = deals.filter(deal => {
    const closeDate = new Date(deal.closedate);
    return closeDate <= now && 
           closeDate > twoWeeksAgo && 
           (deal.hs_deal_stage_probability === 1 || deal.hs_deal_stage_probability === 0);
  });
  insights.push({
    type: 'recentlyClosed',
    message: recentlyClosedDeals.length > 0
      ? `${recentlyClosedDeals.length} deals have been closed in the last two weeks.`
      : 'No deals have been closed in the last two weeks.',
    deals: recentlyClosedDeals,
  });

  // 2. High-value deals closed this quarter
  const highValueDeals = deals.filter(deal => 
    new Date(deal.closedate) >= quarterStartDate &&
    new Date(deal.closedate) <= quarterEndDate &&
    (deal.hs_deal_stage_probability === 1 || deal.hs_deal_stage_probability === 0) &&
    deal.amount > highValueThreshold
  );
  insights.push({
    type: 'highValue',
    message: highValueDeals.length > 0
      ? `${highValueDeals.length} high-value deals have been closed this quarter.`
      : 'No high-value deals have been closed this quarter.',
    deals: highValueDeals,
  });

  // 3. Deals closed this quarter
  const dealsClosedThisQuarter = deals.filter(deal => 
    new Date(deal.closedate) >= quarterStartDate &&
    new Date(deal.closedate) <= quarterEndDate &&
    (deal.hs_deal_stage_probability === 1 || deal.hs_deal_stage_probability === 0)
  );
  insights.push({
    type: 'closedThisQuarter',
    message: dealsClosedThisQuarter.length > 0
      ? `${dealsClosedThisQuarter.length} deals have been closed this quarter.`
      : 'No deals have been closed this quarter.',
    deals: dealsClosedThisQuarter,
  });

  // 4. Top performer this quarter
  const closedWonDealsThisQuarter = deals.filter(deal => 
    new Date(deal.closedate) >= quarterStartDate &&
    new Date(deal.closedate) <= quarterEndDate &&
    deal.hs_deal_stage_probability === 1
  );
  const dealsByOwner = closedWonDealsThisQuarter.reduce((acc, deal) => {
    acc[deal.hubspot_owner_id] = (acc[deal.hubspot_owner_id] || 0) + 1;
    return acc;
  }, {});
  const topOwner = Object.entries(dealsByOwner).sort((a, b) => b[1] - a[1])[0];
  insights.push({
    type: 'topPerformer',
    message: topOwner
      ? `Top performing sales rep has closed ${topOwner[1]} deals this quarter.`
      : 'No deals have been closed by any sales rep this quarter.',
    ownerId: topOwner ? topOwner[0] : null,
    dealCount: topOwner ? topOwner[1] : 0,
  });

  return insights;
};

export const processDealsForAIWatchlist = (deals) => {
  // Filter out closed won and closed lost deals
  const openDeals = deals.filter(deal => parseFloat(deal.hs_deal_stage_probability) > 0 && parseFloat(deal.hs_deal_stage_probability) < 1);
  
  // Sort deals by truepipe probability
  const sortedDeals = openDeals.sort((a, b) => b.truepipeProbability - a.truepipeProbability);
  
  // Get top 10 best deals and bottom 10 critical deals
  const bestDeals = sortedDeals.slice(0, 15);
  const criticalDeals = sortedDeals.slice(-15).reverse();
  
  return { bestDeals, criticalDeals };
};
  
export const generateAIInsights = (deals) => {
  const openDeals = deals.filter(deal => parseFloat(deal.hs_deal_stage_probability) > 0 && parseFloat(deal.hs_deal_stage_probability) < 1);
  const insights = [];
  
  // 1. High truepipe probability deals
  const highProbabilityDeals = openDeals.filter(deal => deal.truepipeProbability > 0.7);
  if (highProbabilityDeals.length > 0) {
    insights.push({
      type: 'highProbability',
      message: `${highProbabilityDeals.length} deals have a high likelihood of closing.`,
      deals: highProbabilityDeals.slice(0, 5),
    });
  }
  
  // 2. Deals needing attention (low truepipe probability)
  const lowProbabilityDeals = openDeals.filter(deal => deal.truepipeProbability < 0.3);
  if (lowProbabilityDeals.length > 0) {
    insights.push({
      type: 'lowProbability',
      message: `${lowProbabilityDeals.length} deals may need additional attention.`,
      deals: lowProbabilityDeals.slice(0, 5),
    });
  }
  
  // 3. Large deals (top 5 by amount)
  const largeDeals = openDeals.sort((a, b) => b.amount - a.amount).slice(0, 5);
  if (largeDeals.length > 0) {
    insights.push({
      type: 'largeDeals',
      message: `Top ${largeDeals.length} largest deals in the pipeline.`,
      deals: largeDeals,
    });
  }
  
  // 4. Deals closing soon (within next 30 days)
  const now = new Date();
  const thirtyDaysFromNow = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000);
  const closingSoonDeals = openDeals.filter(deal => new Date(deal.closedate) <= thirtyDaysFromNow);
  if (closingSoonDeals.length > 0) {
    insights.push({
      type: 'closingSoon',
      message: `${closingSoonDeals.length} deals are set to close within the next 30 days.`,
      deals: closingSoonDeals.slice(0, 5),
    });
  }
  
  return insights;
};

// Helper function to get the current quarter's date range
const getCurrentQuarterDateRange = () => {
  const now = new Date();
  const currentQuarter = Math.floor(now.getMonth() / 3);
  const startDate = new Date(now.getFullYear(), currentQuarter * 3, 1);
  const endDate = new Date(now.getFullYear(), (currentQuarter + 1) * 3, 0);
  return { startDate, endDate };
};

// Helper function to determine high-value threshold
const getHighValueThreshold = (deals) => {
  const amounts = deals.map(deal => deal.amount);
  const averageAmount = amounts.reduce((sum, amount) => sum + amount, 0) / amounts.length;
  return averageAmount * 2; // Threshold set to 2x the average deal amount
};