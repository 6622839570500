// src/components/sales/InsightsDashboard/DynamicInsightCards.jsx

import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Icon,
  Tooltip,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useInsights } from '../../../contexts/InsightsContext';
import { useChartStyles } from '../../hooks/useChartStyles';
import { formatCurrency, formatPercentage, formatNumber } from '../../utils/formatters';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { Sparklines, SparklinesLine } from 'react-sparklines';

const insightTypeIcons = {
  revenue: MonetizationOnIcon,
  averageDealSize: MonetizationOnIcon,
  averageDealAge: AccessTimeIcon,
  // Add more icon mappings as needed
};

const DynamicInsightCards = ({ onInsightClick }) => {
  const { insights, overallMetrics, loading, error } = useInsights();
  const { getChartColor, getTooltipStyles } = useChartStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (loading) {
    return <Typography>Loading insights...</Typography>;
  }

  if (error) {
    return <Typography color="error">Error loading insights: {error.message || error}</Typography>;
  }

  if (!Array.isArray(insights) || insights.length === 0) {
    return <Typography>No insights available at the moment.</Typography>;
  }

  const formatInsightValue = (type, value) => {
    switch (type) {
      case 'revenue':
      case 'averageDealSize':
        return formatCurrency(value);
      case 'averageDealAge':
        return `${value.toFixed(1)} days`;
      default:
        return formatNumber(value);
    }
  };

  const isPositiveTrend = (type, percentageDifference) => {
    switch (type) {
      case 'averageDealAge':
        return percentageDifference < 0; // Lower is better
      default:
        return percentageDifference > 0; // Higher is better
    }
  };

  return (
    <Grid container spacing={isMobile ? 1 : 2}>
      {insights.map((insight) => {
        const IconComponent = insightTypeIcons[insight.type] || TrendingUpIcon;
        const color = getChartColor(insight.id); // Assuming each insight has a unique 'id'

        let percentageDifference = null;
        if (overallMetrics[insight.type] && overallMetrics[insight.type].value) {
          percentageDifference =
            ((insight.value - overallMetrics[insight.type].value) / overallMetrics[insight.type].value) * 100;
        }

        const trendIsPositive =
          percentageDifference !== null
            ? isPositiveTrend(insight.type, percentageDifference)
            : null;

        const IndicatorIcon = trendIsPositive ? TrendingUpIcon : TrendingDownIcon;
        const indicatorColor = trendIsPositive ? 'green' : 'red';

        return (
          <Grid item xs={12} sm={6} md={3} key={insight.id}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer',
                transition: 'box-shadow 0.3s, transform 0.3s',
                '&:hover': {
                  boxShadow: 6,
                  transform: 'translateY(-4px)',
                },
              }}
              onClick={() => onInsightClick && onInsightClick(insight)}
            >
              <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Box display="flex" alignItems="center" mb={1}>
                  <Icon component={IconComponent} sx={{ color, mr: 1 }} />
                  <Typography variant="body1" color={color}>
                    {insight.title}
                  </Typography>
                </Box>
                <Typography variant="h5" component="div" gutterBottom>
                  {formatInsightValue(insight.type, insight.value)}
                </Typography>
                {percentageDifference !== null && (
                  <Box display="flex" alignItems="center" mb={1}>
                    <IndicatorIcon sx={{ color: indicatorColor, mr: 0.5 }} />
                    <Typography variant="body2" color={indicatorColor}>
                      {`${Math.abs(percentageDifference.toFixed(1))}% ${
                        trendIsPositive ? 'increase' : 'decrease'
                      } vs overall`}
                    </Typography>
                  </Box>
                )}
                <Typography variant="body2" sx={{ mb: 1, flexGrow: 1 }}>
                  {insight.insight}
                </Typography>
                {Array.isArray(insight.historicalData) && insight.historicalData.length > 0 && (
                  <Tooltip
                    title="Historical trend"
                    placement="top"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: getTooltipStyles().container,
                      },
                    }}
                  >
                    <Box sx={{ mb: 0.5 }}>
                      <Sparklines data={insight.historicalData || []} width={100} height={20}>
                        <SparklinesLine color={color} />
                      </Sparklines>
                    </Box>
                  </Tooltip>
                )}
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default DynamicInsightCards;