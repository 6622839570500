import { createContext, useState, useMemo, useEffect } from "react";
import { createTheme } from "@mui/material/styles";

// chart Colors we need to use and begin implementing:

// const turquoiseLight = "#66E6D1";
// const turquoise ="#33D3B8";
// const mint = "#00C0A0";
// const persianGreen = "#009688";
// const charcoal = "#33475B";
// const melon= "#FEA58E";
// const hubspotOrange = "#FF4400"; 
// const cloud= "#FFFCF5";
// const white = "#FFFFFF";
// const antiFlashWhite = '#F5F8FA';
// const floralWhite = '#FEF8F0';
// const whiteSmoke = '#F5F5F5';
// const seaSalt = '#FAFAFA';
// const periwinkle= "#BABDE2";
// const maroon = "#895159";
// const peach = "#DFAEA1";
// const black = "#000000";
// const deepBlue = '#2E3A59';
// const navy= "#374375";
// const slateBlue = '#4B5D73';
// const charcoal = "33475B";
// const dodgerBlue = '#1E90FF';
// const eerieBlack = '#1E1E1E';
// const gold = '#FFD700';

// color design tokens
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
      grey: {
        100: "#e0e0e0",
        200: "#c2c2c2",
        300: "#a3a3a3",
        400: "#858585",
        500: "#666666",
        600: "#525252",
        700: "#3d3d3d",
        800: "#292929",
        900: "#141414",
      },
      primary: {
        100: "#e0e0e0",
        200: "#c2c2c2",
        300: "#a3a3a3",
        400: "#858585",
        500: "#666666",
      },
      background: {
        default: "#1a1a1a",
        paper: "#2d2d2d",
        light: "#3a3a3a",
        dark: "#121212",
      },
      text: {
        primary: "#F5F8FA",
        secondary: "#FAFCFD",
        tertiary: "#999999",
        accent: "#33D3B8",
      },
      secondary: {
        main: "#33D3B8", // Turquoise accent color for dark mode
        light: "#66DECA",
      },
      error: {
        main: "#ff6b6b",
      },
      warning: {
        main: "#feca57",
      },
      info: {
        main: "#54a0ff",
      },
      success: {
        main: "#5ed5a8",
      },
      tooltip: {
        background: "#2d2d2d",
        headerBackground: "#1a1a1a",
        text: "#ffffff",
        headerText: "#ffffff",
      },
    }
    : {
      grey: {
        100: "#141414",
        200: "#292929",
        300: "#3d3d3d",
        400: "#525252",
        500: "#666666",
        600: "#858585",
        700: "#a3a3a3",
        800: "#c2c2c2",
        900: "#e0e0e0",
      },
      primary: {
        100: "#ffffff",
        200: "#fafafa",
        300: "#f5f5f5",
        400: "#f0f0f0",
        500: "#e0e0e0",
      },
      background: {
        default: "#ffffff",
        paper: "#FAFAFA",
        light: "#F5F5F5",
        dark: "#E0E0E0",
      },
      text: {
        primary: "#333333",
        secondary: "#666666",
        tertiary: "#999999",
        accent: "#00C0A0",
      },
      secondary: {
        main: "#00C0A0", // Mint color for light mode
      },
      error: {
        main: "#d32f2f",
      },
      warning: {
        main: "#f57c00",
      },
      info: {
        main: "#0288d1",
      },
      success: {
        main: "#388e3c",
      },
      tooltip: {
        background: "#ffffff",
        headerBackground: "#f0f0f0",
        text: "#333333",
        headerText: "#333333",
      },
    }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            primary: {
              main: colors.primary[300],
            },
            secondary: {
              main: colors.secondary.main,
              light: colors.secondary.light,
            },
            background: {
              default: colors.background.default,
              paper: colors.background.paper,
            },
            text: {
              primary: colors.text.primary,
              secondary: colors.text.secondary,
              tertiary: colors.text.tertiary,
              accent: colors.text.accent,
            },
          }
        : {
            primary: {
              main: colors.primary[300],
            },
            secondary: {
              main: colors.secondary.main,
            },
            background: {
              default: colors.background.default,
              paper: colors.background.paper,
            },
            text: {
              primary: colors.text.primary,
              secondary: colors.text.secondary,
            },
          }),
      error: {
        main: colors.error.main,
      },
      warning: {
        main: colors.warning.main,
      },
      info: {
        main: colors.info.main,
      },
      success: {
        main: colors.success.main,
      },
      tooltip: {
        background: colors.tooltip.background,
        headerBackground: colors.tooltip.headerBackground,
        text: colors.tooltip.text,
        headerText: colors.tooltip.headerText,
      },
    },
    typography: {
      fontFamily: ["Circular", "Helvetica", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Circular", "Helvetica", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Circular", "Helvetica", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Circular", "Helvetica", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Circular", "Helvetica", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Circular", "Helvetica", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Circular", "Helvetica", "sans-serif"].join(","),
        fontSize: 14,
      },
      heroTitle: {
        textAlign: 'center',
        fontSize: '2.5rem',
        fontWeight: 800,
        lineHeight: 1.1,
        marginBottom: '0.5rem',
        '@media (min-width:600px)': {
          fontSize: '3.5rem',
        },
        '@media (min-width:900px)': {
          fontSize: '5rem',
        },
      },
      heroSubtitle: {
        textAlign: 'center',
        color: 'text.secondary',
        width: '100%',
        margin: '0 auto',
        lineHeight: 1.4,
        fontWeight: 400,
        fontSize: '1.25rem',
        marginBottom: '0.75rem',
        '@media (min-width:600px)': {
          fontSize: '1.5rem',
        },
        '@media (min-width:900px)': {
          fontSize: '1.75rem',
          maxWidth: '80%',
        },
      },
      sectionTitle: {
        textAlign: 'center',
        fontSize: '2rem',
        fontWeight: 800,
        lineHeight: 1.1,
        width: '100%',
        textAlign: 'center',
        display: 'block',
        marginBottom: '2rem',
        '@media (min-width:600px)': {
          fontSize: '2.5rem',
        },
        '@media (min-width:900px)': {
          fontSize: '2.5rem',
        },
      },
      basicText: {
        fontFamily: 'var(--font-family)',
        fontSize: '1rem',
        lineHeight: 1.5,
        color: 'var(--text-primary)',
      },
    },
    components: {
      MuiTabs: {
        styleOverrides: {
          root: ({ theme }) => ({
            marginBottom: theme.spacing(2),
            '& .MuiTab-root': {
              color: theme.palette.text.secondary,
              '&:hover': {
                color: theme.palette.secondary.main,
              },
            },
            '& .Mui-selected': {
              color: theme.palette.mode === 'light' 
                ? theme.palette.secondary.main 
                : theme.palette.secondary.light,
            },
            '& .MuiTabs-indicator': {
              backgroundColor: theme.palette.mode === 'light' 
                ? theme.palette.secondary.main 
                : theme.palette.secondary.light,
            },
          }),
        },
      },
      MuiTab: {
        styleOverrides: {
          root: ({ theme }) => ({
            color: theme.palette.text.secondary,
            '&:hover': {
              color: theme.palette.secondary.main,
            },
            '&.Mui-selected': {
              color: theme.palette.secondary.main,
            },
          }),
        },
      },
      MuiButton: {
        defaultProps: {
          size: 'medium',
          fullWidth: false,
        },
        styleOverrides: {
          root: {
            textAlign: 'center',
            cursor: 'pointer',
            borderRadius: '10em',
            justifyContent: 'center',
            alignItems: 'center',
            width: '10rem',
            height: '2rem',
            padding: '0 0.5rem',
            fontSize: '0.75rem',
            fontWeight: 600,
            lineHeight: 1,
            transition: 'transform .15s cubic-bezier(.19,1,.22,1), background-color .15s cubic-bezier(.19,1,.22,1)',
            '&:hover': {
              transform: 'scale(1.05)',
            },
          },
        },
        variants: [
          {
            props: { variant: 'contained' },
            style: ({ theme }) => ({
              color: theme.palette.common.white, // This will be white in both modes
              border: 'none',
              height: '2rem',
              width: {xs: '100%', sm: '200px'},
              backgroundColor: theme.palette.secondary.main,
              '&:hover': {
                backgroundColor: theme.palette.secondary.light,
              },
            }),
          },
          {
            props: { variant: 'outlined' },
            style: ({ theme }) => ({
              color: theme.palette.secondary.main,
              border: `2px solid ${theme.palette.secondary.main}`,
              backgroundColor: 'transparent',
              width: {xs: '100%', sm: '200px'},
              '&:hover': {
                backgroundColor: theme.palette.mode === 'light' 
                  ? 'rgba(0, 192, 160, 0.1)' 
                  : 'rgba(51, 211, 184, 0.1)',
              },
            }),
          },
        ],
      },
      MuiBox: {
        variants: [
          {
            props: { variant: 'popup' },
            style: ({ theme }) => ({
              width: '100%',
              maxWidth: '400px',
              height: 'auto',
              maxHeight: 'calc(100vh - 2rem)',
              margin: '1rem',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: theme.palette.background.paper,
              borderRadius: theme.shape.borderRadius * 2,
              boxShadow: theme.shadows[24],
              overflow: 'auto',
              [theme.breakpoints.down('sm')]: {
                maxWidth: 'calc(100% - 2rem)',
              },
            }),
          },
          {
            props: { variant: 'hero' },
            style: ({ theme }) => ({
              width: '100%',
              background: 'transparent',
              display: 'flex',
              alignItems: 'center',
              minHeight: '60vh',
              paddingTop: 16,
              paddingBottom: 16,
              [theme.breakpoints.up('sm')]: {
                paddingTop: 32,
                minHeight: '85vh',
              },
              [theme.breakpoints.up('md')]: {
                paddingTop: theme.spacing(16),
              },
            }),
          },
          {
            props: { variant: 'heroImage' },
            style: {
              alignSelf: 'center',
              height: '750px',
              width: '100%',
              backgroundSize: '100%',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              borderRadius: '25px',
              overflow: 'hidden',
            },
          },
        ],
      },
      MuiDialog: {
        variants: [
          {
            props: { variant: 'popup' },
            style: ({ theme }) => ({
              '& .MuiDialog-paper': {
                padding: theme.spacing(4),
                borderRadius: theme.shape.borderRadius * 2,
                maxWidth: 500,
                width: '90%',
                margin: theme.spacing(2),
                backgroundColor: theme.palette.background.paper,
                boxShadow: theme.shadows[24],
                [theme.breakpoints.up('sm')]: {
                  margin: theme.spacing(4),
                },
              },
            }),
          },
          {
            props: { variant: 'signup' },
            style: ({ theme }) => ({
              '& .MuiDialog-paper': {
                padding: theme.spacing(2),
                borderRadius: theme.shape.borderRadius * 2,
                maxWidth: 400,
                width: '100%',
                margin: theme.spacing(2),
                [theme.breakpoints.up('sm')]: {
                  margin: theme.spacing(4),
                },
              },
            }),
          },
        ],
      },
      MuiDialogActions: {
        variants: [
          {
            props: { variant: 'popup' },
            style: ({ theme }) => ({
              padding: theme.spacing(2, 3),
              [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(3, 4),
              },
            }),
          },
          {
            props: { variant: 'signup' },
            style: ({ theme }) => ({
              flexDirection: 'column',
              padding: theme.spacing(2, 3),
              [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(3, 4),
              },
              '& > :not(:first-of-type)': {
                marginLeft: 0,
                marginTop: theme.spacing(2),
              },
            }),
          },
        ],
      },
      MuiDialogContent: {
        variants: [
          {
            props: { variant: 'popup' },
            style: ({ theme }) => ({
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(3),
              padding: theme.spacing(2, 3),
              [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(3, 4),
              },
            }),
          },
          {
            props: { variant: 'signup' },
            style: ({ theme }) => ({
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(2),
              padding: theme.spacing(2, 3),
              [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(3, 4),
              },
            }),
          },
        ],
      },
      MuiForm: {
        variants: [
          {
            props: { variant: 'signup' },
            style: ({ theme }) => ({
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(3),
              padding: theme.spacing(2),
              [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(3),
              },
              '& .MuiTextField-root': {
                marginBottom: theme.spacing(2),
              },
              '& .MuiButton-root': {
                marginTop: theme.spacing(2),
              },
              '& .MuiTypography-root': {
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
              },
            }),
          },
        ],
      },
      MuiPaper: {
        styleOverrides: {
          root: ({ theme }) => ({
            backgroundColor: theme.palette.background.paper,
          }),
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: ({ theme }) => ({
            '& .MuiOutlinedInput-root': {
              backgroundColor: theme.palette.mode === 'dark' 
                ? 'rgba(255, 255, 255, 0.05)' 
                : 'rgba(0, 0, 0, 0.06)',
              '& fieldset': {
                borderColor: theme.palette.mode === 'dark' 
                  ? 'rgba(255, 255, 255, 0.23)' 
                  : 'rgba(0, 0, 0, 0.23)',
              },
              '&:hover fieldset': {
                borderColor: theme.palette.secondary.main,
              },
              '&.Mui-focused fieldset': {
                borderColor: theme.palette.secondary.main,
              },
            },
            '& .MuiInputBase-input': {
              color: theme.palette.text.primary,
            },
            '& .MuiInputLabel-root': {
              color: theme.palette.text.secondary,
              '&.Mui-focused': {
                color: theme.palette.secondary.main,
              },
            },
            '& .MuiInputLabel-shrink': {
              padding: '0 8px',
              marginLeft: '-8px',
              lineHeight: '1.1',
            },
            '& .MuiFormHelperText-root': {
              marginLeft: '14px',
              marginRight: '14px',
            },
          }),
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: ({ theme }) => ({
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.secondary.main,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.secondary.main,
            },
          }),
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: ({ theme }) => ({
            color: theme.palette.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[600],
            '&.Mui-checked': {
              color: theme.palette.secondary.main,
            },
          }),
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: ({ theme }) => ({
            backgroundColor: theme.palette.background.paper,
            boxShadow: 'none',
            '&:before': {
              display: 'none',
            },
            '&.Mui-expanded': {
              margin: 0,
            },
          }),
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: ({ theme }) => ({
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
            '&.Mui-expanded': {
              minHeight: 48,
            },
          }),
          content: {
            '&.Mui-expanded': {
              margin: '12px 0',
            },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: ({ theme }) => ({
            padding: theme.spacing(2),
            borderTop: `1px solid ${theme.palette.divider}`,
          }),
        },
      },
    },
    spacing: (factor) => `${0.25 * factor}rem`,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  };
};

// Export chart colors
export const turquoise = "#33D3B8";
export const mint = "#00B090";
export const persianGreen = "#009688";
export const melon = "#FE9670";
export const tangerine = "#FF916B";
export const periwinkle = "#BABDE2";
export const coolGray = "#8285A1";
export const lightRed = "#FF8585";
export const bittersweet = "#FF6666";
export const skyBlue = "#87CEEB";
export const steelBlue = "#4682B4";
export const dodgerBlue = '#1E90FF';
export const lightBackground = '#F5F5F7';
export const darkBackground = '#121212';

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState(() => {
    // Check if there's a saved preference in localStorage
    const savedMode = localStorage.getItem('colorMode');
    if (savedMode) {
      return savedMode;
    }
    // If no saved preference, check system preference
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return 'dark';
    }
    return 'light';
  });

  useEffect(() => {
    // Listen for changes in system color scheme preference
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = () => {
      setMode(mediaQuery.matches ? 'dark' : 'light');
    };
    mediaQuery.addListener(handleChange);
    return () => mediaQuery.removeListener(handleChange);
  }, []);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === "light" ? "dark" : "light";
          localStorage.setItem('colorMode', newMode); // Save preference to localStorage
          return newMode;
        });
      },
      mode,
    }),
    [mode]
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};