// src/components/utils/formatters.jsx

export const formatPercentage = (value, options = {}) => {
  const {
    locale = 'en-US',
    minimumFractionDigits = 0,
    maximumFractionDigits = 2,
    signDisplay = 'auto',
  } = options;

  if (value === undefined || value === null) return '-';

  const formatter = new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits,
    maximumFractionDigits,
    signDisplay,
    ...options,
  });

  return formatter.format(value);
};

export const formatWholePercentage = (value, options = {}) => {
  if (value === undefined || value === null || isNaN(value)) return '-';

  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    signDisplay: options.signDisplay || 'auto',
  }).format(value);
};

export const formatNumber = (value, options = {}) => {
  const {
    locale = 'en-US',
    notation = 'standard',
    minimumFractionDigits = 0,
    maximumFractionDigits = 2,
  } = options;

  if (value === undefined || value === null) return '-';

  const formatter = new Intl.NumberFormat(locale, {
    notation,
    minimumFractionDigits,
    maximumFractionDigits,
    ...options,
  });

  return formatter.format(value);
};

export const formatCurrency = (value, options = {}) => {
  const {
    currency = 'USD',
    locale = 'en-US',
    minimumFractionDigits = 0,
    maximumFractionDigits = 2,
  } = options;

  if (value === undefined || value === null) return '-';

  const absValue = Math.abs(value);
  let formattedValue, suffix, decimalPlaces;

  if (absValue >= 1e9) {
    formattedValue = value / 1e9;
    suffix = 'B';
    decimalPlaces = 2;
  } else if (absValue >= 1e6) {
    formattedValue = value / 1e6;
    suffix = 'M';
    decimalPlaces = 2;
  } else if (absValue >= 1e3) {
    formattedValue = value / 1e3;
    suffix = 'K';
    decimalPlaces = 1;
  } else {
    formattedValue = value;
    suffix = '';
    decimalPlaces = maximumFractionDigits;
  }

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: Math.min(minimumFractionDigits, decimalPlaces),
    maximumFractionDigits: decimalPlaces,
  });

  return formatter.format(formattedValue) + suffix;
};

export const formatDate = (date, options = {}) => {
  const {
    locale = 'en-US',
    dateStyle = 'medium',
  } = options;

  if (!date) return '-';

  const dateObj = new Date(date);
  
  if (isNaN(dateObj.getTime())) return '-';

  const formatter = new Intl.DateTimeFormat(locale, {
    dateStyle,
    ...options,
  });

  return formatter.format(dateObj);
};

// Usage examples:
// formatNumber(1234567.89) // "1,234,567.89"
// formatNumber(1234567.89, { notation: 'compact' }) // "1.23M"
// formatNumber(1234.56, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) // "1,234.56"
// formatNumber(1234.56, { locale: 'de-DE' }) // "1.234,56"
// formatCurrency(1234.56) // "$1,234.56"
// formatPercentage(0.1234) // "12.34%"
// formatDate('2023-05-15') // "May 15, 2023"
// formatDate('2023-05-15', { dateStyle: 'full' }) // "Monday, May 15, 2023"
// formatDate('2023-05-15', { dateStyle: 'short' }) // "5/15/23"