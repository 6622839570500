// src/pages/ForecastDashboard.jsx v1.0.1
import React, { useEffect } from 'react'
import { 
  Card, 
  Button, 
  Alert, 
  Typography,
  Grid,
  Box,
  Container,
  Paper,
  LinearProgress
} from '@mui/material'
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts'
import { useDashboardTheme } from '../hooks/useDashboardTheme'
import {
  Warning as AlertCircle,
  CheckCircle as CheckCircle2,
  Cancel as XCircle,
  BarChart as BarChart4,
  Psychology as Brain,
  ShowChart,
  Report as FileWarning,
  TrendingUp,
  AttachMoney as CircleDollarSign,
  Speed,
  Timeline,
  Assessment,
  FileDownload
} from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { useForecast } from '../contexts/ForecastContext'
import { exportToPDF } from '../utils/pdfExport'
import '../utils/pdf.css'
import SkeletonLoader from '../utils/SkeletonLoader'
import ErrorDisplay from '../utils/ErrorDisplay'
import logger from '../utils/frontendLogger'

const RiskFactorCard = ({ title, value, icon: Icon, description }) => (
  <Card sx={{ p: 2 }}>
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
      <Icon sx={{ fontSize: 24, mr: 1, color: value > 0 ? 'success.main' : 'error.main' }} />
      <Typography variant="h6" fontWeight="medium">{title}</Typography>
    </Box>
    <Typography 
      variant="h4" 
      sx={{ 
        color: value > 0 ? 'success.main' : 'error.main',
        fontWeight: 'bold' 
      }}
    >
      {value > 0 ? '+' : ''}{value}%
    </Typography>
    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
      {description}
    </Typography>
    <LinearProgress 
      variant="determinate" 
      value={Math.abs(value)} 
      sx={{ 
        mt: 2,
        bgcolor: 'background.paper',
        '& .MuiLinearProgress-bar': {
          bgcolor: value > 0 ? 'success.main' : 'error.main'
        }
      }}
    />
  </Card>
);

function ForecastDashboard() {
  const { 
    currentMRR = 0,
    currentARR = 0,
    forecastedDeals = {},
    monthlyForecast = [],
    predictedOutcomes = [],
    riskFactors = {},
    pipelineCoverage = 0,
    growthMetrics = {},
    dealHealthMetrics = {
      engagementHealth: {
        highEngagement: 0,
        moderateEngagement: 0,
        lowEngagement: 0,
        atRisk: 0,
        averageContactFrequency: 0,
        responseRates: 0
      },
      velocityMetrics: {
        averageDaysToClose: 0,
        dealsAboveTarget: 0,
        dealsBelowTarget: 0,
        velocityTrend: 0
      },
      qualityIndicators: {
        multiThreaded: 0,
        singleThreaded: 0,
        recentActivity: 0,
        stale: 0
      },
      riskAnalysis: []
    },
    error,
    loading,
    formatCurrency 
  } = useForecast()
  const { colors, isDark } = useDashboardTheme()
  
  useEffect(() => {
    logger.debug('ForecastDashboard state:', {
      hasForecastData: !!monthlyForecast?.length,
      hasOutcomes: !!predictedOutcomes?.length,
      hasRiskFactors: !!riskFactors,
      loading,
      error: error?.message
    });
  }, [monthlyForecast, predictedOutcomes, riskFactors, loading, error]);

  const hasRequiredData = !loading && 
                         monthlyForecast?.length > 0 && 
                         predictedOutcomes?.length > 0 && 
                         typeof currentMRR === 'number' && 
                         typeof currentARR === 'number';

  if (loading) {
    return <SkeletonLoader height={600} />;
  }

  if (error) {
    return <ErrorDisplay 
      error={error} 
      message="An error occurred while loading the forecast data."
      severity="error"
    />;
  }

  if (!hasRequiredData) {
    return <ErrorDisplay 
      message="Loading forecast data... Please wait while we gather your metrics."
      severity="info"
      showRefresh={false}
    />;
  }

  const themeColors = {
    background: colors.background,
    cardBg: colors.card.background,
    cardHover: colors.card.hover,
    text: colors.text,
    chart: {
      baseline: colors.chart.baseline,
      optimistic: colors.chart.optimistic,
      pessimistic: colors.chart.pessimistic,
      actual: colors.chart.actual,
      deals: colors.chart.deals,
      contacts: colors.chart.contacts,
      score: colors.chart.score,
      grid: colors.chart.grid
    },
    status: colors.status,
    border: colors.card.border,
    progressBar: colors.progressBar,
    cta: colors.cta
  }

  const getConfidenceColor = (probability) => {
    if (probability >= 75) return themeColors.status.success
    if (probability >= 50) return themeColors.status.warning
    return themeColors.status.error
  }

  const tooltipFormatter = (value) => {
    if (typeof value === 'number') {
      return formatCurrency(value)
    }
    return value
  }

  const handleExport = async () => {
    await exportToPDF('forecast-dashboard', 'Revenue_Forecast')
  }

  const projectedMRR = monthlyForecast?.[monthlyForecast.length - 1]?.baseline ?? 0

  const topDeals = [...predictedOutcomes]
    .sort((a, b) => b.value - a.value)
    .slice(0, 20);

  const formatPipelineCoverage = (coverage) => {
    return `${(coverage || 0).toFixed(1)}x`
  }

  const formatPercentage = (value) => {
    return `${(value || 0).toFixed(1)}%`
  }

  return (
    <Box 
      id="forecast-dashboard"
      sx={{ 
        bgcolor: themeColors.background,
        p: 3 
      }}
    >
      <Container maxWidth="lg">
        {/* Replace separate export button with combined header */}
        <Box sx={{ mb: 4 }}>
          {/* Title and Export Button Row */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mb: 2 
          }}>
            <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 'bold', 
                color: themeColors.text.primary
              }}
            >
              AI-Powered Revenue Forecast
            </Typography>
            <Button
              variant="outlined"
              onClick={handleExport}
              startIcon={<FileDownload />}
            >
              Export PDF
            </Button>
          </Box>

          {/* Key Metrics Grid */}
          <Grid container spacing={2}>
            {[
              {
                title: "Current MRR",
                value: formatCurrency(currentMRR),
                icon: <CircleDollarSign sx={{ fontSize: 24, opacity: 0.8, color: themeColors.chart.baseline }} />
              },
              {
                title: "Projected MRR",
                value: formatCurrency(projectedMRR),
                subtext: growthMetrics.momGrowthRate > 0 ? `+${formatPercentage(growthMetrics.momGrowthRate)} MoM` : null,
                icon: <TrendingUp sx={{ fontSize: 24, opacity: 0.8, color: 'success.main' }} />
              },
              {
                title: "Current ARR",
                value: formatCurrency(currentARR),
                icon: <BarChart4 sx={{ fontSize: 24, opacity: 0.8, color: themeColors.chart.baseline }} />
              },
              {
                title: "Pipeline Coverage",
                value: formatPipelineCoverage(pipelineCoverage),
                subtext: "Target: 3.0x",
                icon: <ShowChart sx={{ 
                  fontSize: 24, 
                  opacity: 0.8, 
                  color: pipelineCoverage >= 3 ? 'success.main' : 'warning.main' 
                }} />
              }
            ].map((metric, index) => (
              <Grid item xs={3} key={index}>
                <Card sx={{ 
                  p: 3,
                  height: '100%', // Ensure all cards are the same height
                  minHeight: '120px', // Set minimum height
                  bgcolor: themeColors.cardBg,
                  border: isDark ? `1px solid ${themeColors.border}` : 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  '&:hover': {
                    bgcolor: themeColors.cardHover
                  }
                }}>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'flex-start',
                    height: '100%'
                  }}>
                    <Box sx={{ flex: 1 }}>
                      <Typography 
                        variant="body2" 
                        color="text.secondary" 
                        sx={{ mb: 1 }}
                      >
                        {metric.title}
                      </Typography>
                      <Typography 
                        variant="h5" 
                        fontWeight="bold" 
                        color="text.primary"
                        sx={{ mb: metric.subtext ? 1 : 0 }}
                      >
                        {metric.value}
                      </Typography>
                      {metric.subtext && (
                        <Typography 
                          variant="caption" 
                          color={metric.title === "Projected MRR" ? "success.main" : "text.secondary"}
                          sx={{ display: 'block' }}
                        >
                          {metric.subtext}
                        </Typography>
                      )}
                    </Box>
                    {metric.icon}
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* AI Insights Alert */}
        <Alert 
          icon={<Brain />}
          severity="info"
          sx={{ 
            mb: 3,
            bgcolor: isDark ? 'rgba(54, 181, 173, 0.1)' : undefined,
            border: isDark ? `1px solid ${themeColors.border}` : undefined,
            '& .MuiAlert-icon': {
              color: isDark ? themeColors.chart.baseline : undefined
            }
          }}
        >
          <Typography>
            AI Analysis: Based on current trends and market conditions, we predict a
            <Box component="span" sx={{ fontWeight: 'bold' }}> 68% probability </Box>
            of exceeding the baseline forecast by 15% or more.
          </Typography>
        </Alert>

        {/* Revenue Forecast Chart */}
        <Card sx={{ 
          p: 2, 
          mb: 6,
          bgcolor: themeColors.cardBg,
          border: isDark ? `1px solid ${themeColors.border}` : 'none'
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
            <Typography 
              variant="h6" 
              fontWeight="semibold"
              color="text.primary"
            >
              6-Month Revenue Forecast
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ 
                  width: 10, 
                  height: 10, 
                  bgcolor: themeColors.chart.baseline,
                  borderRadius: '50%', 
                  mr: 1 
                }} />
                <Typography variant="body2" color="text.secondary">Baseline</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: 10, height: 10, bgcolor: themeColors.chart.optimistic, borderRadius: '50%', mr: 1 }} />
                <Typography variant="body2" color="text.secondary">Optimistic</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: 10, height: 10, bgcolor: themeColors.chart.pessimistic, borderRadius: '50%', mr: 1 }} />
                <Typography variant="body2" color="text.secondary">Pessimistic</Typography>
              </Box>
            </Box>
          </Box>
          <div style={{ height: 256 }}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart 
                data={monthlyForecast || []}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <XAxis 
                  dataKey="month" 
                  axisLine={false}
                  tickLine={false}
                  tick={{ fill: themeColors.text.secondary }}
                />
                <YAxis 
                  tickFormatter={(value) => `$${value/1000}k`}
                  axisLine={false}
                  tickLine={false}
                  tick={{ fill: themeColors.text.secondary }}
                />
                <Tooltip 
                  formatter={tooltipFormatter}
                  contentStyle={{
                    backgroundColor: themeColors.cardBg,
                    border: `1px solid ${themeColors.border}`,
                    borderRadius: '4px',
                    padding: '8px'
                  }}
                  labelStyle={{ color: themeColors.text.primary }}
                />
                <Line 
                  type="monotone" 
                  dataKey="baseline" 
                  stroke={themeColors.chart.baseline}
                  strokeWidth={2.5}
                  dot={false}
                  activeDot={{ r: 6, fill: themeColors.chart.baseline }}
                />
                <Line 
                  type="monotone" 
                  dataKey="optimistic" 
                  stroke={themeColors.chart.optimistic}
                  strokeWidth={2.5}
                  dot={false}
                  activeDot={{ r: 6, fill: themeColors.chart.optimistic }}
                />
                <Line 
                  type="monotone" 
                  dataKey="pessimistic" 
                  stroke={themeColors.chart.pessimistic}
                  strokeWidth={2.5}
                  dot={false}
                  activeDot={{ r: 6, fill: themeColors.chart.pessimistic }}
                />
                <Line 
                  type="monotone" 
                  dataKey="actual" 
                  stroke={themeColors.chart.actual}
                  strokeWidth={3}
                  dot={{ r: 4, fill: themeColors.chart.actual }}
                  activeDot={{ r: 6, fill: themeColors.chart.actual }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Card>

        {/* Predicted Deal Outcomes */}
        <Card sx={{ p: 2, mb: 6 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
            <Brain sx={{ fontSize: 24, color: 'purple.main', mr: 2 }} />
            <Typography variant="h6" fontWeight="semibold">Top 20 AI-Predicted Deal Outcomes</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {topDeals.map((deal) => (
              <Box key={deal.id} sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #e0e0e0', pb: 2 }}>
                <Box>
                  <Typography variant="body1" fontWeight="medium">{deal.name}</Typography>
                  <Typography variant="body2" color="text.secondary">Close Date: {deal.closeDate}</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Box sx={{ textAlign: 'right' }}>
                    <Typography variant="body1" fontWeight="medium">{formatCurrency(deal.value)}</Typography>
                    <Typography variant="body2" color={getConfidenceColor(deal.probability)}>
                      {deal.probability}% Confidence
                    </Typography>
                  </Box>
                  {deal.probability >= 75 ? (
                    <CheckCircle2 sx={{ fontSize: 24, color: 'success.main' }} />
                  ) : deal.probability >= 50 ? (
                    <AlertCircle sx={{ fontSize: 24, color: 'warning.main' }} />
                  ) : (
                    <XCircle sx={{ fontSize: 24, color: 'error.main' }} />
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        </Card>

        {/* Risk Factors */}
        <Card sx={{ p: 2, mb: 8 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
            <FileWarning sx={{ fontSize: 24, color: 'warning.main', mr: 2 }} />
            <Typography variant="h6" fontWeight="semibold">Risk Factor Analysis</Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <RiskFactorCard
                title="Deal Velocity"
                value={riskFactors.dealVelocity}
                icon={Speed}
                description="Sales cycle speed compared to historical average"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <RiskFactorCard
                title="Deal Size Trend"
                value={riskFactors.dealSize}
                icon={TrendingUp}
                description="Average deal size trend over last 3 months"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <RiskFactorCard
                title="Revenue Stability"
                value={riskFactors.revenueStability}
                icon={Timeline}
                description="Consistency of monthly revenue performance"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RiskFactorCard
                title="Pipeline Health"
                value={riskFactors.pipelineHealth}
                icon={Assessment}
                description="Current pipeline coverage vs annual target"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RiskFactorCard
                title="Growth Momentum"
                value={riskFactors.growthMomentum}
                icon={ShowChart}
                description="Month-over-month revenue growth trajectory"
              />
            </Grid>
          </Grid>
        </Card>

        {/* Deal Health Analytics */}
        <Card sx={{ p: 2, mb: 6 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
            <Brain sx={{ fontSize: 24, color: 'info.main', mr: 2 }} />
            <Typography variant="h6" fontWeight="semibold">Deal Health Analytics</Typography>
          </Box>
          
          <Grid container spacing={3}>
            {/* Engagement Distribution */}
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" sx={{ mb: 2 }}>Engagement Distribution</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {[
                    { label: 'High Engagement', value: dealHealthMetrics.engagementHealth.highEngagement, color: 'success.main' },
                    { label: 'Moderate Engagement', value: dealHealthMetrics.engagementHealth.moderateEngagement, color: 'info.main' },
                    { label: 'Low Engagement', value: dealHealthMetrics.engagementHealth.lowEngagement, color: 'warning.main' },
                    { label: 'At Risk', value: dealHealthMetrics.engagementHealth.atRisk, color: 'error.main' }
                  ].map((item) => (
                    <Box key={item.label}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography variant="body2">{item.label}</Typography>
                        <Typography variant="body2" fontWeight="bold">{item.value}</Typography>
                      </Box>
                      <LinearProgress
                        variant="determinate"
                        value={(item.value / forecastedDeals.totalCount) * 100}
                        sx={{ 
                          height: 8,
                          bgcolor: 'background.paper',
                          '& .MuiLinearProgress-bar': {
                            bgcolor: item.color
                          }
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              </Paper>
            </Grid>

            {/* Deal Velocity */}
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" sx={{ mb: 2 }}>Deal Velocity Metrics</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                  <Box>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                      Average Days to Close
                    </Typography>
                    <Typography variant="h4">
                      {Math.round(dealHealthMetrics.velocityMetrics.averageDaysToClose)} days
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 4 }}>
                    <Box>
                      <Typography variant="body2" color="success.main">
                        Above Target
                      </Typography>
                      <Typography variant="h5">
                        {dealHealthMetrics.velocityMetrics.dealsAboveTarget}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" color="error.main">
                        Below Target
                      </Typography>
                      <Typography variant="h5">
                        {dealHealthMetrics.velocityMetrics.dealsBelowTarget}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>

            {/* Quality Indicators */}
            <Grid item xs={12}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" sx={{ mb: 3 }}>Deal Quality Indicators</Typography>
                <Grid container spacing={3}>
                  {[
                    { 
                      label: 'Multi-threaded Deals',
                      value: dealHealthMetrics.qualityIndicators.multiThreaded,
                      total: forecastedDeals.totalCount,
                      color: 'success.main'
                    },
                    {
                      label: 'Single-threaded Deals',
                      value: dealHealthMetrics.qualityIndicators.singleThreaded,
                      total: forecastedDeals.totalCount,
                      color: 'warning.main'
                    },
                    {
                      label: 'Recent Activity',
                      value: dealHealthMetrics.qualityIndicators.recentActivity,
                      total: forecastedDeals.totalCount,
                      color: 'info.main'
                    },
                    {
                      label: 'Stale Deals',
                      value: dealHealthMetrics.qualityIndicators.stale,
                      total: forecastedDeals.totalCount,
                      color: 'error.main'
                    }
                  ].map((indicator) => (
                    <Grid item xs={12} sm={6} md={3} key={indicator.label}>
                      <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="h4" sx={{ color: indicator.color }}>
                          {Math.round((indicator.value / indicator.total) * 100)}%
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {indicator.label}
                        </Typography>
                        <Typography variant="caption" display="block">
                          {indicator.value} deals
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Card>

        {/* CTA Section */}
        <Paper
          elevation={0}
          sx={{ 
            p: 8,
            borderRadius: 2,
            bgcolor: themeColors.cta.background,
            border: `1px solid ${themeColors.cta.border}`,
            textAlign: 'center',
            mb: 3,
            backdropFilter: isDark ? 'blur(8px)' : 'none',
          }}
        >
          <Brain 
            sx={{ 
              color: themeColors.cta.text,
              fontSize: 48,
              mb: 4
            }} 
          />
          
          <Typography 
            variant="h4" 
            sx={{ 
              fontWeight: 700,
              fontSize: '1.875rem',
              color: themeColors.cta.text,
              mb: 2
            }}
          >
            Want More Accurate Revenue Predictions?
          </Typography>
          
          <Typography 
            variant="body1" 
            sx={{ 
              color: themeColors.cta.text,
              mb: 6,
              maxWidth: '600px',
              mx: 'auto',
              lineHeight: 1.5
            }}
          >
            Our AI-powered forecasting model can help you make data-driven decisions 
            with up to 95% accuracy. Schedule a demo to see how we can enhance your 
            revenue forecasting.
          </Typography>

          <Link 
            to="/contact/sales" 
            style={{ textDecoration: 'none' }}
          >
            <Button
              variant="contained"
              size="large"
              sx={{
                px: 8,
                py: 2,
                minWidth: '320px',
                fontSize: '1.1rem',
                bgcolor: themeColors.cta.button,
                color: isDark ? '#000000' : '#FFFFFF',
                '&:hover': {
                  bgcolor: themeColors.cta.buttonHover,
                },
                boxShadow: 'none',
                textTransform: 'uppercase',
                fontWeight: 600,
                letterSpacing: '0.5px'
              }}
            >
              Sign Up for a Demo
            </Button>
          </Link>
        </Paper>
      </Container>
    </Box>
  )
}

export default ForecastDashboard