export const formatPercentage = (value, options = {}) => {
  const {
    locale = 'en-US',
    minimumFractionDigits = 0,
    maximumFractionDigits = 2,
    signDisplay = 'auto',
  } = options;

  if (value === undefined || value === null) return '-';

  const formatter = new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits,
    maximumFractionDigits,
    signDisplay,
    ...options,
  });

  return formatter.format(value);
};
