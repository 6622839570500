import React, { useState, useCallback } from 'react';
import { Box, Typography, IconButton, Tooltip, useTheme, useMediaQuery } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import SyncProgress from './SyncProgress';
import DataSyncStatus from './DataSyncStatus';
import DataFetchDetails from './DataFetchDetails';
import { useDashboardStyles } from '../../hooks/useDashboardStyles';
import { mint } from '../../../theme';
import { useOverview } from '../../../contexts/OverviewContext';

const DataSyncOverview = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { layout, typography, components } = useDashboardStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { loading } = useOverview();

  const toggleExpand = useCallback(() => setIsExpanded(prev => !prev), []);

  const containerStyles = {
    ...layout.sectionWrapper,
    height: isMobile ? 'auto' : '400px',
    minHeight: isMobile ? '400px' : 'auto',
    width: '100%',
    transition: 'all 0.3s ease-in-out',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  };

  const expandButtonStyles = {
    position: 'absolute',
    top: 8,
    right: 8,
    color: theme.palette.text.secondary,
  };

  const contentStyles = {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    height: 'calc(100% - 70px)',
    width: '100%',
  };

  return (
    <Box sx={containerStyles}>
      <Typography variant="h6" sx={{ ...typography.sectionTitle, mb: 1 }}>
        Data Sync Overview
      </Typography>
      <IconButton onClick={toggleExpand} sx={expandButtonStyles}>
        <Tooltip title={isExpanded ? "Collapse" : "Expand"}>
          {isExpanded ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </Tooltip>
      </IconButton>
      <Box sx={contentStyles}>
        <Box sx={{ 
          flex: isMobile ? 'none' : 3, 
          height: isMobile ? 'calc(100% - 80px)' : '100%',
          mr: isMobile ? 0 : 2, 
          mb: isMobile ? 2 : 0 
        }}>
          <Typography variant="subtitle2" sx={{ mb: 1, color: theme.palette.text.secondary }}>
            Sync Progress
          </Typography>
          <Box sx={{ height: 'calc(100% - 25px)' }}>
            <SyncProgress compact={!isExpanded} />
          </Box>
        </Box>
        {!isMobile && (
          <Box sx={{ 
            flex: 1, 
            height: '100%',
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Typography variant="subtitle2" sx={{ mb: 1, color: theme.palette.text.secondary }}>
              Sync Status
            </Typography>
            <Box sx={{ height: 'calc(100% - 25px)', width: '100%' }}>
              <DataSyncStatus compact={!isExpanded} />
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={{ 
        mt: 2, 
        height: isMobile ? '80px' : '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <DataFetchDetails compact={true} />
      </Box>
    </Box>
  );
};

export default React.memo(DataSyncOverview);