import React from 'react';
import { Box, Typography, Container, Grid } from '@mui/material';

const styles = {
  heroTitle1: {
    fontFamily: '"Circular", "Helvetica", sans-serif',
    fontSize: '70px',
    fontWeight: 500,
    lineHeight: '72.03px',
    letterSpacing: '-3.44px',
    color: 'rgb(43, 28, 80)',
    textAlign: 'center',
    margin: '0 0 16px 0',
    maxWidth: '1744.4px',
    fontFeatureSettings: '"ss01", "ss02", "ss08"',
    WebkitFontSmoothing: 'antialiased',
    textSizeAdjust: '100%',
    textWrap: 'wrap',
    whiteSpaceCollapse: 'preserve-breaks',
    '@media (max-width:1200px)': {
      fontSize: '3.5rem',
    },
    '@media (max-width:900px)': {
      fontSize: '2.5rem',
    },
  },
  heroTitle2: {
    fontFamily: '"Circular", "Helvetica", sans-serif',
    fontSize: '70px',
    fontWeight: 500,
    lineHeight: '72.03px',
    letterSpacing: '-3.44px',
    color: 'rgb(43, 28, 80)',
    textAlign: 'center',
    margin: '0 0 16px 0',
    maxWidth: '1744.4px',
    fontFeatureSettings: '"ss01", "ss02", "ss08"',
    WebkitFontSmoothing: 'antialiased',
    textSizeAdjust: '100%',
    textWrap: 'wrap',
    whiteSpaceCollapse: 'preserve-breaks',
    width: '1318.52px',
    height: '72.0139px',
    display: 'block',
    unicodeBidi: 'isolate',
    tabSize: '4',
  },
  heroTitle3: {
    display: "flex",
    alignItems: "center",
    columnGap: "16px",
    height: "88.0035px",
    width: "1318.52px",
    color: "rgb(43, 28, 80)",
    fontFamily: "Circular, Helvetica, sans-serif",
    fontSize: "70px",
    fontWeight: 500,
    lineHeight: "72.03px",
    letterSpacing: "-3.44px",
    marginBottom: "16px",
    textAlign: "center",
    maxWidth: "1744.4px",
  },
  // Add more style variations here
  heroTitle4: {
    // ... another variation
  },
  heroTitle5: {
    // ... another variation
  },
};

const TypographyComparison = () => {
  return (
    <Container maxWidth="lg">
      <Grid container direction="column" spacing={4}>
        {Object.entries(styles).map(([key, style]) => (
          <Grid item key={key}>
            <Box sx={{ border: '1px solid #ccc', padding: 2, borderRadius: 2 }}>
              <Typography variant="caption" display="block" gutterBottom>
                {key}
              </Typography>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: ['heroTitle2', 'heroTitle3'].includes(key) ? '88.0035px' : 'auto',
                width: ['heroTitle2', 'heroTitle3'].includes(key) ? '1318.52px' : 'auto',
              }}>
                <Typography sx={style} variant={key === 'heroTitle3' ? 'h1' : 'body1'}>
                  This is a sample hero title
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default TypographyComparison;