// src/components/sales/HealthcheckDashboard/MissingDataChart.jsx
import React from 'react';
import { useRawData } from '../../../contexts/RawDataContext';
import { Paper, Typography, useTheme, Box } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { useChartStyles } from '../../hooks/useChartStyles';

const MissingDataChart = () => {
  const { deals } = useRawData();
  const theme = useTheme();
  const { getCommonChartProperties, getChartColor } = useChartStyles();

  const missingAmount = deals.filter((deal) => !deal.amount || deal.amount === 0).length;
  const missingCloseDate = deals.filter((deal) => !deal.closedate).length;
  const missingOwner = deals.filter((deal) => !deal.hubspot_owner_id).length;

  const data = [
    { field: 'Amount', Missing: missingAmount },
    { field: 'Close Date', Missing: missingCloseDate },
    { field: 'Owner', Missing: missingOwner },
  ];

  const chartProperties = getCommonChartProperties('bar');

  const CustomTooltip = ({ id, value, color }) => (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        padding: theme.spacing(1, 1.5),
        border: `1px solid ${color}`,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[3],
      }}
    >
      <Typography variant="subtitle2" sx={{ color, fontWeight: 'bold' }}>
        {id}
      </Typography>
      <Typography variant="body2">Missing: {value}</Typography>
    </Box>
  );

  return (
    <Paper sx={{ p: 3, height: '100%', backgroundColor: theme.palette.background.paper }}>
      <Typography variant="h6" gutterBottom color="text.primary">
        Missing Data
      </Typography>
      <div style={{ height: 300 }}>
        <ResponsiveBar
          {...chartProperties}
          data={data}
          keys={['Missing']}
          indexBy="field"
          colors={({ index }) => getChartColor(index)}
          axisBottom={{
            ...chartProperties.axisBottom,
            legend: 'Field',
          }}
          axisLeft={{
            ...chartProperties.axisLeft,
            legend: 'Number of Deals',
          }}
          labelTextColor={theme.palette.getContrastText(theme.palette.background.paper)}
          tooltip={({ id, value, color }) => (
            <CustomTooltip id={id} value={value} color={color} />
          )}
          legends={[]} // Add this line to remove the legend
        />
      </div>
    </Paper>
  );
};

export default MissingDataChart;
