import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';

// Import the images
import CertificationBadge from './assets/Certification-Badge.png';
import MainHero from './assets/MainHero.png';
import DarkModePartner from './assets/DarkModePartner.png';
import LightModePartner from './assets/LightModePartner.png';

const StyledBox = styled(Box)(({ theme }) => ({
  background: theme.palette.mode === 'dark'
    ? 'whitesmoke'
    : '#333333',
  color: theme.palette.mode === 'dark'
    ? '#333333'
    : 'whitesmoke',
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    height: '100%', // Fill the height of its container
  },
}));

const HighlightedText = styled('span')(({ theme }) => ({
  color: theme.palette.mode === 'dark'
    ? theme.palette.secondary.dark
    : theme.palette.secondary.light,
  fontWeight: 700,
}));

const ContactHero = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <StyledBox>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" sx={{ mb: 1, fontWeight: 700 }}>
          <HighlightedText>Transform</HighlightedText> Your HubSpot Data into{' '}
          <HighlightedText>Actionable Insights</HighlightedText>
        </Typography>
        <Typography variant="body2" sx={{ mb: 2, opacity: 0.9 }}>
          Unlock the full potential of your sales data with tailored dashboards for non-technical leaders.
        </Typography>
      </Box>
      
      <Box sx={{ position: 'relative', mb: 2, width: '100%', maxWidth: '400px' }}>
        <Box
          component="img"
          src={MainHero}
          alt="Product screenshot"
          sx={{
            width: '100%',
            height: 'auto',
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[4],
          }}
        />
        <Box
          component="img"
          src={CertificationBadge}
          alt="HubSpot Certified App"
          sx={{
            position: 'absolute',
            top: 5,
            right: 5,
            width: '40px',
            height: 'auto',
          }}
        />
      </Box>
      
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          component="img"
          src={theme.palette.mode === 'dark' ? LightModePartner : DarkModePartner}
          alt="Truepipe Partner"
          sx={{ height: 30, width: 'auto' }}
        />
      </Box>
    </StyledBox>
  );
};

export default ContactHero;