import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Paper, Typography, useTheme } from '@mui/material';
import { useChartStyles } from '../../../hooks/useChartStyles';
import RepLeadersTable from './RepLeadersTable'; // Import the table component
import { tangerine } from '../../../../theme'; // Import the tangerine color

const RepLeaders = ({ data }) => {
  const theme = useTheme();
  const { 
    getRepPerformanceChartProperties, 
    getCustomTooltip 
  } = useChartStyles();

  // Merge and prepare data
  const chartData = data
    .sort((a, b) => b.totalDeals - a.totalDeals)
    .slice(0, 5)
    .map((item, index) => ({
      ownerName: item.ownerName,
      rank: `#${index + 1}`,
      "Total Deals": item.totalDeals,
      "Closed Won Deals": item.closedWonDeals,
      winRate: item.winRate,
    }))
    .reverse();

  const chartProperties = getRepPerformanceChartProperties('repLeaders');

  // Update the getWinRateColor function
  const getWinRateColor = (winRate) => {
    const baseColor = tangerine;
    const opacity = Math.max(0.3, Math.min(1, winRate / 100));
    return `${baseColor}${Math.round(opacity * 255).toString(16).padStart(2, '0')}`;
  };

  return (
    <Paper sx={{ p: 3, backgroundColor: theme.palette.background.paper }}>
      <Typography variant="h6" gutterBottom color="text.primary">
        Rep Leaders
      </Typography>
      <ResponsiveBar
        {...chartProperties}
        data={chartData}
        keys={['Total Deals', 'Closed Won Deals']}
        indexBy="rank"
        layout="horizontal"
        groupMode="grouped"
        margin={{ top: 20, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={({ id }) => {
          if (id === 'Total Deals') {
            return theme.palette.grey[300];
          }
          if (id === 'Closed Won Deals') {
            return theme.palette.secondary.main;
          }
          return theme.palette.grey[500];
        }}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Number of Deals',
          legendPosition: 'middle',
          legendOffset: 40,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Rep',
          legendPosition: 'middle',
          legendOffset: -50,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        legends={[]}
        tooltip={({ id, value, indexValue, data }) =>
          getCustomTooltip({
            title: data.ownerName,
            items: [
              { label: 'Total Deals', value: data['Total Deals'], color: theme.palette.grey[300] },
              { label: 'Closed Won Deals', value: data['Closed Won Deals'], color: theme.palette.secondary.main },
              { label: 'Win Rate', value: `${data.winRate.toFixed(1)}%`, color: getWinRateColor(data.winRate) },
            ],
          })
        }
      />
      {/* Integrate the table component below the chart */}
      <RepLeadersTable />
    </Paper>
  );
};

export default RepLeaders;