// src/components/charts/chartColors.js v1.0.0

// Import these colors from your theme.js or define them here
import { turquoise, mint, persianGreen, melon, tangerine, periwinkle, maroon, coolGray, dodgerBlue, skyBlue, bittersweet } from '../../theme';

const chartColors = {
  light: {
    lines: [mint, tangerine, coolGray, dodgerBlue],
    areas: [turquoise, melon, periwinkle, persianGreen],
  },
  dark: {
    lines: [turquoise, melon, periwinkle, dodgerBlue],
    areas: [mint, tangerine, coolGray, persianGreen],
  },
};

const funnelColors = {
  light: [turquoise, mint, persianGreen, melon, tangerine],
  dark: [turquoise, mint, persianGreen, melon, tangerine],
};

export const getChartColor = (index, mode, type = 'lines') => {
  const colors = chartColors[mode][type];
  return colors[index % colors.length];
};

export const getChartColorPair = (index, mode) => {
  return {
    lineColor: getChartColor(index, mode, 'lines'),
    areaColor: getChartColor(index, mode, 'areas'),
  };
};

export const getFunnelColor = (index, mode) => {
  const colors = funnelColors[mode];
  return colors[index % colors.length];
};

export const stageColors = {
  light: {
    '16602267': turquoise,
    '16602268': skyBlue,
    '16602269': periwinkle,
    '16602270': bittersweet,
    '16602271': tangerine,
    // Add more stage IDs and colors as needed
  },
  dark: {
    '16602267': mint,
    '16602268': dodgerBlue,
    '16602269': coolGray,
    '16602270': melon,
    '16602271': persianGreen,
    // Add more stage IDs and colors as needed
  },
};

export const getStageColor = (stageId, mode) => {
  return stageColors[mode][stageId] || coolGray; // Default to coolGray if stage not found
};

// New function to get link color based on theme mode
export const getLinkColor = (mode) => {
  return mode === 'light' ? mint : turquoise;
};