// src/services/hubspotService.js v2.1.0

import { getFunctions, httpsCallable } from 'firebase/functions';
import { getApp } from 'firebase/app';
import logger from '../utils/frontendLogger';

const app = getApp();
const functions = getFunctions(app);

export const initiateHubSpotIntegration = async (code, state) => {
  try {
    logger.info('Initiating HubSpot integration');
    const handleCallbackFunction = httpsCallable(functions, 'handleHubSpotCallback');
    const result = await handleCallbackFunction({ code, state });
    logger.info('HubSpot integration initiated:', result.data);
    
    if (!result.data.success) {
      throw new Error(result.data.error || 'HubSpot integration failed');
    }
    
    return result.data;
  } catch (error) {
    logger.error('Error initiating HubSpot integration:', error);
    throw error;
  }
};