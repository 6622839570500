import React, { useState } from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Topbar from "../../components/global/Topbar";
import ProfileSidebar from "../../components/profile/ProfileSidebar";

const ProfileLayout = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <Box sx={{ 
      display: 'flex', 
      height: '100vh', 
      overflow: 'hidden',
      backgroundColor: theme.palette.background.default, // Use the default background color
    }}>
      {!isMobile && (
        <ProfileSidebar 
          sx={{ height: '100%', overflow: 'hidden' }} 
          isMobile={isMobile}
          isOpen={isSidebarOpen}
          onToggle={() => setIsSidebarOpen(!isSidebarOpen)}
        />
      )}
      <Box sx={{ 
        flexGrow: 1, 
        display: 'flex', 
        flexDirection: 'column', 
        overflow: 'hidden',
        backgroundColor: theme.palette.background.default, // Use the default background color
      }}>
        <Topbar />
        <Box sx={{ 
          flexGrow: 1, 
          p: 3, 
          overflow: 'auto', 
          minHeight: 0,
          backgroundColor: theme.palette.background.default, // Use the default background color
        }}>
          <Outlet />
        </Box>
      </Box>
      {isMobile && (
        <ProfileSidebar 
          isMobile={isMobile}
          isOpen={isSidebarOpen}
          onToggle={() => setIsSidebarOpen(!isSidebarOpen)}
        />
      )}
    </Box>
  );
};

export default ProfileLayout;