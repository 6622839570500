import React from 'react';
import { ResponsiveFunnel } from '@nivo/funnel';
import { useNavigate } from 'react-router-dom';
import { usePipeline } from '../../../contexts/PipelineContext';
import { Box, Typography } from '@mui/material';
import { getStageInfo } from '../../../contexts/utils/dealStages';

const PipelineFunnelChart = () => {
  const navigate = useNavigate();
  const {
    pipelineFunnel,
    getCommonChartProperties,
    isMobile,
    getCustomTooltip,
    formatCurrency,
    getPipelineFunnelTooltip,
  } = usePipeline();

  if (!pipelineFunnel || pipelineFunnel.length === 0) {
    return <Typography>No pipeline funnel data available.</Typography>;
  }

  const handleSliceClick = (slice) => {
    navigate(`/sales/${slice.id}`);
  };

  const chartProps = {
    ...getCommonChartProperties('funnel'),
    data: pipelineFunnel,
    margin: isMobile ? { top: 10, right: 10, bottom: 10, left: 10 } : { top: 20, right: 20, bottom: 20, left: 20 },
    valueFormat: (value) => formatCurrency(value, { notation: 'compact' }),
    colors: ({ id }) => {
      const { color } = getStageInfo(id);
      console.log(`Funnel Stage: ${id}, Color: ${color}`); // Add this log
      return color || '#999999'; // Fallback color
    },
    borderWidth: isMobile ? 10 : 20,
    labelColor: {
      from: 'color',
      modifiers: [['darker', 3]]
    },
    beforeSeparatorLength: isMobile ? 50 : 100,
    beforeSeparatorOffset: isMobile ? 10 : 20,
    afterSeparatorLength: isMobile ? 50 : 100,
    afterSeparatorOffset: isMobile ? 10 : 20,
    currentPartSizeExtension: isMobile ? 5 : 10,
    currentBorderWidth: isMobile ? 20 : 40,
    motionConfig: "wobbly",
    enableLabel: !isMobile,
    labelFormat: d => `${d.id} (${formatCurrency(d.value)})`,
    tooltip: ({ part }) => {
      console.log('Funnel part data:', JSON.stringify(part, null, 2)); // More detailed logging
      return getCustomTooltip(getPipelineFunnelTooltip(part));
    },
    onClick: handleSliceClick,
    isInteractive: true,
  };

  console.log('Pipeline Funnel Data:', pipelineFunnel); // Add this log

  return (
    <Box sx={{ height: 400 }}>
      <ResponsiveFunnel {...chartProps} />
    </Box>
  );
};

export default PipelineFunnelChart;