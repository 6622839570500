import { useState, useEffect, useRef } from 'react';
import { trackABTestVariant } from '../utils/analytics';

export const useABTest = (tests) => {
  const [variants, setVariants] = useState(() => {
    const storedVariants = {};
    Object.entries(tests).forEach(([testName, testVariants]) => {
      const storedVariant = localStorage.getItem(`abtest_${testName}`);
      if (storedVariant && testVariants.includes(storedVariant)) {
        storedVariants[testName] = storedVariant;
      }
    });
    return storedVariants;
  });

  const isInitialized = useRef(false);
  const impressionsTracked = useRef(false);

  useEffect(() => {
    if (isInitialized.current) return;

    const newVariants = { ...variants };
    let shouldUpdate = false;

    Object.entries(tests).forEach(([testName, testVariants]) => {
      if (!newVariants[testName]) {
        shouldUpdate = true;
        const randomVariant = testVariants[Math.floor(Math.random() * testVariants.length)];
        localStorage.setItem(`abtest_${testName}`, randomVariant);
        newVariants[testName] = randomVariant;
        console.log(`New A/B Test: ${testName}, Variant: ${randomVariant}`);
      }
    });

    if (shouldUpdate) {
      setVariants(newVariants);
    }

    isInitialized.current = true;
  }, [tests, variants]);

  useEffect(() => {
    if (!impressionsTracked.current && Object.keys(variants).length > 0) {
      Object.entries(variants).forEach(([testName, variant]) => {
        trackABTestVariant(testName, variant);
        console.log(`AB Test initialized: ${testName}, Variant: ${variant}`);
      });
      impressionsTracked.current = true;
      console.log('All AB Test variants:', variants);
    }
  }, [variants]);

  return variants;
};