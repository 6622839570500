// src/components/sales/HealthcheckDashboard/OwnerPipelineTable.jsx

import React from 'react';
import { useRawData } from '../../../contexts/RawDataContext'; // Updated import
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Chip,
  LinearProgress,
  Tooltip,
  Box,
  useTheme,
  Collapse,
  IconButton,
} from '@mui/material';
import {
  Warning as WarningIcon,
  CheckCircle as CheckCircleIcon,
  Schedule as ScheduleIcon,
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
  Speed as SpeedIcon,
  AttachMoney as MoneyIcon,
  Assessment as AssessmentIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';
import { turquoise, mint, melon, tangerine, bittersweet, lightRed } from '../../../theme';

const OwnerPipelineTable = () => {
  const { calculateOwnerHealthMetrics, formatCurrency, formatPercentage } = useRawData(); // Updated hook and destructuring
  const [orderBy, setOrderBy] = React.useState('healthScore');
  const [order, setOrder] = React.useState('desc');
  const theme = useTheme();

  const ownerData = calculateOwnerHealthMetrics(); // Updated function call

  // Sorting logic
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData = ownerData.sort((a, b) => {
    if (order === 'asc') {
      return a[orderBy] < b[orderBy] ? -1 : 1;
    }
    return a[orderBy] > b[orderBy] ? -1 : 1;
  });

  const getColorForScore = (score) => {
    const isDarkMode = theme.palette.mode === 'dark';
    if (score > 70) return isDarkMode ? turquoise : mint;
    if (score > 40) return isDarkMode ? melon : tangerine;
    return isDarkMode ? bittersweet : lightRed;
  };

  const getChipColor = (value, thresholds) => {
    const isDarkMode = theme.palette.mode === 'dark';
    if (value > thresholds.high) return isDarkMode ? turquoise : mint;
    if (value > thresholds.medium) return isDarkMode ? melon : tangerine;
    return isDarkMode ? bittersweet : lightRed;
  };

  return (
    <Paper style={{ padding: '20px', backgroundColor: theme.palette.background.paper }}>
      <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
        Owner Pipeline Health
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'owner'}
                  direction={order}
                  onClick={() => handleRequestSort('owner')}
                >
                  Owner
                </TableSortLabel>
              </TableCell>
              <TableCell>Deals & Value</TableCell>
              <TableCell>Health Indicators</TableCell>
              <TableCell>Performance Metrics</TableCell>
              <TableCell>Risk Assessment</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'healthScore'}
                  direction={order}
                  onClick={() => handleRequestSort('healthScore')}
                >
                  Overall Health
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row) => (
              <Row
                key={row.id} // Use owner id as the key
                row={row}
                formatCurrency={formatCurrency}
                formatPercentage={formatPercentage}
                getColorForScore={getColorForScore}
                getChipColor={getChipColor}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const Row = (props) => {
  const { row, formatCurrency, formatPercentage, getColorForScore, getChipColor } = props;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <React.Fragment>
      <TableRow hover>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.owner}</TableCell>
        <TableCell>
          <Box>
            <Typography variant="body2">
              {row.totalDeals} deals
              {row.dealsClosingSoon > 0 && (
                <Tooltip title={`${row.dealsClosingSoon} deals closing soon`}>
                  <Chip
                    icon={<ScheduleIcon />}
                    label={row.dealsClosingSoon}
                    size="small"
                    sx={{
                      ml: 1,
                      backgroundColor: isDarkMode ? melon : tangerine,
                      color: theme.palette.getContrastText(isDarkMode ? melon : tangerine),
                    }}
                  />
                </Tooltip>
              )}
            </Typography>
            <Typography variant="body2">{formatCurrency(row.totalValue)}</Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box>
            <Tooltip title={`${row.outdatedDeals} outdated deals`}>
              <Chip
                label={`${row.outdatedDeals} outdated`}
                size="small"
                icon={row.outdatedDeals > 0 ? <WarningIcon /> : <CheckCircleIcon />}
                sx={{
                  mb: 1,
                  backgroundColor:
                    row.outdatedDeals > 0
                      ? isDarkMode
                        ? bittersweet
                        : lightRed
                      : isDarkMode
                      ? turquoise
                      : mint,
                  color: theme.palette.getContrastText(
                    row.outdatedDeals > 0
                      ? isDarkMode
                        ? bittersweet
                        : lightRed
                      : isDarkMode
                      ? turquoise
                      : mint
                  ),
                }}
              />
            </Tooltip>
            <Typography variant="body2">
              Avg. Age: {row.avgDealAge ? row.avgDealAge.toFixed(1) : 'N/A'} days
              {row.avgDealAge > 30 && (
                <Tooltip title="Deals are aging">
                  <TrendingUpIcon
                    sx={{ ml: 1, fontSize: 16, color: isDarkMode ? bittersweet : lightRed }}
                  />
                </Tooltip>
              )}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box>
            <Typography variant="body2">
              Win Prob: {formatPercentage(row.avgWinProbability)}
              {row.avgWinProbability < 0.3 && (
                <Tooltip title="Low win probability">
                  <TrendingDownIcon
                    sx={{ ml: 1, fontSize: 16, color: isDarkMode ? bittersweet : lightRed }}
                  />
                </Tooltip>
              )}
            </Typography>
            <Typography variant="body2">
              <Tooltip title="Deal Velocity">
                <SpeedIcon
                  sx={{ mr: 1, fontSize: 16, color: isDarkMode ? turquoise : mint }}
                />
              </Tooltip>
              {row.dealVelocity.toFixed(1)} days
            </Typography>
            <Typography variant="body2">
              <Tooltip title="Forecast Accuracy">
                <AssessmentIcon
                  sx={{ mr: 1, fontSize: 16, color: isDarkMode ? turquoise : mint }}
                />
              </Tooltip>
              {formatPercentage(row.forecastAccuracy)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box>
            <Tooltip title="Engagement Score">
              <Chip
                label={`Engagement: ${
                  row.engagementScore !== undefined ? row.engagementScore.toFixed(0) : 'N/A'
                }%`}
                size="small"
                sx={{
                  mb: 1,
                  backgroundColor: getChipColor(row.engagementScore, { high: 70, medium: 40 }),
                  color: theme.palette.getContrastText(
                    getChipColor(row.engagementScore, { high: 70, medium: 40 })
                  ),
                }}
              />
            </Tooltip>
            <Typography variant="body2">
              <Tooltip title="Risk Score">
                <Chip
                  label={`Risk: ${row.riskScore.toFixed(0)}%`}
                  size="small"
                  sx={{
                    mt: 1,
                    backgroundColor: getChipColor(100 - row.riskScore, { high: 70, medium: 40 }),
                    color: theme.palette.getContrastText(
                      getChipColor(100 - row.riskScore, { high: 70, medium: 40 })
                    ),
                  }}
                />
              </Tooltip>
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress
                variant="determinate"
                value={row.healthScore}
                sx={{
                  height: 10,
                  borderRadius: 5,
                  backgroundColor:
                    theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
                  '& .MuiLinearProgress-bar': {
                    borderRadius: 5,
                    backgroundColor: getColorForScore(row.healthScore),
                  },
                }}
              />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">
                {`${Math.round(row.healthScore)}%`}
              </Typography>
            </Box>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={7} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Additional Metrics
              </Typography>
              <Table size="small" aria-label="details">
                <TableBody>
                  <TableRow>
                    <TableCell>Deals Created This Quarter</TableCell>
                    <TableCell>{row.dealsCreatedThisQuarter}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Deals Closing This Quarter</TableCell>
                    <TableCell>{row.dealsClosingThisQuarter}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Needs Attention Deals</TableCell>
                    <TableCell>{row.needsAttentionDeals}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Avg. Number of Associated Contacts</TableCell>
                    <TableCell>{row.avgNumAssociatedContacts.toFixed(1)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Avg. Number of Notes</TableCell>
                    <TableCell>{row.avgNotesPerDeal.toFixed(1)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Avg. Lead Conversion Rate</TableCell>
                    <TableCell>{formatPercentage(row.avgLeadConversionRate)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Forecast Amount</TableCell>
                    <TableCell>{formatCurrency(row.totalForecastAmount)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Projected Amount</TableCell>
                    <TableCell>{formatCurrency(row.totalProjectedAmount)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total MRR</TableCell>
                    <TableCell>{formatCurrency(row.totalMRR)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total ARR</TableCell>
                    <TableCell>{formatCurrency(row.totalARR)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Avg. Time Since Last Contacted</TableCell>
                    <TableCell>{row.avgTimeSinceLastContacted.toFixed(1)} days</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Deals with No Next Activity</TableCell>
                    <TableCell>{row.dealsWithNoNextActivity}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Avg. Time in Deal Stage</TableCell>
                    <TableCell>{row.avgTimeInDealStage.toFixed(1)} days</TableCell>
                  </TableRow>
                  {/* Add more detailed metrics as needed */}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default OwnerPipelineTable;