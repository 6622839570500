import React, { useState } from 'react';
import { Box, Typography, Button, Grid, Switch, FormControlLabel, Divider, useTheme, Alert } from '@mui/material';
import { TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useChartConfig } from '../hooks/useChartConfig';
import { useAuth } from '../../contexts/AuthContext';

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Current password is required'),
  newPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/\d/, 'Password must contain at least one number')
    .matches(/[@$!%*?&]/, 'Password must contain at least one special character')
    .required('New password is required'),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm new password is required'),
});

const SecuritySettings = () => {
  const theme = useTheme();
  const { isMobile } = useChartConfig();
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState(null);
  const { updateUserPassword } = useAuth();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      console.log('Attempting to update password');
      console.log('Current password:', values.currentPassword);
      console.log('New password:', values.newPassword);
      
      await updateUserPassword(values.currentPassword, values.newPassword);
      console.log('Password updated successfully');
      setFeedbackMessage({ type: 'success', message: 'Password updated successfully!' });
      resetForm();
    } catch (error) {
      console.error('Error updating password:', error);
      let errorMessage = 'Failed to update password. Please try again.';
      if (error.code === 'auth/wrong-password') {
        errorMessage = 'The current password you entered is incorrect. Please try again.';
      }
      setFeedbackMessage({ type: 'error', message: errorMessage });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box sx={{ 
      backgroundColor: theme.palette.background.paper, 
      p: isMobile ? 2 : 3,
      color: theme.palette.text.primary
    }}>
      <Typography variant={isMobile ? "h5" : "h3"} mb={3}>
        Security Settings
      </Typography>

      {feedbackMessage && (
        <Alert severity={feedbackMessage.type} sx={{ mb: 2 }}>
          {feedbackMessage.message}
        </Alert>
      )}

      <Formik
        initialValues={{ currentPassword: '', newPassword: '', confirmNewPassword: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" mb={2}>Password</Typography>
                <Field
                  as={TextField}
                  fullWidth
                  name="currentPassword"
                  label="Current Password"
                  type="password"
                  margin="normal"
                  error={touched.currentPassword && Boolean(errors.currentPassword)}
                  helperText={touched.currentPassword && errors.currentPassword}
                />
                <Field
                  as={TextField}
                  fullWidth
                  name="newPassword"
                  label="New Password"
                  type="password"
                  margin="normal"
                  error={touched.newPassword && Boolean(errors.newPassword)}
                  helperText={touched.newPassword && errors.newPassword}
                />
                <Field
                  as={TextField}
                  fullWidth
                  name="confirmNewPassword"
                  label="Confirm New Password"
                  type="password"
                  margin="normal"
                  error={touched.confirmNewPassword && Boolean(errors.confirmNewPassword)}
                  helperText={touched.confirmNewPassword && errors.confirmNewPassword}
                />
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                  sx={{ 
                    mt: 2, 
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                    '&:hover': {
                      backgroundColor: theme.palette.secondary.dark,
                    }
                  }}
                >
                  {isSubmitting ? 'Updating...' : 'Change Password'}
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ my: 3 }} />
                <Typography variant="h6" mb={2}>Two-Factor Authentication</Typography>
                <FormControlLabel
                  control={<Switch checked={twoFactorEnabled} onChange={(e) => setTwoFactorEnabled(e.target.checked)} />}
                  label="Enable Two-Factor Authentication"
                />
                {twoFactorEnabled && (
                  <Box mt={2}>
                    <Typography variant="body1" mb={2}>
                      Scan this QR code with your authenticator app:
                    </Typography>
                    <Box width={200} height={200} bgcolor={theme.palette.grey[300]} />
                    <TextField fullWidth label="Enter verification code" margin="normal" />
                    <Button
                      variant="contained"
                      sx={{ 
                        mt: 2, 
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.secondary.contrastText,
                        '&:hover': {
                          backgroundColor: theme.palette.secondary.dark,
                        }
                      }}
                    >
                      Verify and Enable
                    </Button>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default SecuritySettings;