// src/components/hooks/useChartConfig.js v1.0.0

import { useTheme, useMediaQuery } from '@mui/material';
import { getChartColor, getChartColorPair, getLinkColor } from '../charts/chartColors';
import { formatNumber } from '../utils/formatNumber';
import { formatCurrency } from '../utils/formatCurrency';
import { formatPercentage } from '../utils/formatPercentage';

export const useChartConfig = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getCurrentAndPreviousYear = () => {
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;
    return { currentYear, previousYear };
  };

  const getBarWidth = (dataLength, totalWidth) => {
    const barsPerMonth = 2;
    const availableWidth = totalWidth - (dataLength * 10);
    const barWidth = availableWidth / (dataLength * barsPerMonth);
    return Math.min(barWidth, isMobile ? 40 : 60);
  };

  const getCommonProperties = (dataLength = 12) => {
    const yScalePadding = isMobile ? 0.2 : 0;
  
    return {
      margin: isMobile 
        ? { top: 10, right: 5, bottom: 5, left: 5 }
        : { top: 50, right: 30, bottom: 50, left: 60 },
      xScale: { type: 'point' },
      yScale: { 
        type: 'linear', 
        min: 'auto',
        max: 'auto',
        stacked: false, 
        reverse: false,
        padding: yScalePadding
      },
      curve: "monotoneX",
      enableGridX: false,
      enableGridY: false,
      pointSize: isMobile ? 0 : 10,
      pointColor: { theme: 'background' },
      pointBorderWidth: 2,
      pointBorderColor: { from: 'serieColor' },
      pointLabelYOffset: -12,
      useMesh: true,
      colors: ({ index }) => getChartColor(index, theme.palette.mode),
      theme: {
        axis: {
          domain: {
            line: {
              stroke: isMobile ? 'transparent' : theme.palette.text.primary,
            },
          },
          legend: {
            text: {
              fill: isMobile ? 'transparent' : theme.palette.text.primary,
            },
          },
          ticks: {
            line: {
              stroke: isMobile ? 'transparent' : theme.palette.text.primary,
              strokeWidth: 1,
            },
            text: {
              fill: isMobile ? 'transparent' : theme.palette.text.primary,
            },
          },
        },
        legends: {
          text: {
            fill: theme.palette.text.primary,
          },
        },
        tooltip: {
          container: {
            background: theme.palette.background.paper,
            color: theme.palette.text.primary,
          },
        },
      },
      legends: getLegendConfig(),
      barWidth: getBarWidth(dataLength),
    };
  };

  const getYAxisFormatter = (dataType) => {
    switch (dataType) {
      case 'currency':
        return (value) => formatCurrency(value, { notation: 'compact', maximumFractionDigits: 0 });
      case 'percentage':
        return (value) => formatPercentage(value, { maximumFractionDigits: 1 });
      case 'number':
      default:
        return (value) => formatNumber(value, { notation: 'compact', maximumFractionDigits: 0 });
    }
  };
  
  const getAxisConfig = (xLegend, yLegend, dataType = 'number') => ({
    axisTop: null,
    axisRight: null,
    axisBottom: isMobile ? null : {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: xLegend,
      legendOffset: 36,
      legendPosition: 'middle'
    },
    axisLeft: isMobile ? null : {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: yLegend,
      legendOffset: -40,
      legendPosition: 'middle',
      format: getYAxisFormatter(dataType),
    },
  });

  const getLegendConfig = (customLabels = []) => {
    const { currentYear, previousYear } = getCurrentAndPreviousYear();
    const defaultLabels = [currentYear.toString(), previousYear.toString(), "Forecast"];
    const labels = customLabels.length > 0 ? customLabels : defaultLabels;
  
    return isMobile ? [] : [
      {
        anchor: 'top-right',
        direction: 'column',
        justify: false,
        translateX: 40,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1
            }
          }
        ],
        data: labels.map((label, index) => ({
          id: label,
          label,
          color: getChartColor(index, theme.palette.mode),
        })),
        position: 'absolute',
        top: 0,
        right: 0,
      }
    ];
  };

  const getTooltipStyles = () => ({
    container: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.divider}`,
      boxShadow: theme.shadows[3],
      fontFamily: theme.typography.fontFamily,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      height: '200px',
    },
    header: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      padding: theme.spacing(1),
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      fontWeight: theme.typography.fontWeightBold,
    },
    content: {
      padding: theme.spacing(1),
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    label: {
      display: 'flex',
      alignItems: 'center',
    },
    colorDot: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      marginRight: theme.spacing(1),
    },
  });

  const getReferenceLineConfig = (data) => {
    if (!Array.isArray(data) || data.length === 0) return [];

    const { currentYear, previousYear } = getCurrentAndPreviousYear();
    const quarters = [3, 6, 9, 12];
    return quarters.map((month, index) => {
      const quarterData = data.slice(0, month);
      if (quarterData.length === 0) return null;

      const currentYearRevenue = quarterData.reduce((sum, d) => sum + (d[currentYear] || d.currentYearOrForecast || 0), 0);
      const previousYearRevenue = quarterData.reduce((sum, d) => sum + (d[previousYear] || d.previousYear || 0), 0);
      const difference = currentYearRevenue - previousYearRevenue;
      const percentageChange = previousYearRevenue !== 0 ? ((difference / previousYearRevenue) * 100).toFixed(2) : 0;

      const lastDataPoint = quarterData[quarterData.length - 1];
      if (!lastDataPoint || !lastDataPoint.month) return null;

      return {
        axis: 'x',
        value: lastDataPoint.month,
        lineStyle: { stroke: theme.palette.divider, strokeWidth: 2 },
        legend: `Q${index + 1}`,
        legendOrientation: 'vertical',
        legendPosition: 'top',
        textStyle: {
          fill: theme.palette.text.primary,
          fontSize: 12,
        },
        legendStyle: {
          fill: theme.palette.text.primary,
          fontSize: 14,
          fontWeight: 'bold',
        },
        annotation: `${formatCurrency(difference, { signDisplay: 'always' })} (${percentageChange}%)`,
        annotationStyle: {
          fill: difference >= 0 ? theme.palette.success.main : theme.palette.error.main,
          fontSize: 12,
        },
      };
    }).filter(Boolean);
  };

  return {
    getCommonProperties,
    getAxisConfig,
    getLegendConfig,
    getTooltipStyles,
    getYAxisFormatter,
    isMobile,
    getCurrentAndPreviousYear,
    getReferenceLineConfig,
    getBarWidth,
    getChartColor: (index) => getChartColor(index, theme.palette.mode),
    getChartColorPair: (index) => getChartColorPair(index, theme.palette.mode),
    getLinkColor: () => getLinkColor(theme.palette.mode), // Expose the new function
  };
};