import React, { useMemo, useState, useEffect } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, InputAdornment, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import SearchIcon from '@mui/icons-material/Search';
import { useChartStyles } from '../../hooks/useChartStyles';
import { useDashboardStyles } from '../../hooks/useDashboardStyles';
import { useOverview } from '../../../contexts/OverviewContext';
import { formatCurrency, formatDate, formatNumber, formatPercentage } from '../../utils/formatters';
import CustomTooltip from '../../charts/CustomTooltip';

const RecentlyClosedDeals = () => {
  const { deals } = useOverview();
  const { getCommonChartProperties, getTableStyles, isMobile } = useChartStyles();
  const { components } = useDashboardStyles();
  const tableStyles = getTableStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const [timePeriod, setTimePeriod] = useState('week');

  const getTimePeriodDays = (period) => {
    switch (period) {
      case 'week': return 7;
      case 'month': return 30;
      case 'quarter': return 90;
      default: return 7;
    }
  };

  const getRecentlyClosedDeals = (period) => {
    const now = new Date();
    const periodDays = getTimePeriodDays(period);
    const periodStart = new Date(now.getTime() - periodDays * 24 * 60 * 60 * 1000);

    return deals
      .filter(deal => 
        deal.closedate && 
        parseFloat(deal.hs_deal_stage_probability) === 1.0 &&
        new Date(deal.closedate) >= periodStart &&
        deal.dealname.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => new Date(b.closedate) - new Date(a.closedate));
  };

  const recentlyClosedDeals = useMemo(() => getRecentlyClosedDeals(timePeriod), [deals, timePeriod, searchTerm]);

  useEffect(() => {
    if (recentlyClosedDeals.length === 0) {
      if (timePeriod === 'week') {
        setTimePeriod('month');
      } else if (timePeriod === 'month') {
        setTimePeriod('quarter');
      }
    }
  }, [recentlyClosedDeals, timePeriod]);

  const summaryStats = useMemo(() => {
    const totalValue = recentlyClosedDeals.reduce((sum, deal) => sum + parseFloat(deal.amount), 0);
    const avgDealSize = totalValue / recentlyClosedDeals.length || 0;
    
    // Calculate previous period stats
    const previousPeriodStart = new Date(new Date().getTime() - getTimePeriodDays(timePeriod) * 2 * 24 * 60 * 60 * 1000);
    const previousPeriodEnd = new Date(new Date().getTime() - getTimePeriodDays(timePeriod) * 24 * 60 * 60 * 1000);
    const previousPeriodDeals = deals.filter(deal => 
      deal.closedate && 
      parseFloat(deal.hs_deal_stage_probability) === 1.0 &&
      new Date(deal.closedate) >= previousPeriodStart &&
      new Date(deal.closedate) < previousPeriodEnd
    );
    const previousTotalValue = previousPeriodDeals.reduce((sum, deal) => sum + parseFloat(deal.amount), 0);
    const valueChange = previousTotalValue !== 0 ? ((totalValue - previousTotalValue) / previousTotalValue) * 100 : 0;

    return { totalValue, avgDealSize, valueChange };
  }, [recentlyClosedDeals, deals, timePeriod]);

  const barChartData = useMemo(() => {
    return recentlyClosedDeals.slice(0, 10).map(deal => ({
      dealname: deal.dealname,
      amount: parseFloat(deal.amount)
    }));
  }, [recentlyClosedDeals]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const CustomTick = ({ x, y, value }) => {
    const truncatedValue = value.length > 15 ? value.substring(0, 15) + '...' : value;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          textAnchor="end"
          transform="rotate(-45)"
          dy={-4}
          dx={-8}
          style={{
            fill: 'currentColor',
            fontSize: 10,
          }}
        >
          {truncatedValue}
        </text>
      </g>
    );
  };

  const customTooltip = ({ value, label, formatType }) => (
    <CustomTooltip
      title={label}
      items={[{ label: 'Amount', value, formatType }]}
    />
  );

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Recently Closed Deals</Typography>
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <ToggleButtonGroup
          value={timePeriod}
          exclusive
          onChange={(e, newPeriod) => newPeriod && setTimePeriod(newPeriod)}
          aria-label="time period"
          sx={components.tabs.root}
        >
          <ToggleButton value="week" aria-label="last week" sx={components.tabs.tab}>Week</ToggleButton>
          <ToggleButton value="month" aria-label="last month" sx={components.tabs.tab}>Month</ToggleButton>
          <ToggleButton value="quarter" aria-label="last quarter" sx={components.tabs.tab}>Quarter</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {recentlyClosedDeals.length === 0 ? (
        <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography>No closed deals found in the selected time period.</Typography>
        </Box>
      ) : (
        <>
          <Box sx={{ position: 'relative', height: 300, mb: 2 }}>
            <ResponsiveBar
              data={barChartData}
              keys={['amount']}
              indexBy="dealname"
              {...getCommonChartProperties('bar')}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: 32,
                renderTick: CustomTick
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                label: false,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: -40,
                format: value => formatCurrency(value, { notation: 'compact' })
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              tooltip={({ value, indexValue }) => customTooltip({ value, label: indexValue, formatType: 'currency' })}
              margin={{ top: 20, right: 120, bottom: 60, left: 60 }}
              legends={[]}
            />
            <Box sx={{ position: 'absolute', top: 0, right: 0, width: 120, p: 1, backgroundColor: 'background.paper', borderRadius: 1, boxShadow: 1 }}>
              <Typography variant="subtitle2">Total Value</Typography>
              <Typography variant="h6">{formatCurrency(summaryStats.totalValue)}</Typography>
              <Typography variant="subtitle2" sx={{ mt: 1 }}>Avg Deal Size</Typography>
              <Typography variant="h6">{formatCurrency(summaryStats.avgDealSize)}</Typography>
              <Typography variant="subtitle2" sx={{ mt: 1 }}>Change from Previous</Typography>
              <Typography variant="h6" color={summaryStats.valueChange >= 0 ? 'success.main' : 'error.main'}>
                {formatPercentage(summaryStats.valueChange)}
              </Typography>
            </Box>
          </Box>

          <TableContainer component={Paper} sx={{ maxHeight: 400, overflow: 'auto' }}>
            <Table stickyHeader aria-label="recently closed deals table" sx={tableStyles.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Deal Name</TableCell>
                  {!isMobile && (
                    <>
                      <TableCell>Close Date</TableCell>
                      <TableCell>Deal Owner</TableCell>
                      <TableCell>Deal Stage</TableCell>
                    </>
                  )}
                  <TableCell align="right">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recentlyClosedDeals.map((deal) => (
                  <TableRow key={deal.id} sx={tableStyles.tableRow}>
                    <TableCell component="th" scope="row">
                      {deal.dealname}
                    </TableCell>
                    {!isMobile && (
                      <>
                        <TableCell>{formatDate(new Date(deal.closedate))}</TableCell>
                        <TableCell>{deal.hubspot_owner_id}</TableCell>
                        <TableCell>{deal.dealstage}</TableCell>
                      </>
                    )}
                    <TableCell align="right">{formatCurrency(parseFloat(deal.amount))}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
};

export default RecentlyClosedDeals;