import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { formatCurrency } from '../../utils/formatters';
import { Link } from 'react-router-dom';
import { useChartConfig } from '../../hooks/useChartConfig';

const MobileKPITooltip = ({ open, onClose, deals, portalId, owners, title }) => {
  const theme = useTheme();
  const { getLinkColor } = useChartConfig();
  const linkColor = getLinkColor();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const isPastDue = (closeDate) => {
    return new Date(closeDate) < new Date();
  };

  const formatCloseDate = (closeDate) => {
    if (!closeDate) return <span style={{ color: 'red' }}>Missing Close Date</span>;
    const formattedDate = new Date(closeDate).toLocaleDateString();
    return isPastDue(closeDate) ? (
      <span style={{ color: 'red' }}>{formattedDate} (Past Due)</span>
    ) : (
      formattedDate
    );
  };

  const getOwnerName = (ownerId) => {
    if (!ownerId || !owners[ownerId]) {
      return <span style={{ color: 'red' }}>No Owner</span>;
    }
    return owners[ownerId].name;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
        },
      }}
    >
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {deals && deals.length > 0 ? (
          deals.map((deal, index) => (
            <Box
              key={index}
              sx={{
                mt: 2,
                '&:not(:last-child)': {
                  mb: 2,
                  pb: 2,
                  borderBottom: `1px solid ${theme.palette.divider}`,
                },
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 1 }}>
                {deal.dealname || 'Unnamed Deal'}
              </Typography>
              <Typography variant="body2">
                <strong>Amount:</strong>{' '}
                {deal.amount ? formatCurrency(deal.amount) : <span style={{ color: 'red' }}>Missing Amount</span>}
              </Typography>
              <Typography variant="body2">
                <strong>Close Date:</strong> {formatCloseDate(deal.closedate)}
              </Typography>
              <Typography variant="body2">
                <strong>Owner:</strong> {getOwnerName(deal.hubspot_owner_id)}
              </Typography>
              <Typography variant="body2" sx={{ color: theme.palette.text.secondary, mt: 1 }}>
                <Link to={`/deals/${deal.hs_object_id}`} style={{ color: linkColor }}>
                  View Deal
                </Link>
              </Typography>
            </Box>
          ))
        ) : (
          <Typography variant="body2">No deals data available</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default MobileKPITooltip;