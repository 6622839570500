export const mockNotifications = [
    {
      id: '1',
      type: 'message',
      status: 'unread',
      text: 'New message from John Doe',
      time: '5 minutes ago',
      source: 'user',
      action: '/messages/123'
    },
    {
      id: '2',
      type: 'alert',
      status: 'urgent',
      text: 'System maintenance scheduled in 1 hour',
      time: '10 minutes ago',
      source: 'system',
      action: '/maintenance'
    },
    {
      id: '3',
      type: 'update',
      status: 'read',
      text: 'Your report has been generated',
      time: '1 hour ago',
      source: 'ai',
      action: '/reports/456'
    },
    {
      id: '4',
      type: 'message',
      status: 'unread',
      text: 'Team meeting reminder: 3 PM today',
      time: '2 hours ago',
      source: 'system',
      action: '/calendar'
    },
    {
      id: '5',
      type: 'alert',
      status: 'urgent',
      text: 'Critical security update required',
      time: '3 hours ago',
      source: 'system',
      action: '/security'
    },
    {
      id: '6',
      type: 'deal',
      status: 'unread',
      text: 'New deal available',
      time: '4 hours ago',
      source: 'user',
      action: '/deals/789'
    },
    {
      id: '7',
      type: 'sync',
      status: 'read',
      text: 'Data sync completed',
      time: '5 hours ago',
      source: 'system',
      action: '/sync'
    },
    {
      id: '8',
      type: 'watchlist',
      status: 'unread',
      text: 'New item added to your watchlist',
      time: '6 hours ago',
      source: 'user',
      action: '/watchlist'
    },
    {
      id: '9',
      type: 'report',
      status: 'urgent',
      text: 'Monthly report is overdue',
      time: '7 hours ago',
      source: 'system',
      action: '/reports/overdue'
    },
    {
      id: '10',
      type: 'message',
      status: 'read',
      text: 'Message from Jane Smith',
      time: '8 hours ago',
      source: 'user',
      action: '/messages/456'
    },
    {
      id: '11',
      type: 'alert',
      status: 'unread',
      text: 'New alert: Check your settings',
      time: '9 hours ago',
      source: 'system',
      action: '/settings'
    },
    {
      id: '12',
      type: 'update',
      status: 'urgent',
      text: 'System update required',
      time: '10 hours ago',
      source: 'system',
      action: '/update'
    },
    {
      id: '13',
      type: 'message',
      status: 'unread',
      text: 'Reminder: Submit your timesheet',
      time: '11 hours ago',
      source: 'system',
      action: '/timesheet'
    },
    {
      id: '14',
      type: 'alert',
      status: 'read',
      text: 'Low disk space warning',
      time: '12 hours ago',
      source: 'system',
      action: '/disk-space'
    },
    {
      id: '15',
      type: 'update',
      status: 'unread',
      text: 'New software version available',
      time: '13 hours ago',
      source: 'system',
      action: '/software-update'
    },
    {
      id: '16',
      type: 'message',
      status: 'urgent',
      text: 'Urgent: Password reset required',
      time: '14 hours ago',
      source: 'system',
      action: '/password-reset'
    },
    {
      id: '17',
      type: 'alert',
      status: 'unread',
      text: 'New alert: Unusual login activity',
      time: '15 hours ago',
      source: 'system',
      action: '/login-activity'
    },
    {
      id: '18',
      type: 'update',
      status: 'read',
      text: 'Your profile has been updated',
      time: '16 hours ago',
      source: 'user',
      action: '/profile'
    },
    {
      id: '19',
      type: 'message',
      status: 'unread',
      text: 'New message from support',
      time: '17 hours ago',
      source: 'support',
      action: '/support/123'
    },
    {
      id: '20',
      type: 'alert',
      status: 'urgent',
      text: 'Security breach detected',
      time: '18 hours ago',
      source: 'system',
      action: '/security-breach'
    }
  ];