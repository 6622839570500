import React, { useState } from 'react';
import { Box, Typography, Grid, Tabs, Tab, useTheme } from '@mui/material';
import ProblemAreas from '../../components/sales/HealthcheckDashboard/ProblemAreas';
import OwnerPipelineTable from '../../components/sales/HealthcheckDashboard/OwnerPipelineTable';
import SummaryCards from '../../components/sales/HealthcheckDashboard/SummaryCards';
import { useRawData, RawDataProvider } from '../../contexts/RawDataContext';
import SkeletonLoader from '../../utils/SkeletonLoader';

const HealthcheckDashboard = () => (
  <RawDataProvider>
    <HealthcheckDashboardContent />
  </RawDataProvider>
);

const HealthcheckDashboardContent = () => {
  const [activeTab, setActiveTab] = useState(0); // This now represents "Rep Details" as default
  const { deals, loading, overallHealthMetrics, calculateOwnerHealthMetrics, formatCurrency } = useRawData();
  const theme = useTheme();

  if (loading) {
    return <SkeletonLoader height={600} />;
  }

  // Compute dashboard metrics
  const openDeals = deals.filter((deal) => deal.isOpenDeal);
  const totalOpenDeals = openDeals.length;
  const averageDealAge = (
    openDeals.reduce((sum, deal) => sum + (deal.daysOpen || 0), 0) / (totalOpenDeals || 1)
  ).toFixed(1);
  const averageWinProbability = (
    (openDeals.reduce((sum, deal) => sum + (deal.hs_deal_stage_probability || 0), 0) /
      (totalOpenDeals || 1)) *
    100
  ).toFixed(1);
  const dealsClosingSoon = openDeals.filter((deal) => {
    const closeDate = new Date(deal.closedate);
    const today = new Date();
    const daysUntilClose = (closeDate - today) / (1000 * 3600 * 24);
    return daysUntilClose > 0 && daysUntilClose <= 30;
  }).length;

  const dashboardData = {
    totalOpenDeals,
    averageDealAge,
    averageWinProbability,
    dealsClosingSoon,
  };

  return (
    <Box sx={{ p: 3, backgroundColor: theme.palette.background.default }}>
      <Typography variant="h4" gutterBottom sx={{ color: theme.palette.text.primary }}>
        CRM Healthcheck Dashboard
      </Typography>
      
      <Tabs 
        value={activeTab} 
        onChange={(event, newValue) => setActiveTab(newValue)}
        sx={{ mb: 3 }}
      >
        <Tab label="Rep Details" />
        <Tab label="Overview" />
      </Tabs>

      {activeTab === 0 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <OwnerPipelineTable />
          </Grid>
        </Grid>
      )}

      {activeTab === 1 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SummaryCards data={dashboardData} />
          </Grid>
          <Grid item xs={12}>
            <ProblemAreas />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default HealthcheckDashboard;
