import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from "@mui/material";
import Topbar from "../../components/global/Topbar";
import SidebarComponent from "../../components/global/Sidebar";

const AdminLayout = () => {
  return (
    <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
      <SidebarComponent />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <Topbar />
        <Box sx={{ flexGrow: 1, p: 3, overflow: 'auto', minHeight: 0 }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default AdminLayout;