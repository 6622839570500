import React, { useMemo } from 'react';
import { Box, LinearProgress, Typography, useTheme } from '@mui/material';
import { useOverview } from '../../../contexts/OverviewContext';
import { mint } from '../../../theme';

const OverallSyncProgress = () => {
  const { syncProgress, loading, error } = useOverview();
  const theme = useTheme();

  const stepMapping = {
    'fetchPipelineStages': 'Fetching pipeline stages',
    'fetchDeals': 'Searching for two years of deals',
    'fetchOwners': 'Looking for sales reps',
    'fetchAssociations': 'Grabbing relevant associations',
    'fetchContacts': 'Finding associated contacts and leads',
    'fetchCompanies': 'Analyzing associated companies',
    'fetchEngagements': 'Fetching sales engagement metrics',
    'calculateCustomMetrics': 'Running advanced analysis and AI calcs',
    'structureDashboardData': 'Structuring data for presentation'
  };

  const { progress, isComplete, totalSteps, completedSteps } = useMemo(() => {
    if (!syncProgress) return { progress: 0, isComplete: false, totalSteps: 0, completedSteps: 0 };

    const totalSteps = Object.keys(stepMapping).length;
    const completedSteps = Object.keys(stepMapping).filter(step => syncProgress[step]?.status === 'completed').length;
    const progress = totalSteps > 0 ? Math.round((completedSteps / totalSteps) * 100) : 0;
    const isComplete = progress === 100;

    return { progress, isComplete, totalSteps, completedSteps };
  }, [syncProgress, stepMapping]);

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <Typography color="error">Error loading sync progress: {error}</Typography>;
  }

  const progressColor = theme.palette.mode === 'dark' ? theme.palette.secondary.main : mint;

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <LinearProgress 
        key={progress}
        variant="determinate" 
        value={progress} 
        sx={{ 
          height: 5, 
          borderRadius: 5,
          backgroundColor: theme.palette.background.paper,
          '& .MuiLinearProgress-bar': {
            backgroundColor: progressColor,
            transition: 'transform 0.4s linear',
          }
        }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
        <Typography variant="body2" color="text.secondary">
          {isComplete ? 'Sync Complete!' : `${completedSteps} of ${totalSteps} steps completed (${progress}%)`}
        </Typography>
        {isComplete && (
          <Typography variant="body2" color="success.main">
            All data synced successfully!
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default OverallSyncProgress;