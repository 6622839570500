import React, { useContext, useState } from "react";
import { Box, IconButton, useTheme, styled, keyframes, Fab, Menu, MenuItem, Typography, useMediaQuery } from "@mui/material";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link, useNavigate } from "react-router-dom";
import { useChartConfig } from '../hooks/useChartConfig';
import { useSpring, animated } from 'react-spring';
import NotificationDropdown from "../profile/NotificationDropdown";
import Popover from "@mui/material/Popover";
import RightSidebar from "./RightSidebar";
import { useAuth } from "../../contexts/AuthContext";

import LogoBlack from "../home/assets/tp_new_logo_black.svg";
import LogoWhite from "../home/assets/tp_horizontal_whitetext_logo.svg";
import { ReactComponent as LogoNoText } from "../home/assets/LogoNoText.svg";
import HomeIcon from "@mui/icons-material/Home";
import ArticleIcon from '@mui/icons-material/Article';
import LightModePartner from "../home/assets/LightModePartner.png";
import DarkModePartner from "../home/assets/DarkModePartner.png";

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const AnimatedLogo = styled('img')(({ theme }) => ({
  height: '40px',
  marginLeft: '1rem',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'scale(1.1)',
    filter: 'brightness(1.2)',
    cursor: 'pointer',
  },
}));

const LogoWrapper = ({ children, isMobile }) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
    setTimeout(() => setIsClicked(false), 300);
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        animation: isClicked ? `${pulse} 0.3s ease-in-out` : 'none',
        display: 'flex',
        justifyContent: isMobile ? 'center' : 'flex-start',
        alignItems: 'center',
        width: isMobile ? '100%' : 'auto',
      }}
    >
      {children}
    </Box>
  );
};

const FloatingMenuButton = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: '20px',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1000,
}));

const MenuItemButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const FloatingMenu = ({ colorMode, navigate, handleLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  const menuAnimation = useSpring({
    transform: isOpen ? 'scale(1)' : 'scale(0)',
    opacity: isOpen ? 1 : 0,
  });

  const menuItems = [
    { icon: <PersonOutlinedIcon />, action: () => navigate('/profile/settings'), angle: 45 },
    { icon: theme.palette.mode === "dark" ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />, action: colorMode.toggleColorMode, angle: 0 },
    { icon: <DashboardIcon />, action: () => navigate('/sales'), angle: 0 },
    { icon: <NotificationsOutlinedIcon />, action: () => navigate('/profile/notifications'), angle: 0 },
    { icon: <HomeIcon />, action: () => navigate('/'), angle: -135 },
  ];

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleItemClick = (action) => {
    action();
    // Menu stays open after item click
  };

  return (
    <>
      <FloatingMenuButton color="primary" onClick={toggleMenu}>
        <MenuIcon />
      </FloatingMenuButton>
      <animated.div style={{
        ...menuAnimation,
        position: 'fixed',
        bottom: '80px',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999,
      }}>
        {menuItems.map((item, index) => (
          <MenuItemButton
            key={index}
            onClick={() => handleItemClick(item.action)}
            sx={{
              position: 'absolute',
              transform: `rotate(${-index * 45}deg) translate(80px) rotate(${index * 45}deg)`,
            }}
          >
            {item.icon}
          </MenuItemButton>
        ))}
      </animated.div>
    </>
  );
};

const PartnerLogo = styled('img')(({ theme }) => ({
  height: '20px',
  marginLeft: '1rem',
  [theme.breakpoints.down('sm')]: {
    height: '18px',
  },
}));

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const { currentUser, logOut } = useAuth();

  const handleNotificationClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    setSidebarOpen(true);
    handleNotificationClose();
  };

  const handleProfileClick = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setProfileAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await logOut();
      navigate('/');
    } catch (error) {
      console.error("Failed to log out", error);
    }
    handleProfileClose();
  };

  const open = Boolean(anchorEl);
  const profileOpen = Boolean(profileAnchorEl);

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
      {/* LOGO */}
      <Box display="flex" alignItems="center">
        <LogoWrapper isMobile={isMobile}>
          <Link to="/" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {isMobile ? (
              <LogoNoText
                width="40"
                height="40"
                style={{
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.1)',
                    filter: 'brightness(1.2)',
                    cursor: 'pointer',
                  },
                }}
              />
            ) : (
              <AnimatedLogo
                src={theme.palette.mode === "dark" ? LogoWhite : LogoBlack}
                alt="Logo"
              />
            )}
          </Link>
        </LogoWrapper>
        <PartnerLogo 
          src={theme.palette.mode === "dark" ? DarkModePartner : LightModePartner} 
          alt="HubSpot App Partner"
        />
      </Box>

      {/* SEARCH BAR */}
      {!isMobile && (
        <Box
          display="flex"
          backgroundColor={theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.06)'}
          borderRadius="3px"
          width="30%"
        >
          <InputBase sx={{ ml: 2, flex: 1, color: theme.palette.text.primary }} placeholder="Search" />
          <IconButton type="button" sx={{ p: 1, color: theme.palette.text.secondary }}>
            <SearchIcon />
          </IconButton>
        </Box>
      )}

      {/* ICONS */}
      {isMobile ? (
        <FloatingMenu 
          colorMode={colorMode} 
          navigate={navigate} 
          handleLogout={handleLogout}
        />
      ) : (
        <Box display="flex">
          <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>
          <IconButton onClick={handleNotificationClick}>
            <NotificationsOutlinedIcon />
          </IconButton>
          <IconButton onClick={handleProfileClick}>
            <PersonOutlinedIcon />
          </IconButton>
        </Box>
      )}

      {/* Notification Popover */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleNotificationClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <NotificationDropdown onCategoryClick={handleCategoryClick} />
      </Popover>

      {/* Profile Menu */}
      <Menu
        anchorEl={profileAnchorEl}
        open={profileOpen}
        onClose={handleProfileClose}
        PaperProps={{
          sx: {
            backgroundColor: theme => theme.palette.background.paper,
            borderRadius: '8px',
            border: theme => `1px solid ${theme.palette.divider}`,
            boxShadow: theme => theme.shadows[3],
          }
        }}
        MenuListProps={{
          sx: {
            padding: '8px 0',
          }
        }}
      >
        <MenuItem onClick={() => { navigate('/sales'); handleProfileClose(); }} sx={{ padding: '12px 24px' }}>
          <DashboardIcon sx={{ marginRight: '12px', color: 'text.secondary' }} />
          <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>Dashboard</Typography>
        </MenuItem>
        <MenuItem onClick={() => { navigate('/profile/settings'); handleProfileClose(); }} sx={{ padding: '12px 24px' }}>
          <SettingsOutlinedIcon sx={{ marginRight: '12px', color: 'text.secondary' }} />
          <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>Settings</Typography>
        </MenuItem>
        <MenuItem onClick={handleLogout} sx={{ padding: '12px 24px' }}>
          <LogoutIcon sx={{ marginRight: '12px', color: 'text.secondary' }} />
          <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>Logout</Typography>
        </MenuItem>
      </Menu>

      <RightSidebar
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
        activeCategory={activeCategory}
      />
    </Box>
  );
};

export default Topbar;