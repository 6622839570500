// src/utils/pdfExport.ts v1.0.5

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import mainLogo from '../components/home/assets/mainlogoPNG.png';

export const exportToPDF = async (elementId, filename) => {
  const element = document.getElementById(elementId);
  if (!element) {
    console.error('Element not found');
    return;
  }

  try {
    // Add temporary classes for PDF-specific styling
    element.classList.add('pdf-export-mode', 'force-light-mode');

    // Add border radius to the element for PDF export
    element.style.borderRadius = '12px';
    element.style.overflow = 'hidden';

    const scale = Math.min(2, 2000 / element.offsetWidth);
    
    const canvas = await html2canvas(element, {
      scale: scale,
      useCORS: true,
      logging: false,
      backgroundColor: '#ffffff',
      windowWidth: element.scrollWidth,
      windowHeight: element.scrollHeight,
      onclone: (clonedDoc) => {
        const clonedElement = clonedDoc.getElementById(elementId);
        if (clonedElement) {
          clonedElement.style.padding = '40px';
          clonedElement.style.background = '#ffffff';
          clonedElement.style.borderRadius = '12px';
          clonedElement.style.overflow = 'hidden';
          
          // Add a container div with background to make border radius visible
          const wrapper = clonedDoc.createElement('div');
          wrapper.style.backgroundColor = '#ffffff';
          wrapper.style.padding = '1px'; // Minimal padding to ensure background shows
          clonedElement.parentNode?.insertBefore(wrapper, clonedElement);
          wrapper.appendChild(clonedElement);
        }
      }
    });

    // Remove temporary classes and styles
    element.classList.remove('pdf-export-mode', 'force-light-mode');
    element.style.borderRadius = '';
    element.style.overflow = '';

    const imgData = canvas.toDataURL('image/jpeg', 1.0);
    const pdf = new jsPDF({
      orientation: canvas.width > canvas.height ? 'landscape' : 'portrait',
      unit: 'mm',
      format: 'a4'
    });

    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    
    // Calculate dimensions for the main content
    const imgWidth = pageWidth - 20;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    
    const finalImgWidth = imgHeight > (pageHeight - 40) 
      ? (pageHeight - 40) * (imgWidth / imgHeight)
      : imgWidth;
    const finalImgHeight = imgHeight > (pageHeight - 40)
      ? pageHeight - 40
      : imgHeight;

    // Center the image
    const xOffset = (pageWidth - finalImgWidth) / 2;
    const yOffset = 25; // Increased top margin for branding

    // Add logo and branding with proper aspect ratio
    const logoMaxHeight = 8; // Maximum height in mm
    const logoMaxWidth = 24; // Maximum width in mm
    
    // Create and load the image properly
    const loadImage = (src) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = src;
        });
    };

    // Load and process the logo
    const logoImg = await loadImage(mainLogo);
    const logoAspectRatio = logoImg.width / logoImg.height;
    
    // Calculate dimensions maintaining aspect ratio
    let finalLogoWidth = logoMaxWidth;
    let finalLogoHeight = logoMaxWidth / logoAspectRatio;
    
    if (finalLogoHeight > logoMaxHeight) {
        finalLogoHeight = logoMaxHeight;
        finalLogoWidth = logoMaxHeight * logoAspectRatio;
    }
    
    pdf.addImage(mainLogo, 'PNG', xOffset, 10, finalLogoWidth, finalLogoHeight);
    
    // Adjust the "Generated by" text position based on the actual logo width
    pdf.setFontSize(11);
    pdf.setTextColor(54, 181, 173); // TruePipe teal color
    pdf.setFont('helvetica', 'normal');
    pdf.text('Generated by ', xOffset + finalLogoWidth + 4, 16);
    
    pdf.setFont('helvetica', 'bold');
    const url = 'https://truepipe.ai';
    pdf.textWithLink('truepipe.ai', xOffset + finalLogoWidth + 28, 16, {
        url: url
    });
    
    // Add the main content
    pdf.addImage(imgData, 'JPEG', xOffset, yOffset, finalImgWidth, finalImgHeight);
    
    // Add footer
    const footer = `${filename} • ${new Date().toLocaleDateString()}`;
    pdf.setFontSize(8);
    pdf.setTextColor(128, 128, 128);
    pdf.setFont('helvetica', 'normal');
    pdf.text(footer, xOffset, pageHeight - 10);
    
    // Add clickable URL in footer
    pdf.setTextColor(54, 181, 173);
    pdf.setFont('helvetica', 'bold');
    pdf.textWithLink('truepipe.ai', pageWidth - xOffset - 20, pageHeight - 10, {
        url: url
    });

    pdf.save(`${filename}.pdf`);
  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};