import React, { useMemo } from 'react';
import { ResponsiveScatterPlot } from '@nivo/scatterplot';
import { usePipeline } from '../../../contexts/PipelineContext';
import { Box, Typography } from '@mui/material';

const DealSizeScatterPlot = () => {
  const { 
    dealSizeScatterPlot, 
    formatCurrency, 
    getCommonChartProperties, 
    getScatterPlotStyles, 
    getTooltipStyles,
    getColor,
    isMobile,
    getDealSizeScatterPlotTooltip,
    getCustomTooltip,
  } = usePipeline();

  const commonProperties = getCommonChartProperties('scatter');
  const scatterPlotStyles = getScatterPlotStyles();
  const tooltipStyles = getTooltipStyles();

  // Normalize node size based on deal size
  const normalizeNodeSize = useMemo(() => {
    if (!dealSizeScatterPlot || dealSizeScatterPlot.length === 0) {
      return () => scatterPlotStyles.nodeSize.min;
    }
    const allSizes = dealSizeScatterPlot[0].data.map(d => d.x); // Assuming x is the deal size
    const minSize = Math.min(...allSizes);
    const maxSize = Math.max(...allSizes);
    const sizeRange = maxSize - minSize;

    return (size) => {
      const normalizedSize = (size - minSize) / sizeRange;
      return scatterPlotStyles.nodeSize.min + 
        normalizedSize * (scatterPlotStyles.nodeSize.max - scatterPlotStyles.nodeSize.min);
    };
  }, [dealSizeScatterPlot, scatterPlotStyles.nodeSize]);

  // Truncate string function
  const truncateString = (str, num) => {
    if (str.length <= num) return str;
    return str.slice(0, num) + '...';
  };

  if (!dealSizeScatterPlot || dealSizeScatterPlot.length === 0) {
    return <Typography>No data available for Deal Size Scatter Plot</Typography>;
  }

  const chartProps = {
    ...commonProperties,
    ...scatterPlotStyles,
    data: dealSizeScatterPlot,
    xFormat: ">-.2f",
    yFormat: ">-.2f",
    colors: getColor,
    tooltip: ({ node }) => {
      const tooltipData = getDealSizeScatterPlotTooltip(node);
      return getCustomTooltip(tooltipData);
    },
    nodeSize: ({ data }) => normalizeNodeSize(data.x), // Assuming x is the deal size
  };

  // Calculate min and max values for x and y
  const xValues = dealSizeScatterPlot[0].data.map(d => d.x);
  const yValues = dealSizeScatterPlot[0].data.map(d => d.y);
  const xMin = Math.min(...xValues);
  const xMax = Math.max(...xValues);
  const yMin = Math.min(...yValues);
  const yMax = Math.max(...yValues);

  // Apply 10% buffer for mobile
  const xMinBuffer = isMobile ? xMin - (xMax - xMin) * 0.1 : xMin;
  const yMinBuffer = isMobile ? yMin - (yMax - yMin) * 0.1 : yMin;
  const yMaxBuffer = isMobile ? yMax + (yMax - yMin) * 0.15 : yMax;

  chartProps.xScale = { type: 'linear', min: xMinBuffer, max: 'auto' };
  chartProps.yScale = { type: 'linear', min: yMinBuffer, max: yMaxBuffer };

  return (
    <Box sx={{ height: 400 }}>
      <ResponsiveScatterPlot {...chartProps} />
    </Box>
  );
};

export default DealSizeScatterPlot;