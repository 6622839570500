   // src/components/common/ErrorBoundary.jsx
   import React from 'react';
   import { 
     Box, 
     Typography, 
     Button, 
     Paper 
   } from '@mui/material';
   import { 
     Refresh as RefreshIcon,
     Error as ErrorIcon 
   } from '@mui/icons-material';
   import logger from '../../utils/frontendLogger';

   class ErrorBoundary extends React.Component {
     constructor(props) {
       super(props);
       this.state = { 
         hasError: false,
         error: null,
         errorInfo: null
       };
     }

     static getDerivedStateFromError(error) {
       return { 
         hasError: true,
         error 
       };
     }

     componentDidCatch(error, errorInfo) {
       this.setState({
         errorInfo
       });
       
       logger.error('ErrorBoundary caught an error', { 
         error, 
         errorInfo,
         component: this.props.componentName || 'Unknown'
       });
     }

     handleReset = () => {
       this.setState({ 
         hasError: false,
         error: null,
         errorInfo: null 
       });
       
       // If a reset handler was provided, call it
       if (this.props.onReset) {
         this.props.onReset();
       }
       
       // Force a refresh of the component
       window.location.reload();
     }

     render() {
       if (this.state.hasError) {
         return (
           <Box
             sx={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               minHeight: '400px',
               p: 3
             }}
           >
             <Paper
               elevation={3}
               sx={{
                 p: 4,
                 maxWidth: 500,
                 textAlign: 'center'
               }}
             >
               <ErrorIcon 
                 color="error" 
                 sx={{ fontSize: 64, mb: 2 }} 
               />
               
               <Typography variant="h5" gutterBottom>
                 {this.props.fallbackTitle || 'Something went wrong'}
               </Typography>
               
               <Typography 
                 variant="body1" 
                 color="text.secondary"
                 sx={{ mb: 3 }}
               >
                 {this.props.fallbackMessage || 
                   'We encountered an error while loading this component. Please try again.'}
               </Typography>

               {process.env.NODE_ENV === 'development' && this.state.error && (
                 <Typography 
                   variant="body2" 
                   color="error"
                   sx={{ 
                     mb: 3,
                     p: 2,
                     bgcolor: 'error.light',
                     borderRadius: 1,
                     fontFamily: 'monospace'
                   }}
                 >
                   {this.state.error.toString()}
                 </Typography>
               )}

               <Button
                 variant="contained"
                 startIcon={<RefreshIcon />}
                 onClick={this.handleReset}
                 sx={{ mt: 2 }}
               >
                 Try Again
               </Button>
             </Paper>
           </Box>
         );
       }

       return this.props.children; 
     }
   }

   export default ErrorBoundary;