import { useState, useEffect } from "react";
import { Menu, Sidebar, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, Drawer, Fab } from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import LogoutIcon from "@mui/icons-material/Logout"; // Add this import
import { useAuth } from '../../contexts/AuthContext';

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  return (
    <MenuItem
      active={selected === title}
      style={{ color: theme.palette.text.primary }}
      onClick={() => setSelected(title)}
      icon={icon}
      component={<Link to={to} />}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

const ProfileSidebar = ({ isMobile, isOpen, onToggle }) => {
  const theme = useTheme();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const { logOut } = useAuth(); // Use logOut instead of logout to match AppAppBar.jsx
  const location = useLocation();
  const navigate = useNavigate(); // Add this hook

  const getSelectedFromPath = (path) => {
    const routeToTitle = {
      '/sales': 'Dashboard',
      '/profile/settings': 'Profile Settings',
      '/profile/security': 'Security',
      '/profile/notifications': 'Notifications'
    };
    return routeToTitle[path] || 'Dashboard';
  };

  useEffect(() => {
    const currentSelected = getSelectedFromPath(location.pathname);
    setSelected(currentSelected);
  }, [location]);

  const toggleSidebar = () => {
    if (isMobile) {
      onToggle();
    } else {
      setIsCollapsed(!isCollapsed);
    }
  };

  const handleSignOut = async () => {
    try {
      await logOut();
      navigate('/'); // Navigate to home page after signing out
    } catch (error) {
      console.error("Failed to sign out", error);
    }
  };

  const sidebarContent = (
    <Box
      sx={{
        height: '100%',
        '& .ps-sidebar-root': {
          height: '100%',
          border: 'none',
        },
        '& .ps-sidebar-container': {
          background: `${theme.palette.background.default} !important`,
        },
        "& .ps-menu-root": {
          padding: '5px 0',
        },
        "& .ps-menu-button": {
          padding: "5px 35px 5px 20px !important",
          color: theme.palette.text.primary,
        },
        "& .ps-menu-button:hover": {
          backgroundColor: `${theme.palette.action.hover} !important`,
        },
        "& .ps-menu-button.ps-active": {
          color: `${theme.palette.secondary.main} !important`,
          backgroundColor: `${theme.palette.action.selected} !important`,
        },
      }}
    >
      <Sidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          <MenuItem
            onClick={toggleSidebar}
            icon={<MenuOutlinedIcon />}
            style={{ margin: "10px 0", color: theme.palette.text.primary }}
          >
            {!isCollapsed && (
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h3" color={theme.palette.text.primary}>
                  PROFILE
                </Typography>
              </Box>
            )}
          </MenuItem>

          <Box paddingTop={2}>
            <Item
              title="Dashboard"
              to="/sales"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Profile Settings"
              to="/profile/settings"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Security"
              to="/profile/security"
              icon={<LockOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Notifications"
              to="/profile/notifications"
              icon={<NotificationsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {isMobile && (
              <MenuItem
                icon={<LogoutIcon />}
                onClick={handleSignOut}
                style={{ color: theme.palette.text.primary }}
              >
                <Typography>Sign Out</Typography>
              </MenuItem>
            )}
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );

  if (isMobile) {
    return (
      <>
        <IconButton
          aria-label="menu"
          sx={{
            position: 'fixed',
            top: '16px',
            left: '16px',
            zIndex: 1000,
            color: theme.palette.text.primary,
          }}
          onClick={onToggle}
        >
          <MenuOutlinedIcon />
        </IconButton>
        <Drawer
          anchor="left"
          open={isOpen}
          onClose={onToggle}
          sx={{
            '& .MuiDrawer-paper': {
              width: 240,
              boxSizing: 'border-box',
              backgroundColor: theme.palette.background.default,
            },
          }}
        >
          {sidebarContent}
        </Drawer>
      </>
    );
  }

  return sidebarContent;
};

export default ProfileSidebar;