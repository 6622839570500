import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { getFirestore, doc, onSnapshot, collection, query } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { processDealsForAIInsights, processDealsForAIWatchlist, generateAIInsights } from './utils/overviewProcessing';
import { calculateTruepipeProbability, categorizeDealsByTruepipeProbability } from './utils/truepipeProbability';
import { debounce } from 'lodash';

const OverviewContext = createContext();

export const useOverview = () => useContext(OverviewContext);

export const OverviewProvider = ({ children }) => {
  const [syncProgress, setSyncProgress] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deals, setDeals] = useState([]);
  const [aiInsights, setAiInsights] = useState([]);
  const [aiWatchlist, setAiWatchlist] = useState({ bestDeals: [], criticalDeals: [] });
  const [bestDeals, setBestDeals] = useState([]);
  const [criticalDeals, setCriticalDeals] = useState([]);

  const debouncedSetSyncProgress = useMemo(
    () => debounce((newProgress) => setSyncProgress(newProgress), 500),
    []
  );

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();

    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        const syncDocRef = doc(db, 'users', user.uid, 'syncProgress', 'currentSync');
        
        const unsubscribeSnapshot = onSnapshot(syncDocRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            debouncedSetSyncProgress(docSnapshot.data());
            setLoading(false);
          } else {
            debouncedSetSyncProgress({});
            setLoading(false);
          }
        }, (err) => {
          console.error('Error in Firestore snapshot listener:', err);
          setError(err.message);
          setLoading(false);
        });

        const dealsRef = collection(db, 'users', user.uid, 'deals');
        const dealsUnsubscribe = onSnapshot(query(dealsRef), (snapshot) => {
          const dealsData = snapshot.docs.map(doc => {
            const data = doc.data();
            return {
              id: doc.id,
              ...data,
              closedate: data.closedate ? new Date(data.closedate).getTime() : null,
              createdate: data.createdate ? new Date(data.createdate).getTime() : null,
              amount: parseFloat(data.amount) || 0,
              dealname: data.dealname || 'Unnamed Deal',
              hs_deal_stage_probability: parseFloat(data.hs_deal_stage_probability) || 0,
            };
          });
          
          // Calculate average deal size and expected time in stage
          const averageDealSize = dealsData.reduce((sum, deal) => sum + deal.amount, 0) / dealsData.length;
          const expectedTimeInStage = 30; // This is a placeholder. You might want to calculate this based on your data.

          // Apply truepipe probability to each deal
          const dealsWithTruepipe = dealsData.map(deal => ({
            ...deal,
            truepipeProbability: calculateTruepipeProbability(deal, averageDealSize, expectedTimeInStage)
          }));

          // Categorize deals
          const { bestDeals, criticalDeals } = categorizeDealsByTruepipeProbability(dealsWithTruepipe, averageDealSize, expectedTimeInStage);

          setDeals(dealsWithTruepipe);
          setBestDeals(bestDeals);
          setCriticalDeals(criticalDeals);

          // Process deals for AI watchlist
          const watchlist = processDealsForAIWatchlist(dealsWithTruepipe);
          setAiWatchlist(watchlist);

          // Generate AI insights
          const insights = generateAIInsights(dealsWithTruepipe);
          setAiInsights(insights);
        });

        return () => {
          unsubscribeSnapshot();
          dealsUnsubscribe();
        };
      } else {
        setSyncProgress({});
        setLoading(false);
        setDeals([]);
        setAiInsights([]);
        setAiWatchlist({ bestDeals: [], criticalDeals: [] });
        setBestDeals([]);
        setCriticalDeals([]);
      }
    });

    return () => unsubscribe();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const processedInsights = processDealsForAIInsights(deals);
    setAiInsights(processedInsights);
  }, [deals]);

  const value = useMemo(() => ({
    syncProgress,
    loading,
    error,
    deals,
    aiInsights,
    aiWatchlist,
    bestDeals,
    criticalDeals,
  }), [syncProgress, loading, error, deals, aiInsights, aiWatchlist, bestDeals, criticalDeals]);

  return (
    <OverviewContext.Provider value={value}>
      {children}
    </OverviewContext.Provider>
  );
};