// src/contexts/utils/publicRoute.jsx
import React from 'react';
import { useAuth } from '../AuthContext';

const PublicRoute = ({ children }) => {
  const { loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return children;
};

export default PublicRoute;