// src/components/sales/HealthcheckDashboard/PastDueDealsChart.jsx
import React from 'react';
import { useRawData } from '../../../contexts/RawDataContext';
import { Paper, Typography, useTheme, Box } from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import { bittersweet, turquoise, lightRed, mint } from '../../../theme'; // Import these colors from your theme file

const PastDueDealsChart = () => {
  const { deals } = useRawData();
  const theme = useTheme();

  const pastDueDeals = deals.filter((deal) => deal.pastDue === 1);
  const onTimeDeals = deals.length - pastDueDeals.length;

  const data = [
    { id: 'Past Due', label: 'Past Due', value: pastDueDeals.length },
    { id: 'On Time', label: 'On Time', value: onTimeDeals },
  ];

  const colors = {
    'Past Due': theme.palette.mode === 'dark' ? bittersweet : lightRed,
    'On Time': theme.palette.mode === 'dark' ? turquoise : mint,
  };

  const CustomTooltip = ({ datum }) => (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        padding: theme.spacing(1, 1.5),
        border: `1px solid ${colors[datum.id]}`,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[3],
      }}
    >
      <Typography variant="subtitle2" sx={{ color: colors[datum.id], fontWeight: 'bold' }}>
        {datum.label}
      </Typography>
      <Typography variant="body2">Deals: {datum.value}</Typography>
      <Typography variant="body2">
        Percentage: {((datum.value / deals.length) * 100).toFixed(1)}%
      </Typography>
    </Box>
  );

  return (
    <Paper sx={{ p: 3, height: '100%', backgroundColor: theme.palette.background.paper }}>
      <Typography variant="h6" gutterBottom color="text.primary">
        Past Due Deals
      </Typography>
      <div style={{ height: 300 }}>
        <ResponsivePie
          data={data}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.6}
          padAngle={1}
          cornerRadius={5}
          colors={(datum) => colors[datum.id]}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
          enableArcLinkLabels={false}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={theme.palette.getContrastText(theme.palette.background.paper)}
          arcLabelsRadiusOffset={0.55}
          arcLabel={(d) => `${d.value} (${((d.value / deals.length) * 100).toFixed(1)}%)`}
          tooltip={({ datum }) => <CustomTooltip datum={datum} />}
          legends={[
            {
              anchor: 'bottom',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: 56,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: theme.palette.text.primary,
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: 'circle',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemTextColor: theme.palette.text.primary,
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </Paper>
  );
};

export default PastDueDealsChart;
