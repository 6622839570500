import React, { useContext } from 'react';
import { Box, Container, useTheme, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import AppAppBar from './AppAppBar';
import Footer from '../global/Footer';
import { ColorModeContext } from '../../theme';

const ContactLayout = ({ children }) => {
  const colorMode = useContext(ColorModeContext);
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenSignupModal = () => {
    // Implement this function to open the signup modal
    console.log('Open signup modal');
  };

  const isHomePage = location.pathname === '/';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppAppBar 
        mode={colorMode.mode}
        toggleColorMode={colorMode.toggleColorMode}
        onOpenSignupModal={handleOpenSignupModal}
        isHomePage={isHomePage}
      />
      <Container 
        component="main" 
        sx={{ 
          flexGrow: 1, 
          py: 4, 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center',
          mt: isMobile ? 2.5 : 0 // Add top margin for mobile
        }}
      >
        {children}
      </Container>
      <Footer />
    </Box>
  );
};

export default ContactLayout;