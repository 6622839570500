import React, { useState } from 'react';
import { Box, Typography, Select, MenuItem, Grid, FormControl, InputLabel } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, LabelList } from 'recharts';
import { useTheme } from '@mui/material/styles';
import { useRevenue } from '../../../../contexts/RevenueContext';

const metrics = [
  { key: 'wonRevenue', label: 'Won Revenue', format: 'currency' },
  { key: 'totalDeals', label: 'Total Deals', format: 'number' },
  { key: 'closedWonDeals', label: 'Won Deals', format: 'number' },
  { key: 'winRate', label: 'Win Rate', format: 'percentage' },
  { key: 'averageSalesCycle', label: 'Avg Cycle (Days)', format: 'number' },
  { key: 'averageDealSize', label: 'Avg Deal Size', format: 'currency' },
  { key: 'engagementScore', label: 'Engagement Score', format: 'percentage' },
];

const RepPerformanceVisualizations = ({ data }) => {
  const theme = useTheme();
  const { 
    pipelineStages, 
    formatCurrency, 
    formatNumber, 
    formatPercentage,
    getCommonChartProperties,
    getRepPerformanceTooltip, // Updated to use the new tooltip function
  } = useRevenue();
  const [selectedMetric, setSelectedMetric] = useState('wonRevenue');
  const [selectedRep, setSelectedRep] = useState(null);
  const [localPipelineFilter, setLocalPipelineFilter] = useState('All');

  const handleMetricChange = (event) => {
    setSelectedMetric(event.target.value);
    setSelectedRep(null);
  };

  const handlePipelineChange = (event) => {
    setLocalPipelineFilter(event.target.value);
    setSelectedRep(null);
  };

  const handleBarClick = (entry) => {
    setSelectedRep(entry.ownerId);
  };

  // Apply local pipeline filter
  const filteredData = localPipelineFilter === 'All' 
    ? data 
    : data.filter(rep => rep.pipelineId === localPipelineFilter);

  const sortedData = [...filteredData].sort((a, b) => b[selectedMetric] - a[selectedMetric]);

  const formatValue = (value, format) => {
    if (value === undefined || value === null) return 'N/A'; // Handle undefined or null values
    switch (format) {
      case 'currency':
        return formatCurrency(value);
      case 'percentage':
        return formatPercentage(value / 100);
      default:
        return formatNumber(value);
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const rep = payload[0].payload;
      const tooltipData = getRepPerformanceTooltip(rep); // Use the new tooltip function
      return tooltipData;
    }
    return null;
  };

  const commonChartProperties = getCommonChartProperties('bar');

  return (
    <Box>
      {/* Local Filters */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6" sx={{ color: 'text.primary' }}>Rep Performance Visualization</Typography>
        <Box display="flex" gap={2}>
          {/* Metric Selector */}
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel id="metric-select-label">Metric</InputLabel>
            <Select
              labelId="metric-select-label"
              value={selectedMetric}
              label="Metric"
              onChange={handleMetricChange}
            >
              {metrics.map((metric) => (
                <MenuItem key={metric.key} value={metric.key}>{metric.label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Local Pipeline Filter */}
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel id="local-pipeline-filter-label">Pipeline</InputLabel>
            <Select
              labelId="local-pipeline-filter-label"
              value={localPipelineFilter}
              label="Pipeline"
              onChange={handlePipelineChange}
            >
              <MenuItem value="All">All</MenuItem>
              {pipelineStages.map((pipeline) => (
                <MenuItem key={pipeline.id} value={pipeline.id}>{pipeline.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart 
          data={sortedData} 
          layout="vertical"
          {...commonChartProperties}
        >
          <XAxis type="number" stroke={theme.palette.text.secondary} />
          <YAxis dataKey="ownerName" type="category" width={150} stroke={theme.palette.text.secondary} />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey={selectedMetric}
            fill={theme.palette.secondary.main}
            onClick={handleBarClick}
            cursor="pointer"
            radius={[0, 8, 8, 0]}
          >
            <LabelList 
              dataKey={selectedMetric} 
              position="right" 
              formatter={(value) => formatValue(value, metrics.find(m => m.key === selectedMetric).format)} 
              style={{ fill: theme.palette.text.primary, fontSize: 12 }}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      {selectedRep && (
        <Grid container spacing={2} mt={2}>
          {metrics.filter(m => m.key !== selectedMetric).map((metric) => (
            <Grid item xs={12} sm={6} md={4} key={metric.key}>
              <Box
                bgcolor="background.paper"
                p={2}
                borderRadius={1}
                boxShadow={1}
                sx={{
                  transition: 'background-color 0.3s',
                }}
              >
                <Typography variant="body2" color="text.secondary">{metric.label}</Typography>
                <Typography variant="h6" sx={{ color: 'text.primary' }}>
                  {formatValue(data.find(r => r.ownerId === selectedRep)[metric.key], metric.format)}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default RepPerformanceVisualizations;