// src/hooks/useDashboardStyles.js

import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

export const useDashboardStyles = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return {
    layout: {
      pageWrapper: {
        backgroundColor: theme.palette.background.default,
        minHeight: '100vh',
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(2),
        },
      },
      sectionWrapper: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(3),
        borderRadius: theme.shape.borderRadius,
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(2),
        },
      },
      chartContainer: {
        height: isMobile ? '300px' : '400px',
        width: '100%',
      },
    },
    typography: {
      pageTitle: {
        ...theme.typography.h2,
        fontWeight: 'bold',
        marginBottom: theme.spacing(2),
        color: theme.palette.text.primary,
      },
      sectionTitle: {
        ...theme.typography.h6,
        marginBottom: theme.spacing(2),
        color: theme.palette.text.secondary,
      },
    },
    components: {
      tabs: {
        root: {
          marginBottom: theme.spacing(2),
        },
        tab: {
          color: theme.palette.text.secondary,
          '&:hover': {
            color: theme.palette.secondary.main,
          },
          '&.Mui-selected': {
            color: theme.palette.secondary.main,
          },
        },
        indicator: {
          backgroundColor: theme.palette.secondary.main,
        },
      },
      alert: {
        marginBottom: theme.spacing(2),
      },
    },
    utils: {
      isMobile,
      spacing: theme.spacing,
    },
  };
};