// src/components/charts/CustomTooltip.jsx v1.0.0

import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useChartConfig } from '../hooks/useChartConfig';
import { formatNumber, formatCurrency, formatPercentage } from '../utils/formatters';
import { getChartColor } from './chartColors';

const CustomTooltip = ({ 
  title, 
  items, 
  comparisonItem,
  formatType = 'number',
  suffix = '',
}) => {
  const theme = useTheme();
  const { getTooltipStyles } = useChartConfig();
  const tooltipStyles = getTooltipStyles();

  const formatValue = (value, itemFormatType) => {
    switch (itemFormatType || formatType) {
      case 'currency':
        return formatCurrency(value, { maximumFractionDigits: 0 });
      case 'percentage':
        return formatPercentage(value / 100, { maximumFractionDigits: 1 });
      default:
        return formatNumber(value, { maximumFractionDigits: 0 });
    }
  };

  return (
    <Box sx={{
      ...tooltipStyles.container,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[3],
      padding: 0,
      justifyContent: 'space-between',
      height: 'auto',
      minWidth: '170px',
      width: 'auto',
      overflow: 'hidden',
      '& .MuiTypography-root': {
        fontSize: '0.875rem',
      },
    }}>
      <Box sx={{
        ...tooltipStyles.header,
        backgroundColor: theme.palette.mode === 'light' 
          ? theme.palette.grey[200] 
          : theme.palette.grey[800],
        color: theme.palette.mode === 'light'
          ? theme.palette.text.primary
          : theme.palette.common.white,
        padding: theme.spacing(1, 2),  // Remove horizontal padding
        width: '100%',
      }}>
        <Typography variant="subtitle2">
          {title}
        </Typography>
      </Box>
      <Box sx={{
        ...tooltipStyles.content,
        padding: theme.spacing(1, 1.5),
      }}>
        {items.map((item, index) => (
          <Box key={item.label} sx={{
            ...tooltipStyles.row,
            justifyContent: 'space-between',  // Add this line
          }}>
            <Typography sx={{
              ...tooltipStyles.label,
              display: 'flex',
              alignItems: 'center',
              marginRight: theme.spacing(1),  // Add this line
            }}>
              <Box sx={{
                ...tooltipStyles.colorDot,
                backgroundColor: getChartColor(index, theme.palette.mode),
                marginRight: theme.spacing(1),
              }} />
              {item.label}:
            </Typography>
            <Typography>
              {formatValue(item.value, item.formatType)}{suffix}
            </Typography>
          </Box>
        ))}
        {comparisonItem && (
          <Box sx={{
            ...tooltipStyles.row,
            justifyContent: 'space-between',  // Add this line
          }}>
            <Typography sx={{
              ...tooltipStyles.label,
              display: 'flex',
              alignItems: 'center',
              marginRight: theme.spacing(3),  // Add this line
            }}>
              <Box sx={{
                ...tooltipStyles.colorDot,
                backgroundColor: getChartColor(items.length, theme.palette.mode),
                marginRight: theme.spacing(1),
              }} />
              {comparisonItem.label}:
            </Typography>
            <Typography sx={{color: comparisonItem.value >= 0 ? theme.palette.success.main : theme.palette.error.main 
            }}>
              {comparisonItem.value >= 0 ? '+' : '-'}{formatValue(Math.abs(comparisonItem.value))}
              {comparisonItem.percentage && ` (${formatPercentage(Math.abs(comparisonItem.percentage), { signDisplay: 'always', maximumFractionDigits: 0 })})`}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CustomTooltip;