import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  LinearProgress,
  Typography,
  Box,
  Tooltip
} from '@mui/material';
import { useRevenue } from '../../../../contexts/RevenueContext';
import { useTheme } from '@mui/material/styles';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { formatCurrency, formatPercentage } from '../../../utils/formatters';
import { useChartStyles } from '../../../hooks/useChartStyles';

const RepLeadersTable = () => {
  const theme = useTheme();
  const { repPerformanceData, overallWinRate, overallAverages, loading, error } = useRevenue();
  const [orderBy, setOrderBy] = useState('wonRevenue');
  const [order, setOrder] = useState('desc');
  const { getWinRateColor, getEngagementScoreColor } = useChartStyles();

  const getComparisonArrow = (value, average, higherIsBetter = true) => {
    const isAboveAverage = value > average;
    const color = isAboveAverage === higherIsBetter ? theme.palette.success.main : theme.palette.error.main;
    const Arrow = isAboveAverage ? ArrowUpwardIcon : ArrowDownwardIcon;
    return <Arrow sx={{ color, fontSize: '1rem', verticalAlign: 'middle', ml: 0.5 }} />;
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">Error loading data.</Typography>;
  }

  // Filter out any remaining entries with zero deals (this should be redundant, but it's a safeguard)
  const filteredRepPerformanceData = repPerformanceData.filter(rep => rep.totalDeals > 0);

  const sortedData = filteredRepPerformanceData.sort((a, b) => {
    if (order === 'desc') {
      return b[orderBy] - a[orderBy];
    } else {
      return a[orderBy] - b[orderBy];
    }
  });

  return (
    <TableContainer component={Paper} sx={{ mt: 4, overflowX: 'auto' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="5%" align="center"><strong>Rank</strong></TableCell>
            <TableCell width="10%">
              <TableSortLabel
                active={orderBy === 'ownerName'}
                direction={orderBy === 'ownerName' ? order : 'asc'}
                onClick={() => handleSort('ownerName')}
              >
                <strong>Rep Name</strong>
              </TableSortLabel>
            </TableCell>
            <TableCell width="15%" align="center">
              <TableSortLabel
                active={orderBy === 'wonRevenue'}
                direction={orderBy === 'wonRevenue' ? order : 'asc'}
                onClick={() => handleSort('wonRevenue')}
              >
                <strong>Won Revenue</strong>
              </TableSortLabel>
            </TableCell>
            <TableCell width="10%" align="center">
              <TableSortLabel
                active={orderBy === 'totalDeals'}
                direction={orderBy === 'totalDeals' ? order : 'asc'}
                onClick={() => handleSort('totalDeals')}
              >
                <strong>Total Deals</strong>
              </TableSortLabel>
            </TableCell>
            <TableCell width="10%" align="center">
              <TableSortLabel
                active={orderBy === 'closedWonDeals'}
                direction={orderBy === 'closedWonDeals' ? order : 'asc'}
                onClick={() => handleSort('closedWonDeals')}
              >
                <strong>Won Deals</strong>
              </TableSortLabel>
            </TableCell>
            <TableCell width="15%">
              <TableSortLabel
                active={orderBy === 'winRate'}
                direction={orderBy === 'winRate' ? order : 'asc'}
                onClick={() => handleSort('winRate')}
              >
                <strong>Win Rate</strong>
              </TableSortLabel>
            </TableCell>
            <TableCell width="8%" align="center">
              <TableSortLabel
                active={orderBy === 'averageSalesCycle'}
                direction={orderBy === 'averageSalesCycle' ? order : 'asc'}
                onClick={() => handleSort('averageSalesCycle')}
              >
                <strong>Avg Cycle (Days)</strong>
              </TableSortLabel>
            </TableCell>
            <TableCell width="8%" align="center">
              <TableSortLabel
                active={orderBy === 'averageDealSize'}
                direction={orderBy === 'averageDealSize' ? order : 'asc'}
                onClick={() => handleSort('averageDealSize')}
              >
                <strong>Avg Deal Size</strong>
              </TableSortLabel>
            </TableCell>
            <TableCell width="15%">
              <TableSortLabel
                active={orderBy === 'engagementScore'}
                direction={orderBy === 'engagementScore' ? order : 'asc'}
                onClick={() => handleSort('engagementScore')}
              >
                <strong>Engagement Score</strong>
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.slice(0, 10).map((rep, index) => (
            <TableRow key={rep.ownerId}>
              <TableCell width="5%" align="center">{`#${index + 1}`}</TableCell>
              <TableCell width="10%">{rep.ownerName}</TableCell>
              <TableCell width="15%" align="center">
                <Typography variant="body1" fontWeight="bold">
                  {formatCurrency(rep.wonRevenue)}
                </Typography>
              </TableCell>
              <TableCell width="8%" align="center">
                <Typography variant="body2" color="textSecondary">
                  {rep.totalDeals}
                  {getComparisonArrow(rep.totalDeals, overallAverages.totalDeals)}
                </Typography>
              </TableCell>
              <TableCell width="8%" align="center">
                <Typography variant="body2" color="textSecondary">
                  {rep.closedWonDeals}
                  {getComparisonArrow(rep.closedWonDeals, overallAverages.closedWonDeals)}
                </Typography>
              </TableCell>
              <TableCell width="15%">
                <Box display="flex" alignItems="center">
                  <Box width="100%" mr={1}>
                    <LinearProgress
                      variant="determinate"
                      value={rep.winRate}
                      sx={{
                        height: 10,
                        borderRadius: 5,
                        [`& .MuiLinearProgress-bar`]: {
                          backgroundColor: getWinRateColor(rep.winRate),
                        },
                      }}
                    />
                  </Box>
                  <Box minWidth={35}>
                    <Typography variant="body2" color="textSecondary">
                      {formatPercentage(rep.winRate / 100, { maximumFractionDigits: 1 })}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell width="8%" align="center">
                <Typography variant="body2" color="textSecondary">
                  {rep.averageSalesCycle.toFixed(1)}
                  {getComparisonArrow(rep.averageSalesCycle, overallAverages.averageSalesCycle, false)}
                </Typography>
              </TableCell>
              <TableCell width="8%" align="center">
                <Typography variant="body2" color="textSecondary">
                  {formatCurrency(rep.averageDealSize)}
                  {getComparisonArrow(rep.averageDealSize, overallAverages.averageDealSize)}
                </Typography>
              </TableCell>
              <TableCell width="15%">
                <Box display="flex" alignItems="center">
                  <Box width="100%" mr={1}>
                    <LinearProgress
                      variant="determinate"
                      value={rep.engagementScore}
                      sx={{
                        height: 10,
                        borderRadius: 5,
                        [`& .MuiLinearProgress-bar`]: {
                          backgroundColor: getEngagementScoreColor(rep.engagementScore),
                        },
                      }}
                    />
                  </Box>
                  <Box minWidth={35}>
                    <Typography variant="body2" color="textSecondary">
                      {formatPercentage(rep.engagementScore / 100, { maximumFractionDigits: 1 })}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RepLeadersTable;