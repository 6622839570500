// src/components/sales/InsightsDashboard/ExpandedInsightView.jsx

import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useInsights } from '../../../contexts/InsightsContext';
import { formatCurrency } from '../../utils/formatters';

const ExpandedInsightView = ({ selectedInsight, onClose }) => {
  const { filteredDeals, owners, getStageLabel } = useInsights();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!selectedInsight) return null;

  // Filter deals based on selectedInsight.type
  let dealsToDisplay = filteredDeals;

  switch (selectedInsight.type) {
    case 'revenue':
    case 'averageDealSize':
      dealsToDisplay = dealsToDisplay.filter((deal) => deal.amount && deal.amount > 0);
      break;
    case 'averageDealAge':
      dealsToDisplay = dealsToDisplay.filter((deal) => typeof deal.daysOpen === 'number');
      break;
    case 'dealCount':
      // All deals are relevant
      break;
    default:
      break;
  }

  console.log(`Displaying deals for insight type: ${selectedInsight.type}`, dealsToDisplay);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">
          {selectedInsight.title} - Detailed View
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      {dealsToDisplay.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Deal Name</TableCell>
                {!isMobile && <TableCell>Owner</TableCell>}
                <TableCell>Stage</TableCell>
                <TableCell>Amount</TableCell>
                {!isMobile && <TableCell>Close Date</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {dealsToDisplay.map((deal) => (
                <TableRow
                  key={deal.id}
                  sx={{
                    '&:nth-of-type(odd)': {
                      backgroundColor: theme.palette.action.hover,
                    },
                    '&:hover': {
                      backgroundColor: theme.palette.action.selected,
                    },
                  }}
                >
                  <TableCell>{deal.dealname || 'Untitled'}</TableCell>
                  {!isMobile && (
                    <TableCell>
                      {owners[deal.hubspot_owner_id]?.firstName}{' '}
                      {owners[deal.hubspot_owner_id]?.lastName}
                    </TableCell>
                  )}
                  <TableCell>{getStageLabel(deal.dealstage)}</TableCell>
                  <TableCell>
                    {deal.amount ? formatCurrency(deal.amount) : 'N/A'}
                  </TableCell>
                  {!isMobile && (
                    <TableCell>
                      {deal.closedate
                        ? new Date(deal.closedate).toLocaleDateString()
                        : 'N/A'}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1">No deals to display for this insight.</Typography>
      )}
    </Paper>
  );
};

export default ExpandedInsightView;