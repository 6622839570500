import React, { useMemo } from 'react';
import { Box, Typography, Chip, useTheme, Skeleton, useMediaQuery } from '@mui/material';
import { 
  Handshake as HandshakeIcon,
  Person as PersonIcon,
  Business as BusinessIcon,
  Chat as ChatIcon,
  Group as GroupIcon
} from '@mui/icons-material';
import { useOverview } from '../../../contexts/OverviewContext';
import { useDashboardStyles } from '../../hooks/useDashboardStyles';
import { mint, tangerine, bittersweet, steelBlue, periwinkle } from '../../../theme';

const DataFetchChip = ({ icon, label, value, color, compact }) => {
  const theme = useTheme();
  
  return (
    <Chip
      icon={icon}
      label={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" sx={{ mr: 1, ml: 1, fontSize: '0.7rem' }}>{label}</Typography>
          <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.7rem' }}>{value.toLocaleString()}</Typography>
        </Box>
      }
      sx={{
        height: 'auto',
        '& .MuiChip-icon': {
          color: color,
          marginLeft: '4px',
          fontSize: '0.9rem',
        },
        '& .MuiChip-label': {
          display: 'flex',
          alignItems: 'center',
          padding: '4px 6px',
        },
        backgroundColor: 'transparent',
        color: theme.palette.text.primary,
        border: `2px solid ${color}`,
      }}
    />
  );
};

const DataFetchDetails = ({ compact }) => {
  const { syncProgress, loading, error } = useOverview();
  const { layout } = useDashboardStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const finalTotals = useMemo(() => {
    if (!syncProgress || !syncProgress.fetchOwners) return null;
    return {
      dealCount: syncProgress.fetchDeals?.dealCount || 0,
      contactCount: syncProgress.fetchContacts?.contactCount || 0,
      companyCount: syncProgress.fetchCompanies?.companyCount || 0,
      engagementCount: syncProgress.fetchEngagements?.totalEngagements || 0, // Changed from engagementCount to totalEngagements
      ownerCount: syncProgress.fetchOwners?.ownerCount || 0,
    };
  }, [syncProgress]);

  const kpiData = [
    { key: 'dealCount', icon: <HandshakeIcon />, label: 'Deals', color: mint },
    { key: 'contactCount', icon: <PersonIcon />, label: 'Contacts', color: tangerine },
    { key: 'companyCount', icon: <BusinessIcon />, label: 'Companies', color: bittersweet },
    { key: 'engagementCount', icon: <ChatIcon />, label: 'Engagements', color: steelBlue },
    { key: 'ownerCount', icon: <GroupIcon />, label: 'Sales Reps', color: periwinkle },
  ];

  if (loading || !finalTotals) {
    return (
      <Box sx={{ 
        display: 'flex', 
        flexWrap: 'wrap', 
        gap: 1, 
        justifyContent: isMobile ? 'center' : 'flex-start',
        width: '100%'
      }}>
        {kpiData.map((kpi) => (
          <Skeleton
            key={kpi.key}
            variant="rounded"
            width={120}
            height={32}
            sx={{ borderRadius: '16px' }}
          />
        ))}
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">Error loading data fetch details: {error}</Typography>;
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: isMobile ? 'center' : 'flex-start' }}>
      {kpiData.map((kpi) => (
        <DataFetchChip
          key={kpi.key}
          icon={kpi.icon}
          label={kpi.label}
          value={finalTotals[kpi.key] || 0}
          color={kpi.color}
          compact={compact || isMobile}
        />
      ))}
    </Box>
  );
};

export default React.memo(DataFetchDetails);