import React from 'react';
import { Box, Typography, Paper, Grid, useTheme } from '@mui/material';
import { useChartConfig } from '../hooks/useChartConfig';
import onboarding1 from '../home/assets/Onboarding1.png';
import onboarding2 from '../home/assets/Onboarding2.png';
import onboarding3 from '../home/assets/Onboarding3.png';
import SalesHubAnalysis from '../home/assets/SalesHubAnalysis.png';

const steps = [
  {
    title: "Sign up for Truepipe",
    description: "Create your account on Truepipe.ai",
    imagePlaceholder: onboarding1
  },
  {
    title: 'Click "Connect to HubSpot" button',
    description: "Find and click the 'Connect to HubSpot' button in your Truepipe dashboard",
    imagePlaceholder:  onboarding2
  },
  {
    title: "Choose your HubSpot account",
    description: "Select the HubSpot account you want to connect with Truepipe",
    imagePlaceholder: onboarding3
  },
  {
    title: "Wait for data sync",
    description: "You'll be redirected to Truepipe. Wait less than 30 seconds to see your dashboards populated with data",
    imagePlaceholder: SalesHubAnalysis
  }
];

const InstallGuide = () => {
  const theme = useTheme();
  const { isMobile, getCommonProperties } = useChartConfig();
  const colors = getCommonProperties().theme.tooltip.container;

  return (
    <Box sx={{ p: 3, bgcolor: 'background.default' }}>
      <Typography variant={isMobile ? "h4" : "h3"} component="h1" gutterBottom align="center" color="text.primary">
        Installation Guide
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {steps.map((step, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                bgcolor: colors.background,
                color: colors.color,
              }}
            >
              <Typography variant="h6" component="h2" gutterBottom color="primary">
                Step {index + 1}: {step.title}
              </Typography>
              <Box
  component="img"
  sx={{
    width: '100%',
    height: 200,
    objectFit: 'cover',
    mb: 2,
  }}
  alt={`Step ${index + 1}`}
  src={step.imagePlaceholder}
/>
              <Typography variant="body2" color="text.secondary">
                {step.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default InstallGuide;