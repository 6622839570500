// src/components/healthcheck/DemoHealthcheck.jsx v1.0.0
import React from 'react'
import { Box, Button, Typography, Paper, Grid, LinearProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { 
  Warning, 
  CheckCircle, 
  Cancel,
  Storage,
  Report,
  QuestionAnswer,
} from '@mui/icons-material'
import { useDashboardTheme } from '../hooks/useDashboardTheme'
import { Link } from 'react-router-dom'

const sampleData = {
  accountAge: {
    years: 2,
    months: 7,
    totalDeals: 456,
    totalContacts: 1232,
    inactiveMonths: 4,
    totalEngagements: 2845
  },
  dataHealth: {
    deals: {
      total: 456,
      withNulls: 234,
      percentComplete: 48,
      criticalFields: {
        amount: 65,
        stage: 89,
        owner: 45,
        closeDate: 32
      }
    },
    contacts: {
      total: 1232,
      withNulls: 789,
      percentComplete: 36,
      criticalFields: {
        email: 92,
        phone: 45,
        company: 38,
        title: 28
      }
    }
  },
  activityTrends: [
    { month: 'Jun', deals: 45, contacts: 120, score: 82 },
    { month: 'Jul', deals: 38, contacts: 95, score: 75 },
    { month: 'Aug', deals: 25, contacts: 65, score: 60 },
    { month: 'Sep', deals: 12, contacts: 45, score: 42 },
    { month: 'Oct', deals: 8, contacts: 30, score: 35 },
    { month: 'Nov', deals: 5, contacts: 15, score: 28 },
  ],
}

const HealthScore = styled(Typography)(({ theme, score }) => ({
  fontSize: '4rem',
  fontWeight: 700,
  color: score >= 70 ? theme.palette.success.main :
         score >= 40 ? theme.palette.warning.main :
         theme.palette.error.main,
  textShadow: theme.palette.mode === 'dark' ? '0 0 20px rgba(255,255,255,0.1)' : 'none',
}))

function DemoHealthcheck() {
  const { colors: dashboardColors, isDark } = useDashboardTheme()

  const getHealthScore = () => {
    const dealScore = sampleData.dataHealth.deals.percentComplete
    const contactScore = sampleData.dataHealth.contacts.percentComplete
    const activityScore = sampleData.activityTrends[5].score
    return Math.round((dealScore + contactScore + activityScore) / 3)
  }

  const healthScore = getHealthScore()

  return (
    <Box sx={{ 
      bgcolor: dashboardColors.background,
      p: 3 
    }}>
      <div id="diagnostic-content">
        <Box sx={{ 
          maxWidth: 'lg', 
          mx: 'auto',
          p: 4
        }}>
          {/* Header Section */}
          <Box sx={{ mb: 4 }}>
            <Typography 
              variant="h4" 
              sx={{ 
                mb: 2, 
                color: dashboardColors.text.primary,
                fontWeight: 'bold'
              }}
            >
              HubSpot CRM Health Diagnostic
            </Typography>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Box display="flex" alignItems="center">
                  <HealthScore score={healthScore}>
                    {healthScore}
                  </HealthScore>
                  <Box ml={2}>
                    <Typography variant="body2" color="text.secondary">
                      Overall Health Score
                    </Typography>
                    <Typography variant="caption" color="text.tertiary">
                      Based on data quality & activity
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="text.secondary">
                  Account Age
                </Typography>
                <Typography variant="body1" color="text.primary">
                  {sampleData.accountAge.years}y {sampleData.accountAge.months}m
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {/* Warning Alert */}
          <Paper 
            elevation={0}
            sx={{ 
              p: 4,
              borderRadius: 2,
              bgcolor: dashboardColors.card.background,
              border: isDark ? `1px solid ${dashboardColors.card.border}` : 'none',
              transition: 'background-color 0.3s',
              '&:hover': {
                bgcolor: dashboardColors.card.hover
              }
            }}
          >
            <Warning sx={{ color: dashboardColors.status.warning }} />
            <Typography variant="body2" color="text.primary">
              Your HubSpot account has shown minimal activity in the last {sampleData.accountAge.inactiveMonths} months. 
              This might be impacting your sales pipeline visibility.
            </Typography>
          </Paper>

          {/* Data Health Cards */}
          <Grid container spacing={2} sx={{ mb: 4 }}>
            {/* Deals Card */}
            <Grid item xs={12} md={6}>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 4,
                  borderRadius: 2,
                  bgcolor: dashboardColors.card.background,
                  border: isDark ? `1px solid ${dashboardColors.card.border}` : 'none',
                  transition: 'background-color 0.3s',
                  '&:hover': {
                    bgcolor: dashboardColors.card.hover
                  }
                }}
              >
                {/* Header */}
                <Box 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 2, 
                    mb: 4 
                  }}
                >
                  <Storage 
                    sx={{ 
                      color: dashboardColors.chart.primary, 
                      fontSize: 20 
                    }} 
                  />
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      fontWeight: 600,
                      fontSize: '1.25rem',
                      color: 'text.primary'
                    }}
                  >
                    Deals Data Health
                  </Typography>
                </Box>

                {/* Progress Section */}
                <Box sx={{ mb: 4 }}>
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      alignItems: 'center',
                      mb: 1 
                    }}
                  >
                    <Typography 
                      variant="body2" 
                      sx={{ color: dashboardColors.text.secondary }}
                    >
                      Data Completeness
                    </Typography>
                    <Typography 
                      variant="body2" 
                      sx={{ fontWeight: 500 }}
                    >
                      {sampleData.dataHealth.deals.percentComplete}%
                    </Typography>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={sampleData.dataHealth.deals.percentComplete}
                    sx={{
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: dashboardColors.progressBar.background,
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: dashboardColors.progressBar.primary,
                        borderRadius: 4,
                      }
                    }}
                  />
                </Box>

                {/* Critical Fields Grid */}
                <Box 
                  sx={{ 
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: 1,
                    fontSize: '0.875rem'
                  }}
                >
                  {Object.entries(sampleData.dataHealth.deals.criticalFields).map(([field, value]) => (
                    <Box 
                      key={field} 
                      sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 1 
                      }}
                    >
                      {value >= 50 ? (
                        <CheckCircle 
                          sx={{ 
                            color: dashboardColors.status.success,
                            fontSize: 16
                          }} 
                        />
                      ) : (
                        <Cancel 
                          sx={{ 
                            color: dashboardColors.status.error,
                            fontSize: 16
                          }} 
                        />
                      )}
                      <Typography variant="body2">
                        {field}: {value}% complete
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Paper>
            </Grid>

            {/* Contacts Card */}
            <Grid item xs={12} md={6}>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 4,
                  borderRadius: 2,
                  bgcolor: dashboardColors.card.background,
                  border: isDark ? `1px solid ${dashboardColors.card.border}` : 'none',
                  transition: 'background-color 0.3s',
                  '&:hover': {
                    bgcolor: dashboardColors.card.hover
                  }
                }}
              >
                {/* Header */}
                <Box 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 2, 
                    mb: 4 
                  }}
                >
                  <Storage 
                    sx={{ 
                      color: dashboardColors.chart.secondary, 
                      fontSize: 20 
                    }} 
                  />
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      fontWeight: 600,
                      fontSize: '1.25rem',
                      color: 'text.primary'
                    }}
                  >
                    Contacts Data Health
                  </Typography>
                </Box>

                {/* Progress Section */}
                <Box sx={{ mb: 4 }}>
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      alignItems: 'center',
                      mb: 1 
                    }}
                  >
                    <Typography 
                      variant="body2" 
                      sx={{ color: dashboardColors.text.secondary }}
                    >
                      Data Completeness
                    </Typography>
                    <Typography 
                      variant="body2" 
                      sx={{ fontWeight: 500 }}
                    >
                      {sampleData.dataHealth.contacts.percentComplete}%
                    </Typography>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={sampleData.dataHealth.contacts.percentComplete}
                    sx={{
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: dashboardColors.progressBar.background,
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: dashboardColors.progressBar.secondary,
                        borderRadius: 4,
                      }
                    }}
                  />
                </Box>

                {/* Critical Fields Grid */}
                <Box 
                  sx={{ 
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: 2,
                    mt: 3
                  }}
                >
                  {Object.entries(sampleData.dataHealth.contacts.criticalFields).map(([field, value]) => (
                    <Box 
                      key={field} 
                      sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 1.5
                      }}
                    >
                      {value >= 50 ? (
                        <CheckCircle 
                          sx={{ 
                            color: dashboardColors.status.success,
                            fontSize: 16
                          }} 
                        />
                      ) : (
                        <Cancel 
                          sx={{ 
                            color: dashboardColors.status.error,
                            fontSize: 16
                          }} 
                        />
                      )}
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          color: 'text.primary',
                          fontWeight: 400
                        }}
                      >
                        {field}: {value}% complete
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Paper>
            </Grid>
          </Grid>

          {/* Critical Findings */}
          <Paper 
            elevation={0}
            sx={{ 
              p: 4,
              borderRadius: 2,
              bgcolor: dashboardColors.card.background,
              border: isDark ? `1px solid ${dashboardColors.card.border}` : 'none',
              mb: 3
            }}
          >
            {/* Header */}
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 2, 
                mb: 3 
              }}
            >
              <Report 
                sx={{ 
                  color: dashboardColors.status.error,
                  fontSize: 20
                }} 
              />
              <Typography 
                variant="h6" 
                sx={{ 
                  fontWeight: 600,
                  fontSize: '1.25rem'
                }}
              >
                Critical Findings
              </Typography>
            </Box>

            {/* Findings List */}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {[
                '51% of your deals have incomplete or missing data',
                '64% of contacts lack proper company associations',
                'Activity has decreased by 82% in the last 6 months'
              ].map((finding, index) => (
                <Box 
                  key={index}
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    gap: 2,
                    color: dashboardColors.status.error
                  }}
                >
                  <Warning sx={{ fontSize: 16 }} />
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: 'inherit',
                      fontWeight: 400
                    }}
                  >
                    {finding}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Paper>

          {/* Activity Trend Chart */}
          <Paper 
            elevation={0} 
            sx={{ 
              mt: 4,
              p: 4,
              borderRadius: 2,
              bgcolor: dashboardColors.card.background,
              border: isDark ? `1px solid ${dashboardColors.card.border}` : 'none',
            }}
          >
            <Box sx={{ height: 300 }}>
              <ResponsiveContainer>
                <BarChart data={sampleData.activityTrends}>
                  <CartesianGrid 
                    strokeDasharray="3 3" 
                    stroke={isDark ? dashboardColors.chart.grid : 'rgba(0,0,0,0.1)'}
                  />
                  <XAxis 
                    dataKey="month" 
                    tick={{ fill: dashboardColors.text.secondary }}
                  />
                  <YAxis 
                    tick={{ fill: dashboardColors.text.secondary }}
                  />
                  <Tooltip 
                    contentStyle={{
                      backgroundColor: dashboardColors.card.background,
                      border: `1px solid ${dashboardColors.card.border}`,
                      borderRadius: '8px',
                    }}
                  />
                  <Bar 
                    dataKey="deals" 
                    name="New Deals" 
                    fill={dashboardColors.chart.primary}
                    radius={[4, 4, 0, 0]}
                  />
                  <Bar 
                    dataKey="contacts" 
                    name="New Contacts" 
                    fill={dashboardColors.chart.secondary}
                    radius={[4, 4, 0, 0]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Paper>

          {/* CTA Section */}
          <Paper
            elevation={0}
            sx={{ 
              p: 8,
              mt: 4,
              borderRadius: 2,
              bgcolor: dashboardColors.cta.background,
              border: `1px solid ${dashboardColors.cta.border}`,
              textAlign: 'center',
              backdropFilter: isDark ? 'blur(8px)' : 'none',
            }}
          >
            <QuestionAnswer 
              sx={{ 
                color: dashboardColors.cta.text,
                fontSize: 48,
                mb: 4
              }} 
            />
            
            <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 700,
                fontSize: '1.875rem',
                color: dashboardColors.cta.text,
                mb: 2
              }}
            >
              Ready to Maximize Your HubSpot Investment?
            </Typography>
            
            <Typography 
              variant="body1" 
              sx={{ 
                color: dashboardColors.cta.text,
                mb: 6,
                maxWidth: '600px',
                mx: 'auto',
                lineHeight: 1.5
              }}
            >
              Our HubSpot certified consultants can help you implement best practices, 
              clean up your data, and establish processes that will make your CRM a 
              powerful driver of sales success.
            </Typography>

            <Link 
              to="/contact/sales" 
              style={{ textDecoration: 'none' }}
            >
              <Button
                variant="contained"
                size="large"
                sx={{
                  px: 8,
                  py: 2,
                  minWidth: '280px',
                  fontSize: '1.1rem',
                  bgcolor: dashboardColors.cta.button,
                  color: isDark ? '#000000' : '#FFFFFF',
                  '&:hover': {
                    bgcolor: dashboardColors.cta.buttonHover,
                  },
                  boxShadow: 'none',
                  textTransform: 'uppercase',
                  fontWeight: 600,
                  letterSpacing: '0.5px'
                }}
              >
                Sign Up for a Demo
              </Button>
            </Link>
          </Paper>
        </Box>
      </div>
    </Box>
  )
}

export default DemoHealthcheck