// src/scenes/sales/insights.jsx

import React, { useState } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { InsightsProvider } from '../../contexts/InsightsContext';
import QueryBuilder from '../../components/sales/InsightsDashboard/QueryBuilder';
import DynamicInsightCards from '../../components/sales/InsightsDashboard/DynamicInsightCards';
import ExpandedInsightView from '../../components/sales/InsightsDashboard/ExpandedInsightView';

const InsightsDashboardContent = () => {
  const [selectedInsight, setSelectedInsight] = useState(null);

  const handleInsightClick = (insight) => {
    setSelectedInsight(selectedInsight?.type === insight.type ? null : insight);
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        AI-Driven Sales Insights
      </Typography>

      <Paper sx={{ padding: 2, marginBottom: 3 }}>
        <QueryBuilder />
      </Paper>

      <DynamicInsightCards onInsightClick={handleInsightClick} />

      {selectedInsight && (
        <ExpandedInsightView
          selectedInsight={selectedInsight}
          onClose={() => setSelectedInsight(null)}
        />
      )}
    </Box>
  );
};

const InsightsDashboard = () => (
  <InsightsProvider>
    <InsightsDashboardContent />
  </InsightsProvider>
);

export default InsightsDashboard;
