import React, { useEffect } from 'react';
import { Typography, Container, Box, Paper, Divider } from '@mui/material';
import Footer from '../global/Footer';
import AppAppBar from './AppAppBar';

const SectionTitle = ({ children }) => (
  <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4, mb: 2, fontWeight: 'bold' }}>
    {children}
  </Typography>
);

const SubsectionTitle = ({ children }) => (
  <Typography variant="h6" component="h3" gutterBottom sx={{ mt: 3, mb: 1, fontWeight: 'bold' }}>
    {children}
  </Typography>
);

const Paragraph = ({ children }) => (
  <Typography variant="body1" paragraph>
    {children}
  </Typography>
);

export default function TermsAndConditions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container maxWidth="md" sx={{ pt: 12 }}>
        <AppAppBar />
      <Paper elevation={3} sx={{ my: 4, p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold' }}>
          Terms and Conditions
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Last Updated: March 17th, 2024
        </Typography>
        <Divider sx={{ my: 2 }} />

        <Paragraph>
          These Terms of Service ("Terms") and the Privacy Policy govern your access and use of truepipe's ("truepipe," "us," "we," and "our") website, proprietary software and services (collectively the "Services"). By using the Services or signing up for an account, you acknowledge that you have read, understood, and agree to be legally bound by the Terms and affirm that you are over the age of 18.
        </Paragraph>

        <Paragraph>
          If you are using the Services on behalf of an organization and your organization has executed a truepipe Master Subscription Agreement and Order Form and/or other relevant agreements ("MSA") with us, the MSA will supersede any conflicting terms in these Terms. If you are using the Services on behalf of an organization and your organization has not executed the MSA, you are agreeing to the Terms on behalf of that organization and representing that you have the authority to bind that organization to the Terms. In that case, "you" and "your" will refer to that organization. If you do not have such authority, or if you do not agree with the Terms and the Privacy Policy, you must not accept the Terms and may not use the Services.
        </Paragraph>

        <Paragraph>
          We may change these Terms from time to time by posting a revised version of the Terms on this site. Please review the site on a regular basis to obtain timely notice of any revisions. The "Last Updated" legend above indicates when the Terms were last changed. If you continue to use the site after the revisions take effect, you agree to be bound by the revised terms.
        </Paragraph>

        <Box sx={{ my: 3, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
          <Typography variant="body1" fontWeight="bold">
            PLEASE BE AWARE THAT THE TERMS CONTAIN CLASS ACTION AND ARBITRATION PROVISIONS THAT MAY AFFECT YOUR RIGHTS.
          </Typography>
        </Box>

        <SectionTitle>1. Access and Use of the Services</SectionTitle>

        <SubsectionTitle>1.1 Software as a Service Subscription</SubsectionTitle>
        <Paragraph>
          Subject to the Terms and our Privacy Policy, and in consideration of the Fees at section 4, we grant you a non-exclusive, non-transferable, revocable, limited right to access and use the Services and any business information you capture ("Data") from the Services for your own internal business purposes and in accordance with the terms of your chosen subscription plan ("Subscription") for the duration of the subscription term ("Subscription Term"). Upgrading your Subscription during the Subscription Term is permitted by submitting a written request to truepipe.
        </Paragraph>

        <SubsectionTitle>1.2 Acceptable Use</SubsectionTitle>
        <Paragraph>
          You acknowledge and agree that at all times in your use of the Services and Data that you will:
        </Paragraph>
        <Box component="ol" sx={{ pl: 4 }}>
          <li>Comply with the Terms and all applicable law, rules and regulations;</li>
          <li>Use and have measures in place to keep Data secure, confidential and comply with all international data privacy, security and marketing laws and regulations, including but not limited to the General Data Protection Regulation ("GDPR"), the Privacy and Electronic Communications Directive 2002/58/EC, the California Consumer Privacy Act ("CCPA"), CAN-SPAM Act of 2003, and all other equivalent laws and regulations in any relevant jurisdiction relating to data privacy that apply to any personal data you collect and use in connection with the Services;</li>
          <li>Monitor and control all activity conducted through your account in connection with the Services, including preventing unauthorized access and use of the Services and Data.</li>
        </Box>

        <SubsectionTitle>1.3 Prohibited Use</SubsectionTitle>
        <Paragraph>
          You acknowledge and agree that at all times during your use of the Services, you shall not and shall not permit any third parties to:
        </Paragraph>
        <Box component="ol" sx={{ pl: 4 }}>
          <li>Use the Services for any purposes other than your own internal business to business sales, marketing, or customer relationship management;</li>
          <li>Share your login credentials, publish, distribute, share, sell, lease, transfer or otherwise make any portion of the Services and Data available to third parties;</li>
          <li>Interfere with or disrupt the integrity or performance of the Services;</li>
          <li>Reverse engineer, decompile, disassemble, copy, or otherwise attempt to derive source code of any software making up the Services;</li>
          <li>Use any robot, spider, crawler, scraper or other automated means or interface not provided by us to access the Services or to extract or export data collected using the Services;</li>
          <li>Access the Services in order to build a competitive product or service;</li>
          <li>Use the Services and Data in violation of any applicable laws, including but not limited to international data privacy laws and regulations, and particularly laws that prohibit the sending of bulk mail, junk mail, spam or other forms of duplicative messages;</li>
          <li>Use the Services and Data to stalk, harass, bully or harm another individual, or use the Services in any way that is misleading, unlawful, defamatory, obscene, invasive, threatening, or otherwise objectionable.</li>
        </Box>
        <Paragraph>
          If you undertake any of the aforementioned actions at section 1.3, your privileges to use the Services may, at our discretion, be terminated or suspended in accordance with sections 5.3 and 5.4. truepipe shall also have the right to pursue any damages or injunctive relief it may be entitled to under law.
        </Paragraph>

        <SubsectionTitle>1.4 Usage Data</SubsectionTitle>
        <Paragraph>
          truepipe may collect, use and analyze general usage and performance information and data about our customers in an aggregated manner for the purpose of improving the Services or for publishing statistics, provided that we will not specifically identify you in the course of collecting, using, analyzing or publishing that information or data.
        </Paragraph>

        <SubsectionTitle>1.5 User Content</SubsectionTitle>
        <Paragraph>
          User Content means any information, material, feedback that you submit to us, for whatever purpose. You grant to us a worldwide, irrevocable, non-exclusive, royalty-free license to use, reproduce, adapt, publish, translate, communicate, display, and distribute your User Content for the purposes of operating or improving our Services.
        </Paragraph>

        <SectionTitle>2. Intellectual Property</SectionTitle>
        <Paragraph>
          truepipe owns the proprietary software application ("Software") that you may be granted access to through the Services. The name "truepipe," the design of the Services along with truepipe created text, writings, images, templates, scripts, graphics, interactive features and the trademarks, marks and logos contained therein ("Marks"), and the Data are owned by or licensed to truepipe. The Marks are subject to copyright and other intellectual property rights under US laws and international conventions.
        </Paragraph>
        <Paragraph>
          You agree that the Software and Marks are the property of truepipe or its third party licensors and that you have no ownership, right, title, interest, nor any part thereof, in the Licensed Software and Marks, except for the limited right of access and use at section 1.1. truepipe reserves all rights not expressly granted in and to the Services. You agree to not engage in the use, copying, or distribution of anything contained within the Services unless we have given you express written permission.
        </Paragraph>

        <SectionTitle>3. Third Party Services and Links</SectionTitle>
        <Paragraph>
          Some of truepipe's Services may require integration with select third-party software applications ("Third Party Services"). For integrations with Third Party Services, you authorize truepipe to access, store and use information or data from your account with the Third Party Services as reasonably necessary to i) provide the applicable Services offered by truepipe, and ii) derive any insights about your sales practices that will be aggregated and anonymised for internal research and development purposes, industry benchmarking, and may be published, displayed or distributed as part of our Services in anonymised form. You own and shall retain all right, title, and interest in your data originating from the Third Party Services.
        </Paragraph>
        <Paragraph>
          You represent and warrant that you have the authority to provide such access to us and that doing so will not violate your agreement with the Third Party Services. truepipe assumes no responsibility for and disclaims any liability or obligations with respect to Third Party Services that are provided pursuant to the terms of the applicable third-party license or separate agreement between you and the licensor of the Third Party Services.
        </Paragraph>
        <Paragraph>
          The Services may contain links to third party websites that are not owned or controlled by truepipe. truepipe has no control over, and assumes no responsibility for the content, policies or practices of third party websites.
        </Paragraph>

        <SectionTitle>4. Fees and Payment</SectionTitle>

        <SubsectionTitle>4.1 Fees</SubsectionTitle>
        <Paragraph>
          Except for the Freemium Subscription, in order to use and access the Services, you agree to pay the monthly or annual fee for your Subscription as detailed on the Pricing page of our website or in our invoices to you (the "Fees"). All Fees are non-refundable. Upon renewal of your Subscription in accordance with section 5.1, the Fees may be subject to change. truepipe will provide you with advance notice of any changes in Fees.
        </Paragraph>

        <SubsectionTitle>4.2 Payment Terms</SubsectionTitle>
        <Paragraph>
          Unless otherwise provided, truepipe will charge you the Fees on an annual or monthly basis in advance. For settlements of the Fees using credit cards, you authorize us to use a PCI-compliant third party payment processor to process your payment information. If payment will be made by a method other than a credit card, we will invoice you in advance. Invoiced charges are due net 30 days from the invoice date. You are responsible for providing complete and accurate billing and contact information to truepipe and notifying us of any changes to such information.
        </Paragraph>
        <Paragraph>
          Any and all disputes over the Fees must be raised by you via a written notice no later than 10 (ten) days after the invoice date. For any undisputed amount due and not paid by you, we will provide you with notice of non-payment and reserve the right to suspend your access to the Services if payment is not made within thirty 30 (thirty) days after such notice. Except as prohibited by law, truepipe may charge a late fee of one and one-half percent (1.5%) per month on past due amounts.
        </Paragraph>
        <Paragraph>
          Amounts due to truepipe from you shall not be withheld or offset against amounts due or alleged to be due to you from truepipe.
        </Paragraph>

        <SubsectionTitle>4.3 Taxes</SubsectionTitle>
        <Paragraph>
          You are responsible for any applicable taxes, including and without limitation, any sales, use, levies, duties, value added or similar taxes payable and assessed by any local, state, provincial, federal, or foreign jurisdiction. Unless expressly specified otherwise all fees, rates, and estimates provided by us exclude taxes.
        </Paragraph>

        <SectionTitle>5. Term and Termination</SectionTitle>

        <SubsectionTitle>5.1 Term and Renewals</SubsectionTitle>
        <Paragraph>
          The Subscription Term commences on the date you sign up for a truepipe account and agree to these Terms, and will automatically renew at the end of each subscription period for a term equal in time as your original subscription period and, unless otherwise notified to you in advance, for the same Fees. We reserve the right to change the Fees at renewal in accordance with section 4.1. You may terminate your Subscription in accordance with section 5.2 prior to the expiry of each subscription period.
        </Paragraph>

        <SubsectionTitle>5.2 Termination</SubsectionTitle>
        <Paragraph>
          Either party may elect to terminate the Subscription at the end of the current Subscription term by providing written notice no less than thirty (30) days prior to the end of such Subscription term. You agree that no refunds or credits for any part of the Fees will be made. Upon termination, your right to access and use the Services shall immediately terminate.
        </Paragraph>

        <SubsectionTitle>5.3 Termination for Cause</SubsectionTitle>
        <Paragraph>
          Either party may terminate the Subscription for cause (a) upon written notice to the other party of a material breach of the Terms if such breach remains uncured at the expiration of thirty (30) days from the date of receipt of such written notice; or (b) if the other party becomes the subject of a petition in bankruptcy or any other proceeding relating to insolvency, receivership, liquidation or assignment for the benefit of creditors. In no event will termination discharge you of your obligation to pay the Fees.
        </Paragraph>

        <SubsectionTitle>5.4 Suspension of Access</SubsectionTitle>
        <Paragraph>
          truepipe may suspend your access to the Services immediately if: (a) you fail to make a payment for more than thirty (30) days following its due date; or (b) you have, or truepipe reasonably suspects that you have breached sections 1.3 or 2. truepipe will have no liability to you for the period of suspension.
        </Paragraph>

        <SectionTitle>6. Confidentiality and Data Privacy</SectionTitle>

        <SubsectionTitle>6.1 Confidential Information</SubsectionTitle>
        <Paragraph>
          "Confidential Information" means all information of a party disclosed to the other party, whether orally or in writing, that is designated as confidential or is information which a reasonable person would understand to be confidential given the nature of the information and circumstances of disclosure, including, but not limited to, any pricing information, any proprietary materials provided, including product plans, technology and technical information, business and marketing plans and business processes disclosed by such party.
        </Paragraph>

        <SubsectionTitle>6.2 Confidentiality and Disclosures</SubsectionTitle>
        <Paragraph>
          Each party will protect the other's Confidential Information from unauthorized use, access or disclosure in the same manner as each party protects its own Confidential Information, but with no less than reasonable care. Except as otherwise expressly permitted pursuant to these Terms, each party may use the other party's Confidential Information solely to exercise its respective rights and perform its respective obligations under these Terms.
        </Paragraph>
        <Paragraph>
          Each party may disclose Confidential Information (a) solely to the employees and/or non-employee service providers and contractors on a need to know basis and who are bound by terms of confidentiality intended to prevent the misuse of such Confidential Information; (b) as necessary to comply with an order or subpoena of any administrative agency or court of competent jurisdiction; or (c) as reasonably necessary to comply with any applicable law or regulation.
        </Paragraph>

        <SubsectionTitle>6.3 Privacy Policy</SubsectionTitle>
        <Paragraph>
          By using the Services, you expressly consent to data collection and processing by truepipe in accordance with truepipe's Privacy Policy located at: www.truepipe.ai/privacy.
        </Paragraph>

        <SectionTitle>7. Representations, Warranties and Disclaimers</SectionTitle>

        <SubsectionTitle>7.1 Warranties</SubsectionTitle>
        <Paragraph>
          Each party represents and warrants that it has the requisite power and authority to enter into the Terms.
        </Paragraph>

        <SubsectionTitle>7.2 Disclaimer</SubsectionTitle>
        <Paragraph>
          truepipe PROVIDES THE SERVICE AND DATA ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND TO THE FULLEST EXTENT PERMITTED BY LAW, AND WE EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY (INCLUDING WITH REGARDS TO THE DATA) AND NON-INFRINGEMENT. YOU ACKNOWLEDGE THAT truepipe DOES NOT WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED, TIMELY OR ERROR-FREE. truepipe DISCLAIMS ALL LIABILITY AND INDEMNIFICATION OBLIGATIONS FOR ANY HARM OR DAMAGE CAUSED BY ANY THIRD-PARTY APPLICATIONS. NO INFORMATION OR ADVICE OBTAINED BY YOU FROM US OR THROUGH YOUR SUBSCRIPTION SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS AGREEMENT.
        </Paragraph>

        <SectionTitle>8. Indemnification</SectionTitle>
        <Paragraph>
          You agree to defend, indemnify and hold harmless truepipe, its officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from your access and use of the Services and Data: i) in breach of these Terms; and ii) that violates the rights or otherwise caused damage to a third party, including without limitation any copyright, property, or privacy right. This indemnification will survive the Terms and your use of the Services.
        </Paragraph>

        <SectionTitle>9. Limitations of Liability</SectionTitle>

        <SubsectionTitle>9.1 Exclusion of Damages</SubsectionTitle>
        <Paragraph>
          IN NO EVENT WILL truepipe OR OUR AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, SUPPLIERS OR LICENSORS HAVE ANY LIABILITY TO YOU OR ANY THIRD PARTIES FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL OR PUNITIVE DAMAGES HOWEVER CAUSED, INCLUDING LOST PROFITS, LOST SALES OR BUSINESS, LOST CONTENT OR DATA, ARISING OUT OF OR IN CONNECTION WITH THE SERVICE AND THIRD PARTY MATERIALS, AND WHETHER THE ACTION IS IN CONTRACT OR TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY) AND REGARDLESS OF THE THEORY OF LIABILITY, EVEN IF truepipe HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING DISCLAIMER WILL NOT APPLY TO THE EXTENT PROHIBITED BY LAW.
        </Paragraph>

        <SubsectionTitle>9.2 Limitation of Liability</SubsectionTitle>
        <Paragraph>
          truepipe'S AGGREGATE LIABILITY TO YOU WITH RESPECT TO ANY SINGLE INCIDENT OR SERIES OF RELATED INCIDENTS UNDER, ARISING OUT OF OR RELATING TO THESE TERMS SHALL IN NO EVENT EXCEED THE FEES PAID BY YOU IN THE TWELVE (12) MONTHS PRECEDING THE INCIDENT OR SERIES OF RELATED INCIDENTS.
        </Paragraph>
        <Paragraph>
          YOU ACKNOWLEDGE AND AGREES THAT THE ESSENTIAL PURPOSE OF THIS SECTION 9 IS TO ALLOCATE THE RISKS UNDER THIS AGREEMENT BETWEEN THE PARTIES. THE ALLOCATION IS REFLECTED IN THE PRICING OFFERED BY truepipe TO YOU AND IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES.
        </Paragraph>

        <SectionTitle>10. Dispute Resolution and Mandatory Arbitration</SectionTitle>

        <SubsectionTitle>10.1 Informal Dispute Resolution</SubsectionTitle>
        <Paragraph>
          In the event of any disputes or claim arising out of or relating to the Terms, both parties agree to use their reasonable efforts to settle any dispute, claim or disagreement, arising out of or relating to the Terms, directly through consultation and good faith negotiations ("Informal Dispute Resolution"), which shall be a condition to either party initiating any formal legal claims.
        </Paragraph>
        <Paragraph>
          If the parties do not reach an agreed resolution within a period of thirty (30) days from the time Informal Dispute Resolution is initiated, then either party may initiate binding arbitration as the sole means to resolve the claims subject to the terms set forth below.
        </Paragraph>

        <SubsectionTitle>10.2 Mandatory Arbitration</SubsectionTitle>
        <Paragraph>
          You and truepipe hereby agree that any dispute, claim, or disagreement arising out of relating to the Terms and the Services will be settled in binding arbitration between you and truepipe, and not in a court of law. The Federal Arbitration Act governs the interpretation and enforcement of this obligation to arbitrate.
        </Paragraph>
        <Paragraph>
          The arbitration will be conducted by the American Arbitration Association ("AAA") under its rules and procedures available at www.adr.org ("Rules"). Arbitration shall be conducted by one (1) arbitrator as selected pursuant to the Rules. The arbitrator's award shall be final and binding and may be entered as a judgment in any court of competent jurisdiction. Each party shall be responsible for their own arbitration fees and costs.
        </Paragraph>
        <Paragraph>
          Arbitration shall be initiated and take place in Denver, Colorado. If the relief sought is less than US $10,000, then either party may choose whether the arbitration will be conducted on the basis of documents provided to the arbitrator or through a telephonic hearing, subject to the arbitrator's discretion to require an in-person hearing.
        </Paragraph>

        <SubsectionTitle>10.3 Class Action Waiver</SubsectionTitle>
        <Paragraph>
          You and truepipe agree that any proceedings to resolve or litigate any dispute whether through a court of law or arbitration shall be solely conducted on an individual basis. You agree that you will not seek to have any dispute heard as a class action, representative action, collective action, or private attorney general action.
        </Paragraph>

        <SubsectionTitle>10.4 Survival</SubsectionTitle>
        <Paragraph>
          This section 10 shall survive the termination of the Terms and your use of the Services.
        </Paragraph>

        <SectionTitle>11. General Provisions</SectionTitle>

        <SubsectionTitle>11.1 Assignment</SubsectionTitle>
        <Paragraph>
          Neither party may transfer or assign its rights or obligations under the Terms to any third party without the prior written approval of the other party, except for an assignment to an affiliated company or to a successor in connection with a merger, acquisition, reorganization or sale of substantially all of its assets or voting securities.
        </Paragraph>

        <SubsectionTitle>11.2 Notices</SubsectionTitle>
        <Paragraph>
          All notices under the Terms shall be in writing and be deemed to have been given upon: (i) personal delivery, (ii) two (2) business days after mailing or depositing with a nationally recognized courier, or (iii) immediately upon delivery by electronic mail. Notices to truepipe shall be addressed to truepipe, Attn: CEO; 2880 Zuni Street, Unit 406, Denver, CO 80211, USA.
        </Paragraph>

        <SubsectionTitle>11.3 Governing Law</SubsectionTitle>
        <Paragraph>
          Notwithstanding you and truepipe's agreement to mandatory arbitration at section 10.2, either party may seek any interim or preliminary injunctive relief from a court of competent jurisdiction in Denver, Colorado, as necessary to protect the party's rights or property pending the completion of arbitration. You and truepipe hereby submit to the exclusive jurisdiction of, and venue in, any federal or state court of competent jurisdiction located in Denver, Colorado.
        </Paragraph>

        <SubsectionTitle>11.4 Force Majeure</SubsectionTitle>
        <Paragraph>
          Except for your payment obligations, neither party shall be liable for any delay or failure in performance of to the extent caused by a condition, such as natural disaster, an act of war or terrorism, acts of God, riot, labor condition, governmental action, Internet disturbance, or acts undertaken by third parties, including without limitation, denial of service attack that was beyond the party's reasonable control.
        </Paragraph>

        <SubsectionTitle>11.5 Severability</SubsectionTitle>
        <Paragraph>
          If any provision of the Terms is held by a court of competent jurisdiction to be contrary to law, the provision will be deemed null and void, and the remaining provisions of the Terms will remain in effect.
        </Paragraph>

        <SubsectionTitle>11.6 Non-Waiver</SubsectionTitle>
        <Paragraph>
          No failure or delay by either party in exercising any right under the Terms will constitute a waiver of that right.
        </Paragraph>

        <SubsectionTitle>11.7 Entire Agreement</SubsectionTitle>
        <Paragraph>
          Except as expressly agreed to in writing by us, the Terms constitute the entire agreement between the parties with respect to the Services, and supersede all previous or contemporaneous agreements, whether written or oral, between the parties. In the event you execute an MSA with us and there is any conflict or inconsistency with the Terms, the order of precedence shall be: (1) the MSA, and (2) these Terms.
        </Paragraph>

        <SubsectionTitle>11.8 Export Controls</SubsectionTitle>
        <Paragraph>
          You are responsible for complying with United States export controls and for any violation of such controls, including any United States embargoes or other federal rules and regulations restricting exports. You represent, warrant and covenant that you are not (a) located in, or a resident or a national of, any country subject to a U.S. government embargo or other restriction, or that has been designated by the U.S. government as a "terrorist supporting" country; or (b) on any of the U.S. government lists of restricted end users.
        </Paragraph>

        <SectionTitle>12. Contact Us</SectionTitle>
        <Paragraph>
          If you have any questions about our Services and these Terms, please contact us at support@truepipe.ai. For any questions about our privacy practices, please contact us at support@truepipe.ai.
        </Paragraph>
        </Paper>
        <Footer />
      </Container>
    </>
  );
}

