import React, { useState, useCallback, useEffect } from 'react';
import { Box, Typography, Button, Container, Stack } from '@mui/material';
import { trackEvent } from '../utils/analytics';
import MainHero from './assets/MainHero.png';
import HeroVideo from './assets/herovideo.mp4';
import SignupModal from '../common/SignupModal';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import CertificationBadge from './assets/Certification-Badge.png';
import { Link } from 'react-router-dom';

// Styled Components
const StyledVideo = styled('video')(({ theme }) => ({
  marginTop: theme.spacing(3),
  aspectRatio: '1920 / 1022',
  objectFit: 'cover',
  objectPosition: 'center',
  borderRadius: '25px',
  overflow: 'hidden',
  maxHeight: '500px',
  width: '100%',
  maxWidth: '940px',
  display: 'block',
  margin: '0 auto',
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(4),
  },
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(6),
  },
}));

const HighlightedText = styled('span')(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: 700,
}));

const BadgeWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '-25px',
  right: '-90px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  zIndex: 1,
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
  [theme.breakpoints.down('sm')]: {
    top: '-15px',
    right: '0px',
  },
}));

const BadgeImage = styled('img')(({ theme }) => ({
  width: '100px',
  height: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '80px',
  },
}));

const Hero = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const theme = useTheme();

  // Marketing Content
  const title = 'Unlock the Power of AI for Revenue Teams';
  const subtitle = 'State-of-the-art forecasting and real-time pipeline health checks for HubSpot.';
  const cta = 'Free Trial';
  const caption = 'One-click. No CC required.';

  useEffect(() => {
    const impressionParams = {
      event_category: 'impression',
      event_label: 'Hero Section',
      hero_title: title,
      hero_subtitle: subtitle,
      cta_text: cta,
      cta_caption: caption,
    };
    trackEvent('hero_impression', impressionParams);
  }, [title, subtitle, cta, caption]);

  const handleStartNow = useCallback(() => {
    const eventParams = {
      event_category: 'engagement',
      event_label: 'Hero CTA',
      hero_title: title,
      hero_subtitle: subtitle,
      cta_text: cta,
      cta_caption: caption,
    };
    trackEvent('hero_cta_click', eventParams);
    setIsModalOpen(true);
  }, [title, subtitle, cta, caption]);

  return (
    <Box 
      variant="hero" 
      sx={{ 
        pt: { xs: 10, sm: 14, md: 20 },
        pb: { xs: 10, sm: 12, md: 14 },
        position: 'relative',
      }}
    >
      <Container 
        maxWidth="lg" 
        disableGutters 
        sx={{ 
          px: { xs: 2, sm: 3, md: 4 }
        }}
      >
        <Stack spacing={3} useFlexGap sx={{ width: '100%', maxWidth: '70rem', margin: '0 auto' }}>
          <Typography variant="heroTitle">
            {title.split(' ').map((word, index) => (
              ['Keep', 'Automatically'].includes(word) ? (
                <HighlightedText key={index}>{word} </HighlightedText>
              ) : (
                <span key={index}>{word} </span>
              )
            ))}
          </Typography>
          <Typography variant="heroSubtitle">
            {subtitle}
          </Typography>
          <Stack
            direction="column"
            spacing={2}
            useFlexGap
            sx={{ width: '100%', maxWidth: '30rem', margin: '0 auto', alignItems: 'center', position: 'relative' }}
          >
            <Button
              variant="contained"
              onClick={handleStartNow}
              sx={{ height: '40px', width: '200px' }}
            >
              {cta}
            </Button>
            <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8, fontSize: { xs: '0.7rem', sm: '0.75rem' } }}>
              {caption}
            </Typography>
            <BadgeWrapper>
              <Link to="https://ecosystem.hubspot.com/marketplace/apps/dashboards-by-truepipe" target="_blank" rel="noopener noreferrer">
                <BadgeImage src={CertificationBadge} alt="HubSpot Certified App" />
              </Link>
            </BadgeWrapper>
          </Stack>
        </Stack>
        
        <StyledVideo autoPlay loop muted playsInline poster={MainHero}>
          <source src={HeroVideo} type="video/mp4" />
          <img src={MainHero} alt="AI-enabled Sales Intelligence" style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }} />
        </StyledVideo>
      </Container>
      <SignupModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </Box>
  );
};

export default Hero;