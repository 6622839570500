import React, { useMemo } from 'react';
import { Box, Typography, Paper, Tooltip } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { usePipeline } from '../../../contexts/PipelineContext';
import { getStageColor } from '../../../contexts/utils/hubspotStages';

const DealStageProgression = () => {
  const {
    allDeals,
    formatCurrency,
    formatNumber,
    getCommonChartProperties,
  } = usePipeline();

  const stageData = useMemo(() => {
    const stages = {};
    const lostDeals = {};
    const stageOrder = [];

    allDeals.forEach(deal => {
      const stageId = deal.dealstage;
      if (!stages[stageId]) {
        stages[stageId] = { open: 0, won: 0, value: 0 };
        lostDeals[stageId] = 0;
        stageOrder.push(stageId);
      }

      const amount = parseFloat(deal.amount) || 0;

      if (deal.isOpen) {
        stages[stageId].open++;
        stages[stageId].value += amount;
      } else if (stageId === '6543970') { // Closed Won
        stages[stageId].won++;
        stages[stageId].value += amount;
      } else {
        lostDeals[stageId]++;
      }
    });

    return { stages, lostDeals, stageOrder };
  }, [allDeals]);

  const chartData = stageData.stageOrder.map(stageId => ({
    stage: stageId,
    open: stageData.stages[stageId].open,
    won: stageData.stages[stageId].won,
    lost: stageData.lostDeals[stageId],
    value: stageData.stages[stageId].value,
  }));

  const chartProps = {
    ...getCommonChartProperties('bar'),
    data: chartData,
    keys: ['open', 'won'],
    indexBy: 'stage',
    layout: 'horizontal',
    valueScale: { type: 'linear' },
    indexScale: { type: 'band', round: true },
    colors: ({ id, data }) => getStageColor(data.stage),
    padding: 0.3,
    labelSkipWidth: 16,
    labelSkipHeight: 16,
    labelTextColor: { from: 'color', modifiers: [['darker', 1.6]] },
    axisBottom: null,
    axisLeft: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Stages',
      legendPosition: 'middle',
      legendOffset: -40
    },
    tooltip: ({ id, value, data }) => (
      <Box sx={{ p: 1, bgcolor: 'background.paper', borderRadius: 1 }}>
        <Typography variant="body2">{`Stage: ${data.stage}`}</Typography>
        <Typography variant="body2">{`${id.charAt(0).toUpperCase() + id.slice(1)} Deals: ${value}`}</Typography>
        <Typography variant="body2">{`Lost Deals: ${data.lost}`}</Typography>
        <Typography variant="body2">{`Total Value: ${formatCurrency(data.value)}`}</Typography>
      </Box>
    )
  };

  return (
    <Paper elevation={2} sx={{ p: 2, height: 400 }}>
      <Typography variant="h6" gutterBottom>Deal Stage Progression</Typography>
      <ResponsiveBar {...chartProps} />
    </Paper>
  );
};

export default DealStageProgression;