import React from 'react';
import { Box, Skeleton } from '@mui/material';

const SkeletonLoader = ({ variant = 'rectangular', width = '100%', height = '100%', animation = 'wave' }) => {
  return (
    <Box sx={{ width, height }}>
      <Skeleton 
        variant={variant} 
        width={width} 
        height={height} 
        animation={animation} 
      />
    </Box>
  );
};

export default SkeletonLoader;