import React, { useState } from 'react';
import { Box, Typography, Grid, Tabs, Tab, Paper } from '@mui/material';
import { useDashboardStyles } from '../../components/hooks/useDashboardStyles';
import SubscribePopup from '../../components/global/SubscribePopup';
import { useSubscription } from '../../contexts/SubscriptionContext';
import { useNavigate } from 'react-router-dom';

// Placeholder component
const PlaceholderChart = ({ title }) => {
  const { layout } = useDashboardStyles();
  return (
    <Paper sx={{ ...layout.sectionWrapper, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Typography>{title} Placeholder</Typography>
    </Paper>
  );
};

const AIDashboard = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [showSubscribePopup, setShowSubscribePopup] = useState(true);
  const { layout, typography, components } = useDashboardStyles();
  const { isPro } = useSubscription();
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCloseSubscribePopup = () => {
    setShowSubscribePopup(false);
  };

  const handleNotYet = () => {
    setShowSubscribePopup(false);
    navigate('/sales');
  };

  const handleNotifyMe = () => {
    setShowSubscribePopup(false);
    navigate('/sales');
  };

  return (
    <Box sx={layout.pageWrapper}>
      <Typography variant="h2" sx={typography.pageTitle}>
        AI-Powered Sales Insights
      </Typography>
      
      <Box
        sx={{ 
          filter: isPro ? 'none' : 'blur(5px)',
          pointerEvents: isPro ? 'auto' : 'none',
        }}
      >
        <Tabs value={activeTab} onChange={handleTabChange} sx={components.tabs.root}>
          <Tab label="Predictions" sx={components.tabs.tab} />
          <Tab label="Recommendations" sx={components.tabs.tab} />
        </Tabs>

        {activeTab === 0 && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <PlaceholderChart title="Sales Forecast" />
            </Grid>
            <Grid item xs={12} md={6}>
              <PlaceholderChart title="Lead Scoring" />
            </Grid>
            <Grid item xs={12} md={6}>
              <PlaceholderChart title="Churn Prediction" />
            </Grid>
          </Grid>
        )}

        {activeTab === 1 && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <PlaceholderChart title="Next Best Action" />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <PlaceholderChart title="Pricing Optimization" />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <PlaceholderChart title="Cross-Sell Opportunities" />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <PlaceholderChart title="Customer Sentiment" />
            </Grid>
            <Grid item xs={12}>
              <PlaceholderChart title="AI-Generated Insights" />
            </Grid>
          </Grid>
        )}
      </Box>

      <SubscribePopup
        open={!isPro && showSubscribePopup}
        onClose={handleCloseSubscribePopup}
        onNotYet={handleNotYet}
        onNotifyMe={handleNotifyMe}
      />
    </Box>
  );
};

export default AIDashboard;