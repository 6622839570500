/**
 * Enhanced GA4 tracking utility
 */
export const trackEvent = (eventName, params = {}) => {
  if (window.gtag) {
    window.gtag('event', eventName, {
      ...params,
      timestamp: new Date().toISOString(),
    });
    console.log('GA4 Event tracked:', eventName, params);
  } else {
    console.warn('GA4 not initialized. Event not tracked:', eventName);
  }
};

export const trackPageView = (pageTitle, additionalParams = {}) => {
  trackEvent('page_view', {
    page_title: pageTitle,
    page_location: window.location.href,
    page_path: window.location.pathname,
    ...additionalParams
  });
}; 