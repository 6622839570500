import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  TextField, 
  Button, 
  Link,
  Divider,
  InputAdornment,
  IconButton,
  useTheme,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../../contexts/AuthContext';
import logger from '../../utils/frontendLogger'; // Update the import path if necessary
import { ColorModeContext } from '../../theme';
import { ReactComponent as GoogleDarkButton } from '../home/assets/web_dark_rd_ctn.svg';
import { ReactComponent as GoogleLightButton } from '../home/assets/web_light_rd_ctn.svg';
import { ReactComponent as LogoNoText } from '../home/assets/LogoNoText.svg';
import { Link as RouterLink } from 'react-router-dom';

// import Firebase tools
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

// Temporarily simplify the validation schema
const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
  isSignUp: Yup.boolean(),
});

const SignupModal = ({ open, onClose, email: initialEmail }) => {
  const navigate = useNavigate();
  const { signUp, logIn, signInWithGoogle } = useAuth();
  const [loginError, setLoginError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const [isSignUp, setIsSignUp] = useState(true);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [formValues, setFormValues] = useState({ email: initialEmail || '', password: '' });

  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    setShowValidation(true);
    try {
      const { email, password } = values;
      
      if (isSignUp) {
        await signUp(email, password);
        logger.logSignUpSuccess(email);
      } else {
        await logIn(email, password);
        logger.logLoginSuccess(email);
      }

      // Get redirect path from sessionStorage or default to /sales
      const redirectPath = sessionStorage.getItem('redirectAfterAuth') || '/sales'
      console.log('Authentication successful, navigating to', redirectPath)
      navigate(redirectPath)
      // Clean up
      sessionStorage.removeItem('redirectAfterAuth')
      onClose()
    } catch (error) {
      console.error('Authentication failed:', error);
      logger.logSignUpFailure(values.email, error.message, isSignUp);
      setFieldError('general', isSignUp 
        ? 'Failed to create an account. Please try again.' 
        : 'Invalid email or password. Please try again.');
    } finally {
      console.log('Setting submitting to false');
      setSubmitting(false);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
      // Get redirect path from sessionStorage or default to /sales
      const redirectPath = sessionStorage.getItem('redirectAfterAuth') || '/sales'
      navigate(redirectPath)
      // Clean up
      sessionStorage.removeItem('redirectAfterAuth')
      onClose();
    } catch (error) {
      console.error('Google sign-in failed:', error);
    }
  };

  const toggleMode = () => {
    setIsSignUp(!isSignUp);
    setLoginError(null);
    // Preserve the current form values when toggling
    setFormValues(prevValues => ({
      ...prevValues,
      isSignUp: !isSignUp
    }));
  };

  if (!open) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'rgba(0, 0, 0, 0.5)',
        zIndex: (theme) => theme.zIndex.modal,
      }}
      onClick={onClose}
    >
      <Box
        sx={{
          width: '90%', // Change from 100% to 90%
          maxWidth: {
            xs: '100%', // Full width on extra small screens
            sm: 400, // 400px on small screens and above
          },
          p: { xs: 2, sm: 3 }, // Adjust padding for different screen sizes
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          m: 2, // Add margin to ensure it's not touching the edges
          overflow: 'auto', // Allow scrolling if content is too long
          maxHeight: 'calc(100vh - 4rem)', // Limit height and allow scrolling
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
          <LogoNoText style={{ width: '50px', height: 'auto' }} />
        </Box>
        <Typography variant="h5" sx={{ mb: 1, textAlign: 'center' }}>Welcome to truepipe</Typography>

        <Typography variant="body2" sx={{ mb: 4, textAlign: 'center' }}>
          By signing up you agree to our{' '}
          <Link
            component={RouterLink}
            to="/terms"
            onClick={(e) => e.stopPropagation()}
            sx={{
              color: theme.palette.secondary.main,
              '&:hover': {
                color: theme.palette.secondary.dark,
              },
            }}
          >
            Terms & Conditions
          </Link>
        </Typography>

        <Formik
          initialValues={formValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ errors, touched, isSubmitting, values, setFieldValue }) => (
            <Form>
              <Field
                as={TextField}
                fullWidth
                name="email"
                label="Work email address"
                type="email"
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                sx={{ mb: 3 }}
                onChange={(e) => {
                  setFieldValue('email', e.target.value);
                  setFormValues(prev => ({ ...prev, email: e.target.value }));
                }}
              />
              <Field
                as={TextField}
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                sx={{ mb: 2 }}
                onFocus={() => {
                  setIsPasswordFocused(true);
                  setShowValidation(true);
                }}
                onBlur={() => setIsPasswordFocused(false)}
                onChange={(e) => {
                  setFieldValue('password', e.target.value);
                  setFormValues(prev => ({ ...prev, password: e.target.value }));
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {isSignUp && showValidation && (
                <Box sx={{ mb: 2, p: 2, bgcolor: 'background.default', border: 1, borderColor: 'divider', borderRadius: 1 }}>
                  <Typography variant="body2" sx={{ mb: 1 }}>Your password must contain:</Typography>
                  <Typography variant="body2" color={values.password.length >= 8 ? 'success.main' : 'text.secondary'}>
                    ✓ At least 8 characters
                  </Typography>
                  <Typography variant="body2" color={/[a-z]/.test(values.password) ? 'success.main' : 'text.secondary'}>
                    ✓ Lower case letters (a-z)
                  </Typography>
                  <Typography variant="body2" color={/[A-Z]/.test(values.password) ? 'success.main' : 'text.secondary'}>
                    ✓ Upper case letters (A-Z)
                  </Typography>
                  <Typography variant="body2" color={/\d/.test(values.password) ? 'success.main' : 'text.secondary'}>
                    ✓ Numbers (0-9)
                  </Typography>
                  <Typography variant="body2" color={/[@$!%*?&]/.test(values.password) ? 'success.main' : 'text.secondary'}>
                    ✓ Special characters (e.g. !@#$%^&*)
                  </Typography>
                </Box>
              )}

              <Button
                type="submit"
                variant="contained"
                disabled={isSubmitting}
                sx={{ 
                  mb: 2, 
                  width: '100%', 
                  display: 'block', 
                  mx: 'auto',
                  '&:hover': {
                    transform: 'scale(1)',
                  },
                }}
              >
                {isSubmitting ? 'Processing...' : (isSignUp ? 'Sign Up' : 'Log In')}
              </Button>

              {errors.general && (
                <Typography color="error" variant="body2" sx={{ mb: 2 }}>
                  {errors.general}
                </Typography>
              )}
            </Form>
          )}
        </Formik>

        <Typography variant="body2" align="center" sx={{ mb: 2 }}>
          {isSignUp ? "Already have an account?" : "Don't have an account?"}
          {' '}
          <Link 
            href="#" 
            underline="hover" 
            onClick={toggleMode}
            sx={{ 
              color: theme.palette.secondary.main,
              '&:hover': {
                color: theme.palette.secondary.dark,
              },
            }}
          >
            {isSignUp ? "Log in" : "Sign up"}
          </Link>
        </Typography>

        <Divider sx={{ mb: 2 }}>OR</Divider>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box 
            onClick={handleGoogleSignIn} 
            sx={{ 
              cursor: 'pointer',
              '& svg': {
                width: '100%',
                height: 'auto',
                maxWidth: '220px', // Adjust this value as needed
              },
            }}
          >
            {theme.palette.mode === 'dark' ? <GoogleDarkButton /> : <GoogleLightButton />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SignupModal;