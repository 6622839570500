import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { 
  Box, Typography, Button, Grid, useTheme, MenuItem, 
  useMediaQuery, TextField, Select, FormControl, InputLabel,
  Snackbar, Alert, CircularProgress
} from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { industries, companySizes, timezones } from './dropdownOptions';

const ProfileSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  jobTitle: Yup.string(),
  email: Yup.string().email('Invalid email').required('Required'),
  phone: Yup.string(),
  companyName: Yup.string(),
  industry: Yup.string(),
  companySize: Yup.string(),
  companyWebsite: Yup.string().url('Invalid URL'),
  location: Yup.string(),
  timezone: Yup.string(),
});

const ProfileSettings = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { userData, updateUserProfile } = useAuth();
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const initialValues = {
    firstName: userData?.firstName || '',
    lastName: userData?.lastName || '',
    jobTitle: userData?.jobTitle || '',
    email: userData?.email || '',
    phone: userData?.phone || '',
    companyName: userData?.companyName || '',
    industry: userData?.industry || '',
    companySize: userData?.companySize || '',
    companyWebsite: userData?.companyWebsite || '',
    location: userData?.location || '',
    timezone: userData?.timeZone || '',
  };

  const handleSubmit = async (values, { setSubmitting, setFieldTouched, resetForm }) => {
    try {
      await updateUserProfile(values);
      setSnackbar({ open: true, message: 'Profile updated successfully', severity: 'success' });
      // Reset form's dirty state
      Object.keys(values).forEach(key => setFieldTouched(key, false));
      resetForm({ values });
    } catch (error) {
      console.error('Error updating profile:', error);
      setSnackbar({ open: true, message: 'Failed to update profile. Please try again.', severity: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  const fieldStyles = {
    width: '100%',
    '& .MuiInputBase-root': {
      height: '56px',
    },
    marginBottom: theme.spacing(2),
  };

  const buttonStyles = {
    width: '100%',
    minHeight: '48px',
    marginBottom: theme.spacing(2),
  };

  return (
    <Box sx={{ 
      backgroundColor: theme.palette.background.paper, 
      p: isMobile ? 2 : 3,
      mb: isMobile ? 10 : 0,
      color: theme.palette.text.primary
    }}>
      <Typography variant={isMobile ? "h5" : "h3"} mb={3}>
        Profile Settings
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={ProfileSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, submitForm, isSubmitting, dirty, errors, touched }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" mb={2} color="primary">Personal Information</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="firstName"
                      label="First Name *"
                      fullWidth
                      value={values.firstName}
                      onChange={(e) => setFieldValue('firstName', e.target.value)}
                      error={touched.firstName && Boolean(errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                      sx={fieldStyles}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="lastName"
                      label="Last Name *"
                      fullWidth
                      value={values.lastName}
                      onChange={(e) => setFieldValue('lastName', e.target.value)}
                      error={touched.lastName && Boolean(errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                      sx={fieldStyles}
                    />
                  </Grid>
                </Grid>
                {['jobTitle', 'email', 'phone', 'location'].map((field) => (
                  <TextField
                    key={field}
                    name={field}
                    label={field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                    fullWidth
                    value={values[field]}
                    onChange={(e) => setFieldValue(field, e.target.value)}
                    error={touched[field] && Boolean(errors[field])}
                    helperText={touched[field] && errors[field]}
                    sx={fieldStyles}
                  />
                ))}
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="h6" mb={2} color="primary">Company Information</Typography>
                <TextField
                  fullWidth
                  name="companyName"
                  label="Company Name"
                  value={values.companyName}
                  onChange={(e) => setFieldValue('companyName', e.target.value)}
                  error={touched.companyName && Boolean(errors.companyName)}
                  helperText={touched.companyName && errors.companyName}
                  sx={fieldStyles}
                />
                <FormControl fullWidth sx={fieldStyles}>
                  <InputLabel>Industry</InputLabel>
                  <Select
                    name="industry"
                    value={values.industry}
                    onChange={(e) => setFieldValue('industry', e.target.value)}
                    label="Industry"
                    error={touched.industry && Boolean(errors.industry)}
                  >
                    {industries.map((option) => (
                      <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={fieldStyles}>
                  <InputLabel>Company Size</InputLabel>
                  <Select
                    name="companySize"
                    value={values.companySize}
                    onChange={(e) => setFieldValue('companySize', e.target.value)}
                    label="Company Size"
                    error={touched.companySize && Boolean(errors.companySize)}
                  >
                    {companySizes.map((option) => (
                      <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  name="companyWebsite"
                  label="Company Website"
                  fullWidth
                  value={values.companyWebsite}
                  onChange={(e) => setFieldValue('companyWebsite', e.target.value)}
                  error={touched.companyWebsite && Boolean(errors.companyWebsite)}
                  helperText={touched.companyWebsite && errors.companyWebsite}
                  sx={fieldStyles}
                />
                <FormControl fullWidth sx={fieldStyles}>
                  <InputLabel>Timezone</InputLabel>
                  <Select
                    name="timezone"
                    value={values.timezone}
                    onChange={(e) => setFieldValue('timezone', e.target.value)}
                    label="Timezone"
                    error={touched.timezone && Boolean(errors.timezone)}
                  >
                    {timezones.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option.replace(/_/g, ' ')}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: 'center', 
                  mt: 4 
                }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {/* Reset form logic */}}
                    sx={{
                      ...buttonStyles,
                      mr: isMobile ? 0 : 2,
                      mb: isMobile ? 2 : 0,
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || !dirty}
                    onClick={submitForm}
                    sx={{
                      ...buttonStyles,
                      backgroundColor: theme.palette.secondary.main,
                      color: theme.palette.secondary.contrastText,
                      '&:hover': {
                        backgroundColor: theme.palette.secondary.dark,
                      },
                    }}
                  >
                    {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Save Changes'}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ProfileSettings;