export const probabilityBasedStages = [
    { id: 'closed_lost', name: 'Closed Lost', minProbability: 0, maxProbability: 0 },
    { id: 'mutual_interest', name: 'Mutual Interest', minProbability: 0.01, maxProbability: 0.09 },
    { id: 'discovery', name: 'Discovery', minProbability: 0.1, maxProbability: 0.19 },
    { id: 'validate', name: 'Validate', minProbability: 0.2, maxProbability: 0.49 },
    { id: 'decide', name: 'Decide', minProbability: 0.5, maxProbability: 0.74 },
    { id: 'commit', name: 'Commit', minProbability: 0.75, maxProbability: 0.99 },
    { id: 'closed_won', name: 'Closed Won', minProbability: 1, maxProbability: 1 },
  ];
  
  export const getStageFromProbability = (probabilityString) => {
    // Convert the string probability to a number
    const probability = parseFloat(probabilityString);

    // Handle edge cases
    if (isNaN(probability)) {
      return probabilityBasedStages[0]; // Default to 'Closed Lost' if invalid input
    }

    if (probability === 0) {
      return probabilityBasedStages[0]; // 'Closed Lost'
    }

    if (probability === 1) {
      return probabilityBasedStages[probabilityBasedStages.length - 1]; // 'Closed Won'
    }

    // Find the matching stage for probabilities between 0 and 1
    const stage = probabilityBasedStages.find(
      stage => probability > stage.minProbability && probability <= stage.maxProbability
    );

    return stage || probabilityBasedStages[0]; // Default to 'Closed Lost' if no match
  };
  
  // Add this color mapping to the existing dealStages.js file
  export const stageColors = {
    'mutual_interest': '#BABDE2', // Periwinkle
    'discovery': '#FF916B', // Tangerine
    'validate': '#FF6666', // Bittersweet
    'decide': '#33D3B8', // Turquoise
    'commit': '#00B090', // Mint
    'closed_won': '#228B22', // Forest Green (unchanged)
    'closed_lost': '#FF0000', // Red (unchanged)
  };

  export const stageOrder = ['Mutual Interest', 'Discovery', 'Validate', 'Decide', 'Commit', 'Closed Won'];

  export const getStageInfo = (stage) => {
    const normalizedStage = stage.toLowerCase().replace(' ', '_');
    const color = stageColors[normalizedStage];
    
    if (!color) {
      console.warn(`No color found for stage: ${stage}`);
    }

    return {
      name: stage,
      color: color || '#999999' // Default gray if stage not found
    };
  };

  export const getCustomStageFromProbability = (probability) => {
    const numProbability = parseFloat(probability);
    if (isNaN(numProbability)) {
      console.warn(`Invalid probability: ${probability}`);
      return 'Unknown';
    }

    if (numProbability === 0) return 'Closed Lost';
    if (numProbability === 1) return 'Closed Won';
    if (numProbability <= 0.09) return 'Mutual Interest';
    if (numProbability <= 0.19) return 'Discovery';
    if (numProbability <= 0.49) return 'Validate';
    if (numProbability <= 0.74) return 'Decide';
    if (numProbability <= 0.99) return 'Commit';

    console.warn(`Unexpected probability value: ${numProbability}`);
    return 'Unknown';
  };