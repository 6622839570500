// src/components/healthcheck/DemoHealthcheck.jsx v1.0.0
import React from 'react'
import { Box, Button, Typography, Paper, Grid, LinearProgress, Stack, Tooltip, Alert } from '@mui/material'
import { styled } from '@mui/material/styles'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, ResponsiveContainer } from 'recharts'
import { 
  Warning as WarningIcon,
  CheckCircle, 
  Cancel,
  Storage,
  Report,
  QuestionAnswer,
  TrendingDown,
  AccessTime,
  BusinessCenter,
  Email,
  Group,
  FileDownload,
  InfoOutlined,
  Error as ErrorIcon,
  Info as InfoIcon,
} from '@mui/icons-material'
import { useDashboardTheme } from '../hooks/useDashboardTheme'
import { useHealthCheck } from './ContextProvider'
import ErrorDisplay from '../utils/ErrorDisplay'
import { Link } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { exportToPDF } from '../utils/pdfExport'

const HealthScore = styled(Typography)(({ theme, score }) => ({
  fontSize: '4rem',
  fontWeight: 700,
  color: score >= 70 ? theme.palette.success.main :
         score >= 40 ? theme.palette.warning.main :
         theme.palette.error.main,
  textShadow: theme.palette.mode === 'dark' ? '0 0 20px rgba(255,255,255,0.1)' : 'none',
}))

// Add this helper function at the top of the file
const groupBy = (array, key) => {
  return array.reduce((result, item) => {
    const group = item[key];
    result[group] = result[group] || [];
    result[group].push(item);
    return result;
  }, {});
};

// Add date formatter utility
const formatDate = (timestamp) => {
  if (!timestamp || timestamp < 0) return 'No data';
  return new Date(timestamp).toLocaleDateString();
};



// Add this validation helper at the top of the file
const validateMetrics = (metrics) => {
  if (!metrics) return false;
  
  const requiredFields = {
    dataHealth: ['contacts', 'deals'],
    accountAge: ['dataAge', 'inactiveMonths'],
    activityTrends: true,
    emailDeliverability: ['rate', 'bounceRate', 'spamRate'],
    duplicateRate: true
  };

  return Object.entries(requiredFields).every(([key, value]) => {
    if (Array.isArray(value)) {
      return value.every(field => metrics[key]?.[field] !== undefined);
    }
    return metrics[key] !== undefined;
  });
};

const AnalysisSection = ({ metrics, rawData, dashboardColors }) => {
  // Add console logging to debug
  console.log('Analysis Section Props:', { metrics, rawData });
  
  if (!validateMetrics(metrics) || !rawData) {
    console.log('Validation failed:', { 
      metricsValid: validateMetrics(metrics), 
      hasRawData: !!rawData 
    });
    return (
      <Alert 
        severity="warning"
        sx={{ mb: 2 }}
      >
        Unable to generate analysis due to incomplete data.
      </Alert>
    );
  }

  const analysisItems = [
    {
      category: 'Data Quality', 
      items: [
        {
          condition: metrics => metrics.dataHealth.contacts.percentComplete < 85,
          message: metrics => `${metrics.dataHealth.contacts.withNulls} contacts (${
            Math.round(100 - metrics.dataHealth.contacts.percentComplete)
          }%) lack complete profiles, limiting segmentation capabilities.`,
          severity: 'warning'
        },
        {
          condition: metrics => metrics.dataHealth.deals.percentComplete < 90,
          message: metrics => `${metrics.dataHealth.deals.withNulls} deals (${
            Math.round(100 - metrics.dataHealth.deals.percentComplete)
          }%) are missing critical fields, affecting forecasting accuracy.`,
          severity: 'warning'
        },
        {
          condition: metrics => metrics.dataHealth.deals.percentComplete > 95,
          message: () => `Excellent deal data quality maintained. Keep up the good work!`,
          severity: 'success'
        }
      ]
    },
    {
      category: 'Usage Patterns',
      items: [
        {
          condition: metrics => 
            metrics.aggregates.engagements.emailCount / metrics.dataHealth.contacts.total < 0.5,
          message: metrics => 
            `Low email engagement rate (${Math.round(metrics.aggregates.engagements.emailCount / metrics.dataHealth.contacts.total * 100)}%) with contacts suggests opportunity for more communication.`,
          severity: 'info'
        },
        {
          condition: metrics => 
            metrics.aggregates.engagements.callCount / metrics.dataHealth.contacts.total < 0.2,
          message: metrics => 
            `Consider increasing call activity to improve engagement with contacts.`,
          severity: 'info'
        }
      ]
    }
  ];

  // Add console logging for debugging
  console.log('Filtered Items:', 
    analysisItems.map(section => ({
      category: section.category,
      matchingItems: section.items.filter(item => item.condition(metrics)).length
    }))
  );

  return (
    <Stack spacing={4}>
      {analysisItems.map((section, idx) => {
        const matchingItems = section.items.filter(item => item.condition(metrics));
        
        // Only render sections that have matching items
        if (matchingItems.length === 0) return null;
        
        return (
          <Box key={idx}>
            <Typography 
              variant="h6" 
              sx={{ 
                mb: 2,
                color: dashboardColors.text.primary,
                fontWeight: 600 
              }}
            >
              {section.category}
            </Typography>
            <Stack spacing={2}>
              {matchingItems.map((item, itemIdx) => (
                <Alert 
                  key={itemIdx}
                  severity={item.severity}
                  icon={getSeverityIcon(item.severity)}
                  sx={{
                    bgcolor: `${dashboardColors.status[item.severity]}15`,
                    border: `1px solid ${dashboardColors.status[item.severity]}25`,
                    '& .MuiAlert-icon': {
                      color: dashboardColors.status[item.severity]
                    }
                  }}
                >
                  {item.message(metrics)}
                </Alert>
              ))}
            </Stack>
          </Box>
        );
      })}
    </Stack>
  );
};

const getSeverityIcon = (severity) => {
  switch (severity) {
    case 'error':
      return <ErrorIcon />;
    case 'warning':
      return <WarningIcon />;
    case 'info':
      return <InfoIcon />;
    default:
      return null;
  }
};

function HealthcheckDashboard() {
  const { userData } = useAuth()
  const { metrics: crmHealthMetrics, error, rawData } = useHealthCheck()
  const { engagements = [] } = rawData || {}

  // Access aggregates from crmHealthMetrics
  const aggregates = crmHealthMetrics?.aggregates || {}

  const { colors: dashboardColors, isDark } = useDashboardTheme()
  
  const isHubSpotConnected = userData?.hubspotConnected

  // Early return if HubSpot isn't connected
  if (!isHubSpotConnected) {
    return null
  }

  // Add data validation check
  if (!validateMetrics(crmHealthMetrics)) {
    return (
      <ErrorDisplay 
        error={new Error('Incomplete health metrics data')} 
        details="Some required metrics are missing or incomplete. Please ensure all data is properly loaded."
      />
    );
  }

  const {
    accountAge,
    dataHealth,
    activityTrends,
    emailDeliverability,
    duplicateRate
  } = crmHealthMetrics

  const getHealthScore = () => {
    try {
      const dealScore = dataHealth.deals.percentComplete || 0
      const contactScore = dataHealth.contacts.percentComplete || 0
      const activityScore = activityTrends[5]?.score || 0
      return Math.round((dealScore + contactScore + activityScore) / 3)
    } catch (err) {
      console.error('Error calculating health score:', err)
      return 0
    }
  }

  const healthScore = getHealthScore()

  const generateCriticalFindings = () => {
    const findings = [];
    
    // Ensure aggregates.engagements is defined
    const engagementsAggregates = aggregates.engagements || {};

    // CRM Usage Analysis
    if (!dataHealth.deals.total) {
      findings.push({
        icon: <BusinessCenter sx={{ fontSize: 16 }} />,
        message: "No deals have been created in HubSpot. This suggests the sales pipeline isn't being tracked effectively.",
        severity: 'error',
        category: 'CRM Usage'
      });
    }

    if (!dataHealth.contacts.total) {
      findings.push({
        icon: <Group sx={{ fontSize: 16 }} />,
        message: "No contacts exist in the CRM. This indicates HubSpot isn't being used for contact management.",
        severity: 'error',
        category: 'CRM Usage'
      });
    }

    // Engagement Analysis
    const totalEngagements = (engagementsAggregates.callCount || 0) + (engagementsAggregates.emailCount || 0);
    if (totalEngagements === 0) {
      findings.push({
        icon: <QuestionAnswer sx={{ fontSize: 16 }} />,
        message: "No engagement activities recorded. This suggests the team isn't logging their customer interactions.",
        severity: 'error',
        category: 'Engagement'
      });
    }

    // Deal Pipeline Analysis
    if (dataHealth.deals.percentComplete < 70) {
      findings.push({
        icon: <BusinessCenter sx={{ fontSize: 16 }} />,
        message: `${Math.round(100 - dataHealth.deals.percentComplete)}% of deals have incomplete data, potentially affecting pipeline accuracy.`,
        severity: 'warning',
        category: 'Data Quality'
      });
    }

    // Contact Data Analysis
    if (dataHealth.contacts.percentComplete < 70) {
      findings.push({
        icon: <Group sx={{ fontSize: 16 }} />,
        message: `${Math.round(100 - dataHealth.contacts.percentComplete)}% of contacts lack complete profiles, limiting segmentation capabilities.`,
        severity: 'warning',
        category: 'Data Quality'
      });
    }

    // Email Health Analysis
    if (emailDeliverability?.rate < 95) {
      findings.push({
        icon: <Email sx={{ fontSize: 16 }} />,
        message: `Email deliverability rate of ${Math.round(emailDeliverability.rate)}% may indicate list quality issues or sending practices concerns.`,
        severity: 'warning',
        category: 'Email Health'
      });
    }

    // Duplicate Analysis
    if (duplicateRate > 5) {
      findings.push({
        icon: <Group sx={{ fontSize: 16 }} />,
        message: `${Math.round(duplicateRate)}% duplicate contact rate suggests needed database cleanup and improved data entry processes.`,
        severity: 'warning',
        category: 'Data Quality'
      });
    }

    // Activity Trend Analysis
    const recentActivity = activityTrends[activityTrends.length - 1];
    const previousActivity = activityTrends[activityTrends.length - 2];
    if (recentActivity && previousActivity) {
      const activityChange = ((recentActivity.contacts - previousActivity.contacts) / previousActivity.contacts) * 100;
      if (activityChange < -20) {
        findings.push({
          icon: <TrendingDown sx={{ fontSize: 16 }} />,
          message: `${Math.abs(activityChange.toFixed(0))}% decrease in contact engagement indicates potential team adoption or process issues.`,
          severity: 'warning',
          category: 'Engagement'
        });
      }
    }

    // Engagement Freshness Analysis
    const lastEngagementDate = Math.max(
      ...engagements.map(e => e.timestamp || 0)
    );
    const daysSinceLastEngagement = Math.floor((Date.now() - lastEngagementDate) / (1000 * 60 * 60 * 24));
    if (daysSinceLastEngagement > 7) {
      findings.push({
        icon: <AccessTime sx={{ fontSize: 16 }} />,
        message: `No recorded engagements in ${daysSinceLastEngagement} days suggests possible tracking or adoption issues.`,
        severity: 'warning',
        category: 'Engagement'
      });
    }

    return findings;
  };
  

  // Add new section for Engagement Overview
  const renderEngagementOverview = () => (
    <Paper 
      elevation={0}
      sx={{ 
        p: 4,
        borderRadius: 2,
        bgcolor: dashboardColors.card.background,
        border: isDark ? `1px solid ${dashboardColors.card.border}` : 'none',
        mb: 3
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 4 }}>
        <Storage sx={{ color: dashboardColors.chart.primary, fontSize: 24 }} />
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          HubSpot CRM Objects Overview
        </Typography>
      </Box>
  
      <Grid container spacing={4}>
        <Grid item xs={12} md={3}>
          <Box sx={{ p: 2, borderRadius: 2, bgcolor: isDark ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,0.02)' }}>
            <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
              Total Calls
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 700 }}>
              {aggregates?.engagements?.callCount?.toLocaleString() ?? 0}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ p: 2, borderRadius: 2, bgcolor: isDark ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,0.02)' }}>
            <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
              Total Emails
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 700 }}>
              {aggregates?.engagements?.emailCount?.toLocaleString() ?? 0}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ p: 2, borderRadius: 2, bgcolor: isDark ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,0.02)' }}>
            <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
              Total Deals
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 700 }}>
              {dataHealth?.deals?.total?.toLocaleString() ?? 0}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ p: 2, borderRadius: 2, bgcolor: isDark ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,0.02)' }}>
            <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
              Total Contacts
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 700 }}>
              {dataHealth?.contacts?.total?.toLocaleString() ?? 0}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );

  const criticalFindings = generateCriticalFindings();

  const handleExport = async () => {
    await exportToPDF('diagnostic-content', 'CRM_Health_Diagnostic')
  }

  // Update the Critical Findings section
  const renderCriticalFindings = () => {
    const groupedFindings = groupBy(criticalFindings, 'category');
    
    return (
      <Grid container spacing={3}>
        {Object.entries(groupedFindings).map(([category, findings]) => (
          <Grid item xs={12} md={6} key={category}>
            <Box 
              sx={{ 
                p: 3,
                borderRadius: 2,
                bgcolor: isDark ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,0.02)',
                height: '100%'
              }}
            >
              <Typography 
                variant="h6" 
                sx={{ 
                  mb: 3, 
                  fontWeight: 600,
                  color: dashboardColors.text.primary,
                  borderBottom: `2px solid ${dashboardColors.chart.primary}`,
                  pb: 1
                }}
              >
                {category}
              </Typography>
              <Stack spacing={2.5}>
                {findings.map((finding, index) => (
                  <Box 
                    key={index}
                    sx={{ 
                      display: 'flex', 
                      gap: 2,
                      p: 2,
                      borderRadius: 1,
                      bgcolor: finding.severity === 'warning' 
                        ? `${dashboardColors.status.warning}15`
                        : `${dashboardColors.status.error}15`,
                      border: `1px solid ${finding.severity === 'warning' 
                        ? dashboardColors.status.warning 
                        : dashboardColors.status.error}25`
                    }}
                  >
                    <Box 
                      sx={{ 
                        color: finding.severity === 'warning' 
                          ? dashboardColors.status.warning 
                          : dashboardColors.status.error,
                        mt: '2px'
                      }}
                    >
                      {finding.icon}
                    </Box>
                    <Typography 
                      variant="body2"
                      sx={{ 
                        color: dashboardColors.text.primary,
                        fontWeight: 500,
                        flex: 1
                      }}
                    >
                      {finding.message}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderDataCompleteness = (percentComplete) => {
    if (percentComplete === null) {
      return (
        <Tooltip title="This feature may not be in use.">
          <InfoOutlined color="action" />
        </Tooltip>
      );
    } else if (percentComplete === 0) {
      return (
        <Tooltip title="Value may not be accurate.">
          <Typography variant="body2" sx={{ fontWeight: 500, color: 'warning.main' }}>
            {percentComplete}%
          </Typography>
        </Tooltip>
      );
    } else {
      return (
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {percentComplete}%
        </Typography>
      );
    }
  };

  const renderEmailMetrics = () => {
    const hasEmailData = emailDeliverability?.rate > 0 || 
                        emailDeliverability?.bounceRate > 0 || 
                        emailDeliverability?.spamRate > 0;
                        
    if (!hasEmailData) {
      return (
        <Box sx={{ textAlign: 'center', py: 4 }}>
          <Typography color="text.secondary">
            No email metrics available yet
          </Typography>
        </Box>
      );
    }

    return (
      <Box>
        <Typography variant="subtitle2" color="text.secondary">
          Email Health Metrics
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6">
              {emailDeliverability?.rate || 0}%
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Deliverability Rate
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6">
              {emailDeliverability?.bounceRate || 0}%
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Bounce Rate
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6">
              {emailDeliverability?.spamRate || 0}%
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Spam Rate
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderFieldCompleteness = (field, value) => {
    const getStatusColor = (percent) => {
      if (percent >= 90) return 'success';
      if (percent >= 60) return 'warning';
      return 'error';
    };

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {value >= 90 ? <CheckCircle color="success" /> : 
         value >= 60 ? <WarningIcon color="warning" /> : 
         <Cancel color="error" />}
        <Typography 
          variant="body2"
          color={`${getStatusColor(value)}.main`}
        >
          {field}: {value}% complete
        </Typography>
      </Box>
    );
  };

  const renderDataFreshness = () => {
    const months = accountAge?.inactiveMonths || 0;
    
    return (
      <Box>
        <Typography variant="subtitle2" color="text.secondary">
          Data Freshness
        </Typography>
        <Typography 
          variant="h5"
          color={months > 3 ? 'error.main' : 'text.primary'}
        >
          {months === 0 ? 'Active' : `${months} months`}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {months > 3 ? 'Needs attention' : 'since last update'}
        </Typography>
      </Box>
    );
  };

  return (
    <Box sx={{ 
      bgcolor: dashboardColors.background,
      p: 3 
    }}>
      <div id="diagnostic-content">
        <Box sx={{ 
          maxWidth: 'lg', 
          mx: 'auto',
          p: 4
        }}>
          {/* Header Section */}
          <Box sx={{ mb: 4 }}>
            {/* Title and Export Button Row */}
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              mb: 2 
            }}>
              <Typography 
                variant="h4" 
                sx={{ 
                  color: dashboardColors.text.primary,
                  fontWeight: 'bold'
                }}
              >
                HubSpot CRM Health Diagnostic
              </Typography>
              <Button
                variant="outlined"
                onClick={handleExport}
                startIcon={<FileDownload />}
              >
                Export PDF
              </Button>
            </Box>

            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Box display="flex" alignItems="center">
                  <HealthScore score={healthScore}>
                    {healthScore}
                  </HealthScore>
                  <Box ml={2}>
                    <Typography variant="body2" color="text.secondary">
                      Overall Health Score
                    </Typography>
                    <Typography variant="caption" color="text.tertiary">
                      Based on data quality & activity
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/* Warning Alert */}
          <Paper 
            elevation={0}
            sx={{ 
              p: 4,
              borderRadius: 2,
              bgcolor: dashboardColors.card.background,
              border: isDark ? `1px solid ${dashboardColors.card.border}` : 'none',
              transition: 'background-color 0.3s',
              '&:hover': {
                bgcolor: dashboardColors.card.hover
              }
            }}
          >
            <WarningIcon sx={{ color: dashboardColors.status.warning }} />
            <Typography variant="body2" color="text.primary">
              Your HubSpot account has shown minimal activity in the last {accountAge.inactiveMonths} months. 
              This might be impacting your sales pipeline visibility.
            </Typography>
          </Paper>

          {/* Add Engagement Overview section after the header */}
          {renderEngagementOverview()}

          {/* Data Health Cards */}
          <Grid container spacing={2} sx={{ mb: 4 }}>
            {/* Deals Card */}
            <Grid item xs={12} md={6}>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 4,
                  borderRadius: 2,
                  bgcolor: dashboardColors.card.background,
                  border: isDark ? `1px solid ${dashboardColors.card.border}` : 'none',
                  transition: 'background-color 0.3s',
                  '&:hover': {
                    bgcolor: dashboardColors.card.hover
                  }
                }}
              >
                {/* Header */}
                <Box 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 2, 
                    mb: 4 
                  }}
                >
                  <Storage 
                    sx={{ 
                      color: dashboardColors.chart.primary, 
                      fontSize: 20 
                    }} 
                  />
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      fontWeight: 600,
                      fontSize: '1.25rem',
                      color: 'text.primary'
                    }}
                  >
                    Deals Data Health
                  </Typography>
                </Box>

                {/* Progress Section */}
                <Box sx={{ mb: 4 }}>
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      alignItems: 'center',
                      mb: 1 
                    }}
                  >
                    <Typography 
                      variant="body2" 
                      sx={{ color: dashboardColors.text.secondary }}
                    >
                      Data Completeness
                    </Typography>
                    <Typography 
                      variant="body2" 
                      sx={{ fontWeight: 500 }}
                    >
                      {renderDataCompleteness(dataHealth.deals.percentComplete)}
                    </Typography>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={dataHealth.deals.percentComplete}
                    sx={{
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: dashboardColors.progressBar.background,
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: dashboardColors.progressBar.primary,
                        borderRadius: 4,
                      }
                    }}
                  />
                </Box>

                {/* Critical Fields Grid */}
                <Box 
                  sx={{ 
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: 1,
                    fontSize: '0.875rem'
                  }}
                >
                  {Object.entries(dataHealth.deals.criticalFields).map(([field, value]) => (
                    <Box 
                      key={field} 
                      sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 1 
                      }}
                    >
                      {value >= 50 ? (
                        <CheckCircle 
                          sx={{ 
                            color: dashboardColors.status.success,
                            fontSize: 16
                          }} 
                        />
                      ) : (
                        <Cancel 
                          sx={{ 
                            color: dashboardColors.status.error,
                            fontSize: 16
                          }} 
                        />
                      )}
                      <Typography variant="body2">
                        {field}: {value}% complete
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Paper>
            </Grid>

            {/* Contacts Card */}
            <Grid item xs={12} md={6}>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 4,
                  borderRadius: 2,
                  bgcolor: dashboardColors.card.background,
                  border: isDark ? `1px solid ${dashboardColors.card.border}` : 'none',
                  transition: 'background-color 0.3s',
                  '&:hover': {
                    bgcolor: dashboardColors.card.hover
                  }
                }}
              >
                {/* Header */}
                <Box 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 2, 
                    mb: 4 
                  }}
                >
                  <Storage 
                    sx={{ 
                      color: dashboardColors.chart.secondary, 
                      fontSize: 20 
                    }} 
                  />
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      fontWeight: 600,
                      fontSize: '1.25rem',
                      color: 'text.primary'
                    }}
                  >
                    Contacts Data Health
                  </Typography>
                </Box>

                {/* Progress Section */}
                <Box sx={{ mb: 4 }}>
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      alignItems: 'center',
                      mb: 1 
                    }}
                  >
                    <Typography 
                      variant="body2" 
                      sx={{ color: dashboardColors.text.secondary }}
                    >
                      Data Completeness
                    </Typography>
                    <Typography 
                      variant="body2" 
                      sx={{ fontWeight: 500 }}
                    >
                      {renderDataCompleteness(dataHealth.contacts.percentComplete)}
                    </Typography>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={dataHealth.contacts.percentComplete}
                    sx={{
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: dashboardColors.progressBar.background,
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: dashboardColors.progressBar.secondary,
                        borderRadius: 4,
                      }
                    }}
                  />
                </Box>

                {/* Critical Fields Grid */}
                <Box 
                  sx={{ 
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: 2,
                    mt: 3
                  }}
                >
                  {Object.entries(dataHealth.contacts.criticalFields).map(([field, value]) => (
                    <Box 
                      key={field} 
                      sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 1.5
                      }}
                    >
                      {value >= 50 ? (
                        <CheckCircle 
                          sx={{ 
                            color: dashboardColors.status.success,
                            fontSize: 16
                          }} 
                        />
                      ) : (
                        <Cancel 
                          sx={{ 
                            color: dashboardColors.status.error,
                            fontSize: 16
                          }} 
                        />
                      )}
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          color: 'text.primary',
                          fontWeight: 400
                        }}
                      >
                        {field}: {value}% complete
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Paper>
            </Grid>
          </Grid>

          {/* Analysis & Recommendations Section */}
          <Paper 
            elevation={0}
            sx={{ 
              p: 4,
              borderRadius: 2,
              bgcolor: dashboardColors.card.background,
              border: isDark ? `1px solid ${dashboardColors.card.border}` : 'none',
              mb: 3
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
              <Report sx={{ color: dashboardColors.status.error, fontSize: 20 }} />
              <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '1.25rem' }}>
                Analysis & Recommendations
              </Typography>
            </Box>
            
            <AnalysisSection 
              metrics={crmHealthMetrics} 
              rawData={rawData} 
              dashboardColors={dashboardColors}
            />
          </Paper>

          {/* Activity Trend Chart */}
          <Paper 
            elevation={0} 
            sx={{ 
              mt: 4,
              p: 4,
              borderRadius: 2,
              bgcolor: dashboardColors.card.background,
              border: isDark ? `1px solid ${dashboardColors.card.border}` : 'none',
            }}
          >
            <Box sx={{ height: 300 }}>
              <ResponsiveContainer>
                <BarChart data={activityTrends}>
                  <CartesianGrid 
                    strokeDasharray="3 3" 
                    stroke={isDark ? dashboardColors.chart.grid : 'rgba(0,0,0,0.1)'}
                  />
                  <XAxis 
                    dataKey="month" 
                    tick={{ fill: dashboardColors.text.secondary }}
                  />
                  <YAxis 
                    tick={{ fill: dashboardColors.text.secondary }}
                  />
                  <RechartsTooltip 
                    contentStyle={{
                      backgroundColor: dashboardColors.card.background,
                      border: `1px solid ${dashboardColors.card.border}`,
                      borderRadius: '8px',
                    }}
                  />
                  <Bar 
                    dataKey="deals" 
                    name="New Deals" 
                    fill={dashboardColors.chart.primary}
                    radius={[4, 4, 0, 0]}
                  />
                  <Bar 
                    dataKey="contacts" 
                    name="New Contacts" 
                    fill={dashboardColors.chart.secondary}
                    radius={[4, 4, 0, 0]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Paper>

          {/* CTA Section */}
          <Paper
            elevation={0}
            sx={{ 
              p: 8,
              mt: 4,
              borderRadius: 2,
              bgcolor: dashboardColors.cta.background,
              border: `1px solid ${dashboardColors.cta.border}`,
              textAlign: 'center',
              backdropFilter: isDark ? 'blur(8px)' : 'none',
            }}
          >
            <QuestionAnswer 
              sx={{ 
                color: dashboardColors.cta.text,
                fontSize: 48,
                mb: 4
              }} 
            />
            
            <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 700,
                fontSize: '1.875rem',
                color: dashboardColors.cta.text,
                mb: 2
              }}
            >
              Ready to Maximize Your HubSpot Investment?
            </Typography>
            
            <Typography 
              variant="body1" 
              sx={{ 
                color: dashboardColors.cta.text,
                mb: 6,
                maxWidth: '600px',
                mx: 'auto',
                lineHeight: 1.5
              }}
            >
              Our HubSpot certified consultants can help you implement best practices, 
              clean up your data, and establish processes that will make your CRM a 
              powerful driver of sales success.
            </Typography>

            <Link 
              to="/contact/sales" 
              style={{ textDecoration: 'none' }}
            >
              <Button
                variant="contained"
                size="large"
                sx={{
                  px: 8,
                  py: 2,
                  minWidth: '280px',
                  fontSize: '1.1rem',
                  bgcolor: dashboardColors.cta.button,
                  color: isDark ? '#000000' : '#FFFFFF',
                  '&:hover': {
                    bgcolor: dashboardColors.cta.buttonHover,
                  },
                  boxShadow: 'none',
                  textTransform: 'uppercase',
                  fontWeight: 600,
                  letterSpacing: '0.5px'
                }}
              >
                Sign Up for a Demo
              </Button>
            </Link>
          </Paper>
        </Box>
      </div>
    </Box>
  )
}

export default HealthcheckDashboard