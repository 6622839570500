import { useTheme, useMediaQuery } from '@mui/material';
import { getChartColor, getChartColorPair, getStageColor, stageColors } from '../charts/chartColors';
import { formatNumber, formatCurrency, formatPercentage } from '../utils/formatters';
import { alpha } from '@mui/material/styles';
import {
  turquoise,
  mint,
  persianGreen,
  melon,
  tangerine,
  periwinkle,
  coolGray,
  lightRed,
  bittersweet,
  skyBlue,
  steelBlue,
  dodgerBlue
} from '../../theme';
import CustomTooltip from '../charts/CustomTooltip';

export const useChartStyles = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getCurrentAndPreviousYear = () => {
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;
    return { currentYear, previousYear };
  };

  const baseTheme = {
    background: 'transparent',
    textColor: theme.palette.text.primary,
    fontSize: 11,
    axis: {
      domain: {
        line: {
          stroke: theme.palette.divider,
          strokeWidth: 1,
        },
      },
      legend: {
        text: {
          fontSize: 12,
          fill: theme.palette.text.primary,
        },
      },
      ticks: {
        line: {
          stroke: theme.palette.divider,
          strokeWidth: 1,
        },
        text: {
          fontSize: 11,
          fill: theme.palette.text.secondary,
        },
      },
    },
    grid: {
      line: {
        stroke: 'transparent',
        strokeWidth: 0,
      },
    },
    legends: {
      text: {
        fontSize: 12,
        fill: theme.palette.text.primary,
      },
    },
    tooltip: {
      container: {
        background: theme.palette.background.paper,
        color: theme.palette.text.primary,
        fontSize: 12,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[3],
      },
    },
    labels: {
      text: {
        fill: theme.palette.text.primary,
        fontSize: 12,
      },
    },
    dots: {
      text: {
        fill: theme.palette.text.primary,
        fontSize: 12,
      },
    },
  };

  const getCommonChartProperties = (chartType) => {
    const yScalePadding = isMobile ? 0.2 : 0;
  
    const commonProps = {
      margin: isMobile 
        ? { top: 10, right: 5, bottom: 5, left: 5 }
        : { top: 20, right: 20, bottom: 40, left: 60 }, // Adjusted margins
      theme: baseTheme,
      colors: ({ index }) => getChartColor(index, theme.palette.mode),
    };

    switch (chartType) {
      case 'line':
        return {
          ...commonProps,
          xScale: { type: 'point' },
          yScale: { 
            type: 'linear', 
            min: 'auto',
            max: 'auto',
            stacked: false, 
            reverse: false,
            padding: yScalePadding
          },
          curve: "monotoneX",
          pointSize: isMobile ? 0 : 8, // Reduced point size
          pointColor: { theme: 'background' },
          pointBorderWidth: 2,
          pointBorderColor: { from: 'serieColor' },
          pointLabelYOffset: -12,
          useMesh: true,
          enableGridX: false,
          enableGridY: true, // Enable horizontal grid lines
          gridYValues: 5, // Show 5 horizontal grid lines
        };
      case 'pie':
        return {
          ...commonProps,
          ...(isMobile ? getMobilePieChartProperties() : {
            margin: { top: 40, right: 80, bottom: 80, left: 80 },
            innerRadius: 0.5,
            padAngle: 0.7,
            cornerRadius: 3,
            activeOuterRadiusOffset: 8,
            arcLinkLabelsSkipAngle: 10,
            arcLinkLabelsTextColor: theme.palette.text.primary,
            arcLinkLabelsThickness: 2,
            arcLinkLabelsColor: { from: 'color' },
            arcLabelsSkipAngle: 10,
            arcLabelsTextColor: { from: 'color', modifiers: [['darker', 2]] },
            legends: [
              {
                anchor: 'bottom',
                direction: 'row', // Change to column for vertical layout
                justify: false,
                translateX: 0,
                translateY: 56,
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: theme.palette.text.primary,
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: 'circle',
              }
            ]
          }),
        };
      case 'heatmap':
        return {
          ...commonProps,
          margin: { top: 10, right: 10, bottom: 10, left: 10 },
          colors: {
            type: 'sequential',
            scheme: getHeatmapColorScale(),
          },
          axisTop: null,
          axisRight: null,
          axisBottom: null,
          axisLeft: null,
          enableLabels: false,
          cellOpacity: 1,
          cellBorderColor: { from: 'color', modifiers: [['darker', 0.4]] },
          labelTextColor: { from: 'color', modifiers: [['darker', 1.8]] },
          hoverTarget: "cell",
          cellHoverOthersOpacity: 0.25,
          animate: true,
          motionStiffness: 80,
          motionDamping: 9,
        };
      case 'scatter':
        const scatterMargin = isMobile 
          ? { top: 10, right: 10, bottom: 30, left: 40 }
          : { top: 20, right: 20, bottom: 50, left: 60 };
        return {
          ...commonProps,
          margin: scatterMargin,
          xScale: { 
            type: 'linear', 
            min: 'auto', 
            max: 'auto',
            padding: isMobile ? 0.03 : 0
          },
          yScale: { 
            type: 'linear', 
            min: 'auto', 
            max: 'auto',
            padding: isMobile ? 0.03: 0
          },
          nodeSize: { min: 8, max: 20 },
          colors: [theme.palette.mode === 'dark' ? turquoise : mint],
          blendMode: 'normal',
          enableGridX: false,
          enableGridY: false,
          axisBottom: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: isMobile ? '' : 'Days in Pipeline',
            legendPosition: 'middle',
            legendOffset: 40,
            format: isMobile ? () => '' : value => formatNumber(value, { notation: 'compact' }),
          },
          axisLeft: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: isMobile ? '' : 'Deal Size',
            legendPosition: 'middle',
            legendOffset: -50,
            format: isMobile ? () => '' : value => formatCurrency(value, { notation: 'compact', maximumFractionDigits: 0 })
          },
        };
      case 'treemap':
        return {
          ...commonProps,
          margin: { top: 10, right: 10, bottom: 10, left: 10 },
          colors: { scheme: 'nivo' }, // Use Nivo's built-in color scheme
          borderColor: { from: 'color', modifiers: [['darker', 0.3]] },
          labelSkipSize: 12,
          labelTextColor: { from: 'color', modifiers: [['darker', 2]] },
          parentLabelPosition: 'left',
          parentLabelTextColor: { from: 'color', modifiers: [['darker', 2]] },
          nodeOpacity: 1,
          borderWidth: 2,
          animate: true,
          motionConfig: 'gentle',
        };
      case 'bar':
        return {
          ...commonProps,
          margin: isMobile 
            ? { top: 10, right: 10, bottom: 50, left: 50 } // Increased bottom margin for mobile
            : { top: 20, right: 20, bottom: 70, left: 60 }, // Increased bottom margin for desktop
          padding: 0.3,
          labelSkipWidth: 16,
          labelSkipHeight: 16,
          labelTextColor: { from: 'color', modifiers: [['darker', 1.6]] },
          axisBottom: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -45,
            legend: 'Sales Representatives',
            legendPosition: 'middle',
            legendOffset: 60 // Increased offset to accommodate rotated labels
          },
          axisLeft: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Revenue',
            legendPosition: 'middle',
            legendOffset: -50
          },
          legends: getLegendConfig(),
        };
      default:
        return commonProps;
    }
  };

  const getMobilePieChartProperties = () => ({
    margin: { top: 10, right: 10, bottom: 10, left: 10 },
    innerRadius: 0.6,
    padAngle: 0.5,
    cornerRadius: 3,
    activeOuterRadiusOffset: 4,
    arcLinkLabelsSkipAngle: 10,
    arcLinkLabelsTextColor: theme.palette.text.primary,
    arcLinkLabelsThickness: 2,
    arcLinkLabelsColor: { from: 'color' },
    arcLabelsSkipAngle: 10,
    arcLabelsTextColor: { from: 'color', modifiers: [['darker', 2]] },
    legends: [] // Remove legends on mobile
  });

  const getAxisConfig = (xLegend, yLegend, dataType = 'number') => ({
    axisTop: null,
    axisRight: null,
    axisBottom: null,
    axisLeft: null,
  });

  const getLegendConfig = () => {
    const { currentYear, previousYear } = getCurrentAndPreviousYear();

    return isMobile ? [] : [
      {
        anchor: 'top-right',
        direction: 'column', // Change to column for vertical layout
        justify: false,
        translateX: 10, // Adjust to move legend left
        translateY: 0, // Adjust to move legend down
        itemsSpacing: 2, // Reduce spacing between items
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1
            }
          }
        ],
        data: [
          {
            id: currentYear.toString(),
            label: currentYear.toString(),
            color: getChartColor(0, theme.palette.mode),
          },
          {
            id: previousYear.toString(),
            label: previousYear.toString(),
            color: getChartColor(1, theme.palette.mode),
          }
        ],
      }
    ];
  };

  const getTooltipStyles = () => ({
    container: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.divider}`,
      boxShadow: theme.shadows[3],
      fontFamily: theme.typography.fontFamily,
      padding: theme.spacing(1),
      fontSize: theme.typography.fontSize,
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: theme.spacing(1),
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
    },
    item: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(0.5),
    },
    label: {
      marginRight: theme.spacing(1),
    },
    value: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  });

  const getYAxisFormatter = (dataType) => {
    switch (dataType) {
      case 'currency':
        return (value) => formatCurrency(value, { notation: 'compact', maximumFractionDigits: 0 });
      case 'percentage':
        return (value) => formatPercentage(value, { maximumFractionDigits: 1 });
      case 'number':
      default:
        return (value) => formatNumber(value, { notation: 'compact', maximumFractionDigits: 0 });
    }
  };

  const getHeatmapColorScale = () => {
    return {
      type: 'sequential',
      scheme: 'blues'
    };
  };

  const getScatterPlotStyles = () => ({
    nodeSize: { min: 8, max: 20 },
    colors: [theme.palette.mode === 'dark' ? turquoise : mint],
  });

  const getColor = (node) => {
    if (!node || !node.data) return theme.palette.mode === 'dark' ? turquoise : mint;
    
    const baseColor = theme.palette.mode === 'dark' ? turquoise : mint;
    const probability = parseFloat(node.data.probability) / 100 || 0;
    
    // Create a gradient from white to the base color
    const r = Math.round(255 - (255 - parseInt(baseColor.slice(1, 3), 16)) * probability);
    const g = Math.round(255 - (255 - parseInt(baseColor.slice(3, 5), 16)) * probability);
    const b = Math.round(255 - (255 - parseInt(baseColor.slice(5, 7), 16)) * probability);
    
    return `rgb(${r}, ${g}, ${b})`;
  };

  const getTableStyles = () => ({
    table: {
      '& .MuiTableCell-root': {
        padding: theme.spacing(1.5),
      },
    },
    tableHead: {
      '& .MuiTableCell-root': {
        fontWeight: theme.typography.fontWeightBold,
        backgroundColor: theme.palette.background.default,
      },
    },
    tableRow: {
      '&:nth-of-type(odd)': {
        backgroundColor: alpha(theme.palette.primary.main, 0.05),
      },
    },
    statusPill: {
      display: 'inline-block',
      padding: theme.spacing(0.5, 1),
      borderRadius: 12,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: '0.75rem',
      textAlign: 'center',
      minWidth: 80,
    },
    probabilityBar: {
      height: 4,
      borderRadius: 2,
      backgroundColor: theme.palette.grey[200],
      '& .MuiLinearProgress-bar': {
        backgroundColor: theme.palette.mode === 'dark' ? turquoise : mint,
      },
    },
  });

  const getStatusColor = (status) => {
    const mode = theme.palette.mode;
    switch (status) {
      case 'Closing Soon':
        return stageColors[mode]['16602271']; // Using the last stage color for 'Closing Soon'
      case 'Closing This Month':
        return stageColors[mode]['16602270']; // Using the second to last stage color for 'Closing This Month'
      case 'Open':
      default:
        return stageColors[mode]['16602267']; // Using the first stage color for 'Open'
    }
  };

  const getCustomTooltip = (tooltipProps) => (
    <CustomTooltip
      {...tooltipProps}
      formatValue={(value, formatType) => {
        switch (formatType) {
          case 'currency':
            return formatCurrency(value, { notation: 'compact' });
          case 'number':
            return formatNumber(value);
          case 'percentage':
            return formatPercentage(value);
          default:
            return value;
        }
      }}
    />
  );

  // New function for stacked bar color pairing
  const getStackedBarColors = () => {
    const { currentYear, previousYear } = getCurrentAndPreviousYear();
    const isDarkMode = theme.palette.mode === 'dark';

    return ({ id }) => {
      if (id === previousYear.toString()) {
        return isDarkMode ? melon : tangerine;
      } else if (id === currentYear.toString()) {
        return isDarkMode ? turquoise : mint;
      }
      return isDarkMode ? turquoise : dodgerBlue; // Fallback color
    };
  };

  const getWinRateColor = (winRate) => {
    const isDarkMode = theme.palette.mode === 'dark';

    if (winRate < 10) {
      return isDarkMode ? bittersweet : lightRed;
    } else if (winRate >= 10 && winRate < 30) {
      return isDarkMode ? tangerine : melon;
    } else {
      return isDarkMode ? turquoise : mint;
    }
  };

  const getEngagementScoreColor = (engagementScore) => {
    const isDarkMode = theme.palette.mode === 'dark';

    if (engagementScore >= 90) {
      return isDarkMode ? turquoise : mint;
    } else if (engagementScore >= 70) {
      return isDarkMode ? tangerine : melon;
    } else {
      return isDarkMode ? bittersweet : lightRed;
    }
  };

  const getRepPerformanceChartProperties = (chartType) => {
    // Determine margins and axis labels based on chartType
    let adjustedMargin = { top: 20, right: 20, bottom: 20, left: 80 };
    let axisBottom = {};
    if (chartType === 'winRate' || chartType === 'averageDealSize') {
      adjustedMargin.left = 30; // Less left margin
      axisBottom = null; // No x-axis labels
    }

    return {
      margin: adjustedMargin,
      layout: 'horizontal',
      valueScale: { type: 'linear' },
      indexScale: { type: 'band', round: true },
      padding: 0.3,
      minValue: 0,
      maxValue: chartType === 'winRate' ? 100 : 'auto',
      valueFormat: (value) => {
        switch (chartType) {
          case 'winRate':
            return `${value.toFixed(1)}%`;
          case 'currency':
            return `$${value.toLocaleString()}`;
          default:
            return value.toLocaleString();
        }
      },
      colors: ({ id, data }) => {
        if (chartType === 'winRate') {
          return getWinRateColor(data.winRate);
        }
        return theme.palette.mode === 'dark' ? turquoise : mint;
      },
      borderColor: { from: 'color', modifiers: [['darker', 1.6]] },
      axisTop: null,
      axisRight: null,
      axisBottom: axisBottom,
      axisLeft: {
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        format: (value) => `#${value}`,
        truncateTickAt: 0, // This will prevent tick labels from being cut off
      },
      enableGridX: chartType !== 'averageDealSize',
      enableGridY: false,
      labelSkipWidth: 12,
      labelSkipHeight: 12,
      labelTextColor: theme.palette.text.primary,
      enableLabels: false,
      legends: [],
      role: 'application',
      isInteractive: true,
      barAriaLabel: function(e) { return `${e.indexValue}: ${e.value}` },
      layers: ['grid', 'bars', 'axes', 'markers', 'legends', 'annotations'],
      theme: {
        ...baseTheme,
        axis: {
          ...baseTheme.axis,
          ticks: {
            ...baseTheme.axis.ticks,
            text: {
              ...baseTheme.axis.ticks.text,
              fontSize: 12,
              fill: theme.palette.text.primary,
            },
          },
        },
      },
    };
  };

  return {
    getCommonChartProperties,
    getAxisConfig,
    getLegendConfig,
    getTooltipStyles,
    getYAxisFormatter,
    isMobile,
    getCurrentAndPreviousYear,
    getChartColor: (index) => getChartColor(index, theme.palette.mode),
    getChartColorPair: (index) => getChartColorPair(index, theme.palette.mode),
    getMobilePieChartProperties,
    getHeatmapColorScale,
    getScatterPlotStyles,
    getTableStyles,
    getStageColor: (stageId) => getStageColor(stageId, theme.palette.mode),
    getStatusColor,
    getTreemapColors: () => ({ scheme: 'nivo' }),
    getColor,
    getCustomTooltip,
    getStackedBarColors,
    getRepPerformanceChartProperties,
    getWinRateColor,
    getEngagementScoreColor,
  };
};