import React, { useState } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  useTheme, 
  useMediaQuery 
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AppAppBar from '../home/AppAppBar';
import Footer from '../global/Footer';
import CallToAction from '../home/CallToAction';
import SignupModal from '../common/SignupModal';

const features = [
  { name: 'Real-time Pipeline Visibility', hubspot: true, truepipeFree: true, truepipePaid: true },
  { name: 'Deal Tracking and Analytics', hubspot: true, truepipeFree: true, truepipePaid: true },
  { name: 'AI-Powered Insights', hubspot: false, truepipeFree: true, truepipePaid: true },
  { name: 'Custom Dashboards', hubspot: false, truepipeFree: true, truepipePaid: true },
  { name: 'Advanced Forecasting', hubspot: false, truepipeFree: true, truepipePaid: true },
  { name: 'CRM Healthcheck', hubspot: false, truepipeFree: true, truepipePaid: true },
  { name: 'Automated Insights', hubspot: false, truepipeFree: true, truepipePaid: true },
  { name: 'Seamless HubSpot Integration', hubspot: true, truepipeFree: true, truepipePaid: true },
  { name: 'Customizable Reporting', hubspot: false, truepipeFree: true, truepipePaid: true },
  { name: 'Unlimited HubSpot Refreshes', hubspot: false, truepipeFree: false, truepipePaid: true },
  { name: 'Custom Branding and White Labeling', hubspot: false, truepipeFree: false, truepipePaid: true },
  { name: 'Advanced Export Options (PDF, Excel)', hubspot: false, truepipeFree: false, truepipePaid: true },
  { name: 'Priority Support', hubspot: false, truepipeFree: false, truepipePaid: true },
];

const ComparisonPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);

  const CheckIcon = () => <CheckCircleIcon sx={{ color: theme.palette.success.main }} />;
  const CrossIcon = () => <CancelIcon sx={{ color: theme.palette.error.main }} />;

  const handleOpenSignupModal = () => {
    setIsSignupModalOpen(true);
  };

  const handleCloseSignupModal = () => {
    setIsSignupModalOpen(false);
  };

  return (
    <>
      <AppAppBar onOpenSignupModal={handleOpenSignupModal} />
      <Box sx={{ 
        pt: { xs: 12, sm: 14, md: 16 }, // Increased top padding, especially for mobile
        pb: { xs: 8, sm: 12 }, 
        backgroundColor: theme.palette.background.default 
      }}>
        <Container maxWidth="lg">
          <Typography variant="h2" sx={{ mb: 6, textAlign: 'center', fontWeight: 700 }}>
            Truepipe vs HubSpot
          </Typography>
          <Typography variant="body1" sx={{ mb: 4, textAlign: 'center' }}>
            See how Truepipe stacks up against HubSpot's Sales Hub Professional ($890/month for 5 users). 
            Our free version offers powerful features, while our $15/month plan provides even more value.
          </Typography>
          <TableContainer component={Paper} elevation={3}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Feature</TableCell>
                  <TableCell align="center">HubSpot Sales Hub Professional</TableCell>
                  <TableCell align="center">Truepipe (Free)</TableCell>
                  <TableCell align="center">Truepipe ($15/mo)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {features.map((feature) => (
                  <TableRow key={feature.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">{feature.name}</TableCell>
                    <TableCell align="center">{feature.hubspot ? <CheckIcon /> : <CrossIcon />}</TableCell>
                    <TableCell align="center">{feature.truepipeFree ? <CheckIcon /> : <CrossIcon />}</TableCell>
                    <TableCell align="center">{feature.truepipePaid ? <CheckIcon /> : <CrossIcon />}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography variant="body2" sx={{ mt: 4, textAlign: 'center', fontStyle: 'italic' }}>
            * Features may vary. Please check our detailed pricing page for the most up-to-date information.
          </Typography>
        </Container>
      </Box>
      <CallToAction onOpenSignupModal={handleOpenSignupModal} />
      <Footer />
      <SignupModal open={isSignupModalOpen} onClose={handleCloseSignupModal} />
    </>
  );
};

export default ComparisonPage;